import { IAiTokenUsage } from "./interfaces/models";

export enum AiModelType {
    LLM = "llm",
    EMBEDDING = "embedding"
}

export enum AiModel {
    GPT_35_TURBO = "gpt-3.5-turbo",
    GPT_4 = "gpt-4",
    CLAUDE_INSTANT_V11 = "claude-instant-v1.1",
    CLAUDE_INSTANT_V11_100K = "claude-instant-v1.1-100k",
    CLAUDE_V13 = "claude-v1.3",
    CLAUDE_V13_100K = "claude-v1.3-100k",
    CLAUDE_V2 = "claude-2.0",
    CLAUDE_V3_OPUS = "claude-3-opus-20240229",
    CLAUDE_V3_SONNET = "claude-3-sonnet-20240229",
    CLAUDE_V3_HAIKU = "claude-3-haiku-20240307",
    TEXT_DAVINCI_003 = "text-davinci-003",
    TEXT_EMBEDDING_ADA_002 = "text-embedding-ada-002",
    TEXT_EMBEDDING_3_SMALL = "text-embedding-3-small"
}

export enum AiProvider {
    OPENAI = "openAI",
    OPENAI_CHAT = "openAIChat",
    ANTHROPIC = "anthropic"
}

export const AiModelProvider: Record<AiModel, AiProvider> = {
    [AiModel.GPT_35_TURBO]: AiProvider.OPENAI,
    [AiModel.GPT_4]: AiProvider.OPENAI_CHAT,
    [AiModel.CLAUDE_INSTANT_V11]: AiProvider.ANTHROPIC,
    [AiModel.CLAUDE_INSTANT_V11_100K]: AiProvider.ANTHROPIC,
    [AiModel.CLAUDE_V13]: AiProvider.ANTHROPIC,
    [AiModel.CLAUDE_V13_100K]: AiProvider.ANTHROPIC,
    [AiModel.CLAUDE_V2]: AiProvider.ANTHROPIC,
    [AiModel.CLAUDE_V3_OPUS]: AiProvider.ANTHROPIC,
    [AiModel.CLAUDE_V3_SONNET]: AiProvider.ANTHROPIC,
    [AiModel.CLAUDE_V3_HAIKU]: AiProvider.ANTHROPIC,
    [AiModel.TEXT_DAVINCI_003]: AiProvider.OPENAI,
    [AiModel.TEXT_EMBEDDING_ADA_002]: AiProvider.OPENAI,
    [AiModel.TEXT_EMBEDDING_3_SMALL]: AiProvider.OPENAI
};

export interface TraceData {
    uid?: string,
    workspaceId?: string,
    request?: {
        id: string,
        type: AiGenerativeRequestType
    },
    parentRequest?: {
        id: string,
        type: AiGenerativeRequestType
    }
}

export enum AiTokenUsageReportByModelsType {
    LAST_HOUR = "LAST_HOUR",
    LAST_24_HOURS = "LAST_24_HOURS",
    LAST_30_DAYS = "LAST_30_DAYS"
}

export interface AiTokenUsageReportByModelsRecord {
    date: string,
    models: [
        {
            model: AiModel,
            requestCount: number,
            tokenCount: number,
            totalCost: number
        }
    ]
}

export interface AiTokenUsageReportByPresentationsRecord {
    createdAt: number
    initialRequestId: string
    requestId: string
    uid: string
    userEmail: string
    outline: {
        models: {
            model: AiModel,
            promptTokenCount: number,
            completionTokenCount: number,
            totalCost: number
        }[]
    },
    slides: {
        models: {
            model: AiModel,
            promptTokenCount: number,
            completionTokenCount: number,
            totalCost: number
        }[],
        slideCount: number
    },
    models: {
        model: AiModel,
        promptTokenCount: number,
        completionTokenCount: number,
        totalCost: number
    }[]
}

export type TokenUsageRecord = Omit<IAiTokenUsage, "id" | "createdAt" | "modifiedAt">;

export const TokenPrice = {
    [AiProvider.OPENAI]: {
        [AiModelType.LLM]: {
            [AiModel.GPT_35_TURBO]: {
                prompt: 0.002 / 1000,
                completion: 0.002 / 1000
            },
            [AiModel.TEXT_DAVINCI_003]: {
                prompt: 0.02 / 1000,
                completion: 0.02 / 1000
            }
        },
        [AiModelType.EMBEDDING]: {
            [AiModel.TEXT_EMBEDDING_ADA_002]: {
                prompt: 0.0004 / 1000,
                completion: 0.0004 / 1000
            },
            [AiModel.TEXT_EMBEDDING_3_SMALL]: {
                prompt: 0.00002 / 1000,
                completion: 0.00002 / 1000
            }
        }
    },
    [AiProvider.OPENAI_CHAT]: {
        [AiModelType.LLM]: {
            [AiModel.GPT_4]: {
                prompt: 0.03 / 1000,
                completion: 0.06 / 1000
            }
        },
    },
    [AiProvider.ANTHROPIC]: {
        [AiModelType.LLM]: {
            [AiModel.CLAUDE_INSTANT_V11]: {
                prompt: 1.63 / 1000000,
                completion: 5.51 / 1000000
            },
            [AiModel.CLAUDE_INSTANT_V11_100K]: {
                prompt: 1.63 / 1000000,
                completion: 5.51 / 1000000
            },
            [AiModel.CLAUDE_V13]: {
                prompt: 11.02 / 1000000,
                completion: 32.68 / 1000000
            },
            [AiModel.CLAUDE_V13_100K]: {
                prompt: 11.02 / 1000000,
                completion: 32.68 / 1000000
            },
            [AiModel.CLAUDE_V2]: {
                prompt: 11.02 / 1000000,
                completion: 32.68 / 1000000
            },
            [AiModel.CLAUDE_V3_OPUS]: {
                prompt: 15 / 1000000,
                completion: 75 / 1000000
            },
            [AiModel.CLAUDE_V3_SONNET]: {
                prompt: 3 / 1000000,
                completion: 15 / 1000000
            },
            [AiModel.CLAUDE_V3_HAIKU]: {
                prompt: 0.25 / 1000000,
                completion: 1.25 / 1000000
            }
        }
    }
};

export enum AiGenerativeRequestType {
    GENERATE_PRESENTATION_OUTLINE = "generate-presentation-outline",
    GENERATE_SLIDE = "generate-slide",
    REWRITE_TEXT = "rewrite-text",
    GENERATE_WORDCLOUD = "generate-wordcloud",
    GENERATE_ITEM = "generate-item",
    ANALYZE_WEBPAGE = "analyze-webpage",
    GENERATE_IMAGES = "generate-image",
    GENERATE_SLIDE_NOTES = "generate-slide-notes"
}

export enum AiGeneratedSlideType {
    TITLE = "title",
    TEXT_WITH_IMAGE = "text-with-image",
    BULLET_LIST = "bullet-list",
    TIMELINE = "timeline-diagram",
    TEAM_MEMBERS = "team-members",
    TABLE = "table",
    PROCESS = "process-diagram",
    ORDERED_LIST = "numbered-list",
    COMPANY_INFO = "basic-company-info",
    IMAGES_WITH_TEXT = "images-with-text",
    PHOTOS = "photo-collage",
    QUOTE = "quote",
    CHART = "chart",
    COMPANY_LOGOS = "company-logos",
    BIOGRAPHY = "biography",
    VENN_DIAGRAM = "venn-diagram",
    COMPARISON = "comparison-diagram",
    AGENDA = "agenda",
    BOXES_WITH_TEXT = "boxes-with-text",
    ARROW_BARS = "arrow-bars",
    THERMOMETER = "thermometer",
    X_Y_PLOT = "x-y-plot",
    FUNNEL = "funnel",
    WORDCLOUD = "wordcloud",
    SWOT_DIAGRAM = "swot-diagram",
    ARROW_CYCLES = "arrow-cycles",
    CYCLE = "cycle",
    HUB_AND_SPOKE = "hub-and-spoke",
    TARGET = "target",
    JOURNEY = "journey",
    GANTT_CHART = "gantt-chart",
}

export enum AiGeneratedSlideGroupType {
    ALL_BUT_TITLE = "All but title",
    LIST = "list",
    STEPS = "steps",
    CHART = "chart",
    TABLE = "table",
    DIAGRAM = "diagram",
    IMAGES = "images",
    TEXT = "text",
    PEOPLE = "people",
    COMPARISON = "comparison",
    WORDCLOUD = "Word Cloud",
    CYCLES = "Cycles",
    FUNNEL = "Funnel",
    TIMELINE = "Timeline",
    GANTT_CHART = "Gantt Chart",
    PRESENTATION = "presentation"
}

export const AiGeneratedSlideTypeGroups: Record<AiGeneratedSlideGroupType, AiGeneratedSlideType[]> = {
    [AiGeneratedSlideGroupType.ALL_BUT_TITLE]: Object.values(AiGeneratedSlideType).filter(type => type !== AiGeneratedSlideType.TITLE),
    [AiGeneratedSlideGroupType.PRESENTATION]: [
        AiGeneratedSlideType.TEXT_WITH_IMAGE,
        AiGeneratedSlideType.BULLET_LIST,
        AiGeneratedSlideType.ORDERED_LIST,
        AiGeneratedSlideType.IMAGES_WITH_TEXT,
        AiGeneratedSlideType.BOXES_WITH_TEXT,
        AiGeneratedSlideType.ARROW_BARS,
        AiGeneratedSlideType.ORDERED_LIST,
        AiGeneratedSlideType.PROCESS,
        AiGeneratedSlideType.TIMELINE,
        AiGeneratedSlideType.FUNNEL,
        AiGeneratedSlideType.TABLE,
        AiGeneratedSlideType.COMPARISON,
        AiGeneratedSlideType.COMPANY_LOGOS,
        AiGeneratedSlideType.QUOTE,
        AiGeneratedSlideType.BIOGRAPHY,
        AiGeneratedSlideType.COMPANY_INFO,
        AiGeneratedSlideType.TEAM_MEMBERS,
        AiGeneratedSlideType.BIOGRAPHY,
        AiGeneratedSlideType.WORDCLOUD,
        AiGeneratedSlideType.TARGET,
        AiGeneratedSlideType.JOURNEY
    ],
    [AiGeneratedSlideGroupType.LIST]: [
        AiGeneratedSlideType.BULLET_LIST,
        AiGeneratedSlideType.ORDERED_LIST,
        AiGeneratedSlideType.IMAGES_WITH_TEXT,
        AiGeneratedSlideType.BOXES_WITH_TEXT,
        AiGeneratedSlideType.ARROW_BARS,
        AiGeneratedSlideType.SWOT_DIAGRAM
    ],
    [AiGeneratedSlideGroupType.STEPS]: [
        AiGeneratedSlideType.ORDERED_LIST,
        AiGeneratedSlideType.PROCESS,
        AiGeneratedSlideType.TIMELINE,
        AiGeneratedSlideType.FUNNEL,
        AiGeneratedSlideType.TARGET,
        AiGeneratedSlideType.HUB_AND_SPOKE,
        AiGeneratedSlideType.CYCLE,
        AiGeneratedSlideType.ARROW_CYCLES,
        AiGeneratedSlideType.JOURNEY
    ],
    [AiGeneratedSlideGroupType.CHART]: [
        AiGeneratedSlideType.X_Y_PLOT,
        AiGeneratedSlideType.CHART
    ],
    [AiGeneratedSlideGroupType.COMPARISON]: [
        AiGeneratedSlideType.COMPARISON,
        AiGeneratedSlideType.X_Y_PLOT,
        AiGeneratedSlideType.SWOT_DIAGRAM
    ],
    [AiGeneratedSlideGroupType.TABLE]: [
        AiGeneratedSlideType.TABLE
    ],
    [AiGeneratedSlideGroupType.DIAGRAM]: [
        AiGeneratedSlideType.TIMELINE,
        AiGeneratedSlideType.PROCESS,
        AiGeneratedSlideType.VENN_DIAGRAM,
        AiGeneratedSlideType.THERMOMETER,
        AiGeneratedSlideType.FUNNEL,
        AiGeneratedSlideType.SWOT_DIAGRAM,
        AiGeneratedSlideType.HUB_AND_SPOKE,
        AiGeneratedSlideType.CYCLE,
        AiGeneratedSlideType.ARROW_CYCLES,
        AiGeneratedSlideType.TARGET,
        AiGeneratedSlideType.JOURNEY
    ],
    [AiGeneratedSlideGroupType.IMAGES]: [
        AiGeneratedSlideType.PHOTOS,
        AiGeneratedSlideType.COMPANY_LOGOS,
        AiGeneratedSlideType.IMAGES_WITH_TEXT
    ],
    [AiGeneratedSlideGroupType.TEXT]: [
        AiGeneratedSlideType.QUOTE,
        AiGeneratedSlideType.BIOGRAPHY,
        AiGeneratedSlideType.TEXT_WITH_IMAGE,
        AiGeneratedSlideType.IMAGES_WITH_TEXT,
        AiGeneratedSlideType.COMPANY_INFO,
        AiGeneratedSlideType.BOXES_WITH_TEXT,
        AiGeneratedSlideType.SWOT_DIAGRAM
    ],
    [AiGeneratedSlideGroupType.PEOPLE]: [
        AiGeneratedSlideType.TEAM_MEMBERS,
        AiGeneratedSlideType.BIOGRAPHY
    ],
    [AiGeneratedSlideGroupType.WORDCLOUD]: [
        AiGeneratedSlideType.WORDCLOUD
    ],
    [AiGeneratedSlideGroupType.CYCLES]: [
        AiGeneratedSlideType.HUB_AND_SPOKE,
        AiGeneratedSlideType.CYCLE,
        AiGeneratedSlideType.ARROW_CYCLES
    ],
    [AiGeneratedSlideGroupType.FUNNEL]: [
        AiGeneratedSlideType.FUNNEL,
        AiGeneratedSlideType.TARGET
    ],
    [AiGeneratedSlideGroupType.TIMELINE]: [
        AiGeneratedSlideType.TIMELINE,
        AiGeneratedSlideType.JOURNEY
    ],
    [AiGeneratedSlideGroupType.GANTT_CHART]: [
        AiGeneratedSlideType.GANTT_CHART
    ]
};

export interface GeneratePresentationOutlineRequest {
    prompt?: string
    allowedSlideTypes: AiGeneratedSlideType[]
    tempAssetFileNames?: string[]
    contextUrls?: string[]
    indexFileName?: string
    traceData?: TraceData
}

export interface GeneratePresentationOutlineResponse {
    title: string,
    slides: [{
        title: string
        summary: string
        type: AiGeneratedSlideType
    }],
    indexFileName?: string,
    traceData?: TraceData,
    subject?: string,
    about?: string,
    realWorldEntity?: boolean,
    aboutName?: string,
    conclusion?: string,
    finalPrompt?: string
}

export interface GenerateSlideRequest {
    prompt?: string
    presentationPrompt?: string
    outline?: {
        title: string
        summary: string
    }[]
    slideTitle?: string
    slideSummary?: string
    allowedSlideTypes: AiGeneratedSlideType[]
    presentationTitle?: string
    tempAssetFileNames?: string[]
    contextUrls?: string[]
    indexFileName?: string
    traceData?: TraceData
}

export interface GenerateSlideResponse {
    indexFileName?: string
    slideType: AiGeneratedSlideType,
    slideTitle: string,
    slide: Record<any, any>,
    traceData?: TraceData,
    finalPrompt?: string
}

export interface GeneratedSlideModel extends Object {
    template_id: string,
    version: number,
    layout: Object,
    states: Object[],
    migrationVersion: number
}

export interface GenerateImagesRequest {
    prompt: string,
    imagesCount: number,
    traceData?: TraceData
}

export interface GenerateImagesResponse {
    assets: {
        assetId: string,
        attribution?: string,
        readUrl: string
    }[];
    traceData?: TraceData;
}

export interface GenerateWordCloudRequest {
    text: string,
    wordCount?: number,
    traceData?: TraceData
}

export interface GenerateWordCloudResponse {
    textResults: string[],
    traceData?: TraceData
}

export enum RewriteTextTask {
    REWRITE = "rewrite",
    GENERATE = "generate",
    SPELLCHECK = "spellcheck",
}

export interface RewriteTextRequest {
    text: string,
    allText?: string,
    task: RewriteTextTask,
    customPrompt?: string,
    variationCount?: number,
    traceData?: TraceData
}

export interface RewriteTextResponse {
    results: string[]
    traceData?: TraceData
}

export interface GenerateNextCollectionItemRequest {
    slideTitle: string
    existingItems: string[]
    needsImage: boolean
    traceData?: TraceData
}

export interface GenerateNextCollectionItemResponse {
    item: string
    search?: string
    traceData?: TraceData
}

export enum GenerateSlideNoteType {
    BULLET_POINTS = "bullet-points",
    SCRIPT = "script",
    REWRITE = "rewrite"
}

export interface GenerateSlideNotesRequest {
    slideText: string,
    notesType: GenerateSlideNoteType,
    slideIndex: number,
    variationCount?: number,
    traceData?: TraceData,
    notesText?: string,
    prevSlideText?: string,
    nextSlideText?: string,
    prevSlideNotes?: string
}

export interface GenerateSlideNotesResponse {
    results: string[],
    traceData?: TraceData
}

export interface SlideFormatDescriptor {
    choiceHelper: string
    reason: string
    prompt: string
    schema: Record<any, any>
    groups: AiGeneratedSlideGroupType[],
    validator: (data: any, attemptsMade: number, maxAttempts: number, contextUsed: boolean) => boolean
}

const _getGroups = (slideType: AiGeneratedSlideType): AiGeneratedSlideGroupType[] =>
    Object.entries(AiGeneratedSlideTypeGroups)
        .filter(([group, slideTypes]) => group !== AiGeneratedSlideGroupType.ALL_BUT_TITLE && slideTypes.includes(slideType))
        .map(([group]) => group as AiGeneratedSlideGroupType);

function _wrapValidator(validator: (data: any, attemptsMade: number, maxAttempts: number, contextUsed: boolean) => boolean) {
    return (data: any, attemptsMade: number, maxAttempts: number, contextUsed: boolean) => {
        let response = false;
        try {
            response = validator(data, attemptsMade, maxAttempts, contextUsed);
            // eslint-disable-next-line no-empty
        } catch { }
        return response;
    };
}

function _isNumber(value: any) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

function _isNumberAndInRange(value: any, min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY) {
    return _isNumber(value) && parseFloat(value) >= min && parseFloat(value) <= max;
}

function _checkArrayLength(array: any[], min = 0, max = Number.POSITIVE_INFINITY) {
    return Array.isArray(array) && array.length >= min && array.length <= max;
}

function _canLoosenValidation(attemptsMade: number, maxAttempts: number, contextUsed: boolean) {
    return attemptsMade > 2 || (contextUsed && attemptsMade > 1) || attemptsMade === maxAttempts;
}

export const SlideFormatDescriptors: Record<AiGeneratedSlideType, SlideFormatDescriptor> = {
    [AiGeneratedSlideType.TITLE]: {
        choiceHelper: "A title with a background image.",
        reason: "Using a title slide with a background image creates a visually engaging entry point, setting the tone and atmosphere for your presentation. It’s ideal for establishing brand identity or evoking an emotional response right from the start.",
        prompt: [
            "Provide a short title for this presentation and a very short description about the topic of this presentation. Don't use words like 'presentation' or 'slide'"
        ].join(" "),
        schema: {
            title: "string",
            description: "string"
        },
        groups: _getGroups(AiGeneratedSlideType.TITLE),
        validator: _wrapValidator(({ title, description }) => title && description)
    },
    [AiGeneratedSlideType.BULLET_LIST]: {
        choiceHelper: "A list of bullet points.",
        reason: "Bullet points help you distill complex information into digestible, key ideas. They work great for summarizing topics, outlining agendas, or breaking down steps in a process.",
        prompt: [
            `Provide some bullet points for the slide with a short phrase expanding on the details of the point.`,
            `For each point, suggest a keyword to find an icon.`,
            `Write a short conclusion for the entire slide.`
        ].join(" "),
        schema: {
            items: [{
                bullet: "string",
                details: "string",
                icon: "string"
            }],
            conclusion: "string"
        },
        groups: _getGroups(AiGeneratedSlideType.BULLET_LIST),
        validator: _wrapValidator(({ items, conclusion }, attemptsMade, maxAttempts, contextUsed) =>
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 2) &&
            items.every(({ bullet, details, icon }) => bullet && details && icon) &&
            conclusion
        )
    },
    [AiGeneratedSlideType.VENN_DIAGRAM]: {
        choiceHelper: "A venn (bubbles) diagram.",
        reason: "A Venn diagram visually represents overlaps and differences between concepts or groups, making it easier to highlight shared elements. It’s particularly useful when comparing ideas or identifying intersections in data.",
        prompt: `Provide 3 or 4 short items (1-2 words) to display in a venn diagram and a very short sentence for a conclusion.`,
        schema: {
            items: [{
                text: "string"
            }],
            conclusion: "string"
        },
        groups: _getGroups(AiGeneratedSlideType.VENN_DIAGRAM),
        validator: _wrapValidator(({ items, conclusion }, attemptsMade, maxAttempts, contextUsed) =>
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 3) &&
            items.every(({ text }) => text) &&
            conclusion
        )
    },
    [AiGeneratedSlideType.TIMELINE]: {
        choiceHelper: "A timeline with multiple milestones representing dates or events.",
        reason: "Timelines offer a clear, chronological representation of events, allowing your audience to grasp the progression of a project or historical development at a glance. They are perfect for tracking milestones and deadlines.",
        prompt: `Provide a list of 3 to 6 milestones, each with a very short phrase of text.`,
        schema: {
            items: [{
                date: "string",
                text: "string"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.TIMELINE),
        validator: _wrapValidator(({ items }, attemptsMade, maxAttempts, contextUsed) =>
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 3) &&
            items.every(({ date, text }) => date && text)
        )
    },
    [AiGeneratedSlideType.QUOTE]: {
        choiceHelper: "A famous quote.",
        reason: "Featuring a famous quote can add credibility and inspiration to your presentation. It serves as a powerful hook to set the theme or underscore a key message.",
        prompt: `Provide a very short quote and the name of the author.`,
        schema: {
            quote: "string",
            author: "string"
        },
        groups: _getGroups(AiGeneratedSlideType.QUOTE),
        validator: _wrapValidator(({ quote, author }) => quote && author)
    },
    [AiGeneratedSlideType.BIOGRAPHY]: {
        choiceHelper: "A biography of a person.",
        reason: "A biography slide provides background information and context about a key individual, helping the audience connect with their achievements and influence. It’s effective for highlighting expertise or personal stories.",
        prompt: `Create a short biography for the slide and whether you know if they are a real person or not.`,
        schema: {
            name: "string",
            biography: "string",
            isRealPerson: "boolean"
        },
        groups: _getGroups(AiGeneratedSlideType.BIOGRAPHY),
        validator: _wrapValidator(({ name, biography }) => name && biography)
    },
    [AiGeneratedSlideType.TEAM_MEMBERS]: {
        choiceHelper: "A list of team members with their photos.",
        reason: "Displaying team members with their photos personalizes your presentation and builds trust by showcasing the people behind the work. It’s a great way to introduce the human side of your organization or project.",
        prompt: `For each person on the slide, provide their name, title and whether you know if they are a real person or not. Do not use generic names like "John Doe".`,
        schema: {
            items: [{
                name: "string",
                title: "string",
                isRealPerson: "boolean"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.TEAM_MEMBERS),
        validator: _wrapValidator(({ items }, attemptsMade, maxAttempts, contextUsed) =>
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 2) &&
            items.every(({ name, title }) => name && title)
        )
    },
    [AiGeneratedSlideType.COMPANY_INFO]: {
        choiceHelper: "A logo of a company with a short description.",
        reason: "Using a company logo with a brief description reinforces brand recognition and provides essential context. It’s ideal for corporate presentations, partnerships, or brand introductions.",
        prompt: `Provide a description and a search term to find a logo for the company.`,
        schema: {
            company: "string",
            description: "string",
            search: "string"
        },
        groups: _getGroups(AiGeneratedSlideType.COMPANY_INFO),
        validator: _wrapValidator(({ company, description, search }) => company && description && search)
    },
    [AiGeneratedSlideType.IMAGES_WITH_TEXT]: {
        choiceHelper: "A list of images with short attributions.",
        reason: "A curated list of images accompanied by brief attributions adds credibility while visually supporting your narrative. This format works well for case studies, portfolios, or creative showcases.",
        prompt: `Provide a list of 3 to 6 images and a short phrase for each image.`,
        schema: {
            items: [{
                title: "string",
                text: "string",
                search: "string"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.IMAGES_WITH_TEXT),
        validator: _wrapValidator(({ items }) =>
            items?.length >= 3 &&
            items.every(({ title, text, search }) => title && text && search)
        )
    },
    [AiGeneratedSlideType.CHART]: {
        choiceHelper: "A column, line, area, spline, pie, or a waterfall chart.",
        reason: "Charts transform raw data into clear visuals, making trends and comparisons easier to understand. Whether it’s a column, line, area, spline, pie, or waterfall chart, each type can highlight different aspects of your data effectively.",
        prompt: `Provide chart data with series names and values, x-axis label and categories, y-axis label, an attribution for where you got the data, and a very short sentence for a conclusion.`,
        schema: {
            chartType: "column|line|area|spline|pie|waterfall",
            series: [{
                name: "string",
                data: ["number"]
            }],
            xaxis: {
                label: "string",
                categories: ["string"]
            },
            yaxis: "string",
            conclusion: "string",
            attribution: "string"
        },
        groups: _getGroups(AiGeneratedSlideType.CHART),
        validator: _wrapValidator(({ chartType, series, xaxis, yaxis, conclusion, attribution }, attemptsMade, maxAttempts, contextUsed) => (
            ["column", "line", "area", "spline", "pie", "waterfall"].includes(chartType) &&
            series && series.length && series.every(({ name, data }) =>
                name &&
                data.every(_isNumber)
            ) &&
            xaxis && xaxis.label && xaxis.categories && xaxis.categories.length &&
            yaxis &&
            conclusion &&
            attribution
        ))
    },
    [AiGeneratedSlideType.TABLE]: {
        choiceHelper: "A table.",
        reason: "Tables offer a structured and precise way to display data, allowing for detailed comparisons and quick reference. They are particularly useful when you need to present multiple data points side by side.",
        prompt: [
            `Provide data for a table and include an attribution for where you got this data from.`,
            `Make sure all cells have a value.`,
            `Cells is an array of arrays, where each inner array represents a row.`,
            `Do not include a header row in the cells array.`,
            `The table must have 3 to 10 rows, and 2 to 5 columns. `
        ].join(" "),
        schema: {
            columnHeaders: ["string"],
            cells: [["string"]],
            attribution: "string"
        },
        groups: _getGroups(AiGeneratedSlideType.TABLE),
        validator: _wrapValidator(({ attribution, columnHeaders, cells }, attemptsMade, maxAttempts, contextUsed) =>
            attribution &&
            columnHeaders?.length >= 2 &&
            columnHeaders.every(header => !!header) &&
            _checkArrayLength(cells, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 2) &&
            cells.every(row => row.length === columnHeaders.length && row.every(cell => cell != null))
        )
    },
    [AiGeneratedSlideType.PHOTOS]: {
        choiceHelper: "A collage of photos.",
        reason: "A photo collage creates an engaging visual story by combining multiple images into one cohesive layout. It’s perfect for event recaps, showcasing projects, or celebrating diverse perspectives.",
        prompt: `Provide a search term to find photos.`,
        schema: {
            search: "string"
        },
        groups: _getGroups(AiGeneratedSlideType.PHOTOS),
        validator: _wrapValidator(({ search }) => !!search)
    },
    [AiGeneratedSlideType.COMPANY_LOGOS]: {
        choiceHelper: "A list of companies logos.",
        reason: "Presenting a list of company logos provides an immediate visual cue of credibility and partnerships. It’s effective for illustrating client lists, endorsements, or market presence.",
        prompt: `Provide a list of the names of companies.`,
        schema: {
            companies: ["string"]
        },
        groups: _getGroups(AiGeneratedSlideType.COMPANY_LOGOS),
        validator: _wrapValidator(({ companies }, attemptsMade, maxAttempts, contextUsed) =>
            _checkArrayLength(companies, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 2) &&
            companies.every(company => !!company)
        )
    },
    [AiGeneratedSlideType.PROCESS]: {
        choiceHelper: "A multi step process diagram.",
        reason: "Multi step process diagrams break down complex procedures into manageable stages, making it easier for the audience to follow along. They are excellent for instructional presentations and workflow explanations.",
        prompt: `Provide titles and short descriptions for each step.`,
        schema: {
            steps: [{
                title: "string",
                description: "string"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.PROCESS),
        validator: _wrapValidator(({ steps }, attemptsMade, maxAttempts, contextUsed) =>
            _checkArrayLength(steps, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 2) &&
            steps.every(({ title, description }) => title && description)
        )
    },
    [AiGeneratedSlideType.ORDERED_LIST]: {
        choiceHelper: "A numbered list of items.",
        reason: "Numbered lists organize information sequentially, highlighting order and priority. This is ideal for step-by-step instructions or ranking processes in a clear, logical format.",
        prompt: `Provide a sequentially ordered list of items and a short description. Do not include numbers.`,
        schema: {
            items: [{
                title: "string",
                description: "string"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.ORDERED_LIST),
        validator: _wrapValidator(({ items }, attemptsMade, maxAttempts, contextUsed) =>
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 2) &&
            items.every(({ title, description }) => title && description)
        )
    },
    [AiGeneratedSlideType.AGENDA]: {
        choiceHelper: "A list of items representing agenda.",
        reason: "An agenda slide sets expectations by outlining the topics or segments to be covered. It helps keep the presentation organized and lets the audience know what to anticipate.",
        prompt: `Provide a list of agenda items. Do not include numbers.`,
        schema: {
            items: [{
                title: "string",
                type: "event|break"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.AGENDA),
        validator: _wrapValidator(({ items }, attemptsMade, maxAttempts, contextUsed) =>
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 2) &&
            items.every(({ title, type }) => title && ["event", "break"].includes(type))
        )
    },
    [AiGeneratedSlideType.COMPARISON]: {
        choiceHelper: "A comparison chart.",
        reason: "Comparison charts visually juxtapose features, benefits, or statistics side by side, facilitating quick decision-making. They are particularly useful when evaluating multiple options or competitive data.",
        prompt: [
            `Provide a title and data for a comparison chart with horizontal bars`,
            `the values should be within a comparable range to allow for a meaningful comparison (e.g. 0-100)`,
            `there should be 3 to 5 items`,
            `describe the values in the subtitle`,
            `the values should be in the same units (e.g. all in dollars or all in percentages).`
        ].join(", "),
        schema: {
            title: "string",
            subtitle: "string",
            format: "percent|number",
            items: [{
                name: "string",
                value: "number"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.COMPARISON),
        validator: _wrapValidator(({ title, subtitle, format, items }, attemptsMade, maxAttempts, contextUsed) =>
            title &&
            subtitle &&
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 2 : 3) &&
            items.every(({ name, value }) => name && _isNumber(value)) &&
            ["percent", "number"].includes(format)
        )
    },
    [AiGeneratedSlideType.TEXT_WITH_IMAGE]: {
        choiceHelper: "A text with an image.",
        reason: "Combining text with an image creates a balanced slide that offers both detailed information and visual appeal. This approach works well to emphasize key points while keeping the audience engaged.",
        prompt: [
            `Provide a couple of sentences of body text.`,
            `Determine if this slide is about a person, place, thing or a broader concept and provide the name of it.`,
            `This should be real content, not instructions.`,
            `Avoid using language like "This slide".`
        ].join(" "),
        schema: {
            body: "string",
            about: "string",
            aboutName: "string"
        },
        groups: _getGroups(AiGeneratedSlideType.TEXT_WITH_IMAGE),
        validator: _wrapValidator(({ body }) => !!body)
    },
    [AiGeneratedSlideType.BOXES_WITH_TEXT]: {
        choiceHelper: "Boxes with titles and bodies.",
        reason: "Using boxes to compartmentalize information with clear titles and supporting text helps in organizing content logically. They are effective for summarizing points, features, or components of a larger topic.",
        prompt: `Provide a title for the slide and 4 to 6 items with title and body text.`,
        schema: {
            title: "string",
            items: [{
                title: "string",
                body: "string"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.BOXES_WITH_TEXT),
        validator: _wrapValidator(({ title, items }, attemptsMade, maxAttempts, contextUsed) =>
            title &&
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 4) &&
            items.every(({ title, body }) => title && body)
        )
    },
    [AiGeneratedSlideType.ARROW_BARS]: {
        choiceHelper: "Arrow bars with titles.",
        reason: "Arrow bars direct attention through a sequence of steps or ideas, emphasizing flow and progression. They are ideal for process diagrams or visualizing gradual improvements.",
        prompt: `Provide a title for the slide and 3 to 4 items with title and numeric value strictly in the range of [0, 100].`,
        schema: {
            title: "string",
            items: [{
                title: "string",
                value: "number"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.ARROW_BARS),
        validator: _wrapValidator(({ title, items }, attemptsMade, maxAttempts, contextUsed) =>
            title &&
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 2) &&
            items.every(({ title, value }) => title && _isNumberAndInRange(value, 0, 100))
        )
    },
    [AiGeneratedSlideType.THERMOMETER]: {
        choiceHelper: "A thermometer with callouts.",
        reason: "A thermometer visualization is excellent for showing progress towards a goal or measuring performance against a target. Callouts add context by highlighting key milestones or thresholds.",
        prompt: [
            `Provide a title for the slide, `,
            `a numeric value strictly in the range of [0, 100] for the thermomether, `,
            `a list of 2 to 3 callouts with titles and numeric values strictly in the range of [0, 100], callouts MUST be distributed evenly across the range of [0, 100], `,
            `and a short sentence for a conclusion. `,
            `Do not put callouts too close to each other.`
        ].join(" "),
        schema: {
            title: "string",
            value: "number",
            conclusion: "string",
            callouts: [{
                title: "string",
                value: "number"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.THERMOMETER),
        validator: _wrapValidator(({ title, conclusion, value, callouts }, attemptsMade, maxAttempts, contextUsed) =>
            title &&
            conclusion &&
            _checkArrayLength(callouts, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 2) &&
            callouts.every(({ title, value }) => title && _isNumberAndInRange(value, 0, 100)) &&
            _isNumberAndInRange(value, 0, 100)
        )
    },
    [AiGeneratedSlideType.X_Y_PLOT]: {
        choiceHelper: "A scatter plot. A good option for showing 2 dimensional comparisons.",
        reason: "Scatter plots display the relationship between two variables, revealing patterns, clusters, and outliers. They are particularly useful for data-driven presentations that require detailed analysis.",
        prompt: `Provide a title for the slide, x and y axis labels, and a list of 3 to 6 items with titles and x and y values in the range of [0, 100].`,
        schema: {
            title: "string",
            xAxisLabel: "string",
            yAxisLabel: "string",
            items: [{
                title: "string",
                x: "number",
                y: "number"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.X_Y_PLOT),
        validator: _wrapValidator(({ title, xAxisLabel, yAxisLabel, items }, attemptsMade, maxAttempts, contextUsed) =>
            title &&
            xAxisLabel &&
            yAxisLabel &&
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 3) &&
            items.every(({ x, y }) => title && _isNumberAndInRange(x, 0) && _isNumberAndInRange(y, 0))
        )
    },
    [AiGeneratedSlideType.FUNNEL]: {
        choiceHelper: "A funnel.",
        reason: "A funnel diagram breaks down a process into stages, effectively illustrating conversions, drop-offs, or the narrowing of options. It’s great for sales processes, marketing pipelines, or customer journeys.",
        prompt: `Provide a title and 4-6 ordered items/steps for a funnel. Do not include numbers in the steps.`,
        schema: {
            title: "string",
            items: ["string"]
        },
        groups: _getGroups(AiGeneratedSlideType.ARROW_BARS),
        validator: _wrapValidator(({ title, items }, attemptsMade, maxAttempts, contextUsed) =>
            title &&
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 1 : 4) &&
            items.every(item => !!item)
        )
    },
    [AiGeneratedSlideType.TARGET]: {
        choiceHelper: "A target diagram.",
        reason: "Target diagrams focus attention on specific goals and the steps required to reach them. They are useful for strategic planning sessions where aligning actions with objectives is key.",
        prompt: `Provide a title for the slide and 3 to 4 items with 1 to 2 word title and 3 to 4 words description.`,
        schema: {
            title: "string",
            items: [{
                title: "string",
                description: "string"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.TARGET),
        validator: _wrapValidator(({ title, items }, attemptsMade, maxAttempts, contextUsed) =>
            title &&
            _checkArrayLength(items, _canLoosenValidation(attemptsMade, maxAttempts, contextUsed) ? 2 : 3) &&
            items.every(({ title, description }) => title && description)
        )
    },
    [AiGeneratedSlideType.WORDCLOUD]: {
        choiceHelper: "A word cloud.",
        reason: "Word clouds visually emphasize the most frequent terms or themes in a set of data. They are effective for summarizing feedback, brainstorming sessions, or highlighting key topics.",
        prompt: `Generate a list of 50 unique words in order of relevance.`,
        schema: {
            words: ["string"]
        },
        groups: _getGroups(AiGeneratedSlideType.WORDCLOUD),
        validator: _wrapValidator(({ words }) =>
            _checkArrayLength(words, 50) &&
            words.every(word => !!word)
        )
    },
    [AiGeneratedSlideType.SWOT_DIAGRAM]: {
        choiceHelper: "A SWOT diagram.",
        reason: "A SWOT diagram organizes strengths, weaknesses, opportunities, and threats in a clear format, aiding strategic analysis. It’s an invaluable tool for business planning and competitive assessments.",
        prompt: `Provide a title for the slide and 4 lists of items for each quadrant.`,
        schema: {
            title: "string",
            strengths: ["string"],
            weaknesses: ["string"],
            opportunities: ["string"],
            threats: ["string"]
        },
        groups: _getGroups(AiGeneratedSlideType.SWOT_DIAGRAM),
        validator: _wrapValidator(({ title, strengths, weaknesses, opportunities, threats }) =>
            title &&
            _checkArrayLength(strengths, 1) &&
            _checkArrayLength(weaknesses, 1) &&
            _checkArrayLength(opportunities, 1) &&
            _checkArrayLength(threats, 1) &&
            strengths.every(item => !!item) &&
            weaknesses.every(item => !!item) &&
            opportunities.every(item => !!item) &&
            threats.every(item => !!item)
        )
    },
    [AiGeneratedSlideType.HUB_AND_SPOKE]: {
        choiceHelper: "A hub and spoke diagram.",
        reason: "Hub and spoke diagrams center a primary idea surrounded by related subtopics, clarifying relationships and dependencies. They are ideal for brainstorming sessions or mapping out complex systems.",
        prompt: [
            `Provide a title for the slide, 1 word title for the hub, 4 to 8 items with 1 word label and 2-3 words description for the spoke items.`,
            `For each point, suggest a keyword to find an icon.`
        ].join(" "),
        schema: {
            title: "string",
            hub: "string",
            spokes: [{
                label: "string",
                description: "string",
                icon: "string"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.HUB_AND_SPOKE),
        validator: _wrapValidator(({ title, hub, spokes }) =>
            title &&
            hub &&
            _checkArrayLength(spokes, 4) &&
            spokes.every(({ label, description, icon }) => label && description && icon)
        )
    },
    [AiGeneratedSlideType.CYCLE]: {
        choiceHelper: "A cycle.",
        reason: "Cycle diagrams illustrate recurring processes or continuous improvement loops, emphasizing how one stage leads to another in a circular flow. They are excellent for depicting iterative progress and feedback cycles.",
        prompt: [
            `Provide a title for the slide, and 6 to 8 items with 2-3 words description for each item.`,
            `For each cycle item, suggest a keyword to find an icon.`
        ].join(" "),
        schema: {
            title: "string",
            items: [{
                description: "string",
                icon: "string"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.CYCLE),
        validator: _wrapValidator(({ items }) =>
            _checkArrayLength(items, 4, 8) &&
            items.every(({ description }) => !!description)
        )
    },
    [AiGeneratedSlideType.ARROW_CYCLES]: {
        choiceHelper: "Arrow cycles.",
        reason: "Arrow cycles combine the concepts of flow and repetition, highlighting ongoing processes or cyclical improvements. They’re perfect for illustrating dynamic systems and continuous progress.",
        prompt: `Provide a title for the slide, and 4 to 6 items with 2-3 words title and 1 short-sentence description for each item.`,
        schema: {
            title: "string",
            items: [{
                title: "string",
                description: "string",
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.ARROW_CYCLES),
        validator: _wrapValidator(({ items }) =>
            _checkArrayLength(items, 4, 8) &&
            items.every(({ title, description }) => title && description)
        )
    },
    [AiGeneratedSlideType.JOURNEY]: {
        choiceHelper: "A journey diagram.",
        reason: "Journeys offer a clear, chronological representation of events, allowing your audience to grasp the progression of a project or customer journey at a glance. They are perfect for tracking a journey of events.",
        prompt: [
            "Provide a title for the slide, one word text for start and end markers, and 6 to 8 items with 1-2 words title and 3-4 words description.",
            "For each item, suggest a keyword to find an icon."
        ].join(" "),
        schema: {
            title: "string",
            startMarker: "string",
            endMarker: "string",
            items: [{
                title: "string",
                description: "string",
                icon: "string"
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.JOURNEY),
        validator: _wrapValidator(({ title, startMarker, endMarker, items }) =>
            title &&
            startMarker && endMarker &&
            _checkArrayLength(items, 6, 8) &&
            items.every(({ title, description, icon }) => title && description && icon)
        )
    },
    [AiGeneratedSlideType.GANTT_CHART]: {
        choiceHelper: "A Gantt chart.",
        reason: "Gantt charts are useful for visualizing project timelines, tracking progress, and managing tasks effectively. They're especially useful for complex projects in industries like construction, software development, marketing campaigns, and event planning.",
        prompt: [
            "Provide a title for the slide.",
            "Provide a 4 to 6 tasks that contain a name, start date, and end date, with realistic durations that fit within the overall timeline.",
            "Provide a 1 to 2 milestones that have a name and a single date marking key project achievements.",
            "Ensure that dates are distributed logically, ensuring tasks do not overlap unrealistically and milestones are positioned at meaningful intervals.",
            "Ensure the timeline flows logically, with dependent tasks following predecessors where applicable.",
            "Ensure there are no gaps in the timeline, at least one task must always be active at any given time.",
        ].join(" "),
        schema: {
            title: "string",
            milestones: [{
                label: "string",
                date: "YYYY-MM-DD"
            }],
            tasks: [{
                label: "string",
                startDate: "YYYY-MM-DD",
                endDate: "YYYY-MM-DD",
            }]
        },
        groups: _getGroups(AiGeneratedSlideType.GANTT_CHART),
        validator: _wrapValidator(({ title, milestones, tasks }) =>
            title &&
            _checkArrayLength(milestones, 1, 2) &&
            milestones.every(({ label, date }) => label && date) &&
            _checkArrayLength(tasks, 4, 6) &&
            tasks.every(({ label, startDate, endDate }) => label && startDate && endDate)
        )
    }
};

export const ContextSourceFileTypes = [
    ".doc", ".docx",
    ".pdf", "application/pdf",
    ".txt", "text/plain",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export interface WebPageMetadata {
    title: string,
    description: string,
    imageDataURL: string,
    siteName: string,
    rawMetadata: Record<string, string>
}

export enum DOMElementColorProperty {
    Color = "color",
    BackgroundColor = "background-color",
    BorderColor = "border-color",
    Fill = "fill",
    FillColor = "fill-color",
    Stroke = "stroke",
    StrokeColor = "stroke-color",
    OutlineColor = "outline-color"
}

export interface ParsedDOMElementProps {
    tag: string;
    textContentLength: number;
    cursor: string;
    opacity: number;
    fontFamily: string;
    fontSize: number;
    width: number;
    height: number;
    colors: Record<DOMElementColorProperty, string>;
    children: ParsedDOMElementProps[];
    parent?: ParsedDOMElementProps;
}

export interface ParsedWebPageTheme {
    sortedShapeColors: string[];
    shapeColorsWithScores: Record<string, number>;
    sortedTextColors: string[];
    textColorsWithScores: Record<string, number>;
    sortedFonts: string[];
    fontsWithScores: Record<string, number>;
    loadedFontFamilies: string[];
    pageMetadata: WebPageMetadata;
    elements: ParsedDOMElementProps;
}

export enum AiCreditOwnerType {
    USER = "user",
    ORG = "org"
}

export enum AiCreditEventType {
    INITIALIZED = "initialized",
    CREDITS_CONSUMED = "creditsConsumed",
    UNUSED_CREDITS_RETURNED = "unusedCreditsReturned",
    DEFAULT_CREDITS_ADDED = "defaultCreditsAdded"
}

export interface AiCreditEventData {
    uid: string | null;
    workspaceId: string | null;
    requestType?: AiGenerativeRequestType;
    requestCount?: number;
    requestData?: any;
}

export const DefaultAiCreditsPerUser = 100;
