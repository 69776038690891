import React, { Component } from "react";

import { ActionPermissionsObject } from "common/interfaces";
import WorkspaceController from "js/controllers/WorkspaceController";
import ErrorMessage from "js/react/components/ErrorMessage";
import Spinner from "js/react/components/Spinner";
import { UIPane } from "js/react/components/UiComponents";
import { FetchStatus } from "js/react/constants";
import AnalyticsController from "js/react/views/Analytics/AnalyticsController";

import AnalyticsPane from "./Panes/AnalyticsPane";
import LinkAnalyticsPane from "./Panes/LinkAnalyticsPane";

import "css/analytics.scss";

class Analytics extends Component {
    componentDidMount() {
        setTimeout(async () => {
            if (WorkspaceController.actionPermissions[ActionPermissionsObject.ANALYTICS].use) {
                await AnalyticsController.fetchPresentationAnalytics(this.props.presentation);
                await AnalyticsController.clearSelectedLink();
            } else {
                await AnalyticsController.setDummyData();
            }
        }, 0);
    }

    componentWillUnmount() {
        AnalyticsController.reset();
    }

    render() {
        const { showSelectedLink, status } = this.props.analytics;

        switch (status.type) {
            case FetchStatus.UNSET:
            case FetchStatus.LOADING:
                return (
                    <UIPane>
                        <Spinner />
                    </UIPane>
                );
            case FetchStatus.ERROR:
                return (
                    <UIPane>
                        <ErrorMessage title="An error occurred while loading analytics data" message={status.message} />
                    </UIPane>
                );
            case FetchStatus.SUCCESS:
            default:
                return (<>
                    <AnalyticsPane />
                    {showSelectedLink ? <LinkAnalyticsPane /> : undefined}
                </>);
        }
    }
}

export default AnalyticsController.withState(Analytics);
