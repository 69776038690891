/////////////////////////////////////////////////////
// This file must be imported from app entrypoints //
/////////////////////////////////////////////////////

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

declare global {
    interface Window {
        firebase: any;
        baiFirebase: {
            config: Object,
            authPersistence?: string
        };
    }
}

/**
 * Initializes firebase apps and auth.
 */
export async function init() {
    // Debug
    window.firebase = firebase;

    // Initialize the default firebase app
    firebase.initializeApp(window.baiFirebase.config);

    // This will initialize other firebase apps
    // @ts-ignore
    await import(/* webpackMode: "eager" */ "../db");

    // Initialize auth and mount it on window
    // @ts-ignore
    await import(/* webpackMode: "eager" */ "./auth");
}
