import React, { Component } from "react";
import styled from "styled-components";

import { Box } from "js/react/components/LayoutGrid";
import { Key, isModifier } from "js/core/utilities/keys";
import { numeral } from "js/vendor";
import { stopPropagation } from "js/core/utilities/stopPropagation";

const StepperButton = styled.div`
    width: 12px;
    height: 50%;
    background: #ddd;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;

    &:hover {
        background: #ddd;
    }

    .bai-icon {
        color: #ccc;
        font-size: 20px !important;
    }

    margin-left: 0px;
    margin-right: 2px;
`;

const Container = styled(Box)`
    display: flex;
    height: 30px;
    background: white;
    border: solid 1px #bbb;
    &:hover {
        ${StepperButton} {
            opacity: 1;
        }
    }
`;

const StyledNumericInput = styled.input`
    font-size: 13px;
    width: 100%;
    height: 100%;
    text-align: center;
    //padding-left: 4px;

    &::-webkit-inner-spin-button {
        opacity: 1;
    }

    outline: none;
    box-sizing: border-box;
    border-radius: 0px;
    border: solid 1px #bbb;
    border: none;
    
    &:disabled {
        background: #eee;
    }
`;

export class NumericStepper extends Component {
    constructor(props) {
        super(props);

        const value = this.normalizeValue(props.value);

        this.state = {
            value,
            editingValue: value,
            isEditing: false,
            min: props.min ?? 0,
            max: props.max ?? 9999,
            step: props.step ?? 1
        };

        this.ref = React.createRef();
    }

    normalizeValue(value) {
        value = parseFloat(value);
        if (Number.isNaN(value)) {
            value = "";
        }
        return value;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isEditing } = this.state;
        if (isEditing) {
            return;
        }

        const { value } = this.props;
        const { value: prevValue } = prevState;

        if (this.normalizeValue(value) !== this.normalizeValue(prevValue)) {
            this.setState({
                value: this.normalizeValue(value),
                editingValue: this.getDisplayValue()
            });
        }
    }

    getDisplayValue() {
        const { isEditing, value, editingValue } = this.state;

        if (isEditing) {
            return editingValue;
        } else {
            return this.getFormattedValue(value);
        }
    }

    getFormattedValue(value) {
        const { format, decimals } = this.props;
        switch (format) {
            case "percent":
                return (value * 100).toFixed(decimals ?? 0) + "%";
            default:
                return value;
        }
    }

    getRawValue(value) {
        const { useAutoWhenBlank } = this.props;
        const { min, max } = this.state;

        const _value = parseFloat(value);

        if (isNaN(_value)) {
            if (useAutoWhenBlank) {
                return null;
            } else {
                return min;
            }
        } else {
            return Math.clamp(_value, min, max);
        }
    }

    saveValue(value) {
        const { onChange } = this.props;
        onChange(this.getRawValue(value));
    }

    handleChange = event => {
        this.setState({
            editingValue: event.target.value
        });
    }

    handleKeyDown = event => {
        if (event.which == Key.ENTER) {
            this.ref.current.blur();
        } else if (event.which == Key.TAB) {
            this.ref.current.blur();
        } else if (event.which == Key.UP_ARROW) {
            this.handleIncrement(this.state.editingValue, event.shiftKey ? 10 : 1);
            event.preventDefault();
        } else if (event.which == Key.DOWN_ARROW) {
            this.handleIncrement(this.state.editingValue, event.shiftKey ? -10 : -1);
            event.preventDefault();
        } else if (isModifier(event.which)) {
            return;
        } else {
            this.setState({
                isEdited: true
            });
        }
    }

    handleIncrement = (value, delta) => {
        const { step, min, max } = this.state;
        const { onChange, format } = this.props;

        let _value;

        if (format == "percent") {
            _value = (this.normalizeValue(value) + (step * 100) * delta) / 100.0;
        } else {
            _value = this.getRawValue(value) + step * delta;
        }

        const newValue = Math.clamp(_value, min, max);
        this.setState({
            value: newValue,
            editingValue: this.getFormattedValue(newValue)
        }, () => {
            onChange(newValue);
        });
    }

    handleFocus = () => {
        this.setState({
            isEditing: true,
            editingValue: this.getDisplayValue()
        });
    }

    handleBlur = event => {
        if (this.state.isEdited) {
            let value = event.target.value;
            if (this.props.format == "percent" && !value.endsWith("%")) {
                value += "%";
            }
            this.saveValue(numeral(value).value());
        }
        this.setState({
            isEditing: false,
            isEdited: false
        });
    }

    handleWheel = event => {
        event.preventDefault();
        event.stopPropagation();
    }

    handleStepper = delta => {
        const { value, step, min, max } = this.state;
        const { onChange } = this.props;

        const newValue = Math.clamp(value + step * delta, min, max);
        onChange(newValue);

        return newValue;
    }

    render() {
        const { width, disabled, useAutoWhenBlank } = this.props;

        return (
            <Container width={width ?? 40}>
                <StyledNumericInput type="text"
                    ref={this.ref}
                    value={this.getDisplayValue()}
                    onChange={this.handleChange}
                    onMouseDown={stopPropagation()}
                    onKeyDown={this.handleKeyDown}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onWheel={this.handleWheel}
                    disabled={disabled}
                    placeholder={useAutoWhenBlank ? "Auto" : null}
                />
            </Container>
        );
    }
}

