import _ from "lodash";

import {
    IWorkspaceActionPermissions,
    GET_DEFAULT_RESTRICTED_ACTION_PERMISSIONS,
    IWorkspacePlanPermissions,
    WorkspacePermissionSubjectType,
    IWorkspaceUser,
    IWorkspacePermission,
    WorkspacePermissionObjectType,
    IWorkspacePermissionAccess
} from "common/interfaces";

export function ensureActionPermissionsAreMoreRestricted(actionPermissions: IWorkspaceActionPermissions, limitingActionPermissions: IWorkspaceActionPermissions) {
    const restrictedActionPermissions = _.cloneDeep<IWorkspaceActionPermissions>(actionPermissions);

    let hasChanges = false;
    Object.keys(restrictedActionPermissions).forEach(actionPermissionKey => {
        Object.keys(restrictedActionPermissions[actionPermissionKey]).forEach(action => {
            if (!limitingActionPermissions[actionPermissionKey][action] && restrictedActionPermissions[actionPermissionKey][action]) {
                restrictedActionPermissions[actionPermissionKey][action] = false;
                hasChanges = true;
            }
        });
    });

    if (hasChanges) {
        return restrictedActionPermissions;
    }

    return null;
}

export function getCombinedActionPermissions(actionPermissions: IWorkspaceActionPermissions[]) {
    const combinedActionPermissions = GET_DEFAULT_RESTRICTED_ACTION_PERMISSIONS();

    actionPermissions
        .forEach(actionPermissions => {
            Object.keys(actionPermissions).forEach(key => {
                Object.keys(actionPermissions[key]).forEach(action => {
                    combinedActionPermissions[key][action] = combinedActionPermissions[key][action] || actionPermissions[key][action];
                });
            });
        });

    return combinedActionPermissions;
}

export function mergeWithBaseActionPermissions(baseActionPermissions: IWorkspaceActionPermissions, actionPermissions: IWorkspaceActionPermissions) {
    const mergedActionPermissions = _.cloneDeep<IWorkspaceActionPermissions>(baseActionPermissions);

    Object.keys(mergedActionPermissions).forEach(key => {
        Object.keys(mergedActionPermissions[key]).forEach(action => {
            mergedActionPermissions[key][action] = actionPermissions?.[key]?.[action] ?? mergedActionPermissions[key][action];
        });
    });

    return mergedActionPermissions;
}

export function getActionPermissionsCombinedWithPlanPermissions(actionPermissions: IWorkspaceActionPermissions, planPermissions: IWorkspacePlanPermissions) {
    const combinedActionPermissions = _.cloneDeep(actionPermissions);

    Object.keys(combinedActionPermissions).forEach(key => {
        if (planPermissions[key] === false) {
            Object.keys(combinedActionPermissions[key]).forEach(action => {
                combinedActionPermissions[key][action] = false;
            });
        }
    });

    return combinedActionPermissions;
}

/**
 * Calculates the permissions for a resource
 *
 * @param resourceId The ID of the resource to calculate permissions for
 * @param resourceType The type of resource to calculate permissions for
 * @param permissions The permissions to calculate permissions for
 * @param user The user to calculate permissions for
 * @returns The calculated permissions for the resource
 */
export function calcPermissionsToResource({
    resourceId,
    resourceType,
    permissions,
    user
}: {
    resourceId: string,
    resourceType: WorkspacePermissionObjectType,
    permissions: IWorkspacePermission[],
    user: Pick<IWorkspaceUser, "uid" | "groupIds">
}) {
    const calculatedPermissions: IWorkspacePermissionAccess = {
        read: {
            object: false,
            permissions: false
        },
        write: {
            object: false,
            permissions: false
        },
        owner: false
    };

    const directPermissions = permissions.filter(p => p.objectId === resourceId && p.objectType === resourceType && p.subjectType === WorkspacePermissionSubjectType.USER && p.subjectId === user.uid);
    const groupPermissions = permissions.filter(p => p.subjectType === WorkspacePermissionSubjectType.USER_GROUP && user.groupIds.includes(p.subjectId));

    directPermissions.forEach(p => {
        calculatedPermissions.write.object = calculatedPermissions.write.object || p.write.object;
        calculatedPermissions.write.permissions = calculatedPermissions.write.permissions || p.write.permissions;
        calculatedPermissions.read.object = calculatedPermissions.read.object || p.read.object;
        calculatedPermissions.read.permissions = calculatedPermissions.read.permissions || p.read.permissions;
        calculatedPermissions.owner = calculatedPermissions.owner || p.owner;
    });

    groupPermissions.forEach(p => {
        calculatedPermissions.write.object = calculatedPermissions.write.object || p.write.object;
        calculatedPermissions.write.permissions = calculatedPermissions.write.permissions || p.write.permissions;
        calculatedPermissions.read.object = calculatedPermissions.read.object || p.read.object;
        calculatedPermissions.read.permissions = calculatedPermissions.read.permissions || p.read.permissions;
        calculatedPermissions.owner = calculatedPermissions.owner || p.owner;
    });

    return calculatedPermissions;
}
