export enum MetricName {
    API_HANDLER_RESPONSE_TIME = "api_handler_response_time",
    EDITOR_LOAD_TIME = "editor_load_time",
    PLAYER_LOAD_TIME = "player_load_time",
    LIBRARY_LOAD_TIME = "library_load_time",
    ADVANCE_TO_SLIDE_TIME = "advance_to_slide_time",
    EXPORT_TIME = "export_time",
    FIREBASE_HEALTH_CHECK_RESPONSE_TIME = "firebase_health_check_response_time",
    CANVAS_GENERATE_TIME = "canvas_generate_time",
    CANVAS_RENDER_ELEMENT_TIME = "canvas_render_element_time",
    AI_GENERATE_PRESENTATION_TIME = "ai_generate_presentation_time",
    AI_GENERATE_SLIDE_TIME = "ai_generate_slide_time",
    GENERATE_SCREENSHOT_TIME = "generate_screenshot_time",
    RENDERER_LOAD_SLIDE_TIME = "renderer_load_slide_time",
    SCREENSHOT_TASK_TIME = "screenshot_task_time"
}

export interface IMetricData {
    [key: string]: any;
}

export interface IMetricData_ApiHandlerResponseTime extends IMetricData {
    responseTimeMs: number;
    statusCode: number;
}

export interface IMetricData_EditorLoadTime extends IMetricData {
    presentationId: string;
    loadTimeMs: number;
    loadFailed: boolean;
}

export interface IMetricData_PlayerLoadTime extends IMetricData {
    presentationId: string;
    loadTimeMs: number;
}

export interface IMetricData_LibraryLoadTime extends IMetricData {
    loadTimeMs: number;
    view: string;
    pane?: string;
}

export interface IMetricData_SlideAdvanceTime extends IMetricData {
    presentationId: string;
    slideId: string;
    advanceTimeMs: number;
}

export interface IMetricData_ExportTime extends IMetricData {
    presentationId: string;
    slideCount: number;
    assetType: string;
    pdfCompression: boolean;
    exportTimeMs: number;
    exportFailed: boolean;
    uid: string;
}

export interface IMetricData_FirebaseHealthCheckResponseTime extends IMetricData {
    responseTimeMs: number;
    instanceType: "main" | "secondary";
    callFailed: boolean;
}

export interface IMetricData_CanvasGenerateTime extends IMetricData {
    durationMs: number;
    migrationVersion: number;
    templateId: string;
    slideId: string;
    presentationId: string;
    failed: boolean;
}

export interface IMetricData_CanvasRenderElementTime extends IMetricData_CanvasGenerateTime { }

export interface IMetricData_AiGeneratePresentationTime extends IMetricData {
    generationTimeMs: number;
    slideCount: number;
    withContext: boolean;
    generationFailed: boolean;
}

export interface IMetricData_AiGenerateSlideTime extends IMetricData {
    generationTimeMs: number;
    templateId: string;
    withContext: boolean;
    imageCount: number;
    generationFailed: boolean;
}

export interface IMetricData_GenerateScreenshotTime extends IMetricData {
    durationMs: number;
    assetType: string;
}

export interface IMetricData_RendererLoadSlideTime extends IMetricData {
    durationMs: number;
    assetType: string;
}

export interface IMetricData_ScreenshotTaskTime extends IMetricData {
    durationMs: number;
    assetType: string;
    taskFailed: boolean;
}
