import React, { Component } from "react";
import styled from "styled-components";

import { themeColors } from "js/react/sharedStyles";
import { uppercaseTitle } from "js/textStyles";
import { tinycolor } from "js/vendor";

import { Icon } from "./Icon";
import { DisabledStateToolTip } from "./ToolTip";

interface StyledButtonProps {
    backgroundColor?: string;
    color?: string;
    iconColor?: string;
    hoverColor?: string;
    rounded?: boolean;
    size?: "small" | "large" | null;
    width?: number;
    height?: number;
    unframed?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
    pointer-events: auto;
    cursor: pointer;
    background: ${props => props.backgroundColor};
    color: ${props => props.color};
    border-radius: ${props => props.rounded ? "18px" : "2px"};
    border: none;
    font-size: ${props => {
        if (props.size == "large") {
            return 14;
        } else if (props.size == "small") {
            return 11;
        } else {
            return 12;
        }
    }}px;
    font-family: Source Sans Pro;
    font-weight: 600;
    letter-spacing: 0.025em;
    width: ${props => props.width ? (props.width + "px") : "auto"};
    height: ${props => props.height || 36}px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: ${props => props.hoverColor ?? "black"};
        border-radius: ${props => props.rounded ? "18px" : "2px"};
        opacity: 0;
        z-index: 0;
        transition: opacity 0.2s ease-in-out;
    }

    span {
        z-index: 1;
        text-transform: uppercase;
    }

    .icon {
        z-index: 1;
    }

    .icon + span {
        margin-left: 8px;
    }

    .bai-icon {
        color: ${props => props.iconColor} !important;  
    }

    &:hover {
        &:before {
            opacity: ${props => props.hoverColor ? 1 : 0.2};
            background: ${props => props.hoverColor ?? "black"};
        }
    }

    &:disabled {
        cursor: not-allowed !important;
        opacity: 0.5;
    }
`;

interface ButtonProps {
    large?: boolean;
    rounded?: boolean;
    unframed?: boolean;
    style?: React.CSSProperties;
    small?: boolean;
    blue?: boolean;
    warning?: boolean;
    width?: number;
    height?: number;
    disabledTooltip?: string;
    color?: string;
    children: React.ReactNode | string;
    [key: string]: any;
}

export class Button extends Component<ButtonProps> {
    render() {
        let { outline, solid, large, rounded = false, unframed = false, style = {}, small, blue, warning, width, height, disabledTooltip, children, ...rest } = this.props;

        let flatChildren = React.Children.toArray(children).reduce<React.ReactNode[]>((flat, child) => {
            if (React.isValidElement(child) && child.type === React.Fragment) {
                return flat.concat(React.Children.toArray(child.props.children));
            }
            return flat.concat(child);
        }, []);

        let IconChild = flatChildren.find(child =>
            React.isValidElement(child) && (child.type === Icon || child.type === "img")
        );
        let LabelChildren = flatChildren.filter(child => child !== IconChild);

        let backgroundColor = "#d1d1d1";
        let color = "white";
        let iconColor = "white";
        let hoverColor;

        if (unframed) {
            backgroundColor = "transparent";
            color = "#333";
            iconColor = themeColors.ui_blue;
            hoverColor = themeColors.selection;
            style.justifyContent = "flex-start";
        } else if (blue) {
            backgroundColor = themeColors.ui_blue;
        } else if (warning) {
            backgroundColor = themeColors.warning;
        } else if (rest.color) {
            backgroundColor = rest.color;
            hoverColor = tinycolor(backgroundColor).darken(10).toString();
        } else {
            backgroundColor = themeColors.rollover;
            color = "rgba(0,0,0,0.76)";
            iconColor = "rgba(0,0,0,0.76)";
            style.border = `solid 1px ${themeColors.ui_blue}`;
            hoverColor = themeColors.selection;
        }

        let size = null;
        if (large) {
            size = "large";
        } else if (small) {
            size = "small";
        }

        return (
            <DisabledStateToolTip {...this.props}>
                <StyledButton className="bai-button"
                    width={width} height={height}
                    backgroundColor={backgroundColor}
                    color={color}
                    iconColor={iconColor}
                    hoverColor={hoverColor}
                    size={size}
                    unframed={unframed}
                    rounded={rounded}
                    style={style}
                    onMouseDown={event => event.preventDefault()}
                    onKeyDown={event => event.preventDefault()}
                    {...rest}
                >
                    {IconChild && IconChild}
                    {LabelChildren.length > 0 && <span>{LabelChildren}</span>}
                </StyledButton>
            </DisabledStateToolTip>
        );
    }
}

const dialogButtonStyles = `
    ${uppercaseTitle}
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    border-radius: 2px;
    gap: 6px;
    text-wrap: nowrap;
    padding: 6px 16px;
    background-color: ${themeColors.ui_blue};
    color: white;
`;

export const DialogButton = styled(StyledButton)`
    ${dialogButtonStyles}
`;

export const TextDialogButton = styled(StyledButton)`
    ${dialogButtonStyles}
    background: transparent;
    color: ${themeColors.ui_blue};
    border: none;

    &:hover {
        &:before {
            opacity: 0.15;
            background: ${themeColors.ui_blue};
        }
    }
`;

export const UpgradeButton = styled(StyledButton)`
    ${dialogButtonStyles}
    background-color: #FA0;
`;
