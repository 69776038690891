import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";

const Container = styled.div`
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: relative;
`;

export function ZoomAppContainer() {
    const [zoomApp, setZoomApp] = useState(null);

    useEffect(() => {
        (async () => {
            // @ts-ignore - import the preload script
            await import(/* webpackChunkName: "js-preload" */ "js/preload");

            // @ts-ignore - setup firebase adapter
            const { default: Adapter } = await import(/* webpackChunkName: "js-core-storage-adapter" */ "js/core/storage/adapter"); // @ts-ignore
            const { default: FirebaseAdapter } = await import(/* webpackChunkName: "js-core-storage-firebaseAdapter" */ "js/core/storage/firebaseAdapter");
            Adapter.setDefaultClass(FirebaseAdapter);

            // @ts-ignore - import the ZoomApp root component
            const { default: ZoomApp } = await import(/* webpackChunkName: "js-react-views-ZoomApp-ZoomApp" */ "js/react/views/ZoomApp/ZoomApp");
            setZoomApp(<ZoomApp />);
        })();
    }, []);

    return <Container>{zoomApp}</Container>;
}

export function ZoomAppRedirect() {
    useEffect(() => {
        (async () => {
            // @ts-ignore - import the auth script
            const { auth } = await import("js/firebase/auth");

            const userIdToken = await auth().currentUser.getIdToken();
            Cookies.set("user_id_token", userIdToken, { sameSite: "strict", expires: new Date(Date.now() + 60 * 1000) });

            window.location.href = `${window.location.origin}/zoom/auth` +
                `?continueUrl=${encodeURIComponent("/close")}` +
                "&requestOfflineAccess=true";
        })();
    }, []);

    return null;
}   
