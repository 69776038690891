import moment from "moment";
import React, { PureComponent } from "reactn";

import { ActionPermissionsObject } from "common/interfaces";
import WorkspaceController from "js/controllers/WorkspaceController";
import { ShowUpgradeDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { FlexBox } from "js/react/components/LayoutGrid";
import ProBadge from "js/react/components/ProBadge";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";

import { themeColors } from "js/react/sharedStyles";
import styled from "styled-components";
import { DatePicker } from "../../Components/DatePicker";
import { ToggleSwitch } from "../../Components/ToggleSwitch";
import { WithLabel } from "../../Components/WithLabel";

const DefaultLinkExpirationContainer = styled.div`
    padding: 0 0 15px 0;
    display: flex;
    gap: 15px;

    align-items: center;
`;

const StackedLinkExpirationContainer = styled.div`
    display: grid;
    gap: 15px;

    grid-template-columns: auto auto;
    align-items: center;

    .link-expiration-activation {
        grid-column: 1 / 3;
    }
`;

const ExpiredLinkWarning = styled.div`
    color: ${themeColors.warning};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
`;

export default class LinkExpiration extends PureComponent {
    static getDefaultExpirationDate() {
        return moment().add(30, "day").endOf("day").format("YYYY-MM-DD");
    }

    // updates the link
    updateLink = expiresAt => {
        this.props.onUpdate({
            linkExpiresAt: expiresAt,
            linkShouldExpire: !!expiresAt,
            linkHasExpired: !!expiresAt && checkIfExpired(expiresAt)
        });
    }

    onSelectExpirationDate = value => {
        this.updateLink(getExpirationDate(value));
    }

    // handle toggling the feature on and off
    onToggleExpireLink = () => {
        const { presentation, expiresAt } = this.props;

        // check if they can use this feature
        if (!WorkspaceController.actionPermissions[ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION].use) {
            if (WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION, "use")) {
                ShowUpgradeDialog({
                    type: UpgradePlanDialogType.UPGRADE_PLAN,
                    analytics: { cta: "EnableLink", ...presentation.getAnalytics() },
                    workspaceId: presentation.getWorkspaceId()
                });
                return;
            }

            ShowWarningDialog({
                title: "Oops!",
                message: "You do not have permission to enable link expiration!",
            });
            return;
        }

        // toggle the state
        if (expiresAt) {
            this.updateLink(null);
        } else {
            this.updateLink(LinkExpiration.getDefaultExpirationDate());
        }
    }

    render() {
        const { presentation, expiresAt } = this.props;

        const expireLink = !!expiresAt;
        const isExpired = expireLink && checkIfExpired(expiresAt);
        const Container = "stacked" in this.props ? StackedLinkExpirationContainer : DefaultLinkExpirationContainer;

        return (
            <Container>
                <FlexBox left middle className="link-expiration-activation">
                    <WithLabel label="Expire Link" toolTip="The link will no longer be accessible after the selected date." gap={42}>
                        <ToggleSwitch value={expireLink} onChange={this.onToggleExpireLink} />
                    </WithLabel>

                    <ProBadge
                        show={WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION, "use")}
                        analytics={{
                            cta: "ExpireLink",
                            ...presentation.getAnalytics()
                        }}
                        workspaceId={presentation.getWorkspaceId()}
                    />
                </FlexBox>

                {expireLink && (
                    <WithLabel label="Expires At">
                        <DatePicker value={new Date(expiresAt)}
                            onChange={this.onSelectExpirationDate}
                        />
                        {isExpired && <ExpiredLinkWarning>Please select a date in the future</ExpiredLinkWarning>}
                    </WithLabel>

                )}
            </Container>
        );
    }
}

function checkIfExpired(date) {
    return moment(date).isBefore(Date.now());
}

function getExpirationDate(val) {
    return moment(val).format("YYYY-MM-DD");
}
