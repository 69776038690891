const DEFAULT_RETRY_CONFIG = {
    maxAttempts: 3,
    baseDelayMs: 1000,
    shouldBackoff: false,
    backoffBase: 2
};

export class MaxRetriesReachedError extends Error {
    constructor(message: string, public result: Error | unknown) {
        super(message);
    }
}

export async function withRetry<T = void>(
    action: () => Promise<T>,
    retryConfig: {
        checkRetryCondition: (error: Error, response: T) => boolean,
        onBeforeRetry?: (error: Error, response: T, retriesRemaining: number) => Promise<void>,
        maxAttempts?: number,
        baseDelayMs?: number,
        shouldBackoff?: boolean,
        backoffBase?: number
    }
): Promise<T> {
    const {
        checkRetryCondition, onBeforeRetry, maxAttempts, baseDelayMs, shouldBackoff, backoffBase
    } = { ...DEFAULT_RETRY_CONFIG, ...(retryConfig ?? {}) };

    let attempt = 1;

    const execute = async () => {
        let error = null;
        let response = null;

        try {
            response = await action();
        } catch (err) {
            error = err;
        }

        const shouldRetry = checkRetryCondition(error, response);
        return { error, response, shouldRetry };
    };

    const waitBeforeRetry = async () => {
        const delayMs = shouldBackoff ? baseDelayMs * backoffBase ** attempt : baseDelayMs;
        await new Promise((resolve) => setTimeout(resolve, delayMs));
    };

    while (attempt <= maxAttempts) {
        const { error, response, shouldRetry } = await execute();

        if (attempt === maxAttempts && shouldRetry) {
            throw new MaxRetriesReachedError("Max retries reached", error ?? response);
        }

        if (attempt === maxAttempts || !shouldRetry) {
            if (error) {
                throw error;
            }
            return response;
        }

        await waitBeforeRetry();
        if (onBeforeRetry) {
            await onBeforeRetry(error, response, maxAttempts - attempt);
        }
        attempt++;
    }
}