import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Transition } from "react-transition-group";

import { _ } from "js/vendor";
import { stopPropagation } from "js/core/utilities/stopPropagation";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

const TRANSITION_DURATION = 350;
const TRANSITION = `350ms ease`;

export const SIDE_MENU_BAR_WIDTH = 55;
export const LEFT_PANEL_WIDTH = 280;
export const RIGHT_PANEL_WIDTH = 250;

const LeftPanelContainer = styled.div`
    width: ${LEFT_PANEL_WIDTH}px;
    height: 100%;
    background: white;
    position: absolute;
    top: 0px;
    left: ${props => (props.open ? SIDE_MENU_BAR_WIDTH : -LEFT_PANEL_WIDTH + SIDE_MENU_BAR_WIDTH)}px;
    width: ${props => props.width ?? 280}px;
    transition: left 300ms;
    z-index: 2;
    box-shadow: 10px 0px 40px rgb(0 0 0 / 50%);
`;

const ResizeBar = styled.div`
  cursor: row-resize;
  height: 5px;
  position: absolute;
  width: 100%;
`;

export class LeftPanel extends Component {
    render() {
        const { children, open } = this.props;

        return (
            <LeftPanelContainer
                open={open}
                onWheel={stopPropagation()}
            >
                {children}
            </LeftPanelContainer>
        );
    }
}

const RightPanelContainer = styled.div.attrs(({ width, state }) => ({
    style: {
        width: `${width}px`,
        right: ["entering", "entered"].includes(state) ? 0 : -(width + 40),
        zIndex: ["entering", "entered"].includes(state) ? 3 : 2
    }
}))`
    height: 100%;
    background: white;
    position: absolute;
    top: 0px;
    transition: ${TRANSITION};
`;

export class RightPanel extends Component {
    render() {
        const { visible, width, children, reportTransitionState } = this.props;

        return (
            <Transition
                in={visible}
                timeout={TRANSITION_DURATION}
                mountOnEnter
                unmountOnExit
                onEnter={() => reportTransitionState("entering")}
                onEntered={() => reportTransitionState("entered")}
                onExiting={() => reportTransitionState("exiting")}
                onExited={() => reportTransitionState("exited")}
            >
                {state => (
                    <>
                        <RightPanelContainer
                            state={state}
                            width={width}
                            className={["entering", "entered"].includes(state) ? "activeSidePanel" : ""}
                            onWheel={stopPropagation()}
                        >
                            {React.cloneElement(React.Children.only(children), { transitionState: state })}
                        </RightPanelContainer>
                    </>
                )}
            </Transition>
        );
    }
}

const BottomPanelContainer = styled.div.attrs(({ height, state }) => ({
    style: {
        height: `${height}px`,
        bottom: ["entering", "entered"].includes(state) ? 0 : -(height),
        transition: ["entering", "exiting"].includes(state) ? TRANSITION : "none"
    }
}))`
    width: ${props => props.leftPanelVisible ? `calc(100% - ${LEFT_PANEL_WIDTH + SIDE_MENU_BAR_WIDTH}px)` : `calc(100% - ${SIDE_MENU_BAR_WIDTH}px)`};
    background: white;
    position: absolute;
    left: ${props => props.leftPanelVisible ? `${LEFT_PANEL_WIDTH + SIDE_MENU_BAR_WIDTH}px` : `${SIDE_MENU_BAR_WIDTH}px`};
    z-index: 5;
`;

export class BottomPanel extends Component {
    constructor(props) {
        super(props);

        this.startY = null;
        this.initialHeight = null;

        this.state = {
            userHeight: null,
            isResizing: false
        };
    }

    handleMouseDown = event => {
        const { userHeight } = this.state;
        const { height, reportResizeState } = this.props;

        event.preventDefault();
        event.stopPropagation();

        this.startY = event.screenY;
        this.initialHeight = userHeight ?? height;

        this.setState({ isResizing: true });

        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);

        reportResizeState("start", this.initialHeight);
    };

    handleMouseMove = ({ screenY }) => {
        window.requestAnimationFrame(timestamp => {
            if (this.dragHandledAt === timestamp) {
                return;
            }
            this.dragHandledAt = timestamp;

            const { userHeight, isResizing } = this.state;
            const { reportResizeState, availableBounds } = this.props;

            if (!isResizing) {
                return;
            }

            const diff = this.startY - screenY;
            let newHeight = this.initialHeight + diff;
            if (newHeight > userHeight && availableBounds.height < 250) {
                newHeight = userHeight;
            }
            newHeight = Math.min(newHeight, window.innerHeight / 2);

            this.setState({
                userHeight: newHeight
            });

            reportResizeState("resize", newHeight);
        });
    };

    handleMouseUp = event => {
        const { reportResizeState } = this.props;

        event.preventDefault();

        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);

        this.setState({ isResizing: false });

        reportResizeState("end", this.state.userHeight);
    };

    render() {
        const { userHeight, isResizing } = this.state;
        const { visible, leftPanelVisible, children, height, reportTransitionState } = this.props;

        const actualHeight = userHeight ?? height;

        return (
            <Transition
                in={visible}
                timeout={TRANSITION_DURATION}
                mountOnEnter
                unmountOnExit
                onEnter={() => reportTransitionState("entering", actualHeight)}
                onEntered={() => reportTransitionState("entered", actualHeight)}
                onExiting={() => reportTransitionState("exiting", actualHeight)}
                onExited={() => reportTransitionState("exited", 0)}
            >
                {state => (
                    <>
                        <BottomPanelContainer
                            state={state}
                            height={actualHeight}
                            leftPanelVisible={leftPanelVisible}
                            className={["entering", "entered"].includes(state) ? "activeSidePanel" : ""}
                            onWheel={stopPropagation()}
                            onMouseDown={stopPropagation()}
                        >
                            <ResizeBar onMouseDown={this.handleMouseDown} />
                            {React.cloneElement(React.Children.only(children), { transitionState: state, isResizing })}
                        </BottomPanelContainer>
                    </>
                )}
            </Transition>
        );
    }
}

export const ElementPanelContainer = PresentationEditorController.withFullState(class ElementPanelContainer extends Component {
    componentDidMount() {
        this.handleChanges({});
    }

    componentDidUpdate(prevProps) {
        this.handleChanges(prevProps);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.isCanvasGenerating;
    }

    handleChanges(prevProps = {}) {
        const { currentCanvasController, elementPanelElement, onClose, elementsWithSelection, selectedElements, canvasRenderKey } = this.props;

        const elementsWithElementPanel = (currentCanvasController.canvas?.getElements() ?? [])
            .filter(element => !!element.getElementPanel())
            .filter(element => !element.isDeleted);

        if (
            !_.isEqual(
                prevProps.selectedElements?.map(e => e.uniquePath + e.type),
                selectedElements?.map(e => e.uniquePath + e.type)
            ) || (prevProps.canvasRenderKey !== canvasRenderKey)
        ) {
            // Current element doesn't have panel
            if (!elementsWithElementPanel.includes(elementPanelElement)) {
                onClose();
            }

            const allElementsWithSelection = [...new Set([...selectedElements, ...elementsWithSelection])];

            // Still selected, don't need to do anything
            if (allElementsWithSelection.includes(elementPanelElement)) {
                return;
            }

            // A child is selected, don't need to do anything
            if (allElementsWithSelection.some(element => element.isChildOf(elementPanelElement))) {
                return;
            }

            // A parent is selected, don't need to do anything
            if (allElementsWithSelection.some(element => element.getElementPath().includes(elementPanelElement))) {
                return;
            }

            onClose();
        }
    }

    render() {
        const { elementPanelElement, ElementPanel } = this.props;

        if (!elementPanelElement || !ElementPanel) {
            return null;
        }

        return <ElementPanel {...this.props} element={elementPanelElement} />;
    }
});
