import React, { Component } from "react";
import styled from "styled-components";

import { ActionPermissionsObject } from "common/interfaces";
import WorkspaceController from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { PresentationPrivacyType } from "js/core/models/presentation";
import { app } from "js/namespaces";
import { ShowUpgradeDialog } from "js/react/components/Dialogs/BaseDialog";
import ProBadge from "js/react/components/ProBadge";
import { Section, UIPane, UIPaneContents, UIPaneHeader } from "js/react/components/UiComponents";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";

import PlayerSettings from "../components/PlayerSettings";

import "css/player-settings.scss";

const logger = getLogger(LogGroup.PLAYER);

export default class PlayerSettingsPane extends Component {
    constructor(props) {
        super(props);

        const presentation = props.presentation;
        const privacySetting = presentation.getPrivacySetting();

        // This is a terrible mess below... we should refactor this to be more readable
        // for now, just replacing features/permissions
        this.state = {
            privacySetting,
            removeBeautifulBranding: WorkspaceController.actionPermissions[ActionPermissionsObject.REMOVE_BAI_BRANDING].use,
            showTitle: presentation.get("showTitle"),
            showAuthor: presentation.get("showAuthor"),
            requireEmail: presentation.get("requireEmail") && privacySetting !== PresentationPrivacyType.PRIVATE,
            showSocialSharing: presentation.get("showSocialSharing") && privacySetting == PresentationPrivacyType.PUBLIC,
            showGetDeckUI: presentation.get("showGetDeckUI") && privacySetting === PresentationPrivacyType.PUBLIC,
            autoPlay: presentation.get("autoPlay"),
            autoPlayDuration: presentation.get("autoPlayDuration") || 5,
            autoLoop: presentation.get("autoLoop"),
            showBranding: WorkspaceController.actionPermissions[ActionPermissionsObject.PLAYER_BRANDING].use,
            allowSocialSharing: presentation.get("showSocialSharing") && privacySetting == PresentationPrivacyType.PUBLIC,
            allowPdfDownload: presentation.get("allowPdfDownload") && privacySetting !== PresentationPrivacyType.PRIVATE,
            presentationName: presentation.get("name"),
        };
    }

    componentDidMount = async () => {
        const { presentation } = this.props;

        try {
            const slideThumbnail = await presentation.getThumbnailUrl(null, "large");
            this.setState({ slideThumbnail });
        } catch (err) {
            logger.error(err, "[PlayerSettingsPane] Failed to get slide thumbnail");
        }
    }

    showUpgradeDialog = event => {
        if (WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.PLAYER_SETTINGS, "use")) {
            event.preventDefault();

            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "Player Settings", ...this.props.presentation.getAnalytics() },
                workspaceId: this.props.presentation.getWorkspaceId()
            });
        }
    };

    handleChange = deltaProps => {
        if (WorkspaceController.actionPermissions[ActionPermissionsObject.PLAYER_SETTINGS].use) {
            const { presentation } = this.props;
            presentation.update(deltaProps);
            this.setState(deltaProps);
        }
    }

    render() {
        const { presentation } = this.props;
        const {
            privacySetting,
            removeBeautifulBranding,
            showTitle,
            showAuthor,
            requireEmail,
            showSocialSharing,
            showGetDeckUI,
            autoPlay,
            autoPlayDuration,
            autoLoop,
            slideThumbnail,
            showBranding,
            allowSocialSharing,
            allowPdfDownload,
            presentationName,
        } = this.state;

        return (
            <UIPane className="player_settings">
                <UIPaneHeader>
                    Player Settings
                    <ProBadge
                        show={WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.PLAYER_SETTINGS, "use")}
                        analytics={{ cta: "Player Settings", ...presentation.getAnalytics() }}
                        workspaceId={presentation.getWorkspaceId()}
                    />
                </UIPaneHeader>
                <UIPaneContents>
                    <Section>
                        <PlayerSettings
                            privacySetting={privacySetting}
                            removeBeautifulBranding={removeBeautifulBranding}
                            showTitle={showTitle}
                            showAuthor={showAuthor}
                            creator={{
                                displayName: app.user.getDisplayName(),
                                photoURL: app.user.getAvatarUrl(),
                            }}
                            requireEmail={requireEmail}
                            showSocialSharing={showSocialSharing}
                            showGetDeckUI={showGetDeckUI}
                            includeAutoPlay={true}
                            autoPlay={autoPlay}
                            autoPlayDuration={autoPlayDuration}
                            autoLoop={autoLoop}
                            slideThumbnail={slideThumbnail}
                            showBranding={showBranding}
                            allowSocialSharing={allowSocialSharing}
                            allowPdfDownload={allowPdfDownload}
                            presentationName={presentationName}
                            promptForUpgrade={this.showUpgradeDialog}
                            handleChange={this.handleChange}
                        />
                    </Section>
                </UIPaneContents>
            </UIPane>
        );
    }
}

const AudioPreview = styled.div`
  background: #eee;
  color: black;
  padding: 6px 8px;
  display: inline-flex;
  align-items: center;
`;
