const genExportChannelId = payload => {
    const {
        assetType,
        presentationId,
        presentationLinkId,
        forPrinting
    } = payload;

    const printing = forPrinting ? "printing" : "no-printing";
    const linkId = presentationLinkId || "no-link-id";

    return `private-export-${assetType}@${presentationId}@${linkId}@${printing}`;
};

module.exports = genExportChannelId;
