import { getCallable } from "../../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import type {
    GetWorkspaceUserGroupsRequest,
    GetWorkspaceUserGroupsResponse,
    UpdateWorkspaceUserGroupsRequest,
    UpdateWorkspaceUserGroupsResponse
} from "./types";

const getWorkspaceUserGroups = getCallable<GetWorkspaceUserGroupsRequest, GetWorkspaceUserGroupsResponse>(
    apiName,
    "getWorkspaceUserGroups",
    {
        ...endpoints.getWorkspaceUserGroups,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/user-groups`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const updateWorkspaceUserGroups = getCallable<UpdateWorkspaceUserGroupsRequest, UpdateWorkspaceUserGroupsResponse>(
    apiName,
    "updateWorkspaceUserGroups",
    {
        ...endpoints.updateWorkspaceUserGroup,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/user-groups`,
                options: {
                    method: "PUT",
                    body: JSON.stringify(request)
                }
            };
        }
    });

export const callables = {
    getWorkspaceUserGroups,
    updateWorkspaceUserGroups
};
