import React, { Component } from "react";
import styled from "styled-components";

import { Icon } from "@material-ui/core";

import { FlexSpacer, Gap10 } from "../react/components/Gap";
import InfoToolTip from "../react/components/InfoToolTip";
import { FlexBox } from "../react/components/LayoutGrid";
import { themeColors } from "../react/sharedStyles";

const PropertySectionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: ${props => props.showDivider ? "solid 1px rgba(0,0,0,.125)" : "none"};
    min-height: 46px;
    flex-shrink: 0;

    .popupmenu-icon {
        font-size: 16px;
    }

    .section-label {
        font-size: 15px;
        color: #333;
        font-weight: 600;
        text-transform: capitalize;
    }
    
    &:last-child {
        border-bottom: none;
    }

    cursor: ${props => props.onClick ? "pointer" : "default"};
    background: ${props => props.color ? props.color + " !important" : null};
`;

export const PropertyPanelContainer = styled.div.attrs(({ fullHeight }) => ({
    style: {
        height: fullHeight ? "100%" : undefined
    }
}))`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    background: #f9f9f9;

    ${PropertySectionContainer}:nth-child(even) {
        background: #f0f0f0;
    }
`;

export const PropertySectionList = styled.div`
    padding: 10px 15px; //TODO add media query for smaller screens
    @media (max-height: 800px) {
        padding: 8px 15px;
    }
        
    display: flex;
    gap: 12px;
    width: 100%;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;

    & > .withlabel {
        min-height: 30px;
    }

    .withlabel.left {
        .label-container {
            flex-grow: 2;
            justify-content: flex-start !important;
        }
    }

    .bai-slider {
        flex-grow: 100;

        .MuiSlider-root {
            color: #11a9e2;
        }        
    }

    .MuiButton-root {
        background: #ddd;
    }
    
    .MuiToggleButton-sizeSmall {
        padding: 7px;

        .MuiIcon-root {
            font-size: 18px;
        }
    }
`;

export const PropertySelectionDivider = styled.div`
    width: 100%;
    background: ${themeColors.selection};
    color: rgba(0, 0, 0, 0.75);
    border-bottom: solid 1px #ccc;
    padding: 10px 15px;
    font-weight: 600;
    text-transform: uppercase;
    border-top: solid 1px #333;
`;

export class PropertySection extends Component {
    render() {
        const { fullWidth, color, showDivider = true } = this.props;

        const children = this.props.children && React.Children.map(this.props.children, child => child).filter(child => child);

        let Contents = [];
        if (children?.length) {
            if (fullWidth) {
                const sectionHeader = children.find(child => child.type.name === "PropertySectionHeader");
                if (sectionHeader) {
                    Contents.push(<PropertySectionList key={Contents.length}>{sectionHeader}</PropertySectionList>);
                }
                Contents.push(children.filter(child => child.type.name !== "PropertySectionHeader"));
            } else {
                Contents = <PropertySectionList>{children}</PropertySectionList>;
            }
            return (
                <PropertySectionContainer showDivider={showDivider} color={color}>
                    {Contents}
                </PropertySectionContainer>
            );
        } else {
            return null;
        }
    }
}

export const PropertySectionNote = styled.div`
    max-width: 400px;
    padding: 15px 15px;
    font-size: 13px;
    font-weight: 600;
    background: ${themeColors.selection};
    p + p {
        margin-top: 10px;
    }
`;

export const HorizontalPropertyList = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: ${props => props.distribute ? "space-between" : "flex-start"};
    gap: ${props => props.distribute ? 0 : (props.gap ?? 20)}px;
`;

export const PropertyButtonSection = styled.div`
    border-bottom: solid 1px #ccc;
`;

export const PropertySectionContents = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
`;

const PropertySectionHeaderContainer = styled.div`
    width: calc(100% + 5px);
    display: flex;
    align-items: center;
    gap: ${props => props.gap ?? 10}px;
    margin-right: -10px;
    
    .MuiButtonBase-root {
        padding: 0px;
        height: 25px;
        font-size: 10px;
    }

    .MuiIconButton-root {
        width: 25px;
        height: 25px;

        .MuiIcon-root {
            font-size: 16px;
        }

        &:hover {
            background: ${themeColors.selection};

            .MuiIcon-root {
                color: #333;
            }
        }
    }
`;

const PropertySectionHeaderLabel = styled.div`
    color: #111;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
`;

export function PropertySectionHeader({ children, label, tooltip, gap = 5 }) {
    return (
        <PropertySectionHeaderContainer className="property-section-header" gap={gap}>
            <PropertySectionHeaderLabel>{label}</PropertySectionHeaderLabel>
            {tooltip &&
                <>
                    <Gap10 />
                    <InfoToolTip title={tooltip} />
                </>
            }
            <FlexSpacer />
            {children}
        </PropertySectionHeaderContainer>
    );
}

export const PropertySectionButtonContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 8px 15px;
    border-bottom: solid 1px #ccc;
    cursor: pointer;

    .section-label {
        font-size: 13px;
        color: #333;
        font-weight: 600;
        text-transform: capitalize;
        cursor: pointer;
    }

    .MuiButtonBase-root {
        padding: 0px;
        height: 25px;
        font-size: 10px;
    }

    .MuiIcon-root {
        font-size: 24px;
        color: ${themeColors.ui_blue};
    }

    .label-icon {
        font-size: 20px;
        color: ${themeColors.ui_blue};
    }

    background: ${themeColors.selection};

    &:hover, &.isOpen {
        background: ${themeColors.ui_blue50percent};

        .MuiIcon-root {
            color: ${themeColors.darkBlue};
        }

        .description {
            margin-top: 5px;
            max-height: 30px;
            opacity: 1;
        }
    }

    .description {
        overflow: hidden;
        font-size: 12px;
        color: #777;
        height: auto;
        max-height: 0px;
        opacity: 0;
        transition: 300ms;
    }
`;

export function AddElementButton({ label, icon, onClick }) {
    return (
        <PropertySectionContainer onClick={onClick} color={themeColors.selection}>
            <FlexBox left middle fillWidth>
                <label className="section-label">{label}</label>
                <FlexSpacer />
                <Icon color="primary">{icon}</Icon>
            </FlexBox>
        </PropertySectionContainer>
    );
}

export function PropertyPanelDivider() {
    return (<div style={{ width: "100%", height: 1, background: "#ccc" }} />);
}

export const MoreButton = styled.div`
    padding: 0px;
    color: #555;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    text-align: right;
`;

export const PropertyPanelChildElementListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 600;
    border: solid 1px #ccc;
    background: white;
    text-transform: capitalize;

    .controls {
        opacity: 0;
    }

    &:hover {
        background: ${themeColors.selection};
        .controls {
            opacity: 1;
        }
    }
`;

export const SectionTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    color: #333;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    .bai-icon {
        margin-right: 5px;
    }
`;

export const SectionDescription = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: #444;
`;
