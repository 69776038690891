import _ from "lodash";

import { ActionPermissionsObject } from "./models";

export enum WorkspacePlanTier {
    BASIC = "basic",
    PRO = "pro",
    TEAM = "team",
    ENTERPRISE = "enterprise"
}

export const DEFAULT_WORKSPACE_PLAN_NAMES: Record<WorkspacePlanTier, string> = {
    [WorkspacePlanTier.BASIC]: "Basic",
    [WorkspacePlanTier.PRO]: "Pro",
    [WorkspacePlanTier.TEAM]: "Team",
    [WorkspacePlanTier.ENTERPRISE]: "Enterprise"
};

export type IWorkspacePlanPermissions = Record<ActionPermissionsObject, boolean>;

export interface IWorkspacePlan {
    /** The tier of the plan, defines the features available to the workspace */
    tier: WorkspacePlanTier;
    /** The name of the plan */
    name: string;
    /** Indicates if the plan requires a stripe subscription in order to be used */
    requiresSubscription: boolean;
    /** Indicates if the plan is managed by an account manager (i.e. not self-serve) */
    isManaged: boolean;
    /** The set of action permissions allowed by the plan */
    permissions: IWorkspacePlanPermissions;
}

/**
 * Action permissions that are feature-dependent
 */
const BASE_FEATURE_ACTION_PERMISSIONS = {
    [ActionPermissionsObject.ANALYTICS]: false,
    [ActionPermissionsObject.BRAND_GUARDRAILS]: false,
    [ActionPermissionsObject.CONVERT_TO_CLASSIC]: false,
    [ActionPermissionsObject.CUSTOM_FONTS]: false,
    [ActionPermissionsObject.DATA_LINKING]: false,
    [ActionPermissionsObject.DESKTOP_APP]: false,
    [ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE]: false,
    [ActionPermissionsObject.GENERATIVE_AI]: false,
    [ActionPermissionsObject.INTEGRATIONS]: false,
    [ActionPermissionsObject.PLAYER_BRANDING]: false,
    [ActionPermissionsObject.PLAYER_SETTINGS]: false,
    [ActionPermissionsObject.PRESENTATION_LINKS_DISABLING]: false,
    [ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION]: false,
    [ActionPermissionsObject.REMOVE_BAI_BRANDING]: false,
    [ActionPermissionsObject.RESOURCE_FOLDERS]: false,
    [ActionPermissionsObject.REVISION_HISTORY]: false,
    [ActionPermissionsObject.SECURED_SHARING]: false,
    [ActionPermissionsObject.SHARED_RESOURCES]: false,
    [ActionPermissionsObject.SSO]: false,
    [ActionPermissionsObject.USER_GROUPS]: false
};

const BASE_NON_FEATURE_ACTION_PERMISSIONS = {
    [ActionPermissionsObject.IMPORT_PPT]: true,
    [ActionPermissionsObject.BILLING]: true,
    [ActionPermissionsObject.CLASSIC_SLIDES]: true,
    [ActionPermissionsObject.CUSTOM_THEMES]: true,
    [ActionPermissionsObject.EXPORT_PRESENTATION]: true,
    [ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION]: true,
    [ActionPermissionsObject.EXTERNAL_WORKSPACES_RESOURCE_TRANSFER]: true,
    [ActionPermissionsObject.INSPIRATION_SLIDES]: true,
    [ActionPermissionsObject.RESOURCES]: true,
    [ActionPermissionsObject.STOCK_LIBRARIES]: true,
    [ActionPermissionsObject.USERS]: true,
    [ActionPermissionsObject.WEB_IMAGES]: true,
    [ActionPermissionsObject.WORKSPACE_GUESTS]: true,
    [ActionPermissionsObject.WORKSPACE_NAME]: true
};

const DEFAULT_PLAN_TIERS_PERMISSIONS: Record<WorkspacePlanTier, IWorkspacePlanPermissions> = {
    [WorkspacePlanTier.BASIC]: {
        ...BASE_NON_FEATURE_ACTION_PERMISSIONS,
        ...BASE_FEATURE_ACTION_PERMISSIONS
    },
    [WorkspacePlanTier.PRO]: {
        ...BASE_NON_FEATURE_ACTION_PERMISSIONS,
        [ActionPermissionsObject.ANALYTICS]: true,
        [ActionPermissionsObject.BRAND_GUARDRAILS]: true,
        [ActionPermissionsObject.CONVERT_TO_CLASSIC]: true,
        [ActionPermissionsObject.CUSTOM_FONTS]: true,
        [ActionPermissionsObject.DATA_LINKING]: false,
        [ActionPermissionsObject.DESKTOP_APP]: true,
        [ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE]: true,
        [ActionPermissionsObject.GENERATIVE_AI]: true,
        [ActionPermissionsObject.INTEGRATIONS]: false,
        [ActionPermissionsObject.PLAYER_BRANDING]: true,
        [ActionPermissionsObject.PLAYER_SETTINGS]: true,
        [ActionPermissionsObject.PRESENTATION_LINKS_DISABLING]: true,
        [ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION]: true,
        [ActionPermissionsObject.REMOVE_BAI_BRANDING]: true,
        [ActionPermissionsObject.RESOURCE_FOLDERS]: true,
        [ActionPermissionsObject.REVISION_HISTORY]: true,
        [ActionPermissionsObject.SECURED_SHARING]: true,
        [ActionPermissionsObject.SHARED_RESOURCES]: false,
        [ActionPermissionsObject.SSO]: false,
        [ActionPermissionsObject.USER_GROUPS]: false
    },
    [WorkspacePlanTier.TEAM]: {
        ...BASE_NON_FEATURE_ACTION_PERMISSIONS,
        [ActionPermissionsObject.ANALYTICS]: true,
        [ActionPermissionsObject.BRAND_GUARDRAILS]: true,
        [ActionPermissionsObject.CONVERT_TO_CLASSIC]: true,
        [ActionPermissionsObject.CUSTOM_FONTS]: true,
        [ActionPermissionsObject.DATA_LINKING]: true,
        [ActionPermissionsObject.DESKTOP_APP]: true,
        [ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE]: true,
        [ActionPermissionsObject.GENERATIVE_AI]: true,
        [ActionPermissionsObject.INTEGRATIONS]: true,
        [ActionPermissionsObject.PLAYER_BRANDING]: true,
        [ActionPermissionsObject.PLAYER_SETTINGS]: true,
        [ActionPermissionsObject.PRESENTATION_LINKS_DISABLING]: true,
        [ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION]: true,
        [ActionPermissionsObject.REMOVE_BAI_BRANDING]: true,
        [ActionPermissionsObject.RESOURCE_FOLDERS]: true,
        [ActionPermissionsObject.REVISION_HISTORY]: true,
        [ActionPermissionsObject.SECURED_SHARING]: true,
        [ActionPermissionsObject.SHARED_RESOURCES]: true,
        [ActionPermissionsObject.SSO]: true,
        [ActionPermissionsObject.USER_GROUPS]: true
    },
    [WorkspacePlanTier.ENTERPRISE]: {
        ...BASE_NON_FEATURE_ACTION_PERMISSIONS,
        [ActionPermissionsObject.ANALYTICS]: true,
        [ActionPermissionsObject.BRAND_GUARDRAILS]: true,
        [ActionPermissionsObject.CONVERT_TO_CLASSIC]: true,
        [ActionPermissionsObject.CUSTOM_FONTS]: true,
        [ActionPermissionsObject.DATA_LINKING]: true,
        [ActionPermissionsObject.DESKTOP_APP]: true,
        [ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE]: true,
        [ActionPermissionsObject.GENERATIVE_AI]: true,
        [ActionPermissionsObject.INTEGRATIONS]: true,
        [ActionPermissionsObject.PLAYER_BRANDING]: true,
        [ActionPermissionsObject.PLAYER_SETTINGS]: true,
        [ActionPermissionsObject.PRESENTATION_LINKS_DISABLING]: true,
        [ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION]: true,
        [ActionPermissionsObject.REMOVE_BAI_BRANDING]: true,
        [ActionPermissionsObject.RESOURCE_FOLDERS]: true,
        [ActionPermissionsObject.REVISION_HISTORY]: true,
        [ActionPermissionsObject.SECURED_SHARING]: true,
        [ActionPermissionsObject.SHARED_RESOURCES]: true,
        [ActionPermissionsObject.SSO]: true,
        [ActionPermissionsObject.USER_GROUPS]: true
    }
};

const DEFAULT_BASIC_PLAN: IWorkspacePlan = {
    tier: WorkspacePlanTier.BASIC,
    name: DEFAULT_WORKSPACE_PLAN_NAMES[WorkspacePlanTier.BASIC],
    requiresSubscription: false,
    isManaged: false,
    permissions: DEFAULT_PLAN_TIERS_PERMISSIONS[WorkspacePlanTier.BASIC]
};

const RESTRICTED_PLAN_PERMISSIONS = Object.fromEntries(Object.values(ActionPermissionsObject).map(action => [action, false])) as IWorkspacePlanPermissions;

export function GET_DEFAULT_BASIC_PLAN(): IWorkspacePlan {
    return _.cloneDeep(DEFAULT_BASIC_PLAN);
}

export function GET_DEFAULT_PLAN_PERMISSIONS(planTier: WorkspacePlanTier): IWorkspacePlanPermissions {
    return _.cloneDeep(DEFAULT_PLAN_TIERS_PERMISSIONS[planTier]);
}

export function GET_DEFAULT_RESTRICTED_PLAN_PERMISSIONS(): IWorkspacePlanPermissions {
    return _.cloneDeep(RESTRICTED_PLAN_PERMISSIONS);
}
