import { loadStripe, Stripe, StripeError } from "@stripe/stripe-js";

const key = (window as any).stripePublishableKey;
const stripePromise = key ? loadStripe(key) : Promise.resolve(null);

export async function handleStripeErrors<T extends { error?: StripeError }>(stripeCall: () => Promise<T>) {
    const { error, ...rest } = await stripeCall();
    if (error) {
        throw new Error(error.message);
    }
    return rest;
}

export async function handlePaymentIntentResult(stripe: Stripe, { status, client_secret, payment_method }: { status: string, client_secret: string, payment_method: string }) {
    if (status === "succeeded") {
        return;
    }

    if (status === "requires_setup_action") {
        await handleStripeErrors(() => stripe.confirmCardSetup(client_secret, {
            payment_method
        }));
        return;
    }

    if (status === "requires_action") {
        await handleStripeErrors(() => stripe.confirmCardPayment(client_secret, {
            payment_method
        }));
        return;
    }

    if (status === "requires_payment_method") {
        throw new Error("Payment method is missing or invalid.");
    }

    throw new Error("Unexpected error occurred.");
}

export async function getStripe() {
    return await stripePromise;
}
