import { Snackbar } from "@material-ui/core";
import React, { Component } from "react";
import styled from "styled-components";

import { Slide } from "js/core/models/slide";
import { FadeIn } from "js/react/components/animation";
import Button from "js/react/components/Button2";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { Notice } from "js/react/components/Notice";
import Spinner from "js/react/components/Spinner";
import {
    StyledWarningIcon,
    UIPane,
    UIPaneContents,
    UIPaneHeader,
    WarningBox,
    WarningButton
} from "js/react/components/UiComponents";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import { SlideThumbnail } from "js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import { _ } from "js/vendor";

function fetchDeletedSlides(presentation) {
    let deletedSlides = presentation.get("deletedSlides") || {};
    let slideIds = _.toPairs(deletedSlides)
        .sort((l, r) => r[1] - l[1])
        .slice(0, 100)
        .map(s => s[0]);
    return Promise.all(
        slideIds.map(slideId => {
            return new Slide({ id: slideId }, {
                autoSync: true,
                presentation
            }).load();
        })
    );
}

const DeletedSlides = styled.div`
    display: grid;
    width: 100%;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 300px);

    .slide-thumbnail {
      width: 200px;
    }
`;

const DeletedSlide = styled.div`
  position: relative;
  button {
    opacity: 0;
    transition: opacity 300ms;
  }
  &:hover {
    button {
      opacity: 1;
    }
  }
`;

const ButtonBox = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
`;

export default class VersionHistoryPane extends Component {
    state = {
        isLoading: true,
        slides: []
    };

    componentWillMount() {
        fetchDeletedSlides(this.props.presentation).then(slides => {
            const nonLibrarySlides = slides.filter(slide => !slide.has("libraryItemId"));
            this.setState({ isLoading: false, slides: nonLibrarySlides });
        });
    }

    render() {
        return (
            <UIPane>
                <UIPaneHeader className="recover-deleted-slides-header">
                    Recover Deleted Slides
                    <div className="recover-deleted-slides-header-warning-message">
                        <StyledWarningIcon>warning</StyledWarningIcon>
                        <Gap10 />
                        <WarningBox>Items in your trash will be permanently deleted after 30 days</WarningBox>
                        <Gap20 />
                        <WarningButton
                            // @ts-ignore
                            onClick={this.handleEmptyTrash}>
                            Empty Trash
                        </WarningButton>
                    </div>
                </UIPaneHeader>
                <UIPaneContents className="recover-deleted-slides-content">
                    <Gap10 />
                    {this.renderSlides()}
                    <Snackbar
                        message="Deleted slide has been restored to the end of your presentation"
                        open={this.state.actionCompleted}
                        onClose={() =>
                            this.setState({ actionCompleted: false })
                        }
                        autoHideDuration={3000}
                    />
                </UIPaneContents>
            </UIPane>
        );
    }

    renderSlides() {
        if (this.state.isLoading) {
            return <Spinner />;
        } else if (this.state.slides.length) {
            return (
                <div className="deleted-slides slidegrid">
                    {this.state.slides.map(slide => {
                        return (
                            <DeletedSlide key={slide.id}>
                                <FadeIn>
                                    <SlideThumbnail slideId={slide.id} />
                                </FadeIn>
                                <ButtonBox>
                                    <Button
                                        icon="history"
                                        className="button"
                                        onClick={() =>
                                            this.recoverDeletedSlide(slide)
                                        }
                                        label="Recover Slide"
                                    />
                                </ButtonBox>
                            </DeletedSlide>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <Notice
                    title="Trash is empty"
                />
            );
        }
    }

    recoverDeletedSlide(slide) {
        let { presentation } = this.props;

        let insertIndex = presentation.slides.length;
        let attributes = _.cloneDeep(slide.attributes);

        presentation.addExistingSlides(attributes, insertIndex)
            .then(() => {
                let slides = this.state.slides;
                slides.remove(slide);
                this.setState({ slides });
                this.setState({ actionCompleted: true });
            });
    }

    handleEmptyTrash = () => {
        const { presentation } = this.props;
        const { slides: deletedSlides } = this.state;

        ShowConfirmationDialog({
            title: "Are you sure you want to permanently delete all of the slides in the trash?",
            message: "You can't undo this action.",
            acceptCallback: async () => {
                await presentation.deleteSlidesPermanently(deletedSlides.map(s => s.id));
                this.setState({ slides: [] });
            }
        });
    }
}
