import React, { useEffect, useRef } from "react";

export const usePrev = <T = any>(value: T) => {
    const ref = useRef<T>(value);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};
