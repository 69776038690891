import { v4 as uuid } from "uuid";

import { _ } from "js/vendor";
import { isInternalBAIUser } from "js/config";
import { app } from "js/namespaces";
import Api from "js/core/api";
import isConnected from "js/core/utilities/isConnected";
import { presentations as presentationsApi, player as playerApi } from "apis/callables";
import getUserProfile from "js/core/services/userProfiles";
import { median, trackActivity, trackState } from "js/core/utilities/utilities";
import getLogger, { LogGroup } from "js/core/logger";
import { getAnalyticsPropsForWorkspace } from "js/controllers/WorkspaceController";
import { PlayerSalesforceEvents } from "common/interfaces";

const logger = getLogger(LogGroup.ANALYTICS);

export const PlayerUnmountTrigger = {
    ESC_KEY: "escKey",
    WINDOW_UNLOAD: "windowUnload",
    END_PAGE: "endPage",
    UI_EXIT_BUTTON: "uiExitButton",
    BROWSER_BACK_BUTTON: "browserBackButton"
};

export const AnalyticsContextType = {
    OFFLINE: "offline",
    LIBRARY: "library",
    EDITOR: "editor",
    APP: "app"
};

// NOTE: actual action names mimic the old actions used for analytics
const Action = {
    PLAYBACK_STARTED: "player-start",
    PLAYBACK_COMPLETED: "player-completed",
    PLAYBACK_REPLAYED: "player-replay",
    PLAYBACK_NAVIGATED: "playback-navigate",
    SLIDE_VIEWED: "player-view-slide",
    DOWNLOAD_PDF: "player-download-pdf",
    PLAYER_UNMOUNT: "player-close",
    USE_PRESENTATION_AS_TEMPLATE: "use-presentation-as-template"
};

/**
 * Generates (or retrieves) an anonymous "player id" as a makeshift uid
 */
function getAnonymousPlayerId() {
    const playerIdLocalStorageRecordName = "playerid:beautiful.ai";
    let playerId = localStorage.getItem(playerIdLocalStorageRecordName);
    if (!playerId) {
        playerId = `player-${uuid().replace("-", "")}`;
        localStorage.setItem(playerIdLocalStorageRecordName, playerId);
    }
    return playerId;
}

/**
 * Calculates the correct source value for analytics
 */
function getSource(analyticsContextType, isEmbedded) {
    if (analyticsContextType === AnalyticsContextType.OFFLINE) {
        return "electron";
    } else if (analyticsContextType === AnalyticsContextType.LIBRARY) {
        return "library";
    } else if (analyticsContextType === AnalyticsContextType.EDITOR) {
        return "editor";
    } else if (isEmbedded) {
        return "iframe";
    } else {
        return "direct";
    }
}

function trackAnalytics(
    action,
    {
        isEmbedded,
        contextType,
        referrer,
        user,
        username,
        isCollaborator,
        presentation,
        slideId = null,
        playerUnmountTrigger = null,
        slidesPlaybackTimesMs = null,
        presenterWasUsed = null,
        method = null,
        deviceType = null
    }
) {
    // Skip analytics when in offline player
    if (contextType === AnalyticsContextType.OFFLINE) {
        return;
    }

    // This is legacy code, should revisit in future
    const internalUserIds = new Set([
        "UEuzR0bD2wTwZdlEGO1qUWJbuI42",
        "ogUOhPjqkYbfF6kq0ZMGBAjMCEh1",
        "NzuCwy9B4KR5qJ21jL4nMrA0gpE2"
    ]);

    const ownerId = presentation.get("userId");

    if (action === Action.PLAYBACK_STARTED) {
        (async () => {
            if (app.user && contextType !== AnalyticsContextType.OFFLINE) {
                const workspaceId = presentation.getWorkspaceId();
                const workspaceProps = await getAnalyticsPropsForWorkspace(workspaceId, app.user.id);
                trackState({
                    ...workspaceProps,
                    presentation_id: presentation.id,
                });
            } else {
                trackState({
                    workspace_id: "personal",
                    workspace_type: "personal",
                    presentation_id: presentation.id,
                });
            }

            trackActivity("Player", "Open", null, null, {
                presentation_id: presentation.id,
                owner: ownerId === user?.uid,
                ownerId,
                source: getSource(contextType, isEmbedded),
                referrer,
                isMarketingDeck: internalUserIds.has(ownerId)
            });

            if (isCollaborator) {
                trackActivity("Collab", "ViewPresentation", presentation.id);
            }

            if (username && !isCollaborator && presentation.get("link")?.salesforce) {
                playerApi.reportPlayerSalesforceEvent({
                    type: PlayerSalesforceEvents.PLAYER_OPENED,
                    linkId: presentation.get("link").id,
                    viewerEmail: username
                }).catch(err => logger.error(err, "trackAnalytics() salesforce failed", { action }));
            }
        })();

        return;
    }

    if (action === Action.PLAYBACK_COMPLETED) {
        const timesPerSlide = Object.values(slidesPlaybackTimesMs);
        trackActivity("Player", "Completed", null, null, {
            presentation_id: presentation.id,
            owner: ownerId === user?.uid,
            ownerId,
            source: getSource(contextType, isEmbedded),
            referrer,
            isMarketingDeck: internalUserIds.has(ownerId),
            presenterWasUsed,
            average: Math.round(_.mean(timesPerSlide) / 1000),
            median: Math.round(median(timesPerSlide) / 1000)
        });
        return;
    }

    if (action === Action.PLAYBACK_NAVIGATED) {
        trackActivity("Player", "Navigated", null, null, {
            presentation_id: presentation.id,
            method,
            deviceType,
            owner: ownerId === user?.uid,
            ownerId,
            source: getSource(contextType, isEmbedded),
            referrer,
            isMarketingDeck: internalUserIds.has(ownerId)
        });
        return;
    }

    if (action === Action.USE_PRESENTATION_AS_TEMPLATE) {
        trackActivity(
            "Presentation",
            "GetDeck",
            null,
            null,
            {
                presentationId: presentation.id,
                source: "player"
            },
            { audit: true }
        );
        return;
    }

    if (action === Action.SLIDE_VIEWED) {
        if (username && !isCollaborator && presentation.get("link")?.salesforce) {
            playerApi.reportPlayerSalesforceEvent({
                type: PlayerSalesforceEvents.PLAYER_PROGRESS,
                linkId: presentation.get("link").id,
                slidesPlaybackTimesMs,
                viewerEmail: username
            }).catch(err => logger.error(err, "trackAnalytics() salesforce failed", { action }));
        }
    }

    if (action === Action.PLAYER_UNMOUNT) {
        let exitVia = null;
        if (playerUnmountTrigger === PlayerUnmountTrigger.ESC_KEY) {
            exitVia = "esc";
        } else if (playerUnmountTrigger === PlayerUnmountTrigger.WINDOW_UNLOAD) {
            exitVia = "browser_close/refresh";
        } else if (playerUnmountTrigger === PlayerUnmountTrigger.UI_EXIT_BUTTON) {
            exitVia = "player_ui";
        } else if (playerUnmountTrigger === PlayerUnmountTrigger.END_PAGE) {
            exitVia = "last_slide";
        } else if (playerUnmountTrigger === PlayerUnmountTrigger.BROWSER_BACK_BUTTON) {
            exitVia = "browser_back_button";
        }

        let maxSlideReachedIndex = 0;
        let skippedSlidesCount = 0;
        let viewedSlidesCount = 0;
        let shortViewedSlidesCount = 0;
        let longViewedSlidesCount = 0;
        const slideRefs = presentation.get("slideRefs");
        Object.entries(slidesPlaybackTimesMs)
            .forEach(([slideId, slidePlaybackTimeMs]) => {
                if (slidePlaybackTimeMs > 0) {
                    const slideIndex = slideRefs[slideId];
                    maxSlideReachedIndex = Math.max(maxSlideReachedIndex, slideIndex);
                }

                if (slidePlaybackTimeMs < 2000) {
                    skippedSlidesCount++;
                } else {
                    viewedSlidesCount++;
                    if (slidePlaybackTimeMs < 15000) {
                        shortViewedSlidesCount++;
                    } else {
                        longViewedSlidesCount++;
                    }
                }
            });

        trackActivity("Presentation", "PresentClose", null, null, {
            current_slide_count: (app.presentationLibraryController?.getSlideCount(presentation.getWorkspaceId()) ?? 0),
            presentation_id: presentation.id,
            presentation_slide_count: presentation.getSlideCount(),
            skip_count: skippedSlidesCount,
            short_view_count: shortViewedSlidesCount,
            long_view_count: longViewedSlidesCount,
            unique_slides_viewed: viewedSlidesCount,
            max_slide_reached: maxSlideReachedIndex + 1,
            total_time: Object.values(slidesPlaybackTimesMs).reduce((totalTime, slideTime) => totalTime + slideTime, 0),
            exit_slide_index: slideRefs[slideId] + 1,
            exit_via: exitVia
        });

        if (username && !isCollaborator && presentation.get("link")?.salesforce) {
            playerApi.reportPlayerSalesforceEvent({
                type: PlayerSalesforceEvents.PLAYER_CLOSED,
                linkId: presentation.get("link").id,
                slidesPlaybackTimesMs,
                viewerEmail: username
            }).catch(err => logger.error(err, "trackAnalytics() salesforce failed", { action }));
        }
    }
}

async function trackPlayer(
    action,
    {
        contextType,
        referrer,
        analyticsGroupId,
        username,
        presentation,
        slideId = null,
        playbackTimeMs = null
    }
) {
    // Won't track player close and use as template actions
    if (action === Action.PLAYER_UNMOUNT || action === Action.USE_PRESENTATION_AS_TEMPLATE) {
        return;
    }

    // Skip analytics when offline (of when in offline player)
    if (!isConnected.connected || contextType === AnalyticsContextType.OFFLINE) {
        return;
    }

    // Autoloop plays don't really indicate viewing behavior so should not be tracked
    if (presentation.get("autoLoop") === true) {
        return;
    }

    // Getting link id
    let linkId = null;
    if (contextType === AnalyticsContextType.APP && presentation.has("link")) {
        linkId = presentation.get("link").id;
    }

    // We do not want to track analytics if there is no linkId
    if (!linkId) {
        return;
    }

    // Do not record analytics if user is b.ai employee and link was created by non-b.ai employee
    if (linkId && isInternalBAIUser(username)) {
        // If we're not anonymous, then we can verify the link creator's email
        if (app.user) {
            const { email } = await getUserProfile(presentation.get("link").createdBy);
            if (email && !isInternalBAIUser(email)) {
                return;
            }
        }
    }

    // Report link opened to increment is views counter
    if (action === Action.PLAYBACK_STARTED) {
        await presentationsApi.incrementLinkViewCount({
            id: presentation.id,
            linkId,
        });
    }

    // Payload for the trackPlayer api call
    const trackPlayerPayload = {
        action,
        props: {
            linkid: linkId,
            groupid: analyticsGroupId,
            presentationid: presentation.id,
            ownerid: presentation.get("userId"),
            username: username ?? "logged out",
            referrer,
            organization_id: presentation.get("orgId"),
            // Optional props
            slideid: slideId,
            slideindex: slideId ? presentation.get("slideRefs")[slideId] : null,
            // "seconds" is an old naming which we have to preserve, the variable actually contains milliseconds
            seconds: playbackTimeMs,
        },
        playerId: username ? null : getAnonymousPlayerId()
    };

    await Api.trackPlayer.post(trackPlayerPayload);
}

function track(action, props) {
    // skip tracking player analytics when in zoom app
    if (window.isZoomApp) return;

    try {
        trackAnalytics(action, props);
        trackPlayer(action, props)
            .catch(err => logger.error(err, "player analytics trackPlayer() failed", { action }));
    } catch (err) {
        // Sync errors will be catched as well
        logger.error(err, "player analytics track() failed", { action });
    }
}

export function getAnalytics(contextType, isEmbedded, user, isCollaborator, presentation) {
    const defaultTrackProps = {
        contextType,
        isEmbedded,
        referrer: (document.referrer && !document.referrer.includes(location.host)) ? document.referrer : "direct",
        // Unique id of current analytics session
        analyticsGroupId: uuid(),
        username: user?.email,
        isCollaborator,
        presentation
    };

    return {
        trackPlaybackStarted: ({ username }) => track(Action.PLAYBACK_STARTED, { ...defaultTrackProps, username }),
        trackPlaybackCompleted: ({ slidesPlaybackTimesMs, playbackTimeMs, presenterWasUsed, username }) => track(Action.PLAYBACK_COMPLETED, { ...defaultTrackProps, slidesPlaybackTimesMs, playbackTimeMs, presenterWasUsed, username }),
        trackPlaybackReplayed: ({ username }) => track(Action.PLAYBACK_REPLAYED, { ...defaultTrackProps, username }),
        trackPlaybackNavigated: ({ method, deviceType, username = defaultTrackProps.username }) => track(Action.PLAYBACK_NAVIGATED, { ...defaultTrackProps, method, deviceType, username }),
        trackSlideViewed: ({ slideId, slidesPlaybackTimesMs, playbackTimeMs, username }) => track(Action.SLIDE_VIEWED, { ...defaultTrackProps, slideId, slidesPlaybackTimesMs, playbackTimeMs, username }),
        trackDownloadPdf: ({ username }) => track(Action.DOWNLOAD_PDF, { ...defaultTrackProps, username }),
        trackPlayerUnmount: ({ playerUnmountTrigger, slidesPlaybackTimesMs, slideId, username }) => track(Action.PLAYER_UNMOUNT, { ...defaultTrackProps, playerUnmountTrigger, slidesPlaybackTimesMs, slideId, username }),
        trackUsePresentationAsTemplate: ({ username } = {}) => track(Action.USE_PRESENTATION_AS_TEMPLATE, { ...defaultTrackProps, username })
    };
}
