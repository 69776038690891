import type { EndpointDefinition } from "../../_sdk/types/common";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getWorkspacesMetadata: {
        route: "/workspaces/metadata",
        method: "GET"
    },
    getWorkspaceResourceFolderMetadata: {
        route: "/workspaces/:workspaceId/resource-folders/:resourceFolderId/metadata",
        method: "GET"
    },
};
