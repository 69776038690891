import { EndpointCallableDefinition } from "./types/client";
import {
    BadRequestError,
    NotFoundError,
    InternalServerError,
    UnauthorizedError,
    ForbiddenError,
    TooManyRequestsError,
    ConflictError,
    ErrorResponseBody,
    PaymentRequiredError
} from "./types/errors";
import getLogger, { LogGroup, sessionId } from "../../js/core/logger";
import { auth } from "../../js/firebase/auth";
import { withRetry } from "../../common/utils/withRetry";

const logger = getLogger(LogGroup.API);

declare global {
    interface Window { overrideIdToken: string; }
}

interface CallHandlerOptions {
    // eslint-disable-next-line no-undef
    url: RequestInfo;
    // eslint-disable-next-line no-undef
    options: RequestInit;
    idToken: string | undefined;
    apiName: string;
    endpointName: string;
    useLogger: boolean;
}

async function callHandler<Response>({ url, options, idToken, apiName, endpointName, useLogger }: CallHandlerOptions): Promise<Response> {
    // @ts-ignore
    const baseUrl = window.apis[`api-${apiName.toLowerCase()}`];

    // eslint-disable-next-line no-undef
    const headers: HeadersInit = {
        "Content-Type": "application/json",
        "X-Client-Session-Id": sessionId,
        ...(options.headers ?? {}),
    };

    if (idToken) {
        headers["Authorization"] = `Bearer ${idToken}`;
    }

    // send api request with retries on 503 (service unavailable)
    const res = await withRetry(
        () => fetch(`${baseUrl}${url}`, {
            ...options,
            credentials: "same-origin",
            headers
        }),
        {
            checkRetryCondition: (error, response) => {
                if (error) {
                    return error.message.toLowerCase().includes("failed to fetch");
                } else if (response) {
                    return response.status === 503;
                }
            },
            onBeforeRetry: async (error, response, retriesRemaining: number) => {
                const logData = {
                    url,
                    method: options.method,
                    status: error ? undefined : response.status,
                    body: options.body,
                    endpointName,
                    apiName,
                    requestId: error ? undefined : response.headers.get("X-Request-Id"),
                    retriesRemaining
                };

                if (useLogger) {
                    logger.warn(`[callable][${apiName}] request failed, retrying`, logData);
                } else {
                    console.warn(`[callable][${apiName}] request failed, retrying`, logData);
                }
            }
        }
    );

    if (res.status < 400) {
        return res.json() as Promise<Response>;
    }

    const requestId = res.headers.get("X-Request-Id");

    const responseBody: ErrorResponseBody = (await res.json().catch(() => { })) ?? {};

    let error: Error;
    if (res.status === 400) {
        error = new BadRequestError(responseBody.message, responseBody);
    } else if (res.status === 404) {
        error = new NotFoundError(responseBody.message, responseBody);
    } else if (res.status === 401) {
        error = new UnauthorizedError(responseBody.message, responseBody);
    } else if (res.status === 403) {
        error = new ForbiddenError(responseBody.message, responseBody);
    } else if (res.status === 429) {
        error = new TooManyRequestsError(responseBody.message, responseBody);
    } else if (res.status === 409) {
        error = new ConflictError(responseBody.message, responseBody);
    } else if (res.status === 402) {
        error = new PaymentRequiredError(responseBody.message, responseBody);
    } else {
        error = new InternalServerError(responseBody.message, responseBody);
    }

    const logData = {
        url,
        method: options.method,
        status: res.status,
        body: options.body,
        requestId,
        endpointName,
        apiName
    };

    if (useLogger) {
        logger.error(error, `[callable][${apiName}] request failed`, logData);
    } else {
        console.error(error, `[callable][${apiName}] request failed`, logData);
    }

    throw error;
}

/**
 * Composes the callable API function from EndpointCallableDefinition
 */
export function getCallable<Request, Response>(apiName: string, endpointName: string, endpointDefinition: EndpointCallableDefinition<Request, Response>, useLogger: boolean = true) {
    /**
     * Callable, typed API function
     */
    const callable = async (request: Request) => {
        let idToken: string | undefined = undefined;
        if (window.overrideIdToken) {
            idToken = window.overrideIdToken;
        } else {
            const currentUser = auth().currentUser;
            if (currentUser) {
                idToken = await currentUser.getIdToken();
            }
        }

        const composed = endpointDefinition.composer(request);
        // eslint-disable-next-line no-undef
        const calls: CallHandlerOptions[] = [];
        if (Array.isArray(composed)) {
            composed.forEach(call => calls.push({ ...call, idToken, apiName, endpointName, useLogger }));
        } else {
            calls.push({ ...composed, idToken, apiName, endpointName, useLogger });
        }

        const responses = await Promise.all(calls.map(call => callHandler<Response>(call)));
        if (responses.length === 1) {
            return responses[0];
        }

        const collector = "collector" in endpointDefinition ? endpointDefinition.collector : undefined;
        if (!collector) {
            throw new Error("Collector is required for batched calls");
        }

        return collector(responses);
    };

    return callable;
}
