import type { EndpointDefinition } from "../../_sdk/types/common";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getWorkspaceUserInvites: {
        route: "/workspaces/:workspaceId/userInvites",
        method: "GET"
    },
    getWorkspaceUserInviteUrl: {
        route: "/workspaces/:workspaceId/userInvites/:inviteId/url",
        method: "GET"
    },
    resendWorkspaceUserInvite: {
        route: "/workspaces/:workspaceId/userInvites/:inviteId/resend",
        method: "POST"
    },
    deleteWorkspaceUserInvite: {
        route: "/workspaces/:workspaceId/userInvites/:inviteId",
        method: "DELETE"
    },
    updateWorkspaceUserInvite: {
        route: "/workspaces/:workspaceId/userInvites/:inviteId",
        method: "PUT"
    },
    inviteWorkspaceUsers: {
        route: "/workspaces/:workspaceId/userInvites",
        method: "POST"
    }
};
