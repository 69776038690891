import type { IPresentation, IPresentationLink } from "./models";

export interface IPlayerMetadata {
    requirePlayerPassword: boolean;
    isPlayerPresentationMigrated: boolean;
}

export interface IPlayerContext {
    presentation: IPresentation & {
        link?: IPresentationLink;
    };
    slidesMetadata: {
        [slideId: string]: {
            isSkipped: boolean;
            modifiedAt: number;
        };
    };
    creator: {
        photoURL: string;
        displayName: string;
    };
    isOwner: boolean;
    isCollaborator: boolean;
    canEditPresentation: boolean;
    showBranding: boolean;
    showGetDeckUI: boolean;
    slug: string;
}

export enum PlayerAnalyticsQueries {
    VIEWS_PER_DAY = "viewsPerDay",
    DOWNLOADS_PER_DAY = "downloadsPerDay",
    LINK_SUMMARY = "linkSummary",
    AGGREGATES = "aggregates",
    SUMMARY = "summary",
    RECENT_PLAYS = "recentPlays",
    RECENT_DOWNLOADS = "recentDownloads",
    VIEWING_AGGREGATES = "viewingAggregates",
    SESSION_DETAIL = "sessionDetail",
}

export enum PlayerSalesforceEvents {
    PLAYER_OPENED = "player-opened",
    PLAYER_CLOSED = "player-closed",
    PLAYER_PROGRESS = "player-progress",
}
