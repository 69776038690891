import React, { Component, Fragment } from "reactn";
import { Icon, Button, Link } from "@material-ui/core";
import SortAlphabeticalVariant from "mdi-material-ui/SortAlphabeticalVariant";
import Update from "mdi-material-ui/Update";
import CalendarRange from "mdi-material-ui/CalendarRange";
import Numeric from "mdi-material-ui/Numeric";
import Star from "mdi-material-ui/Star";
import styled from "styled-components";

import { _ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import getLogger, { LogGroup } from "js/core/logger";
import { trackActivity } from "js/core/utilities/utilities";
import { SearchThrottleWait } from "common/constants";
import AppController from "js/core/AppController";
import { Slide } from "js/core/models/slide";
import { ShowConfirmationDialog, ShowDialogAsync, ShowErrorDialog, ShowInputDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { RoundIconButton, UIPane } from "js/react/components/UiComponents";
import { NoMatchNotice, NoTeamSlideNotice, SharedSlideGeneralNotice, SharedSlideNotice } from "js/react/components/Notice";
import Spinner from "js/react/components/Spinner";
import { Gap, Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import TeamSlideDialog from "js/react/views/TeamResources/dialogs/TeamSlideDialog";
import { Breadcrumb } from "js/Components/Breadcrumb";
import { themeColors } from "js/react/sharedStyles";
import getUserProfile from "js/core/services/userProfiles";

import { teamSlidesDataService } from "../DataServices";
import { FilterDropDown } from "./Components/FilterDropDown";
import {
    InnerWhiteFrameContainer,
    SearchBarContainer,
    SearchBarInnerContainer,
    SlideSearchInput,
    UIPaneResultsContainer
} from "./Components/SearchBox";
import { MultiSelectThumbnailGrid, ThumbnailGrid } from "./Components/ThumbnailGrid";
import { SortMenu } from "./Components/SortMenu";
import { TeamSlideThumbnail } from "./Components/TeamSlideThumbnail";

const logger = getLogger(LogGroup.TEAMS);

const filterOptions = [
    {
        id: "all_content",
        name: "All Content"
    },
    {
        id: "name",
        name: "Title"
    },
    {
        id: "description",
        name: "Description"
    },
    {
        id: "text",
        name: "Slide Content"
    },
    {
        id: "tags",
        name: "Tags"
    },
    {
        id: "template",
        name: "Template"
    },
];

const AddSlideContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    background: ${themeColors.lightGray};
    padding-top: 20px;
    //this is to cover any thumbnail spinners behind the container
    z-index: 1000;
`;

const AddSlideButton = styled(Button)`
    background: #2EB4E5 !important;
    color: white !important;
    min-width: 150px !important;
    margin-bottom: 15px !important;
    padding: 10px 20px !important;

    &:disabled {
        background: white !important;
        color: #A9A9A9 !important;
    }
`;

const StatsContainer = styled.div`
    background: #f1f1f1;   
    width: 100%;
    padding: 15px;
    font-weight: 600;
    .MuiIcon-root {
        margin-right: 10px;
    }
    & > div + div {
        margin-top: 10px;
    }
`;

export class TeamTemplatePane extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            query: this.global.searchSlidesQuery ?? "",
            sort: null,
            tags: [{ id: "all_tags", name: "All Tags" }],
            selectedContent: "all_content",
            selectedTag: "all_tags",
            searchResults: [],
            selectedItems: [],
        };

        this.scrollRef = React.createRef();
        this.fetchSearchResultsThrottled = _.throttle(this.fetchSearchResults, SearchThrottleWait, { leading: false });
        this._fetchSearchResultsPromise = Promise.resolve();

        if (props.organizationId) {
            this.defaultTeam = ds.teams.defaultTeamForOrg(props.organizationId);
        }
    }

    get sortOptions() {
        return [{
            value: "name", label: "Name", icon: <SortAlphabeticalVariant />
        }, {
            value: "ratedByCount", label: "Rating", reverse: true, icon: <Star />
        }, {
            value: "presentationCount", label: "Popularity", reverse: true, icon: <Numeric />
        }, {
            value: "createdAt", label: "Created At", reverse: true, icon: <CalendarRange />
        }, {
            value: "modifiedAt", label: "Modified At", reverse: true, icon: <Update />
        }];
    }

    componentDidMount() {
        this.subscribeForLibraryItemsChanges();
        this.fetchSearchResults();
    }

    componentWillUnmount() {
        this.unsubscribeFromLibraryItemsChanges();
    }

    componentDidUpdate(prevProps, prevState) {
        const { organizationId, isTemplate } = this.props;
        const { query, selectedContent, selectedTag } = this.state;

        if (
            prevState.query !== query ||
            prevState.selectedContent !== selectedContent ||
            prevState.selectedTag !== selectedTag ||
            prevProps.organizationId !== organizationId ||
            prevProps.isTemplate !== isTemplate
        ) {
            this.setState({ isLoaded: false, selectedItems: [] });

            if (prevProps.organizationId !== organizationId) {
                this.unsubscribeFromLibraryItemsChanges();
                this.defaultTeam = organizationId ? ds.teams.defaultTeamForOrg(organizationId) : null;
                this.subscribeForLibraryItemsChanges();
            }

            this.fetchSearchResultsThrottled();
        }
    }

    setStateAsync(stateUpdate) {
        return new Promise(resolve => this.setState(stateUpdate, resolve));
    }

    subscribeForLibraryItemsChanges() {
        if (this.defaultTeam?.libraryItems) {
            this.defaultTeam.libraryItems.on("update", this.fetchSearchResults);
        }
    }

    unsubscribeFromLibraryItemsChanges() {
        if (this.defaultTeam?.libraryItems) {
            this.defaultTeam.libraryItems.off("update", this.fetchSearchResults);
        }
    }

    fetchSearchResults = () => {
        this._fetchSearchResultsPromise = this._fetchSearchResultsPromise.then(async () => {
            const { organizationId, isManageLibrary, isTemplate } = this.props;
            const { selectedContent, query, selectedTag } = this.state;

            if (!organizationId) {
                await this.setStateAsync({
                    searchResults: [],
                    tags: [],
                    isLoaded: true
                });
                return;
            }

            let searchResults = await teamSlidesDataService.search({
                fullTextSearchQuery: query,
                organizationId,
                tag: selectedTag !== "all_tags" ? selectedTag : undefined,
                fullTextSearchByFields: (selectedContent && selectedContent !== "all_content") ? [selectedContent] : []
            });

            if (!isManageLibrary) {
                searchResults = searchResults.filter(({ isDisabled }) => !isDisabled);
            }

            searchResults = searchResults.filter(slide => !!slide.isTemplate === isTemplate);

            const tagsMap = {};
            searchResults.forEach(({ tags }) => {
                tags.forEach(tag => {
                    if (!tagsMap[tag]) {
                        tagsMap[tag] = 1;
                    } else {
                        tagsMap[tag]++;
                    }
                });
            });
            const tags = [{ id: "all_tags", name: "All Tags" }, ...Object.entries(tagsMap).map(([tag, tagCount]) => ({ id: tag, name: `${tag} (${tagCount})` }))];
            if (!tags.some(({ id }) => id === selectedTag)) {
                tags.push({ id: selectedTag, name: `${selectedTag} (0)` });
            }

            await this.setStateAsync({
                searchResults,
                tags,
                isLoaded: true
            });
        });

        return this._fetchSearchResultsPromise;
    }

    handleSelectTag = event => {
        this.setState({ selectedTag: event.target.value });
    }

    handleSearch = query => {
        this.setState({ query });
    }

    handleSort = sort => {
        this.setState({ sort });
    }

    handleFilterChange = event => {
        this.setState({ selectedContent: event.target.value });
    }

    handleAddItem = async () => {
        const { resourceType } = this.props;
        AppController.showSlideEditor({ resourceType });
    }

    handleEditSlide = async item => {
        const { resourceType } = this.props;
        AppController.showSlideEditor({ slideId: item.id, resourceType });
    }

    copySlide = async (itemModel, updateProps) => {
        const {
            name,
            tags,
            id,
            description,
            isDisabled,
            teamId,
            isTemplate,
            template_id,
        } = itemModel;

        const slideOld = new Slide({ id });
        await slideOld.load();

        let slideModel = slideOld.attributes;
        delete slideModel.id;
        delete slideModel.libraryItemId;
        delete slideModel.thumbnails;

        const slideNew = new Slide(slideModel, {
            userId: app.userId,
            autoSync: false
        });
        await slideNew.load();

        let newModel = {
            contentId: slideNew.id,
            name,
            tags,
            description,
            type: "slides",
            isDisabled,
            teamId,
            isTemplate,
            template_id,
            ...updateProps,
        };

        await AppController.currentTeam.libraryItems.createLibraryItem(
            newModel,
            slideNew,
            AppController.orgId,
        );
    }

    handleCopySlide = async item => {
        // isTemaplte = slide template
        // !isTemplate = shared slide
        if (item.isTemplate) {
            await this.copySlide(item, {
                isTemplate: false,
                name: "Copy of " + item.name
            });
            this.fetchSearchResults();
            ShowWarningDialog({
                title: "A copy of this slide is now available to your team as a finished, uneditable Shared Slide.",
                icon: "/images/ui/icons/done-check.gif"
            });
        } else {
            await this.copySlide(item, {
                isTemplate: true,
                name: "Copy of " + item.name,
                isDisabled: false
            });
            this.fetchSearchResults();
            ShowWarningDialog({
                title: "A copy of this slide is now available to your team as an editable Slide Template.",
                icon: "/images/ui/icons/done-check.gif"
            });
        }
    }

    handleDuplicateSlide = async item => {
        let name = await ShowInputDialog({
            title: "Enter a name for the copied slide",
            value: "Copy of " + item.name
        });

        // Convert string array to object map
        let tags = item.tags.reduce((obj, tag) => {
            obj[tag] = true;
            return obj;
        }, {});

        if (name) {
            await this.copySlide(item, {
                name,
                tags,
            });

            this.fetchSearchResults();
        }
    }

    async sanitizeLibraryResponse(libraryItem) {
        const response = Object.assign({}, libraryItem.attributes);
        response.usedByCount = Object.keys(libraryItem.attributes.usedByUsers || {}).length;
        response.ratedByCount = Object.keys(libraryItem.attributes.ratedByUsers || {}).length;
        response.presentationCount = Object.keys(libraryItem.attributes.usedInPresentations || {}).length;
        response.tagsList = Object.keys(libraryItem.attributes.tags || {});
        const [createdUser, modifiedUser] = await Promise.all([
            getUserProfile(libraryItem.attributes.createdBy),
            libraryItem.attributes.modifiedBy && getUserProfile(libraryItem.attributes.modifiedBy)
        ]);

        response.createdBy = createdUser;
        response.modifiedBy = modifiedUser;
        return response;
    }

    handleDeleteSlide = async item => {
        const backboneLibraryItem = item.libraryItem;

        if (!this.props.isTemplate) {
            const libraryItem = await this.sanitizeLibraryResponse(backboneLibraryItem);
            if (await ShowConfirmationDialog({
                title: "Are you sure you want to delete this Shared Slide?",
                message: <>
                    <span>
                        Deleting a Shared Slide permanently removes it from your Team Resources.&nbsp;
                        Existing instances of this slide will no longer update automatically and will be editable by presentation editors.&nbsp;
                        <Link onClick={() => this.showTeamSlideDialog(item)}>See where this slide is being used.</Link>
                    </span>
                    <Gap20 />
                    <StatsContainer>
                        <FlexBox left middle>
                            <Icon>star</Icon>
                            {libraryItem.ratedByCount} {"like".pluralize(libraryItem.ratedByCount != 1)}
                        </FlexBox>
                        <FlexBox left middle>
                            <Icon>person</Icon>
                            Used by {libraryItem.usedByCount} {libraryItem.usedByCount == 1 ? "person" : "people"}
                        </FlexBox>
                        <FlexBox left middle>
                            <Icon>cloud_download</Icon>
                            Used in {libraryItem.presentationCount} {"presentation".pluralize(libraryItem.presentationCount !== 1)}
                        </FlexBox>
                    </StatsContainer>
                </>,
                okButtonLabel: "Delete Shared Slide",
                buttonOptions: {
                    acceptButtonColor: "red"
                }
            })) {
                await backboneLibraryItem.destroy({ isSlideTemplate: this.props.isTemplate });
                const props = {
                    slide_id: backboneLibraryItem.get("contentId"),
                };
                trackActivity(this.props.trackActivity, "SlideDeleted", null, null, props);
            }
            return;
        }

        ShowConfirmationDialog({
            title: `Are you sure you want to delete this ${this.props.title}?`,
            message: `Deleting a ${this.props.title} permanently removes it from your ${this.props.title} library and prevents it from being used in any future presentations.`,
            buttonOptions: { cancelButtonVariant: "outlined", acceptButtonColor: "red" },
            okButtonLabel: "PERMANENTLY DELETE TEAM SLIDE",
            acceptCallback: async () => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        isLoading: true
                    };
                });
                try {
                    await backboneLibraryItem.destroy({ isSlideTemplate: this.props.isTemplate });
                    const props = {
                        slide_id: backboneLibraryItem.get("contentId"),
                    };
                    trackActivity(this.props.trackActivity, "SlideDeleted", null, null, props);
                    this.fetchSearchResults();
                } catch (err) {
                    logger.error(err, "[TeamTemplatePane] backboneLibraryItem.destroy() failed", { backboneLibraryItemId: backboneLibraryItem?.id });
                    ShowErrorDialog({
                        error: "An error occurred while deleting the Team Slide.",
                        message: (
                            <Fragment>
                                <p>
                                    <strong>Error:</strong> {err.message}
                                </p>
                                <p>We apologize for the inconvenience. Please contact us at support@beautiful.ai.</p>
                            </Fragment>
                        )
                    });
                }
            }
        });
    }

    handleAddSelectedSlides = async () => {
        const { selectedItems } = this.state;
        const props = {
            ...ds.selection.presentation.getAnalytics(),
            slide_ids: selectedItems.map(item => item.id),
        };
        trackActivity("Presentation", "LibrarySlideAdded", null, null, props);
        this.props.onSelected(selectedItems);
    }

    showTeamSlideDialog = async item => {
        await ShowDialogAsync(TeamSlideDialog, {
            organizationId: this.props.organizationId,
            libraryItemId: item.libraryItemId,
            closePane: this.props.closePane,
            resourceType: this.props.resourceType,
        });

        this.fetchSearchResults();
    }

    render() {
        const {
            isManageLibrary,
            organizationId,
            title,
            resourceType
        } = this.props;
        const {
            query,
            selectedTag,
            selectedContent,
            selectedItems,
            tags
        } = this.state;

        if (!organizationId) {
            return (<UIPane>
                {resourceType === "slide-templates" && <SharedSlideNotice />}
                {resourceType !== "slide-templates" && <NoTeamSlideNotice />}
            </UIPane>
            );
        }

        let panelStyles, searchStyles, scrollStyles;
        if (!isManageLibrary) {
            panelStyles = {
                paddingTop: 4,
                paddingRight: 20,
                paddingBottom: 0,
                paddingLeft: 20
            };
            searchStyles = {
                position: "initial"
            };
        } else {
            scrollStyles = {
                height: "calc(100% - 49px)"
            };
        }

        return (
            <UIPane>
                <SearchBarContainer className="search-container">
                    {isManageLibrary &&
                        <Fragment>
                            <Breadcrumb>{title}s</Breadcrumb>
                            <RoundIconButton onClick={this.handleAddItem}><Icon>add</Icon></RoundIconButton>
                            <Gap size={60} />
                        </Fragment>
                    }
                    <SearchBarInnerContainer>
                        <SlideSearchInput
                            query={query}
                            onSearch={this.handleSearch}
                            placeholder="Search team slides…"
                        />
                        <FilterDropDown
                            onChange={this.handleFilterChange}
                            selectedFilter={selectedContent}
                            filterOptions={filterOptions}
                        />
                        <FilterDropDown
                            onChange={this.handleSelectTag}
                            selectedFilter={selectedTag}
                            filterOptions={tags}
                        />
                        <SortMenu
                            disabled={query.length > 0}
                            sortOptions={this.sortOptions}
                            onSetSort={this.handleSort}
                        />
                    </SearchBarInnerContainer>
                </SearchBarContainer>
                <UIPaneResultsContainer>
                    {this.renderSlides()}
                </UIPaneResultsContainer>
                {!isManageLibrary &&
                    <AddSlideContainer>
                        <AddSlideButton disabled={!selectedItems.length} onClick={this.handleAddSelectedSlides}>
                            Add Selected Slides
                        </AddSlideButton>
                    </AddSlideContainer>
                }
            </UIPane>
        );
    }

    renderSlides() {
        const {
            isManageLibrary,
            resourceType
        } = this.props;
        const {
            isLoaded,
            query,
            searchResults,
            sort
        } = this.state;

        if (!isLoaded) {
            return <Spinner />;
        }

        if (!searchResults.length) {
            if (!query) {
                if (resourceType === "slide-templates") {
                    if (isManageLibrary) return <SharedSlideNotice />;
                    else {
                        // We added this because we we are not in the manageing library
                        // We should present to any user this default message regardless of
                        // their role
                        return (<SharedSlideGeneralNotice />);
                    }
                }
                return <NoTeamSlideNotice />;
            } else {
                return <NoMatchNotice />;
            }
        }

        const sortedResults = sort
            ? _.sortBy(searchResults, item => {
                if (typeof item[sort.field] === "string") {
                    return item[sort.field].toLowerCase().trim();
                } else {
                    return item[sort.field];
                }
            })
            : searchResults;
        if (sort?.reverse) {
            sortedResults.reverse();
        }

        return (<InnerWhiteFrameContainer>
            <MultiSelectThumbnailGrid
                columns={3}
                items={sortedResults}
                allowMultiSelect={!isManageLibrary}
                thumbnailClass={TeamSlideThumbnail}
                thumbnailProps={{
                    isManageLibrary,
                    onEditSlide: this.handleEditSlide,
                    onDeleteSlide: this.handleDeleteSlide,
                    onCopySlide: this.handleCopySlide,
                    onDuplicateSlide: this.handleDuplicateSlide
                }}
                onItemSelected={selectedItems => this.setState({ selectedItems })}
                onDoubleClick={slide => {
                    if (isManageLibrary) {
                        this.showTeamSlideDialog(slide);
                    } else {
                        this.setState({ selectedItems: [slide] }, () => {
                            this.handleAddSelectedSlides();
                        });
                    }
                }}
            />
        </InnerWhiteFrameContainer>);
    }
}
