import { getCallable } from "../../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import type {
    GetWorkspaceStripeDataRequest,
    GetWorkspaceStripeDataResponse,
    GetWorkspacePlanDataRequest,
    GetWorkspacePlanDataResponse,
    CancelSubscriptionRequest,
    CancelSubscriptionResponse,
    SwitchSubscriptionBillingIntervalRequest,
    SwitchSubscriptionBillingIntervalResponse,
    PreviewUpcomingInvoiceForBillingIntervalRequest,
    PreviewUpcomingInvoiceForBillingIntervalResponse,
    PreviewInvoicesForQuantityRequest,
    PreviewInvoicesForQuantityResponse,
    ReactivateSubscriptionRequest,
    ReactivateSubscriptionResponse,
    UpdatePaymentMethodRequest,
    UpdatePaymentMethodResponse,
    PayLatestInvoiceOnPastDueSubscriptionRequest,
    PayLatestInvoiceOnPastDueSubscriptionResponse,
    EndTrialOnSubscriptionRequest,
    EndTrialOnSubscriptionResponse,
    ApplyPromotionCodeToSubscriptionRequest,
    ApplyPromotionCodeToSubscriptionResponse,
    UpdateBillingDetailsRequest,
    UpdateBillingDetailsResponse,
    UpdateSubscriptionQuantityResponse,
    UpdateSubscriptionQuantityRequest,
    FinalizeUpdateSubscriptionQuantityResponse,
    FinalizeUpdateSubscriptionQuantityRequest
} from "./types";

export { apiName };

const getWorkspaceStripeData = getCallable<GetWorkspaceStripeDataRequest, GetWorkspaceStripeDataResponse>(
    apiName,
    "getWorkspaceBillingData",
    {
        ...endpoints.getWorkspaceBillingData,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/stripe`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const getWorkspacePlanData = getCallable<GetWorkspacePlanDataRequest, GetWorkspacePlanDataResponse>(
    apiName,
    "getWorkspacePlanData",
    {
        ...endpoints.getWorkspaceBillingData,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/plan`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const cancelSubscription = getCallable<CancelSubscriptionRequest, CancelSubscriptionResponse>(
    apiName,
    "cancelSubscription",
    {
        ...endpoints.cancelSubscription,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/cancel`,
                options: {
                    method: "POST"
                }
            };
        }
    });

const reactivateSubscription = getCallable<ReactivateSubscriptionRequest, ReactivateSubscriptionResponse>(
    apiName,
    "reactivateSubscription",
    {
        ...endpoints.reactivateSubscription,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/reactivate`,
                options: {
                    method: "POST"
                }
            };
        }
    });

const switchSubscriptionBillingInterval = getCallable<SwitchSubscriptionBillingIntervalRequest, SwitchSubscriptionBillingIntervalResponse>(
    apiName,
    "switchSubscriptionBillingInterval",
    {
        ...endpoints.switchSubscriptionBillingInterval,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/interval`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

const previewUpcomingInvoiceForBillingInterval = getCallable<PreviewUpcomingInvoiceForBillingIntervalRequest, PreviewUpcomingInvoiceForBillingIntervalResponse>(
    apiName,
    "previewUpcomingInvoiceForBillingInterval",
    {
        ...endpoints.previewUpcomingInvoiceForBillingInterval,
        composer: request => {
            const searchParams = new URLSearchParams();
            searchParams.set("billingInterval", request.billingInterval);

            return {
                url: `/workspaces/${request.workspaceId}/billing/invoices/preview/billing-interval?${searchParams.toString()}`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const previewInvoicesForQuantity = getCallable<PreviewInvoicesForQuantityRequest, PreviewInvoicesForQuantityResponse>(
    apiName,
    "previewInvoicesForQuantity",
    {
        ...endpoints.previewInvoicesForQuantity,
        composer: request => {
            const searchParams = new URLSearchParams();
            searchParams.set("quantity", request.quantity.toString());

            return {
                url: `/workspaces/${request.workspaceId}/billing/invoices/preview/quantity?${searchParams.toString()}`,
                options: {
                    method: "GET"
                }
            };
        }
    });
const updatePaymentMethod = getCallable<UpdatePaymentMethodRequest, UpdatePaymentMethodResponse>(
    apiName,
    "updatePaymentMethod",
    {
        ...endpoints.updatePaymentMethod,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/payment-method`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

const payLatestInvoiceOnPastDueSubscription = getCallable<PayLatestInvoiceOnPastDueSubscriptionRequest, PayLatestInvoiceOnPastDueSubscriptionResponse>(
    apiName,
    "payLatestInvoiceOnPastDueSubscription",
    {
        ...endpoints.payLatestInvoiceOnPastDueSubscription,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/pay-latest-invoice`,
                options: {
                    method: "POST"
                }
            };
        }
    });

const endTrialOnSubscription = getCallable<EndTrialOnSubscriptionRequest, EndTrialOnSubscriptionResponse>(
    apiName,
    "endTrialOnSubscription",
    {
        ...endpoints.endTrialOnSubscription,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/end-trial`,
                options: {
                    method: "POST"
                }
            };
        }
    });

const applyPromotionCodeToSubscription = getCallable<ApplyPromotionCodeToSubscriptionRequest, ApplyPromotionCodeToSubscriptionResponse>(
    apiName,
    "applyPromotionCodeToSubscription",
    {
        ...endpoints.applyPromotionCodeToSubscription,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/promotion-code`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

const updateBillingDetails = getCallable<UpdateBillingDetailsRequest, UpdateBillingDetailsResponse>(
    apiName,
    "updateBillingDetails",
    {
        ...endpoints.updateBillingDetails,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/customers/billing-details`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

const updateSubscriptionQuantity = getCallable<UpdateSubscriptionQuantityRequest, UpdateSubscriptionQuantityResponse>(
    apiName,
    "updateSubscriptionQuantity",
    {
        ...endpoints.updateSubscriptionQuantity,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/quantity`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

const finalizeUpdateSubscriptionQuantity = getCallable<FinalizeUpdateSubscriptionQuantityRequest, FinalizeUpdateSubscriptionQuantityResponse>(
    apiName,
    "finalizeUpdateSubscriptionQuantity",
    {
        ...endpoints.finalizeUpdateSubscriptionQuantity,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/quantity-finalize`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

export const callables = {
    getWorkspaceStripeData,
    getWorkspacePlanData,
    cancelSubscription,
    reactivateSubscription,
    switchSubscriptionBillingInterval,
    previewUpcomingInvoiceForBillingInterval,
    previewInvoicesForQuantity,
    updatePaymentMethod,
    payLatestInvoiceOnPastDueSubscription,
    endTrialOnSubscription,
    applyPromotionCodeToSubscription,
    updateBillingDetails,
    updateSubscriptionQuantity,
    finalizeUpdateSubscriptionQuantity
};
