import _ from "lodash";

import { IGenericModelFields } from "../IGenericFields";
import { WorkspaceResourceType } from "./IWorkspaceResource";
import { WorkspaceUserRole } from "./IWorkspace";

export interface IWorkspaceResourceFolder extends IGenericModelFields {
    readonly workspaceId: string;
    name: string;
    readonly resourcesType: WorkspaceResourceType;
    parentFolderId?: string;
}

export interface IWorkspaceResourceFolderMetadataCounters {
    users: {
        [key in WorkspaceUserRole]: number;
    },
    resources: {
        [key in WorkspaceResourceType]: number;
    }
}

export interface IWorkspaceResourceFolderMetadata {
    id: string;
    name: string;
    counters: IWorkspaceResourceFolderMetadataCounters;
}

////////////////////////////// DEFAULTS //////////////////////////////

const DEFAULT_METADATA_COUNTERS: IWorkspaceResourceFolderMetadataCounters = {
    users: Object.values(WorkspaceUserRole).reduce((acc, role) => ({ ...acc, [role]: 0 }), {} as { [key in WorkspaceUserRole]: number }),
    resources: Object.values(WorkspaceResourceType).reduce((acc, resourceType) => ({ ...acc, [resourceType]: 0 }), {} as { [key in WorkspaceResourceType]: number }),
};

export const GET_DEFAULT_WORKSPACERESOURCE_FOLDER_METADATA_COUNTERS = (): IWorkspaceResourceFolderMetadataCounters => _.cloneDeep(DEFAULT_METADATA_COUNTERS);
