export type SequentialRunner = <T = void>(action: () => Promise<T>) => Promise<T>;

export function getSequentialRunner() {
    let _asyncActionsPromiseChain: Promise<void> = Promise.resolve();

    return function _runSequentially<T = void>(action: () => Promise<T>): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            _asyncActionsPromiseChain = _asyncActionsPromiseChain
                .then(action)
                .then(resolve)
                .catch(reject);
        });
    };
}
