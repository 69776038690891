import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { DegreesToRadians, RadiansToDegrees } from "js/core/utilities/geom";
import { DirectionType, ArrowDirection } from "common/constants";

let BoundingBox = window.BoundingBox;

let Callout = {
    calcBounds: function(elementBounds, targetBounds, calloutSize, calloutGap) {
        let calloutBounds = new geom.Rect(targetBounds.right + calloutGap, targetBounds.top - calloutGap - calloutSize.height / 1.333, calloutSize);

        let connectorPath = [];
        connectorPath.push([targetBounds.right - targetBounds.width * .333, targetBounds.top + targetBounds.height * .333]);
        connectorPath.push([targetBounds.right + calloutGap, targetBounds.top - calloutGap]);

        return {
            calloutBounds: calloutBounds,
            connectorPath: connectorPath
        };
    }

};

let Shape = {
    draw: function(svg, type) {
        switch (this.type) {
            case "diamond":
                this.shape = svg.rect().rotate(45);
                break;
            case "arrow":

                // var path = this.drawArrow(50, 50, "right", 30, 30);

                this.shape = svg.polygon("0,0");
                break;
            case "chevron":
                this.shape = svg.polygon("0,0");
                break;
        }
    },

    addPoint: function(path, x, y) {
        if (path == "") {
            path = x + "," + y;
        } else {
            path += " " + x + "," + y;
        }
        return path;
    },

    drawChevron: function(bounds, offset, noTail, direction = ArrowDirection.RIGHT) {
        const path = new Path();

        switch (direction) {
            case ArrowDirection.UP:
                path.moveTo(bounds.left, bounds.top + offset);
                path.lineTo(bounds.left + bounds.width / 2, bounds.top);
                path.lineTo(bounds.right, bounds.top + offset);
                path.lineTo(bounds.right, bounds.bottom);
                if (!noTail) {
                    path.lineTo(bounds.left + bounds.width / 2, bounds.bottom - offset);
                }
                path.lineTo(bounds.left, bounds.bottom);
                path.lineTo(bounds.left, bounds.top + offset);
                break;
            case ArrowDirection.DOWN:
                path.moveTo(bounds.left, bounds.bottom - offset);
                path.lineTo(bounds.left + bounds.width / 2, bounds.bottom);
                path.lineTo(bounds.right, bounds.bottom - offset);
                path.lineTo(bounds.right, bounds.top);
                if (!noTail) {
                    path.lineTo(bounds.left + bounds.width / 2, bounds.top + offset);
                }
                path.lineTo(bounds.left, bounds.top);
                path.lineTo(bounds.left, bounds.bottom - offset);
                break;
            case ArrowDirection.RIGHT:
                path.moveTo(bounds.right - offset, bounds.top);
                path.lineTo(bounds.right, bounds.bottom / 2);
                path.lineTo(bounds.right - offset, bounds.bottom);
                path.lineTo(bounds.left, bounds.bottom);
                if (!noTail) {
                    path.lineTo(bounds.left + offset, bounds.bottom / 2);
                }
                path.lineTo(bounds.left, bounds.top);
                path.lineTo(bounds.right - offset, bounds.top);
                break;
            case ArrowDirection.LEFT:
                path.moveTo(bounds.left + offset, bounds.top);
                path.lineTo(bounds.left, bounds.bottom / 2);
                path.lineTo(bounds.left + offset, bounds.bottom);
                path.lineTo(bounds.right, bounds.bottom);
                if (!noTail) {
                    path.lineTo(bounds.right - offset, bounds.bottom / 2);
                }
                path.lineTo(bounds.right, bounds.top);
                path.lineTo(bounds.left + offset, bounds.top);
                break;
        }

        path.close();

        return path;
    },

    drawStar: function(bounds, inner, points) {
        let centerX = bounds.width / 2;
        let centerY = bounds.height / 2;
        let outerRadius = bounds.size.square().width / 2;
        let innerRadius = outerRadius - inner;

        let degreeIncrement = 360 / (points * 2);
        let d = new Array(points * 2).fill("foo").map((p, i) => {
            let radius = i % 2 == 0 ? outerRadius : innerRadius;
            let degrees = degreeIncrement * i;
            const point = polarToCartesian(centerX, centerY, radius, degrees);
            return `${point.x},${point.y}`;
        });
        return `M${d}Z`;
    },

    drawPolygon: function(bounds, sides) {
        let path = new Path();

        let centerX = bounds.width / 2;
        let centerY = bounds.height / 2;
        let radius = bounds.size.square().width / 2;
        let degreeIncrement = 360 / sides;

        for (let i = 0; i < sides; i++) {
            path.lineTo(polarToCartesian(centerX, centerY, radius, i * degreeIncrement));
        }
        path.close();
        return path;
    },

    drawBoxArrow: function(bounds, arrowOffset, direction = DirectionType.RIGHT) {
        let path = new Path();

        switch (direction) {
            case DirectionType.RIGHT:
                path.moveTo(bounds.left, bounds.top);
                path.lineTo(bounds.right - arrowOffset, bounds.top);
                path.lineTo(bounds.right, bounds.centerV);
                path.lineTo(bounds.right - arrowOffset, bounds.bottom);
                path.lineTo(bounds.left, bounds.bottom);
                path.close();
                break;
            case DirectionType.BOTTOM:
                path.moveTo(bounds.left, bounds.top);
                path.lineTo(bounds.right, bounds.top);
                path.lineTo(bounds.right, bounds.bottom - arrowOffset);
                path.lineTo(bounds.centerH, bounds.bottom);
                path.lineTo(bounds.left, bounds.bottom - arrowOffset);
                path.close();
                break;
        }

        return path;
    },

    drawTestimonialBubble({ width, height }, { cornerRadius, showTail, tailWidth, tailHeight, tailOffset }) {
        let path;
        if (cornerRadius) {
            path = `M0,${height / 2} 
                    L0,${cornerRadius}
                    Q0,0 ${cornerRadius},0
                    L${width - cornerRadius},0
                    Q${width},${0} ${width},${cornerRadius}` +

                (showTail

                    ? `L${width},${height - tailHeight - cornerRadius}
                    Q${width},${height - tailHeight} ${width - cornerRadius},${height - tailHeight}
                    L${tailWidth + tailOffset},${height - tailHeight}
                    L${tailOffset},${height}
                    L${tailOffset},${height - tailHeight}
                    L${cornerRadius},${height - tailHeight}
                    Q0,${height - tailHeight} 0,${height - tailHeight - cornerRadius}`

                    : `L${width},${height - cornerRadius}
                    Q${width},${height} ${width - cornerRadius},${height}
                    L${cornerRadius},${height}
                    Q0,${height} 0,${height - cornerRadius}`) +

                `Z`;
        } else {
            path = `M0,${height / 2} 
                    L0,0
                    L${width},0` +

                (showTail

                    ? `L${width},${height - tailHeight}
                    L${tailWidth + tailOffset},${height - tailHeight}
                    L${tailOffset},${height}
                    L${tailOffset},${height - tailHeight}
                    L0,${height - tailHeight}`

                    : `L${width},${height}
                    L0,${height}`) +

                `Z`;
        }
        return path;
    },

    drawRect: function(rect, cr = 0) {
        let p = new Path();
        if (cr > 0) {
            p.moveTo(rect.left + cr, rect.top);
            p.lineTo(rect.right - cr, rect.top);
            p.arc(cr, cr, 0, 0, 1, rect.right, rect.top + cr);
            p.lineTo(rect.right, rect.bottom - cr);
            p.arc(cr, cr, 0, 0, 1, rect.right - cr, rect.bottom);
            p.lineTo(rect.left + cr, rect.bottom);
            p.arc(cr, cr, 0, 0, 1, rect.left, rect.bottom - cr);
            p.lineTo(rect.left, rect.top + cr);
            p.arc(cr, cr, 0, 0, 1, rect.left + cr, rect.top);
        } else {
            p.moveTo(rect.left, rect.top);
            p.lineTo(rect.right, rect.top);
            p.lineTo(rect.right, rect.bottom);
            p.lineTo(rect.left, rect.bottom);
            p.close();
        }
        return p.toPathData();
    },

    drawRectOld: function(x, y, w, h, cr = 0) {
        var d;

        // normalise radius values, just like the original does it (or should do)
        if (cr < 0) cr = 0;
        if (cr > w / 2) cr = w / 2;

        if (cr) {
            // if there are round corners
            d = `M${x},${y}
                 H${w - cr}
                 A${cr},${cr},0,0,1,${w},${cr}
                 V${h - cr}
                 A${cr},${cr},0,0,1,${w - cr},${h}
                 H${cr}
                 A${cr},${cr},0,0,1,${x},${h - cr}
                 V${cr}
                 A${cr},${cr},0,0,1,${cr},${y}
                 z`;

            //d = [
            //    'M' + cornerRadius + ' ' + y
            //    , 'H' + (w - cornerRadius)
            //    , 'A' + cornerRadius + ' ' + cornerRadius + ' 0 0 1 ' + w + ' ' + cornerRadius
            //    , 'V' + (h - cornerRadius)
            //    , 'A' + cornerRadius + ' ' + cornerRadius + ' 0 0 1 ' + (w - cornerRadius) + ' ' + h
            //    , 'H' + cornerRadius
            //    , 'A' + cornerRadius + ' ' + cornerRadius + ' 0 0 1 ' + x + ' ' + (h - cornerRadius)
            //    , 'V' + cornerRadius
            //    , 'A' + cornerRadius + ' ' + cornerRadius + ' 0 0 1 ' + cornerRadius + ' ' + y
            //    , 'z'
            //]
        } else {
            // no round corners, no need to draw arcs
            d = `
                 M${x},${y}
                 H${w}
                 V${h}
                 H${x}
                 V${y}
                 z`;
            //d = [
            //    'M' + x + ' ' + y
            //    , 'H' + w
            //    , 'V' + h
            //    , 'H' + x
            //    , 'V' + y
            //    , 'z'
            //]
        }
        return d;
    },

    drawCircle: function(r, center) {
        let p = new Path();
        p.moveTo(center.x, center.y - r);
        p.arc(r, r, 0, 0, 1, center.x, center.y + r);
        p.arc(r, r, 0, 0, 1, center.x, center.y - r);
        return p;
    },

    getOctagonVertices: function(size) {
        const radius = Math.min(size.width, size.height) / 2;
        const angleStep = Math.PI / 4; // 45 degrees in radians
        const centerX = size.width / 2;
        const centerY = size.height / 2;
        const points = [];

        for (let i = 0; i < 8; i++) {
            const angle = i * angleStep;
            const x = centerX + radius * Math.cos(angle);
            const y = centerY + radius * Math.sin(angle);
            points.push({ x, y });
        }

        return points;
    },

    drawOctagon: function(size) {
        let p = new Path();
        const points = this.getOctagonVertices(size);

        p.moveTo(points[0].x, points[0].y);
        for (let i = 1; i < points.length; i++) {
            p.lineTo(points[i].x, points[i].y);
        }
        p.close();

        return p;
    },

    drawDiamond: function(bounds) {
        let p = new Path();
        p.moveTo(bounds.centerH, bounds.top);
        p.lineTo(bounds.right, bounds.centerV);
        p.lineTo(bounds.centerH, bounds.bottom);
        p.lineTo(bounds.left, bounds.centerV);
        p.close();
        return p;
    },

    drawCapsule: function(bounds) {
        let p = new Path();
        let r = bounds.height / 2;
        p.moveTo(bounds.left + r, bounds.top);
        p.lineTo(bounds.right - r, bounds.top);
        p.arc(r, r, 0, 0, 1, bounds.right - r, bounds.bottom);
        p.lineTo(bounds.left + r, bounds.bottom);
        p.arc(r, r, 0, 0, 1, bounds.left + r, bounds.top);
        return p;
    },

    drawCalloutBubble: function(bounds, tailPoint, tailWidth) {
        let center = bounds.center;
        let rx = bounds.size.width / 2;
        let ry = bounds.size.height / 2;

        let angle = new geom.Line(center, tailPoint).angle;
        let degrees = RadiansToDegrees(angle);
        if (degrees < 0) {
            degrees = 360 + degrees;
        }
        let tailQuadrant = Math.floor(degrees / 90);

        let angleOffset = DegreesToRadians(5);
        let tailStartAngle = angle - angleOffset;
        let tailEndAngle = angle + angleOffset;

        let p1 = new geom.Point(center.x + rx * Math.cos(angle - angleOffset), center.y + ry * Math.sin(angle - angleOffset));
        let p2 = new geom.Point(center.x + rx * Math.cos(angle + angleOffset), center.y + ry * Math.sin(angle + angleOffset));

        let cur = 0;
        let drawQuadrant = quadrant => {
            cur += Math.PI / 2;

            if (tailStartAngle < cur && tailStartAngle > cur - Math.PI / 2) {
                p.arc(rx, ry, 0, 0, 1, p1.x, p1.y);
                p.lineTo(tailPoint.x, tailPoint.y);
                p.lineTo(p2);
            }

            let endQuadrantPt = new geom.Point(center.x + rx * Math.cos(cur), center.y + ry * Math.sin(cur));
            p.arc(rx, ry, 0, 0, 1, endQuadrantPt.x, endQuadrantPt.y);
        };

        let p = new Path();
        p.moveTo(center.x + rx, center.y);

        for (let i = 0; i < 4; i++) {
            drawQuadrant(i);
        }

        return p;
    },

    drawArrow2: function(bounds, stemWidth, arrowHeadLength, direction) {
        const path = new Path();
        switch (direction) {
            case ArrowDirection.UP:
                path.moveTo(bounds.width / 2, 0);
                path.lineTo(0, arrowHeadLength);
                path.lineTo(bounds.width / 2 - stemWidth / 2, arrowHeadLength);
                path.lineTo(bounds.width / 2 - stemWidth / 2, bounds.height);
                path.lineTo(bounds.width / 2 + stemWidth / 2, bounds.height);
                path.lineTo(bounds.width / 2 + stemWidth / 2, arrowHeadLength);
                path.lineTo(bounds.width, arrowHeadLength);
                path.close();
                break;
            case ArrowDirection.DOWN:
                path.moveTo(bounds.width / 2, bounds.height);
                path.lineTo(0, bounds.height - arrowHeadLength);
                path.lineTo(bounds.width / 2 - stemWidth / 2, bounds.height - arrowHeadLength);
                path.lineTo(bounds.width / 2 - stemWidth / 2, 0);
                path.lineTo(bounds.width / 2 + stemWidth / 2, 0);
                path.lineTo(bounds.width / 2 + stemWidth / 2, bounds.height - arrowHeadLength);
                path.lineTo(bounds.width, bounds.height - arrowHeadLength);
                path.close();
                break;
            case ArrowDirection.RIGHT:
                path.moveTo(bounds.width, bounds.height / 2);
                path.lineTo(bounds.width - arrowHeadLength, 0);
                path.lineTo(bounds.width - arrowHeadLength, bounds.height / 2 - stemWidth / 2);
                path.lineTo(0, bounds.height / 2 - stemWidth / 2);
                path.lineTo(0, bounds.height / 2 + stemWidth / 2);
                path.lineTo(bounds.width - arrowHeadLength, bounds.height / 2 + stemWidth / 2);
                path.lineTo(bounds.width - arrowHeadLength, bounds.height);
                path.close();
                break;
            case ArrowDirection.LEFT:
                path.moveTo(0, bounds.height / 2);
                path.lineTo(0 + arrowHeadLength, 0);
                path.lineTo(0 + arrowHeadLength, bounds.height / 2 - stemWidth / 2);
                path.lineTo(bounds.width, bounds.height / 2 - stemWidth / 2);
                path.lineTo(bounds.width, bounds.height / 2 + stemWidth / 2);
                path.lineTo(0 + arrowHeadLength, bounds.height / 2 + stemWidth / 2);
                path.lineTo(0 + arrowHeadLength, bounds.height);
                path.close();
                break;
        }

        if (bounds.left) {
            path.offset(bounds.left, bounds.top);
        }

        return path;
    },

    drawArrow: function(bounds, barHeight, arrowLength, direction) {
        if (direction === "left") {
            //we will reverse the bounds :)
            bounds = bounds.clone();
            bounds.left = bounds.left + bounds.width;
            bounds.top = bounds.top + bounds.height;
            bounds.width *= -1;
            bounds.height *= -1;
        }

        const barOffset = (bounds.height - barHeight) / 2;

        const right = bounds.left + bounds.width - arrowLength;
        const top = bounds.top + barOffset;
        const bottom = bounds.top + bounds.height - barOffset;
        const arrowBottom = bounds.top + bounds.height;
        const arrowRight = bounds.left + bounds.width;
        const arrowMiddle = (bounds.top + arrowBottom) / 2;

        let path = [[bounds.left, top], [right, top], [right, bounds.top], [arrowRight, arrowMiddle], [right, arrowBottom], [right, bottom], [bounds.left, bottom], [bounds.left, top]];

        return path;
        // shape.plot(path);
        // return shape;
    },

    drawBounds: function(bounds) {
        const right = bounds.left + bounds.width;
        const bottom = bounds.top + bounds.height;

        return [[bounds.left, bounds.top], [bounds.left, bottom], [right, bottom], [right, bounds.top], [bounds.left, bounds.top]];
    },

    drawWedge: function(centerX, centerY, innerRadius, outerRadius, startAngle, endAngle) {
        let path = new Path();
        let largeArc = Math.abs(startAngle - endAngle) > Math.PI ? 1 : 0;
        if (innerRadius > 0) {
            path.moveTo(centerX + innerRadius * Math.cos(startAngle), centerY + innerRadius * Math.sin(startAngle));
            path.lineTo(centerX + outerRadius * Math.cos(startAngle), centerY + outerRadius * Math.sin(startAngle));
            path.arc(outerRadius, outerRadius, 0, largeArc, 1, centerX + outerRadius * Math.cos(endAngle), centerY + outerRadius * Math.sin(endAngle));
            path.lineTo(centerX + innerRadius * Math.cos(endAngle), centerY + innerRadius * Math.sin(endAngle));
            path.arc(innerRadius, innerRadius, 0, largeArc, 0, centerX + innerRadius * Math.cos(startAngle), centerY + innerRadius * Math.sin(startAngle));
        } else {
            path.moveTo(centerX, centerY);
            path.lineTo(centerX + outerRadius * Math.cos(startAngle), centerY + outerRadius * Math.sin(startAngle));
            path.arc(outerRadius, outerRadius, 0, largeArc, 1, centerX + outerRadius * Math.cos(endAngle), centerY + outerRadius * Math.sin(endAngle));
            path.lineTo(centerX, centerY);
        }
        path.close();
        return path;
    },

    drawArc: function(centerX, centerY, radius, startAngle, endAngle) {
        let path = new Path();
        let largeArcFlag = Math.abs(endAngle - startAngle) > Math.PI ? 1 : 0;
        let sweepFlag = 1;

        // Calculate start and end points
        let startX = centerX + radius * Math.cos(startAngle);
        let startY = centerY + radius * Math.sin(startAngle);
        let endX = centerX + radius * Math.cos(endAngle);
        let endY = centerY + radius * Math.sin(endAngle);

        path.moveTo(startX, startY);

        // Check if it's a full circle
        if (Math.abs(endAngle - startAngle) >= 2 * Math.PI - 0.001) {
            // Draw two arcs for a full circle
            let middleAngle = startAngle + Math.PI;
            let middleX = centerX + radius * Math.cos(middleAngle);
            let middleY = centerY + radius * Math.sin(middleAngle);

            path.arc(radius, radius, 0, 1, sweepFlag, middleX, middleY);
            path.arc(radius, radius, 0, 1, sweepFlag, startX, startY);
        } else {
            // Draw a single arc for partial circle
            path.arc(radius, radius, 0, largeArcFlag, sweepFlag, endX, endY);
        }

        return path;
    },

    drawArc2: function(outerRadius, innerRadius, startAngle, endAngle, center) {
        let arcLength = endAngle - startAngle;

        // let arcStartPoint = geom.Point.PointFromAngle(radius, startAngle, center);
        // let arcEndPoint = geom.Point.PointFromAngle(radius, endAngle, center);

        let outerArcStartPoint = geom.Point.PointFromAngle(outerRadius, startAngle, center);
        let outerArcEndPoint = geom.Point.PointFromAngle(outerRadius, endAngle, center);
        let innerArcStartPoint = geom.Point.PointFromAngle(innerRadius, endAngle, center);
        let innerArcEndPoint = geom.Point.PointFromAngle(innerRadius, startAngle, center);

        let path = new Path();
        path.moveTo(outerArcStartPoint);
        path.arc(outerRadius, outerRadius, 0, arcLength > 180 ? 1 : 0, 1, outerArcEndPoint.x, outerArcEndPoint.y);
        path.lineTo(innerArcStartPoint);
        path.arc(innerRadius, innerRadius, 0, arcLength > 180 ? 1 : 0, 0, innerArcEndPoint.x, innerArcEndPoint.y);
        path.close();

        return path;
        // return {
        //     path, arcStartPoint, arcEndPoint
        // }
    },

    getIconList: function(exclude) {
        var list = [{ label: "Male", value: "male" }, { label: "Female", value: "female" }, {
            label: "Star",
            value: "star"
        }, { label: "Circle", value: "circle" }];

        return list;
    },

    getIcon: function(type) {
        switch (type) {
            case "star":
                return "M54.5,0 L71.1049334,33.6452699 L108.234693,39.0405398 L81.3673466,65.2297301 L87.7098668,102.20946 L54.5,84.75 L21.2901332,102.20946 L27.6326534,65.2297301 L0.765306829,39.0405398 L37.8950666,33.6452699 L45.385934,18.4671149 Z";
            case "circle":
                return "M0,50a50,50 0 1,0 100,0a50,50 0 1,0 -100,0";
            case "female":
                return "M39.646,25.33 L46.645,48.338 C48.041,53.287 41.746,55.336 40.245,50.513 L33.947,29.388 L32.114,29.388 L43.03,64.966 L32.973,64.966 L32.973,94.262 C32.973,99.258 25.459,99.233 25.459,94.262 L25.459,64.774 L22.396,64.774 L22.401,94.221 C22.401,99.258 14.856,99.258 14.856,94.221 L14.854,64.966 L4.765,64.966 L15.596,29.388 L13.902,29.388 L7.603,50.588 C6.103,55.209 -0.247,53.312 1.207,48.36 L8.2,25.33 C8.949,22.705 11.245,18.047 16.995,18.047 L30.775,18.047 C36.472,18.047 38.783,22.742 39.646,25.33 Z M23.9299927,16.328 C28.007,16.328 32.089,12.6728527 32.089,8.164 C32.089,3.6551473 28.4338527,0 23.925,0 C19.4161473,0 15.761,3.6551473 15.761,8.164 C15.761,12.6728527 19.843,16.328 23.9299927,16.328 Z";
            case "male":
                return "M18.1290012,16.318 C22.6350925,16.318 26.2880012,12.6650913 26.2880012,8.159 C26.2880012,3.65290873 22.6350925,0 18.1290012,0 C13.6229099,0 9.97000122,3.65290873 9.97000122,8.159 C9.97000122,12.6650913 13.6229099,16.318 18.1290012,16.318 Z M13.046,98 C15.575,98 17.622,95.95 17.622,93.426 L17.626,57.052 L19.663,57.052 L19.65,93.426 C19.65,95.95 21.698,98 24.224,98 C26.75,98 28.797,95.95 28.797,93.426 L28.817,29.268 L30.823,29.268 L30.823,53.648 C30.823,58.553 37.221,58.553 37.207,53.648 L37.207,28.748 C37.207,23.33 34.023,18.02 27.684,18.02 L9.396,18.012 C3.619,18.012 0,22.722 0,28.599 L0,53.649 C0,58.518 6.433,58.518 6.433,53.649 L6.433,29.269 L8.481,29.269 L8.472,93.426 C8.472,95.95 10.522,98 13.046,98 Z";

            case "instagram":
                return '<path d="M27.08,24.2 C27.08,25.79 25.79,27.08 24.2,27.08 L5.8,27.08 C4.21,27.08 2.92,25.79 2.92,24.2 L2.92,11.92 L7.4,11.92 C7.01,12.87 6.8,13.91 6.8,15 C6.8,19.52 10.48,23.2 15,23.2 C19.52,23.2 23.2,19.52 23.2,15 C23.2,13.91 22.98,12.87 22.6,11.92 L27.08,11.92 L27.08,24.2 L27.08,24.2 Z M10.72,11.92 C11.68,10.59 13.24,9.72 15,9.72 C16.76,9.72 18.32,10.59 19.28,11.92 C19.91,12.79 20.28,13.85 20.28,15 C20.28,17.91 17.91,20.28 15,20.28 C12.09,20.28 9.72,17.91 9.72,15 C9.72,13.85 10.09,12.79 10.72,11.92 L10.72,11.92 Z M25.87,3.46 L26.53,3.45 L26.53,8.54 L21.46,8.55 L21.44,3.47 L25.87,3.46 L25.87,3.46 Z M24.2,0 L5.8,0 C2.6,0 0,2.6 0,5.79 L0,24.2 C0,27.4 2.6,30 5.8,30 L24.2,30 C27.4,30 30,27.4 30,24.2 L30,5.79 C30,2.6 27.4,0 24.2,0 L24.2,0 Z" id="Fill-3182"></path>';
            case "facebook":
                return '<path d="M21,40 L27,40 L27,25 L31.453,25 L32,19 L27.232,19 L27.232,16.6 C27.232,15.43 28.011,15.16 28.558,15.16 L31.923,15.16 L31.923,10.02 L27.289,10 C22.144,10 20.975,13.83 20.975,16.29 L20.975,19 L18,19 L18,25 L21,25 L21,40" id="Fill-3070" fill="#000000"></path>';
            case "twitter":
                return "";
            case "skype":
                return "";
            case "tumblr":
                return "M20.31,35.95 C20.58,36.67 21.07,37.36 21.79,38 C22.49,38.63 23.35,39.13 24.36,39.48 C25.37,39.82 26.14,40 27.45,40 C28.6,40 29.68,39.87 30.67,39.63 C31.67,39.39 32.78,38.96 34,38.36 L34,33.63 C32.56,34.62 31.12,35.11 29.67,35.11 C28.85,35.11 28.13,34.91 27.49,34.51 C27.02,34.22 26.57,33.71 26.4,33.22 C26.23,32.73 26,31.72 26,29.98 L26,22 L32.87,22 L32.87,17 L26,17 L26,10 L22.17,10 C21.99,11.54 21.65,12.82 21.16,13.82 C20.68,14.82 20.04,15.68 19.23,16.4 C18.43,17.11 17.12,17.66 16,18.04 L16,22 L20,22 L20,32.81 C20,34.18 20.03,35.23 20.31,35.95";
            case "googleplus":
                return '<path d="M22.776,18.86 L24.065,18.86 C24.41,18.86 24.69,18.58 24.69,18.24 L24.69,13.71 L29.213,13.71 C29.557,13.71 29.838,13.42 29.838,13.08 L29.838,11.79 C29.838,11.44 29.557,11.16 29.213,11.16 L24.69,11.16 L24.69,6.63 C24.69,6.28 24.41,6 24.065,6 L22.776,6 C22.43,6 22.149,6.28 22.149,6.63 L22.149,11.16 L17.625,11.16 C17.281,11.16 17,11.44 17,11.79 L17,13.08 C17,13.42 17.281,13.71 17.625,13.71 L22.149,13.71 L22.149,18.24 C22.149,18.58 22.43,18.86 22.776,18.86" id="Fill-3131"></path><path d="M9.209,17.31 L9.281,17.31 C9.871,17.32 10.445,17.41 10.988,17.57 C11.174,17.7 11.353,17.82 11.527,17.94 C12.781,18.8 13.609,19.36 13.842,20.27 C13.895,20.5 13.922,20.73 13.922,20.95 C13.922,23.28 12.184,24.47 8.756,24.47 C6.156,24.47 4.123,22.87 4.123,20.83 C4.123,18.96 6.5,17.31 9.209,17.31 L9.209,17.31 Z M5.828,6.37 C5.656,5.05 5.932,3.87 6.588,3.12 C7.012,2.64 7.566,2.38 8.188,2.38 L8.26,2.38 C10.018,2.43 11.699,4.4 12.01,6.76 C12.184,8.08 11.891,9.32 11.227,10.08 C10.803,10.57 10.256,10.82 9.607,10.82 L9.578,10.82 C7.855,10.77 6.137,8.73 5.828,6.37 L5.828,6.37 Z M5.119,26.56 C6.264,26.85 7.494,27 8.771,27 C9.92,27 11.014,26.85 12.025,26.55 C15.18,25.64 17.219,23.31 17.219,20.62 C17.219,18.05 16.393,16.51 14.174,14.93 C13.223,14.26 12.357,13.27 12.342,12.96 C12.342,12.41 12.391,12.15 13.631,11.18 C15.234,9.92 16.117,8.27 16.117,6.52 C16.117,4.94 15.635,3.53 14.811,2.54 L15.449,2.54 C15.582,2.54 15.711,2.5 15.816,2.42 L17.596,1.13 C17.816,0.97 17.91,0.69 17.824,0.43 C17.74,0.17 17.5,0 17.228,0 L9.271,0 C8.4,0 7.516,0.15 6.648,0.45 C3.748,1.45 1.721,3.93 1.721,6.47 C1.721,10.07 4.504,12.8 8.227,12.89 C8.154,13.17 8.117,13.45 8.117,13.73 C8.117,14.28 8.258,14.81 8.547,15.32 L8.443,15.32 C4.896,15.32 1.693,17.06 0.478,19.66 C0.16,20.33 0,21.01 0,21.69 C0,22.34 0.168,22.97 0.496,23.57 C1.262,24.94 2.902,26 5.119,26.56 L5.119,26.56 Z"></path>';
            case "whatsapp":
                return "M22.709,17.98 C22.619,17.83 22.378,17.74 22.017,17.56 C21.656,17.38 19.881,16.52 19.55,16.4 C19.219,16.28 18.978,16.22 18.738,16.58 C18.497,16.93 17.805,17.74 17.594,17.98 C17.384,18.22 17.174,18.25 16.813,18.07 C16.452,17.89 15.288,17.51 13.909,16.29 C12.836,15.34 12.112,14.17 11.901,13.81 C11.69,13.45 11.879,13.26 12.059,13.08 C12.222,12.92 12.42,12.66 12.601,12.45 C12.782,12.24 12.842,12.09 12.962,11.86 C13.083,11.62 13.022,11.41 12.932,11.23 C12.842,11.05 12.119,9.29 11.818,8.57 C11.518,7.85 11.218,7.97 11.007,7.97 C10.796,7.97 10.555,7.94 10.314,7.94 C10.074,7.94 9.682,8.03 9.352,8.39 C9.021,8.75 8.089,9.61 8.089,11.38 C8.089,13.14 9.382,14.84 9.563,15.08 C9.743,15.32 12.059,19.05 15.729,20.49 C19.4,21.92 19.4,21.45 20.062,21.39 C20.723,21.33 22.197,20.52 22.498,19.68 C22.799,18.85 22.799,18.13 22.709,17.98 L22.709,17.98 Z M15.273,2.33 C8.445,2.33 2.891,7.84 2.891,14.61 C2.891,17.3 3.767,19.79 5.249,21.82 L3.702,26.38 L8.461,24.87 C10.416,26.15 12.758,26.9 15.273,26.9 C22.1,26.9 27.655,21.39 27.655,14.61 C27.655,7.84 22.1,2.33 15.273,2.33 L15.273,2.33 Z M30,14.61 C30,22.68 23.407,29.23 15.273,29.23 C12.69,29.23 10.264,28.57 8.154,27.41 L0,30 L2.658,22.16 C1.317,19.96 0.545,17.37 0.545,14.61 C0.545,6.54 7.138,0 15.273,0 C23.407,0 30,6.54 30,14.61 L30,14.61 Z";
            case "linkedin":
                return "";
            case "pinterest":
                return "";
            case "youtube":
                return "";
        }
    },

    computeRatio(styleValue, distance) {
        if (typeof (styleValue) === "string" && styleValue[styleValue.length - 1] === "%") {
            return parseFloat(styleValue) / 100;
        } else {
            return styleValue / distance;
        }
    },

    drawArrowHead(line, atEnd, options) {
        let start = atEnd ? line.start : line.end;
        let end = atEnd ? line.end : line.start;

        let arrowOffset = options.arrowOffset ?? 0.5;
        let arrowWidth = options.arrowWidth ?? 12;
        let arrowLength = options.arrowLength ?? 12;
        let lineDistance = start.distance(end);

        let unitVector = end.minus(start);
        unitVector = unitVector.scale(1 / unitVector.magnitude());

        let baseRatio = this.computeRatio(arrowOffset, lineDistance);
        // baseRatio = Math.clamp(baseRatio, arrowLength / lineDistance, 1);
        let base = end.lerp(start, baseRatio);
        let tip = base.plus(unitVector.scale(arrowLength));

        // perpendicular unitVector
        let offset = new geom.Point(-unitVector.y, unitVector.x);
        // then extend it to the desired length.
        offset = offset.scale(arrowWidth / 2);

        let left = base.plus(offset);
        let right = base.minus(offset);

        let path = new Path();
        path.moveTo(left.x, left.y);
        path.lineTo(right.x, right.y);
        path.lineTo(tip.x, tip.y);
        path.close();

        return path.toPathData();
    },

    drawCurvedSegment(cx, cy, rx, ry, startAngle, endAngle, width) {
        let innerRx = rx - width / 2;
        let innerRy = ry - width / 2;
        let outerRx = rx + width / 2;
        let outerRy = ry + width / 2;

        let centerPoint = new geom.Point(cx, cy);

        let pathStartOuterPt = centerPoint.offsetAngleRadians(startAngle, outerRx, outerRy);
        let pathStartInnerPt = centerPoint.offsetAngleRadians(startAngle, innerRx, innerRy);

        let pathEndOuterPt = centerPoint.offsetAngleRadians(endAngle, outerRx, outerRy);
        let pathEndInnerPt = centerPoint.offsetAngleRadians(endAngle, innerRx, innerRy);

        let path = new Path();
        path.moveTo(pathStartOuterPt);
        path.arc(outerRx, outerRy, 0, 0, 1, pathEndOuterPt.x, pathEndOuterPt.y);
        path.lineTo(pathEndInnerPt);
        path.arc(innerRx, innerRy, 0, 0, 0, pathStartInnerPt.x, pathStartInnerPt.y);
        path.close();

        return path;
    },

    drawCurvedArrow(cx, cy, rx, ry, startAngle, endAngle, width, arrowLength, arrowWidth, insetTail = true) {
        let centerPoint = new geom.Point(cx, cy);
        let innerRx = rx - width / 2;
        let innerRy = ry - width / 2;
        let outerRx = rx + width / 2;
        let outerRy = ry + width / 2;

        // calc path points
        let pathStartOuterPt = centerPoint.offsetAngleRadians(startAngle, outerRx, outerRy);
        let pathStartInnerPt = centerPoint.offsetAngleRadians(startAngle, innerRx, innerRy);

        let pathEndOuterPt = centerPoint.offsetAngleRadians(endAngle, outerRx, outerRy);
        let pathEndInnerPt = centerPoint.offsetAngleRadians(endAngle, innerRx, innerRy);

        // calc arrow points
        let arrowInnerRx = rx - arrowWidth / 2;
        let arrowInnerRy = ry - arrowWidth / 2;
        let arrowOuterRx = rx + arrowWidth / 2;
        let arrowOuterRy = ry + arrowWidth / 2;

        let arrowStartOuterPt = centerPoint.offsetAngleRadians(endAngle, arrowOuterRx, arrowOuterRy).toFixed(3);
        let arrowStartInnerPt = centerPoint.offsetAngleRadians(endAngle, arrowInnerRx, arrowInnerRy).toFixed(3);

        let tailOuterPt = centerPoint.offsetAngleRadians(startAngle, arrowOuterRx, arrowOuterRy).toFixed(3);
        let tailInnerPt = centerPoint.offsetAngleRadians(startAngle, arrowInnerRx, arrowInnerRy).toFixed(3);

        let getPerpendicular = function(ptA, ptB, d, angle) {
            angle = angle % (2 * Math.PI);
            let midPoint = new geom.Point((ptB.x + ptA.x) / 2, (ptB.y + ptA.y) / 2);

            // sanity check for horiontal lines
            if (Math.abs(ptA.y - ptB.y) < 0.0001) {
                if (angle == 0) {
                    return midPoint.offset(0, d);
                } else {
                    return midPoint.offset(0, -d);
                }
            }
            if (angle == -(Math.PI + Math.PI / 2)) {
                return midPoint.offset(-d, 0);
            }

            let slope = (ptB.y - ptA.y) / (ptB.x - ptA.x);
            let arrowSlope = -1 / slope;

            let xPlus = midPoint.x + Math.sqrt(d * d / (1 + arrowSlope * arrowSlope));
            let yPlus = arrowSlope * (xPlus - midPoint.x) + midPoint.y;

            let xMinus = midPoint.x - Math.sqrt(d * d / (1 + arrowSlope * arrowSlope));
            let yMinus = arrowSlope * (xMinus - midPoint.x) + midPoint.y;

            if (arrowSlope == Number.NEGATIVE_INFINITY || angle == Math.PI) {
                return midPoint.offset(0, -d);
            } else if (arrowSlope == Number.POSITIVE_INFINITY) {
                return midPoint.offset(0, d);
            } else if (angle < 0) {
                return new geom.Point(xPlus, yPlus);
            } else if (angle > Math.PI) {
                return new geom.Point(xPlus, yPlus);
            } else {
                return new geom.Point(xMinus, yMinus);
            }
        };

        let tailEndPoint = getPerpendicular(pathStartOuterPt, pathStartInnerPt, arrowLength, startAngle);
        let arrowEndPoint = getPerpendicular(arrowStartOuterPt, arrowStartInnerPt, arrowLength, endAngle);

        let adjustedInnerTailPt = pathStartInnerPt;
        let adjustedOuterTailPt = pathStartOuterPt;

        if (arrowWidth != width) {
            let tailSegment1 = new geom.Line(tailInnerPt, tailEndPoint);
            adjustedInnerTailPt = tailSegment1.intersectionsWithCircle(centerPoint.x, centerPoint.y, innerRx)[0];
            let tailSegment2 = new geom.Line(tailEndPoint, tailOuterPt);
            adjustedOuterTailPt = tailSegment2.intersectionsWithCircle(centerPoint.x, centerPoint.y, outerRx)[0];
        }

        let path = new Path();
        path.moveTo(adjustedOuterTailPt);
        // draw path outer curve
        path.arc(outerRx, outerRy, 0, 0, 1, pathEndOuterPt);
        // draw arrowhead
        path.lineTo(arrowStartOuterPt);
        path.lineTo(arrowEndPoint);
        path.lineTo(arrowStartInnerPt);
        path.lineTo(pathEndInnerPt);
        // draw path inner curve
        path.arc(innerRx, innerRy, 0, 0, 0, adjustedInnerTailPt);
        // draw tail inset
        if (insetTail) {
            path.lineTo(tailEndPoint);
            path.lineTo(adjustedOuterTailPt);
        }

        return path;
    },

    drawCurvedBumper(cx, cy, rx, ry, startAngle, endAngle, width, bumperRadius, insetTail = true) {
        let centerPoint = new geom.Point(cx, cy);
        let innerRx = rx - width / 2;
        let innerRy = ry - width / 2;
        let outerRx = rx + width / 2;
        let outerRy = ry + width / 2;

        // calc path points
        let pathStartOuterPt = centerPoint.offsetAngleRadians(startAngle, outerRx, outerRy);
        let pathStartInnerPt = centerPoint.offsetAngleRadians(startAngle, innerRx, innerRy);

        let pathEndOuterPt = centerPoint.offsetAngleRadians(endAngle, outerRx, outerRy);
        let pathEndInnerPt = centerPoint.offsetAngleRadians(endAngle, innerRx, innerRy);

        let path = new Path();
        path.moveTo(pathStartOuterPt);
        // draw path outer curve
        path.arc(outerRx, outerRy, 0, 0, 1, pathEndOuterPt);
        // draw bumper
        path.arc(bumperRadius, bumperRadius, 0, 0, 1, pathEndInnerPt);
        // draw path inner curve
        path.arc(innerRx, innerRy, 0, 0, 0, pathStartInnerPt);
        // draw tail inset
        if (insetTail) {
            path.arc(bumperRadius, bumperRadius, 0, 1, 0, pathStartOuterPt);
        }

        return path;
    },

    drawCurvedPuzzlePiece(cx, cy, rx, ry, startAngle, endAngle, width, connectorRadius, insetTail = true) {
        let centerPoint = new geom.Point(cx, cy);
        let innerRx = rx - width / 2;
        let innerRy = ry - width / 2;
        let outerRx = rx + width / 2;
        let outerRy = ry + width / 2;

        // calc path points
        let pathStartOuterPt = centerPoint.offsetAngleRadians(startAngle, outerRx, outerRy);
        let pathStartInnerPt = centerPoint.offsetAngleRadians(startAngle, innerRx, innerRy);

        let pathEndOuterPt = centerPoint.offsetAngleRadians(endAngle, outerRx, outerRy);
        let pathEndInnerPt = centerPoint.offsetAngleRadians(endAngle, innerRx, innerRy);

        let connectorPt1 = centerPoint.offsetAngleRadians(endAngle, rx + 25, ry + 25);
        let connectorPt2 = centerPoint.offsetAngleRadians(endAngle, rx - 25, ry - 25);

        let tailPt1 = centerPoint.offsetAngleRadians(startAngle, rx + 25, ry + 25);
        let tailPt2 = centerPoint.offsetAngleRadians(startAngle, rx - 25, ry - 25);

        let path = new Path();
        path.moveTo(pathStartOuterPt);
        // draw path outer curve
        path.arc(outerRx, outerRy, 0, 0, 1, pathEndOuterPt);
        // draw puzzle connector
        path.lineTo(connectorPt1);
        path.arc(connectorRadius, connectorRadius, 0, 1, 1, connectorPt2);
        path.lineTo(pathEndInnerPt);
        // draw path inner curve
        path.arc(innerRx, innerRy, 0, 0, 0, pathStartInnerPt);
        // draw tail inset
        if (insetTail) {
            path.lineTo(tailPt2);
            path.arc(connectorRadius, connectorRadius, 0, 1, 0, tailPt1);
            path.lineTo(pathStartOuterPt);
        }

        return path;
    },

    drawArrowHead2(cx, cy, rx, ry, endAngle, width, arrowLength, arrowWidth, drawStroke) {
        let innerRx = rx - width / 2;
        let innerRy = ry - width / 2;
        let outerRx = rx + width / 2;
        let outerRy = ry + width / 2;
        let arrowInnerRx = rx - arrowWidth / 2;
        let arrowInnerRy = ry - arrowWidth / 2;
        let arrowOuterRx = rx + arrowWidth / 2;
        let arrowOuterRy = ry + arrowWidth / 2;

        let centerPoint = new geom.Point(cx, cy);

        let arrowHeadAngle = arrowLength / (rx * Math.PI);

        let stemEndOuterPt = centerPoint.offsetAngleRadians(endAngle, outerRx, outerRy);
        let stemEndInnerPt = centerPoint.offsetAngleRadians(endAngle, innerRx, innerRy);

        let arrowStartOuterPt = centerPoint.offsetAngleRadians(endAngle, arrowOuterRx, arrowOuterRy).toFixed(3);
        let arrowStartInnerPt = centerPoint.offsetAngleRadians(endAngle, arrowInnerRx, arrowInnerRy).toFixed(3);

        let midPoint = new geom.Point((arrowStartOuterPt.x + arrowStartInnerPt.x) / 2, (arrowStartOuterPt.y + arrowStartInnerPt.y) / 2);

        let slope = (arrowStartOuterPt.y - arrowStartInnerPt.y) / (arrowStartOuterPt.x - arrowStartInnerPt.x);
        let arrowSlope = -1 / slope;

        let xPlus = midPoint.x + Math.sqrt(arrowLength * arrowLength / (1 + arrowSlope * arrowSlope));
        let xMinus = midPoint.x - Math.sqrt(arrowLength * arrowLength / (1 + arrowSlope * arrowSlope));

        let yPlus = arrowSlope * (xPlus - midPoint.x) + midPoint.y;
        let yMinus = arrowSlope * (xMinus - midPoint.x) + midPoint.y;

        let arrowEndPoint;
        if (arrowSlope == Number.NEGATIVE_INFINITY) {
            arrowEndPoint = midPoint.offset(0, arrowLength);
        } else if (arrowSlope == Number.POSITIVE_INFINITY) {
            arrowEndPoint = midPoint.offset(0, -arrowLength);
        } else if (endAngle < 0 || endAngle > Math.PI) {
            arrowEndPoint = new geom.Point(xPlus, yPlus);
        } else {
            arrowEndPoint = new geom.Point(xMinus, yMinus);
        }

        let path = new Path();

        path.moveTo(stemEndOuterPt);
        if (!arrowStartOuterPt.equals(stemEndOuterPt)) {
            path.lineTo(arrowStartOuterPt);
        }
        path.lineTo(arrowEndPoint);
        if (!arrowStartInnerPt.equals(stemEndInnerPt)) {
            path.lineTo(arrowStartInnerPt);
        }
        path.lineTo(stemEndInnerPt);

        return path;
    },

    drawPuzzleConnector(cx, cy, rx, ry, endAngle, width) {
        let innerRx = rx - width / 2;
        let innerRy = ry - width / 2;
        let outerRx = rx + width / 2;
        let outerRy = ry + width / 2;

        let centerPoint = new geom.Point(cx, cy);

        let stemEndOuterPt = centerPoint.offsetAngleRadians(endAngle, outerRx, outerRy);
        let stemEndInnerPt = centerPoint.offsetAngleRadians(endAngle, innerRx, innerRy);

        let connectorPt1 = centerPoint.offsetAngleRadians(endAngle, rx + 25, ry + 25);
        let connectorPt2 = centerPoint.offsetAngleRadians(endAngle, rx - 25, ry - 25);

        let path = new Path();
        path.moveTo(stemEndOuterPt);
        path.lineTo(connectorPt1);
        path.arc(30, 30, 0, 1, 1, connectorPt2.x, connectorPt2.y);
        path.lineTo(stemEndInnerPt);

        return path;
    },

};

class PolyLinePath {
    constructor() {
        this.points = [];
    }

    clone() {
        let path = new PolyLinePath();
        path.points = this.points.map(pt => pt.clone());
        return path;
    }

    get length() {
        let lineLength = 0;
        for (let i = 1; i < this.points.length; i++) {
            lineLength += this.points[i - 1].distance(this.points[i]);
        }
        return lineLength;
    }

    get segments() {
        return this.points.length - 1;
    }

    get bounds() {
        const left = _.min(this.points.map(pt => pt.x));
        const top = _.min(this.points.map(pt => pt.y));
        const width = _.max(this.points.map(pt => pt.x)) - left;
        const height = _.max(this.points.map(pt => pt.y)) - top;
        return new geom.Rect(left, top, width, height);
    }

    offset(x, y) {
        if (x instanceof geom.Point) {
            y = x.y;
            x = x.x;
        }

        this.points.forEach(pt => {
            pt.x += x;
            pt.y += y;
        });

        return this;
    }

    negOffset(x, y) {
        if (x instanceof geom.Point) {
            y = x.y;
            x = x.x;
        }

        this.points.forEach(pt => {
            pt.x -= x;
            pt.y -= y;
        });

        return this;
    }

    scale(scale) {
        this.points.forEach(pt => {
            pt.x *= scale;
            pt.y *= scale;
        });

        return this;
    }

    addPoint(x, y) {
        if (x instanceof geom.Point) {
            this.points.push(x);
        } else {
            let pt = new geom.Point(x, y);
            this.points.push(pt);
        }
    }

    horiz(x) {
        if (this.points.length) {
            let pt = new geom.Point(x, this.points[this.points.length - 1].y);
            this.points.push(pt);
        }
    }

    vert(y) {
        if (this.points.length) {
            let pt = new geom.Point(this.points[this.points.length - 1].x, y);
            this.points.push(pt);
        }
    }

    getSegment(index) {
        if (this.points.length > index + 1) {
            return new geom.Line(this.points[index], this.points[index + 1]);
        }
    }

    getPointAt(percentage) {
        let length = this.length * percentage;

        let curLength = 0;
        for (let i = 1; i < this.points.length; i++) {
            let startPt = this.points[i - 1];
            let endPt = this.points[i];

            let segmentLength = startPt.distance(endPt);
            curLength += segmentLength;
            if (curLength > length) {
                return startPt.lerp(endPt, 1 - (curLength - length) / segmentLength);
            }
        }
        return this.points[this.points.length - 1];
    }

    getSegmentForPoint(pt) {
        for (let i = 0; i < this.segments; i++) {
            if (this.getSegment(i).isPointOnLine(pt)) {
                return this.getSegment(i);
            }
        }
    }

    findClosestPointAsPercentageOfLength(fromPoint) {
        let currentDistance = Number.MAX_SAFE_INTEGER;
        let bestFitPercentage = 0;

        for (let i = 0; i < 1; i = i + 0.1) {
            const point = this.getPointAt(i);
            const distance = point.distance(fromPoint);
            if (distance < currentDistance) {
                currentDistance = distance;
                bestFitPercentage = i;
            }
        }

        for (let i = bestFitPercentage - 0.05; i < bestFitPercentage + 0.05; i = i + 0.005) {
            const point = this.getPointAt(i);
            const distance = point.distance(fromPoint);
            if (distance < currentDistance) {
                currentDistance = distance;
                bestFitPercentage = i;
            }
        }

        return bestFitPercentage;
    }

    trimLength(length, fromStart) {
        if (fromStart) {
            length = this.length - length;
            let trimmedPoints = [];
            let trimmedAmount = 0;
            let i = 0;
            while (trimmedAmount < length) {
                let segment = this.getSegment(i);
                if (!segment) break;
                if (length - trimmedAmount < segment.length) {
                    // trim segment and add point to trimmed points
                    trimmedPoints.push(segment.end.lerp(segment.start, 1 - ((length - trimmedAmount) / segment.length)));
                    trimmedPoints.push(segment.end);
                    i++;
                    break;
                } else {
                    // delete segment by not pushing points to trimmedPoints
                    trimmedAmount += segment.length;
                }
                i++;
            }

            // add any remaining points to trimmed points
            while (i < this.points.length) {
                trimmedPoints.push(this.points[i]);
                i++;
            }
            this.points = trimmedPoints;
        } else {
            let trimmedPoints = [this.points[0]];
            let curLength = 0;
            for (let i = 1; i < this.points.length; i++) {
                let startPt = this.points[i - 1];
                let endPt = this.points[i];

                let segmentLength = startPt.distance(endPt);
                curLength += segmentLength;
                if (curLength > length) {
                    endPt = startPt.lerp(endPt, 1 - (curLength - length) / segmentLength);
                    // Ensure the end point isn't redundant
                    if (!endPt.equals(startPt)) {
                        trimmedPoints.push(endPt);
                    }
                    break;
                } else {
                    trimmedPoints.push(endPt);
                }
            }
            this.points = trimmedPoints;
        }
    }

    trimStart(length) {
        return this.trimLength(Math.max(this.length - length, 0), true);
    }

    trimEnd(length) {
        return this.trimLength(Math.max(this.length - length, 0), false);
    }

    toArray() {
        return this.points;
    }

    toPathData(decimalPlaces) {
        var this$1 = this;

        decimalPlaces = decimalPlaces !== undefined ? decimalPlaces : 2;

        function floatToString(v) {
            if (Math.round(v) === v) {
                return "" + Math.round(v);
            } else {
                return v.toFixed(decimalPlaces);
            }
        }

        function packValues() {
            var arguments$1 = arguments;

            var s = "";
            for (var i = 0; i < arguments.length; i += 1) {
                var v = arguments$1[i];
                if (v >= 0 && i > 0) {
                    s += " ";
                }

                s += floatToString(v);
            }

            return s;
        }

        var d = "";
        for (var i = 0; i < this.points.length; i += 1) {
            var point = this$1.points[i];

            if (i == 0) {
                d += "M" + packValues(point.x, point.y);
            } else {
                d += "L" + packValues(point.x, point.y);
            }
        }

        return d;
    }

    containsPoint(point, tolerance) {
        for (let i = 0; i < this.points.length - 1; i++) {
            const startPoint = this.points[i];
            const endPoint = this.points[i + 1];

            const lineLength = startPoint.distance(endPoint);
            const startDistance = startPoint.distance(point);
            const endDistance = endPoint.distance(point);

            const halfPerimeter = (lineLength + startDistance + endDistance) / 2;

            const pointToLineDistance = 2 / lineLength * Math.sqrt(halfPerimeter * (halfPerimeter - lineLength) * (halfPerimeter - startDistance) * (halfPerimeter - endDistance));

            if (pointToLineDistance <= tolerance) {
                return true;
            }
        }

        return false;
    }
}

export class ConnectorPath extends PolyLinePath {
    constructor() {
        super();
        this.adjustments = {};
    }

    clone() {
        const path = new ConnectorPath();
        path.points = this.points.map(pt => {
            const newPt = pt.clone();
            newPt.adjustmentId = pt.adjustmentId;
            newPt.adjustmentDirection = pt.adjustmentDirection;
            return newPt;
        });
        return path;
    }

    setAdjustments(adjustments) {
        this.adjustments = adjustments;
    }

    horiz(x) {
        if (this.points.length) {
            let adjIndex = this.points.length;
            if (adjIndex > 0 && this.adjustments["a" + adjIndex] != null) {
                x = this.adjustments["a" + adjIndex];
            }

            let pt = new geom.Point(x, this.points[this.points.length - 1].y);
            pt.adjustmentId = "a" + adjIndex;
            pt.adjustmentDirection = "H";
            this.points.push(pt);
        }
    }

    vert(y) {
        if (this.points.length) {
            let adjIndex = this.points.length;
            if (adjIndex > 0 && this.adjustments["a" + adjIndex] != null) {
                y = this.adjustments["a" + adjIndex];
            }
            let pt = new geom.Point(this.points[this.points.length - 1].x, y);
            pt.adjustmentId = "a" + adjIndex;
            pt.adjustmentDirection = "V";
            this.points.push(pt);
        }
    }

    addEndPoint(pt) {
        // remove adjustment props from previous point because we can't adjust last segment
        delete this.points[this.points.length - 1].adjustmentId;
        delete this.points[this.points.length - 1].adjustmentDirection;

        this.points.push(pt);
    }
}

/**
 * A bézier path containing a set of path commands similar to a SVG path.
 * Paths can be drawn on a context using `draw`.
 * @exports opentype.Path
 * @class
 * @constructor
 */

class Path {
    constructor() {
        this.commands = [];
        this.fill = "black";
        this.stroke = null;
        this.strokeWidth = 1;
    }

    moveTo(x, y) {
        if (x instanceof geom.Point) {
            y = x.y;
            x = x.x;
        }
        this.commands.push({
            type: "M",
            x: x,
            y: y
        });
        return this;
    }

    lineTo(x, y) {
        if (x instanceof geom.Point) {
            y = x.y;
            x = x.x;
        }
        this.commands.push({
            type: this.commands.length == 0 ? "M" : "L",
            x: x,
            y: y
        });
        return this;
    }

    offset(x, y) {
        if (x instanceof geom.Point) {
            y = x.y;
            x = x.x;
        }
        for (let command of this.commands) {
            command.x += x;
            command.y += y;
        }
        return this;
    }

    /**
     * Draws cubic curve
     * @function
     * curveTo
     * @memberof opentype.Path.prototype
     * @param  {number} x1 - x of control 1
     * @param  {number} y1 - y of control 1
     * @param  {number} x2 - x of control 2
     * @param  {number} y2 - y of control 2
     * @param  {number} x - x of path point
     * @param  {number} y - y of path point
     */

    /**
     * Draws cubic curve
     * @function
     * bezierCurveTo
     * @memberof opentype.Path.prototype
     * @param  {number} x1 - x of control 1
     * @param  {number} y1 - y of control 1
     * @param  {number} x2 - x of control 2
     * @param  {number} y2 - y of control 2
     * @param  {number} x - x of path point
     * @param  {number} y - y of path point
     * @see curveTo
     */
    curveTo(x1, y1, x2, y2, x, y) {
        this.commands.push({
            type: "C",
            x1: x1,
            y1: y1,
            x2: x2,
            y2: y2,
            x: x,
            y: y
        });
    }

    /**
     * Draws quadratic curve
     * @function
     * quadraticCurveTo
     * @memberof opentype.Path.prototype
     * @param  {number} x1 - x of control
     * @param  {number} y1 - y of control
     * @param  {number} x - x of path point
     * @param  {number} y - y of path point
     */

    /**
     * Draws quadratic curve
     * @function
     * quadTo
     * @memberof opentype.Path.prototype
     * @param  {number} x1 - x of control
     * @param  {number} y1 - y of control
     * @param  {number} x - x of path point
     * @param  {number} y - y of path point
     */
    quadTo(x1, y1, x, y) {
        this.commands.push({
            type: "Q",
            x1: x1,
            y1: y1,
            x: x,
            y: y
        });
    }

    arc(rx, ry, rot, largeArcFlag, sweepFlag, x, y) {
        if (x instanceof geom.Point) {
            y = x.y;
            x = x.x;
        }

        this.commands.push({
            type: "A",
            rx,
            ry,
            rot,
            largeArcFlag,
            sweepFlag,
            x,
            y
        });
    }

    /**
     * Closes the path
     * @function closePath
     * @memberof opentype.Path.prototype
     */

    /**
     * Close the path
     * @function close
     * @memberof opentype.Path.prototype
     */
    close() {
        this.commands.push({
            type: "Z"
        });
    }

    /**
     * Add the given path or list of commands to the commands of this path.
     * @param  {Array} pathOrCommands - another opentype.Path, an opentype.BoundingBox, or an array of commands.
     */
    extend(pathOrCommands) {
        if (pathOrCommands.commands) {
            pathOrCommands = pathOrCommands.commands;
        } else if (pathOrCommands instanceof BoundingBox) {
            var box = pathOrCommands;
            this.moveTo(box.x1, box.y1);
            this.lineTo(box.x2, box.y1);
            this.lineTo(box.x2, box.y2);
            this.lineTo(box.x1, box.y2);
            this.close();
            return;
        }

        Array.prototype.push.apply(this.commands, pathOrCommands);
    }

    /**
     * Calculate the bounding box of the path.
     * @returns {opentype.BoundingBox}
     */
    getBoundingBox() {
        var this$1 = this;

        var box = new BoundingBox();

        var startX = 0;
        var startY = 0;
        var prevX = 0;
        var prevY = 0;
        for (var i = 0; i < this.commands.length; i++) {
            var cmd = this$1.commands[i];
            switch (cmd.type) {
                case "M":
                    box.addPoint(cmd.x, cmd.y);
                    startX = prevX = cmd.x;
                    startY = prevY = cmd.y;
                    break;
                case "L":
                    box.addPoint(cmd.x, cmd.y);
                    prevX = cmd.x;
                    prevY = cmd.y;
                    break;
                case "Q":
                    box.addQuad(prevX, prevY, cmd.x1, cmd.y1, cmd.x, cmd.y);
                    prevX = cmd.x;
                    prevY = cmd.y;
                    break;
                case "C":
                    box.addBezier(prevX, prevY, cmd.x1, cmd.y1, cmd.x2, cmd.y2, cmd.x, cmd.y);
                    prevX = cmd.x;
                    prevY = cmd.y;
                    break;
                case "Z":
                    prevX = startX;
                    prevY = startY;
                    break;
                default:
                    throw new Error("Unexpected path command " + cmd.type);
            }
        }
        if (box.isEmpty()) {
            box.addPoint(0, 0);
        }
        return box;
    }

    /**
     * Draw the path to a 2D context.
     * @param {CanvasRenderingContext2D} ctx - A 2D drawing context.
     */
    draw(ctx) {
        var this$1 = this;

        ctx.beginPath();
        for (var i = 0; i < this.commands.length; i += 1) {
            var cmd = this$1.commands[i];
            if (cmd.type === "M") {
                ctx.moveTo(cmd.x, cmd.y);
            } else if (cmd.type === "L") {
                ctx.lineTo(cmd.x, cmd.y);
            } else if (cmd.type === "C") {
                ctx.bezierCurveTo(cmd.x1, cmd.y1, cmd.x2, cmd.y2, cmd.x, cmd.y);
            } else if (cmd.type === "Q") {
                ctx.quadraticCurveTo(cmd.x1, cmd.y1, cmd.x, cmd.y);
            } else if (cmd.type === "Z") {
                ctx.closePath();
            }
        }

        if (this.fill) {
            ctx.fillStyle = this.fill;
            ctx.fill();
        }

        if (this.stroke) {
            ctx.strokeStyle = this.stroke;
            ctx.lineWidth = this.strokeWidth;
            ctx.stroke();
        }
    }

    /**
     * Convert the Path to a string of path data instructions
     * See http://www.w3.org/TR/SVG/paths.html#PathData
     * @param  {number} [decimalPlaces=2] - The amount of decimal places for floating-point values
     * @return {string}
     */
    toPathData(decimalPlaces) {
        var this$1 = this;

        decimalPlaces = decimalPlaces !== undefined ? decimalPlaces : 2;

        function floatToString(v) {
            if (Math.round(v) === v) {
                return "" + Math.round(v);
            } else {
                return v.toFixed(decimalPlaces);
            }
        }

        function packValues() {
            var arguments$1 = arguments;

            var s = "";
            for (var i = 0; i < arguments.length; i += 1) {
                var v = arguments$1[i];
                if (v >= 0 && i > 0) {
                    s += " ";
                }

                s += floatToString(v);
            }

            return s;
        }

        var d = "";
        for (var i = 0; i < this.commands.length; i += 1) {
            var cmd = this$1.commands[i];
            if (cmd.type === "M") {
                d += "M" + packValues(cmd.x, cmd.y);
            } else if (cmd.type === "L") {
                d += "L" + packValues(cmd.x, cmd.y);
            } else if (cmd.type === "C") {
                d += "C" + packValues(cmd.x1, cmd.y1, cmd.x2, cmd.y2, cmd.x, cmd.y);
            } else if (cmd.type === "Q") {
                d += "Q" + packValues(cmd.x1, cmd.y1, cmd.x, cmd.y);
            } else if (cmd.type === "Z") {
                d += "Z";
            } else if (cmd.type === "A") {
                d += "A" + packValues(cmd.rx, cmd.ry, cmd.rot, cmd.largeArcFlag, cmd.sweepFlag, cmd.x, cmd.y);
            }
        }

        return d;
    }

    toPolygonData() {
        let a = [];
        for (let cmd of this.commands) {
            if (cmd.type == "M" || cmd.type == "L") {
                a.push([cmd.x, cmd.y]);
            }
        }
        return a;
    }

    toPolylineData() {
        let a = "";
        for (let cmd of this.commands) {
            if (cmd.type == "M" || cmd.type == "L") {
                a += cmd.x + "," + cmd.y + "  ";
            }
        }
        return a;
    }

    toCSSPolygon() {
        let a = "";
        for (let cmd of this.commands) {
            if (cmd.type == "M" || cmd.type == "L") {
                a += "," + cmd.x + "px " + cmd.y + "px";
            }
        }
        a = a.substr(1);
        return a;
    }

    /**
     * Convert the path to an SVG <path> element, as a string.
     * @param  {number} [decimalPlaces=2] - The amount of decimal places for floating-point values
     * @return {string}
     */
    toSVG(decimalPlaces) {
        var svg = '<path d="';
        svg += this.toPathData(decimalPlaces);
        svg += '"';
        if (this.fill && this.fill !== "black") {
            if (this.fill === null) {
                svg += ' fill="none"';
            } else {
                svg += ' fill="' + this.fill + '"';
            }
        }

        if (this.stroke) {
            svg += ' stroke="' + this.stroke + '" stroke-width="' + this.strokeWidth + '"';
        }

        svg += "/>";
        return svg;
    }

    clone() {
        let path = new Path();
        path.commands = _.clone(this.commands);
        return path;
    }
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return new geom.Point(
        centerX + (radius * Math.cos(angleInRadians)),
        centerY + (radius * Math.sin(angleInRadians))
    );
}

function pointInPolygon(point, vs, start, end) {
    var x = point[0], y = point[1];
    var inside = false;
    if (start === undefined) start = 0;
    if (end === undefined) end = vs.length;
    var len = (end - start) / 2;
    for (var i = 0, j = len - 1; i < len; j = i++) {
        var xi = vs[start + i * 2 + 0], yi = vs[start + i * 2 + 1];
        var xj = vs[start + j * 2 + 0], yj = vs[start + j * 2 + 1];
        var intersect = ((yi > y) !== (yj > y)) &&
            (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
}

export { Callout, Shape, PolyLinePath, Path, polarToCartesian, pointInPolygon };
