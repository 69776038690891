import { workspaces as workspacesApi } from "apis/callables";
import { WorkspacePermissionObjectType } from "common/interfaces";
import WorkspaceController from "js/controllers/WorkspaceController";
import { ds } from "js/core/models/dataService";
import { delay } from "js/core/utilities/promiseHelper";
import { emailRegex } from "js/core/utilities/regex";
import { UIController } from "js/editor/dialogs/UIController";
import { app } from "js/namespaces";
import { _ } from "js/vendor";

export const createInviteSuggestionContext = options => {
    let {
        includeCurrentUser = false,
        includeFolders = false,
        filterPlaceholder,
        labelCategoryOld = "Presentation Collaborators",
        labelCategoryNew = "Suggestions",
        allSuggestions: defaultAllSuggestions = [],
        oldSuggestions: defaultOldSuggestions = [],
        selection: defaultSelection = [],
        oldFirst = false,
        hideOld = false,
        prohibitExternalWorkspaceCollaboration = false,
        saveContext,
        afterInit,
        presentationId,
    } = options;

    filterPlaceholder = (
        filterPlaceholder ||
            includeFolders
            ? "#team folder, @teammate, or type email"
            : "@teammate, or type email"
    );

    const context = {
        allSuggestions: [],
        newSuggestions: [],
        oldSuggestions: [],
        filteredAllSuggestions: [],
        filteredNewSuggestions: [],
        filteredOldSuggestions: [],
        selectableSuggestions: [],
        selection: [],
        filter: "",
        filterPlaceholder,
        labelCategoryOld,
        labelCategoryNew,
        oldFirst,
        pendingSelectionIndex: 0,
    };

    const upgradeEmailsToSuggestions = items => {
        const {
            allSuggestions,
        } = context;

        items = items.map(item => {
            // Convert the string items to an email item
            if (_.isString(item)) {
                // Remove type filters
                if (item.startsWith("@") || item.startsWith("#")) {
                    item = item.slice(1);
                }
                item = {
                    type: "email",
                    email: item,
                };
            }

            // Folder items don't have an email property.
            //   Otherwise, ensure emails are lowercase
            if (item.email) {
                item.email = item.email.toLowerCase();
            }

            if (!item.type) {
                if (item.email) {
                    item.type = item.id ? "user" : "email";
                } else if (item.users) {
                    item.type = "folder";
                }
            }

            // Convert email items to any existing user items
            if (item.type === "email") {
                // Convert the email item to an existing user if possible
                let user = allSuggestions.find(x => x.email === item.email);
                if (user) {
                    return user;
                }
            }

            return item;
        });

        return items;
    };

    const sortSuggestions = () => {
        const sortFunc = (a, b) => {
            const nameA = (a.displayName || a.name || a.email)?.toLowerCase();
            const nameB = (b.displayName || b.name || b.email)?.toLowerCase();

            return nameA < nameB ? -1 : 1;
        };

        context.oldSuggestions = upgradeEmailsToSuggestions(context.oldSuggestions)
            .sort(sortFunc);

        context.newSuggestions = context.allSuggestions
            .filter(item =>
                !context.oldSuggestions.some(x => (
                    (
                        x.id &&
                        x.id === item.id
                    ) ||
                    (
                        x.email &&
                        x.email === item.email
                    )
                ))
            )
            .sort(sortFunc);

        if (oldFirst) {
            context.allSuggestions = [
                ...context.oldSuggestions,
                ...context.newSuggestions,
            ];
        } else {
            context.allSuggestions = [
                ...context.newSuggestions,
                ...context.oldSuggestions,
            ];
        }
    };

    const filterSuggestions = () => {
        let {
            newSuggestions,
            oldSuggestions,
            selection,
            filter,
        } = context;

        context.filteredAllSuggestions = [];
        context.filteredNewSuggestions = [];
        context.filteredOldSuggestions = [];

        // Item type filtering
        let searchNonFolders = true;
        let searchFolders = includeFolders;
        if (filter.startsWith("@")) {
            searchFolders = false;
            filter = filter.slice(1);
        } else if (filter.startsWith("#")) {
            searchNonFolders = false;
            filter = filter.slice(1);
        }

        const includeItem = item => (
            (
                (
                    searchNonFolders &&
                    item.type !== "folder"
                ) ||
                (
                    searchFolders &&
                    item.type === "folder"
                )
            ) &&
            (
                item.displayName?.toLowerCase().includes(filter) ||
                item.name?.toLowerCase().includes(filter) ||
                item.email?.toLowerCase().includes(filter)
            )
        );

        context.filteredNewSuggestions = newSuggestions.filter(item => (
            !selection.some(x => x.id === item.id) &&
            includeItem(item)
        ));

        if (!hideOld) {
            context.filteredOldSuggestions = oldSuggestions.filter(item => (
                !selection.some(x => x.id === item.id) &&
                includeItem(item)
            ));
        }

        if (oldFirst) {
            context.filteredAllSuggestions = [
                ...context.filteredOldSuggestions,
                ...context.filteredNewSuggestions,
            ];
        } else {
            context.filteredAllSuggestions = [
                ...context.filteredNewSuggestions,
                ...context.filteredOldSuggestions,
            ];
        }

        context.selectableSuggestions = context.filteredAllSuggestions;

        if (context.pendingSelectionIndex >= context.selectableSuggestions.length) {
            context.pendingSelectionIndex = Math.max(context.selectableSuggestions.length - 1, 0);
        }
    };

    // Ensure defaults are in the correct form
    defaultAllSuggestions = upgradeEmailsToSuggestions(defaultAllSuggestions);
    defaultSelection = upgradeEmailsToSuggestions(defaultSelection);
    defaultOldSuggestions = upgradeEmailsToSuggestions(defaultOldSuggestions);

    context.init = async () => {
        // Reset suggestions
        context.allSuggestions = [...defaultAllSuggestions];
        context.oldSuggestions = [...defaultOldSuggestions];

        // Clear to defaults
        context.selection = [...defaultSelection];

        if (!context.allSuggestions?.length) {
            context.allSuggestions = await UIController.getAllInviteSuggestions(presentationId, includeFolders);
            if (!includeCurrentUser) {
                context.allSuggestions = context.allSuggestions.filter(item => item.id !== app.user.id);
            }

            // Remove the default folder if user doesn't have modify or access permissions
            const defaultFolderItem = context.allSuggestions.find(item => item.type === "folder" && item.isDefault);
            if (defaultFolderItem) {
                const { write } = await workspacesApi.getUserPermissionsToResource({
                    workspaceId: WorkspaceController.workspaceId,
                    resourceId: defaultFolderItem.id,
                    resourceType: WorkspacePermissionObjectType.RESOURCE_FOLDER
                });

                if (!write.object) {
                    context.allSuggestions = context.allSuggestions.filter(item => item !== defaultFolderItem);
                }
            }
        }

        if (!context.oldSuggestions?.length) {
            // Default the old suggestions to an empty array
            context.oldSuggestions = [];

            // Suggestions with permissions are collaborators
            context.oldSuggestions = context.allSuggestions.filter(item => !!item.permissionType);
        }

        sortSuggestions();

        filterSuggestions();
        saveContext(context);

        if (afterInit) {
            await delay(10);
            afterInit();
        }
    };

    context.getFilterWithoutCategories = () => {
        let {
            filter,
        } = context;

        if (filter.startsWith("@") || filter.startsWith("#")) {
            return filter.slice(1);
        }
        return filter;
    };

    context.clearSelection = () => {
        context.selection = [...defaultSelection];
        context.pendingSelectionIndex = 0;
        filterSuggestions();

        saveContext(context);
    };

    context.selectItems = items => {
        const {
            selection,
            allSuggestions,
        } = context;

        const errors = [];

        const oldLength = selection.length;
        const userEmail = app.user?.get("email");

        // Remove the error on add
        context.errors = null;

        const isUnprohibited = item => {
            // If flagged, only suggested emails will be permitted.
            //   Currently, this only includes workspace team members
            //   and existing collaborators.
            if (prohibitExternalWorkspaceCollaboration) {
                const isExistingSuggestion = allSuggestions.some(x =>
                    (
                        x.id &&
                        x.id === item.id
                    ) ||
                    (
                        x.email &&
                        x.email === item.email
                    )
                );

                // Error and remove the item if the item
                //   is not an existing suggestion
                if (!isExistingSuggestion) {
                    errors.push(`Only members in this workspace can be invited to collaborate.`);
                    return false;
                }
            }

            return true;
        };

        const selectUnselected = item => {
            const isSelected = selection.some(x =>
                (
                    x.id &&
                    x.id === item.id
                ) ||
                (
                    x.email &&
                    x.email === item.email
                )
            );

            if (isSelected) {
                errors.push(`Email "${item.email}" has already been selected`);
                return;
            }

            selection.push(item);
        };

        items = upgradeEmailsToSuggestions(items);

        items.forEach(item => {
            if (item.email === userEmail) {
                errors.push(`You already have access to this presentation`);
            }
            if (
                item.type === "folder" ||
                item.email?.match(emailRegex)
            ) {
                if (isUnprohibited(item)) {
                    selectUnselected(item);
                }
                // If the email failed validation and is non-empty, report a format error
            } else if (item.email) {
                errors.push(`Emails must be in the form of "email@domain.xyz"`);
            }
        });

        // If we've added items, save
        if (oldLength !== selection.length) {
            context.filter = "";
            context.pendingSelectionIndex = 0;
            filterSuggestions();
            saveContext(context);
        }

        // Process after a delay to prevent errors being removed by sequential context calls
        if (errors.length) {
            setTimeout(() => {
                context.errors = _.uniq(errors);
                saveContext(context);
            }, 10);
        }
    };

    context.deselectItems = items => {
        const {
            selection,
        } = context;

        const oldLength = selection.length;

        items.forEach(item => {
            const index = selection.findIndex(x => (
                x === item ||
                x.id === item.id ||
                x.email === item.email
            ));

            if (index > -1) {
                selection.splice(index, 1);
            }
        });

        if (oldLength !== selection.length) {
            // Remove the format error on remove
            context.errors = null;
            filterSuggestions();
            saveContext(context);
        }
    };

    context.popItem = () => {
        const item = context.selection.pop();
        if (item) {
            // Remove the format error on remove
            context.errors = null;
            filterSuggestions();
            saveContext(context);
        }
        return item;
    };

    context.setFilter = filter => {
        filter = filter.toLowerCase();
        if (context.filter !== filter) {
            // If the filter has changed or is empty, remove the format error
            context.errors = null;
            context.filter = filter;
            // Reset the selection index every time the filter changes
            context.pendingSelectionIndex = 0;
            filterSuggestions();
            saveContext(context);
        }
    };

    context.selectFilterAsEmail = () => {
        const filter = context.filter;
        if (filter?.length) {
            context.selectItems([filter]);
            saveContext(context);
        }
    };

    context.setPendingSelectionIndex = index => {
        if (index < 0) {
            index = Math.max(context.selectableSuggestions.length - 1, 0);
        } else if (index >= context.selectableSuggestions.length) {
            index = 0;
        }
        context.pendingSelectionIndex = index;
        saveContext(context);
    };

    context.pendingSelectPrev = () =>
        context.setPendingSelectionIndex(context.pendingSelectionIndex - 1);
    context.pendingSelectNext = () =>
        context.setPendingSelectionIndex(context.pendingSelectionIndex + 1);
    context.selectPendingSelection = () => {
        if (!context.selectableSuggestions.length) {
            return null;
        }

        const item = context.selectableSuggestions[context.pendingSelectionIndex];
        context.selectItems([item]);
        return item;
    };

    context.getSelectedUniqueUsers = () => {
        const {
            selection,
            oldSuggestions,
        } = context;

        let users = selection.map(item => {
            switch (item.type) {
                case "user":
                    return item;
                case "folder":
                    return item.users.filter(user => {
                        // Only include the folder users that aren't part of old suggestions
                        let include = !oldSuggestions.some(x => x.id === user.id);
                        if (!includeCurrentUser) {
                            include = include && user.id !== app.user.id;
                        }
                        return include;
                    });
                case "email":
                default:
                    return {
                        id: null,
                        uid: null,
                        photoUrl: null,
                        ...item,
                        type: "user",
                    };
            }
        });
        users = _.flatten(users);
        users = _.uniqBy(users, "email");
        return users;
    };

    context.getSelectionWithRedundanciesRemoved = () => {
        const {
            selection,
        } = context;

        const folders = selection.filter(item => item.type === "folder");
        let users = selection
            .filter(item => item.type !== "folder")
            .map(item => {
                switch (item.type) {
                    case "user":
                        return item;
                    case "email":
                    default:
                        return {
                            id: null,
                            uid: null,
                            photoUrl: null,
                            ...item,
                            type: "user",
                        };
                }
            });
        users = _.uniqBy(users, "email");

        // Remove users that are already included in the folders,
        //   but keeps those that already have permissions
        users = users.filter(user => (
            !!user.permissionType ||
            !folders.some(folder =>
                folder.users.some(x => (
                    x.id === user.id ||
                    x.email === user.email
                ))
            )
        ));

        return [
            ...folders,
            ...users,
        ];
    };

    // This will be used to trigger refresh when a collaborator was added to a presentation because we can't
    // listen to that ourselves
    ds.on("presentationPermissionsChanged", context.init);

    context.destroy = () => {
        ds.off("presentationPermissionsChanged", context.init);
    };

    context.init();

    return context;
};
