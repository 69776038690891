import React from "react";
import { withRouter, Redirect } from "react-router-dom";

import { KlaviyoLists } from "common/constants";
import { serverContext } from "js/config";
import Api from "js/core/api";
import ErrorMessage from "js/app/ErrorMessage";
import { trackActivity } from "js/core/utilities/utilities";
import { LoadingPage } from "js/react/components/LoadingPage";
import { BEAUTIFUL_WORKSPACE_ID, BEAUTIFUL_WORKSPACE_ACTION, WorkspaceAction } from "common/constants";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { Error } from "js/app/Error";
import getLocalStorage from "js/core/utilities/localStorage";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.INVITES);

const localStorage = getLocalStorage();

class Invite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "pending"
        };
    }

    async componentDidMount() {
        const user = this.props.firebaseUser;
        const { token } = this.props.match.params;
        const { inviteType, fromUser, redirect: unsafeRedirect, email, form, presentationPermission } = serverContext;

        const redirect = unsafeRedirect.startsWith("http")
            ? new URL(unsafeRedirect).pathname
            : unsafeRedirect;

        // email verify link tracking
        const hasSignupParam = new URL(redirect, "http://base").searchParams.get("signup") === "true";
        let isNewUser = hasSignupParam || form == "signup";

        if (inviteType == "share") {
            if (presentationPermission === "secured" && email !== user.email) {
                isNewUser = false;
            }
            this.handleShareInvite(redirect, isNewUser);
        } else if (inviteType == "collaboration") {
            this.handleCollaborationInvite(redirect, token, fromUser, isNewUser);
        } else if (inviteType == "team") {
            this.handleTeamInvite(redirect, token);
        } else {
            this.setState({ status: "failed", error: `Unknown invite type: ${inviteType}` });
        }
    }

    render() {
        switch (this.state.status) {
            case "pending":
                return (
                    <LoadingPage />
                );
            case "succeeded":
                // hack due to the player still needing serverContext but we don't want to navigate,
                // ideally the player could figure this out for itself
                if (this.state.url != "/") {
                    window.serverContext.contextId = this.state.url.match(/[A-Za-z0-9_-]{20}/)[0];
                }
                return (
                    <Redirect to={this.state.url} />
                );
            case "failed":
                return (
                    <ErrorMessage message={this.state.error} />
                );
            case "error":
                return <Error title={this.state.title} message={this.state.message} />;
        }
    }

    handleShareInvite = (redirectUrl, isNewUser) => {
        if (isNewUser) {
            Api.klaviyoSubscribe.post({ listType: "invites" }); // fire and forget
        }
        window.baiSource = "secureshare";
        this.setState({ status: "succeeded", url: redirectUrl });
    }

    handleCollaborationInvite = (redirectUrl, token, fromUser, isNewUser) => {
        Api.invites.post({ token: token })
            .then(res => {
                const isViewOnly = /\/player\//.test(redirectUrl); // TODO: server should tell us this, don't make the client infer it.
                if (isNewUser && res.count) {
                    trackActivity("Collab", "Signup", null, res.count, {
                        fromUser: fromUser,
                        permission: isViewOnly ? "view" : "edit",
                        workspace_id: "personal"
                    }, { audit: true });
                    Api.klaviyoSubscribe.post({ listType: "invites" }); // fire and forget
                }
                window.baiSource = "collab:" + (isViewOnly ? "view" : "edit");
                this.setState({ status: "succeeded", url: redirectUrl });
            })
            .catch(err => {
                logger.error(err, "handleCollaborationInvite() failed", { redirectUrl, token, fromUserUid: fromUser?.uid, isNewUser });
                this.setState({ status: "failed", error: err.message });
            });
    }

    handleTeamInvite = (redirectUrl, token) => {
        Api.teamInvites.post({ token })
            .then(res => {
                // open the main app in the newly joined workspace
                localStorage.setItem(BEAUTIFUL_WORKSPACE_ID, res.orgId);
                localStorage.setItem(BEAUTIFUL_WORKSPACE_ACTION, WorkspaceAction.JOINED);

                trackActivity("Organization", "Joined", null, null, { workspace_id: res.orgId });
                window.baiSource = "team-invite";
                this.setState({ status: "succeeded", url: redirectUrl });
            })
            .catch(err => {
                logger.error(err, "handleTeamInvite() failed", { redirectUrl, token });
                this.setState({ status: "failed", error: err.message });
            });
    }
}

export default withRouter(withFirebaseUser(Invite));
