import React from "react";
import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";

import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.THUMBNAIL);

const ThumbnailContainer = styled.div.attrs(({ hilited, outlineWidth, clickable, width }) => ({
    style: {
        outline: hilited ? `${outlineWidth}px solid #50bbe6` : "none",
        cursor: clickable ? "pointer" : "default",
        pointerEvents: clickable ? "default" : "none",
        width
    }
}))`
    max-height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        float: left;
        padding-top: 56.25%;
        content: "";
    }
    &::after {
        display: block;
        content: "";
        clear: both;
    }


    img {
        width: 100%;
        height: 100%;
    }
`;

const Circle = styled.div`
  position: absolute;
  bottom: -33px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
`;

class Thumbnail extends React.Component {
    constructor() {
        super();

        this.state = {
            url: null
        };
    }

    componentDidMount() {
        const { shouldLoadThumbnail } = this.props;
        if (shouldLoadThumbnail) {
            this.loadThumbnail();
        }
    }

    componentDidUpdate(prevProps) {
        const { slide, shouldLoadThumbnail, playbackStageIndex } = this.props;

        if (slide.id !== prevProps.slide.id && shouldLoadThumbnail) {
            this.loadThumbnail();
        } else if (playbackStageIndex !== prevProps.playbackStageIndex && shouldLoadThumbnail) {
            this.loadThumbnail();
        } else if (shouldLoadThumbnail && !prevProps.shouldLoadThumbnail) {
            this.loadThumbnail();
        }
    }

    loadThumbnail() {
        const {
            presentation,
            slide,
            slideMetadata,
            imageSize = "small",
            playbackStageIndex = 0
        } = this.props;

        this.setState({ url: null });

        const presetationOrLinkId = presentation.get("link")?.id ?? presentation.id;
        slide.getThumbnailUrl(presetationOrLinkId, imageSize, playbackStageIndex, true, slideMetadata?.modifiedAt)
            .then(url => this.setState({ url }))
            .catch(err => {
                logger.error(err, "Thumbnail slide.getThumbnailUrl() failed", { slideId: slide?.id, presentationId: presentation?.id });
            });
    }

    render() {
        const {
            hilited = false,
            onClick = () => { },
            clickable = false,
            outlineWidth = 2,
            width = "100%",
            slideIndex,
            hideSlideIndex = false
        } = this.props;
        const { url } = this.state;

        return (
            <ThumbnailContainer hilited={hilited} onClick={onClick} clickable={clickable} outlineWidth={outlineWidth} width={width}>
                {!url && <CircularProgress size={35} thickness={5} style={{ color: "#23aae0" }} />}
                {url && (
                    <>
                        <img src={url}></img>
                        {!hideSlideIndex && (slideIndex || slideIndex === 0) && <Circle>{slideIndex + 1}</Circle>}
                    </>
                )}
            </ThumbnailContainer >
        );
    }
}

export default Thumbnail;
