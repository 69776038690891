import React, { useEffect, useState, useRef } from "react";
import { Route, Router, Switch } from "react-router-dom";

import { browserHistory } from "js/react/history";
import { FirebaseAuthProvider } from "js/react/views/Auth/FirebaseAuthContext";
import { FirebaseAuthInitalizer } from "js/react/views/Auth/FirebaseAuthInitalizer";
import { serverContext } from "js/config";
import { Authenticate } from "js/react/views/Auth/Authenticate";

import { ConsumeCustomToken } from "./ConsumeCustomToken";
import Invite from "./Invite";
import { Logout } from "./Logout";
import { Stub } from "./Stub";
import { ZoomAppContainer, ZoomAppRedirect } from "./ZoomApp";

// We're importing an empty css file to ensure that webpack
// generates the app.css file even when we do chunking in production
// mode so the <style> tag in the root html always references an
// existing file
import "css/entrypoint.scss";

let preloadPromise: Promise<React.ComponentType<any>> | null = null;
async function preload() {
    if (!preloadPromise) {
        preloadPromise = (async () => {
            // @ts-ignore
            await import(/* webpackChunkName: "js-preload" */ "js/preload");

            // @ts-ignore
            const { IntegratorController } = await import(/* webpackChunkName: "common-integrators" */ "common/integrators/IntegratorController");
            await IntegratorController.initApp();

            // @ts-ignore
            const { default: Adapter } = await import(/* webpackChunkName: "js-core-storage-adapter" */ "js/core/storage/adapter");
            // @ts-ignore
            const { default: FirebaseAdapter } = await import(/* webpackChunkName: "js-core-storage-firebaseAdapter" */ "js/core/storage/firebaseAdapter");
            Adapter.setDefaultClass(FirebaseAdapter);

            // @ts-ignore
            const { default: App } = await import(/* webpackChunkName: "js-app" */ "js/app/App");
            return App;
        })();
    }

    return preloadPromise;
}

export function Entrypoint() {
    const [ready, setReady] = useState(false);
    const appRef = useRef<React.ComponentType<any> | null>(null);

    useEffect(() => {
        preload()
            .then(App => {
                appRef.current = App;
                setReady(true);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    if (!ready) {
        return null;
    }

    return (
        <Router history={browserHistory}>
            <Switch>
                {/* lightweight pages that skip app bundle switcher */}
                <Route path={"/logout"}>
                    <Logout />
                </Route>
                <Route path={["/api/sso/saml/consume", "/testing/fast-login", "/provisioning/signIn"]}>
                    <ConsumeCustomToken />
                </Route>
                <Route path={"/invite/:token"}>
                    <FirebaseAuthProvider>
                        <Authenticate
                            defaultPage={serverContext.form == "login" ? "signIn" : "createAccount"}
                            readonlyEmail={serverContext.email}
                            presentationPermission={serverContext.presentationPermission}
                            presentationName={serverContext.presentationName}
                            presentationSharedBy={serverContext.presentationSharedBy}
                            presentationThumbnailUrl={serverContext.presentationThumbnailUrl}
                            reason="presentation-invite"
                        >
                            <Invite />
                        </Authenticate>
                    </FirebaseAuthProvider>
                </Route>
                <Route path={"/teaminvite/:token"}>
                    <FirebaseAuthProvider>
                        <Authenticate
                            defaultPage={serverContext.form == "login" ? "signIn" : "createAccount"}
                            readonlyEmail={serverContext.email}
                            teamName={serverContext.teamName}
                            reason="team-invite"
                        >
                            <Invite />
                        </Authenticate>
                    </FirebaseAuthProvider>
                </Route>
                <Route path={"/zoom/login"}>
                    <FirebaseAuthProvider>
                        <Authenticate
                            defaultPage={"signIn"}
                            reason="zoom-app"
                        >
                            <ZoomAppRedirect />
                        </Authenticate>
                    </FirebaseAuthProvider>
                </Route>
                <Route path={"/zoom/app"}>
                    <FirebaseAuthProvider>
                        <FirebaseAuthInitalizer>
                            <ZoomAppContainer />
                        </FirebaseAuthInitalizer>
                    </FirebaseAuthProvider>
                </Route>
                {/* stub for cypress */}
                <Route path="/testing/stub">
                    <Stub />
                </Route>
                {/* rest of the app that requires app bundle switcher */}
                <Route path="*">
                    <appRef.current />
                </Route>
            </Switch>
        </Router>
    );
}
