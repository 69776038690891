import { css } from "styled-components";
import { themeColors } from "./react/sharedStyles";

export const largeTitle = css`
    color: ${themeColors.darkGray};
    font-family: "Source Sans Pro";
    font-size: 26px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.35px;
    text-transform: none;
    text-rendering: optimizeLegibility;
`;

export const defaultTitle = css`
    color: ${themeColors.darkGray};
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 1.05px;
    text-transform: none;
    text-rendering: optimizeLegibility;
`;

export const uppercaseTitle = css`
    color: ${themeColors.darkGray};
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.15px;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
`;

export const smallTitle = css`
    color: ${themeColors.darkGray};
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
`;

export const defaultText = css`
    color: ${themeColors.darkGray};
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-transform: none;
    text-rendering: optimizeLegibility;
`;

export const smallText = css`
    color: ${themeColors.darkGray};
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-transform: none;
    text-rendering: optimizeLegibility;
`;
