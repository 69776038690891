import React, { Fragment, Component } from "react";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";

import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import { _ } from "js/vendor";

export default class ExportToPPTWarningDialog extends Component {
    renderFailedSlideIndexes = () => {
        const { failedSlideIndexes } = this.props;

        if (failedSlideIndexes.length === 0) return null;

        return (
            <Fragment>
                <h2>The following slides were not exported due to unexpected errors</h2>
                <ul>
                    <li>{"Slide".pluralize(failedSlideIndexes.length > 1)} {failedSlideIndexes.map(i => i + 1).join(",")}</li>
                </ul>
            </Fragment>
        );
    }

    renderExportWarnings = () => {
        const { exportWarnings } = this.props;

        if (exportWarnings.length === 0) return null;

        return (
            <Fragment>
                <h2>The following issues occurred during your export:</h2>
                <ul>
                    {exportWarnings.map(warning => <li key={warning}>{warning}</li>)}
                </ul>
            </Fragment>
        );
    }
    render() {
        const { closeDialog } = this.props;

        return (
            <BeautifulDialog
                classes={{ paper: "error-dialog" }}
                maxWidth="md"
                closeDialog={closeDialog}
            >
                <DialogTitle>There were some issues with your export</DialogTitle>
                <DialogContent>
                    {this.renderFailedSlideIndexes()}
                    {this.renderExportWarnings()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Ok</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
