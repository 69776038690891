import type { EndpointDefinition } from "apis/_sdk/types/common";

export const apiName = "player";

export const endpoints: Record<string, EndpointDefinition> = {
    getPlayerMetadata: {
        route: "/player/metadata/:id",
        method: "GET",
    },
    getPlayerContext: {
        route: "/player/context/:id",
        method: "GET",
    },
    checkPlayerPassword: {
        route: "/player/password",
        method: "PUT",
    },
    reportPlayerSalesforceEvent: {
        route: "/player/salesforce-progress",
        method: "PUT",
    },
    queryPlayerAnalytics: {
        route: "/player/analytics-query",
        method: "PUT",
    },
    trackPlayerAnalytics: {
        route: "/player/analytics-track",
        method: "PUT",
    },
    getOfflinePlayerDownloadUrl: {
        route: "/player/offline-data",
        method: "GET",
    },
};
