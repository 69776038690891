import { AssetType } from "common/constants";
import { ActionPermissionsObject } from "common/interfaces";
import WorkspaceController from "js/controllers/WorkspaceController";
import { UIActionType, isUIActionRestricted } from "js/core/uiActionsRestrictors";

export enum FileSource {
    Local = "local",
    Google = "google",
    OneDrive = "onedrive",
    Dropbox = "dropbox",
    Box = "box",
    Bynder = "bynder",
    Vimeo = "vimeo",
    Youtube = "youtube"
}

export enum FileType {
    Image = "image",
    Video = "video",
    Spreadsheet = "spreadsheet",
}

export const allowedExtentionsPerFileType = {
    [FileType.Image]: ["jpg", "jpeg", "png", "gif", "svg", "svg+xml"],
    [FileType.Video]: ["mp4", "webm", "mov", "avi", "wmv", "flv", "quicktime"],
    [FileType.Spreadsheet]: ["xls", "xlsx", "csv"]
};

export const maxFileSizePerFileType = {
    [FileType.Image]: 1024 * 1024 * 50, // 50mb
    [FileType.Video]: 1024 * 1024 * 500 // 500mb
};

export function getAllowedFileSources(fileType: FileType): FileSource[] {
    const canUseIntegrations = WorkspaceController.actionPermissions[ActionPermissionsObject.INTEGRATIONS].use;

    const fileSources: FileSource[] = [];
    const fileTypeIsMedia = [FileType.Image, FileType.Video].includes(fileType);
    const fileTypeIsVideo = FileType.Video === fileType;

    if (canUseIntegrations) {
        fileSources.push(FileSource.Google, FileSource.OneDrive, FileSource.Dropbox, FileSource.Box);
        if (fileTypeIsMedia) {
            fileSources.push(FileSource.Bynder);
        }
    }

    if (!isUIActionRestricted(UIActionType.ADD_ASSET, { assetType: AssetType.VIDEO, source: "hosted" }) && fileTypeIsVideo) {
        fileSources.push(FileSource.Vimeo);
        fileSources.push(FileSource.Youtube);
    }

    return fileSources;
}

export function fileTypesToExtensions(fileTypes: FileType[]) {
    const extensions = [];
    for (const fileType of fileTypes) {
        extensions.push(...allowedExtentionsPerFileType[fileType]);
    }
    return extensions;
}

export function validateFileTypeAndSize(file: File, allowedFileTypes?: FileType[]) {
    const mimeTypes = file.type.split("/");
    const fileType = mimeTypes[0] as FileType;
    const fileExtension = mimeTypes[1];

    if (allowedFileTypes && !allowedFileTypes.includes(fileType)) {
        return `Uploaded file should be of type ${allowedFileTypes.join(", ")}`;
    }

    switch (fileType) {
        case FileType.Image:
        case FileType.Video:
            if (!allowedExtentionsPerFileType[fileType].includes(fileExtension)) {
                return `Uploaded ${fileType} extension ${fileExtension} is not supported`;
            }
            if (maxFileSizePerFileType[fileType] < file.size) {
                return `Uploaded ${fileType} should have max size ${fileType === FileType.Image ? "50mb" : "500mb"}`;
            }
            break;
        case FileType.Spreadsheet:
        default:
            break;
    }
}
