export enum TeamMemberRole {
    OWNER = "owner",
    MEMBER = "member",
    MEMBER_LICENSED = "member-licensed",
    LIBRARIAN = "librarian"
}

export const TeamMemberRoleLabels = {
    [TeamMemberRole.MEMBER]: "Member",
    [TeamMemberRole.MEMBER_LICENSED]: "Team Member",
    [TeamMemberRole.LIBRARIAN]: "Librarian",
    [TeamMemberRole.OWNER]: "Owner"
};

export enum TeamMemberLicense {
    TEAM_PRO = "team-pro",
    FREE = "free"
}

export enum BrandingPermission {
    ALL = "all",
    OWNER_AND_LIB = "ownerAndLib",
    NONE = "none",
}

export interface IWorkspaceSettings {
    playerSettingsDefaults?: {
        showTitle?: boolean,
        showAuthor?: boolean,
        requireEmail?: boolean,
        showSocialSharing?: boolean,
        showGetDeckUI?: boolean,
        allowPdfDownload?: boolean,
    },
    brandingPermissions?: {
        inspirationSlides?: BrandingPermission,
        customThemes?: BrandingPermission,
        stockLibraries?: BrandingPermission,
        webImages?: BrandingPermission,
        classicSlides?: BrandingPermission,
        convertToClassic?: BrandingPermission,
        importPPT?: BrandingPermission,
        exportPresentation?: BrandingPermission,
    },
    allTeamMembersFolderSettings?: {
        restrictAccess?: boolean,
        hideFolder?: boolean,
    },
    prohibitExternalWorkspaceCollaboration?: boolean,
    prohibitExternalWorkspaceMovement?: boolean,
    prohibitOpenAIIntegration?: boolean,
    prohibitGenerativeAI?: boolean,
}

export interface IPlayerBranding {
    active?: boolean;
    backgroundColor?: string;
    logoId?: string;
    logoScale?: number;
    useLoadingAnimation?: boolean;
    migratedToThemes?: boolean;
}

export interface ITeamFirebase {
    id: string;
    _changeId: string;
    name: string;
    isDefault: boolean;
    orgId: string;
    onlyOwnerCanEdit: boolean;
    customPlayerURLTemplate: string | null;
    members: {
        [userId: string]: {
            hasSeat: boolean;
            role: TeamMemberRole;
        }
    };
    sharedThemeId: string;
    sharedResources: {
        [key: string]: { // PermissionResourceType values
            [resourceId: string]: any;
        }
    };
    workspaceSettings?: IWorkspaceSettings;
    playerBranding?: IPlayerBranding;
    subFolders?: {
        [index: string]: {
            id: string;
            name: string;
            presentations?: { // {  "0": "presentationId" }
                [key: string]: string;
            };
        };
    };
    createdAt: number;
    modifiedAt: number;
}
