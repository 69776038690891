import { ActionPermissionsObject } from "common/interfaces";
import { isOwnerOrLibrarian } from "common/utils/roles";
import WorkspaceController from "js/controllers/WorkspaceController";
import AppController from "js/core/AppController";
import { ds } from "js/core/models/dataService";
import { getPresentation } from "js/core/models/presentation";
import getUserProfile from "js/core/services/userProfiles";
import isConnected from "js/core/utilities/isConnected";
import { CreatePresentationDialog } from "js/editor/CreatePresentation/CreatePresentationDialog";
import { ShowDialog, ShowOfflineDialog } from "js/react/components/Dialogs/BaseDialog";
import FeedbackDialog from "js/react/components/Dialogs/FeedbackDialog";
import permissionsDS from "js/react/views/PresentationSettings/dataservices/PermissionsDataService";
import { _ } from "js/vendor";

export class UIController {
    static gotoMarketingPage() {
        window.location.href = "/?";
    }

    static getWorkspaceId() {
        return AppController.workspaceId;
    }

    static getOrganizationId() {
        return AppController.orgId;
    }

    static isTeamWorkspace() {
        return UIController.getOrganizationId() !== null;
    }

    // return all team members in the current org
    static async getAllTeamMembers() {
        const defaultTeam = ds.teams.defaultTeamForOrg(AppController.orgId);
        const allMemberIds = Object.keys(defaultTeam.get("members"));
        const userProfiles = await Promise.all(allMemberIds.map(memberId => getUserProfile(memberId)));
        const allUsers = userProfiles.map(user => ({ ...user, isChecked: false }));
        return allUsers;
    }

    static async getAllInviteSuggestions(presentationId = null, includeFolders = false) {
        if (!ds.hasBeenSetup) {
            await ds.setup();
            await ds.teams.loadModels();
        }
        let allTeams = ds.teams.models;
        let availableTeams = allTeams
            .filter(team => team.get("orgId") === AppController.orgId);
        let allFolders = await Promise.all(availableTeams.map(async team => {
            let members = Object.keys(team.get("members"));
            let users = await Promise.all(members.map(memberId => getUserProfile(memberId)));
            // Flag these users as being in the team
            users = users.map(user => {
                return {
                    isInTeam: true,
                    ...user,
                };
            });
            let isDefault = team.get("isDefault");
            let folder = {
                type: "folder",
                id: team.get("id"),
                isDefault,
                name: isDefault ? "All Team Members" : team.get("name"),
                photoUrl: team.get("photoUrl"),
                users,
            };
            return folder;
        }));
        let teamUsers = _.flatten(allFolders.map(folder => folder.users));
        teamUsers = _.unionBy(_.flatten(teamUsers), "id");
        let collaborators = [];
        if (presentationId) {
            const teamIds = allTeams.map(x => x.id);
            let permissions = await permissionsDS.getPermissions(presentationId, teamIds, true);

            // take the id from this view's representation of a presentation and fetch backbone model with updated permissions
            const presentation = await getPresentation(presentationId, { permission: "read", autoSync: false });
            const isTemplate = presentation.get("isTemplate");
            presentation.disconnect();
            if (isTemplate) {
                const defaultTeam = ds.teams.defaultTeamForOrg(this.getWorkspaceId());
                const memberRoles = defaultTeam.get("members");

                permissions = permissions.filter(perm => {
                    // Only allow collaboration with librarians/owners when dealing with a template
                    const role = memberRoles[perm.uid]?.role;
                    return (
                        !isTemplate ||
                        isOwnerOrLibrarian(role)
                    );
                });

                teamUsers = teamUsers.filter(perm => {
                    // Only allow collaboration with librarians/owners when dealing with a template
                    const role = memberRoles[perm.uid]?.role;
                    return (
                        !isTemplate ||
                        isOwnerOrLibrarian(role)
                    );
                });
            }

            collaborators = permissions
                .filter(perm => perm.level !== "team")
                .map(perm => ({
                    id: perm.id,
                    uid: perm.id,
                    email: perm.email,
                    displayName: perm.displayName,
                    permissionType: perm.type,
                    photoURL: perm.photoURL,
                    isPending: perm.pending,
                    isCollaborator: true,
                }));

            if (includeFolders) {
                allFolders = allFolders.map(folder => {
                    let perm = permissions.find(perm => (
                        perm.level === "team" &&
                        perm.id === folder.id
                    ));
                    return {
                        permissionType: perm?.type,
                        ...folder,
                    };
                });
            }
        }

        let emails = _.union([
            ...collaborators.map(x => x.email),
            ...teamUsers.map(x => x.email),
        ]);

        let allUsers = emails.map(email => {
            let cUser = collaborators.find(x => x.email === email) || {};
            let tUser = teamUsers.find(x => x.email === email) || {};

            return {
                type: "user",
                ...cUser,
                ...tUser,
            };
        });

        let allInvitees = [];
        allInvitees.push(...allUsers);
        if (includeFolders) {
            allInvitees.push(...allFolders);
        }

        return allInvitees;
    }

    static isSharedThemeRequiredForWorkspace() {
        const workspaceId = this.getWorkspaceId();
        if (workspaceId === "personal") {
            return false;
        }

        const defaultTeam = ds.teams.defaultTeamForOrg(workspaceId);
        return defaultTeam.get("isSharedThemeRequired");
    }

    static async showCreatePresentationDialog(libraryFilter, documentType) {
        // Offline currently not supported as it just errors out when you try to create a presentation.
        // To be nice to our users, we'll check twice instead of showing the error after they select a theme.
        if (!isConnected.connected) {
            ShowOfflineDialog();
            return;
        }

        ShowDialog(CreatePresentationDialog, {
            libraryFilter,
            documentType,
            requireSharedTheme: WorkspaceController.workspaceId !== "personal" && !WorkspaceController.actionPermissions[ActionPermissionsObject.CUSTOM_THEMES].use
        });
    }

    static showFeedbackDialog() {
        ShowDialog(FeedbackDialog);
    }
}
