import React from "react";
import styled from "styled-components";

import { getStaticUrl } from "js/config";
import { Dropdown } from "js/Components/Dropdown";
import { MenuItem } from "js/Components/Menu";
import { Divider } from "js/Components/Divider";
import { Icon } from "js/Components/Icon";

export const ListHeaderLine = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: #444;
    text-transform: uppercase;
    margin-bottom: 10px;
    word-break: break-all;
`;

const ListHeaderSortableContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    width: fit-content;
`;

export function ListHeaderSortable(props: {
    sort: "asc" | "desc",
    active: boolean,
    onClick: () => void, children?: React.ReactNode
}) {
    const { sort, active, onClick, children } = props;

    return (<ListHeaderSortableContainer onClick={onClick}>
        {children}
        {active && (
            <Icon small>
                {sort === "asc" ? "arrow_upward" : "arrow_downward"}
            </Icon>
        )}
    </ListHeaderSortableContainer>);
}

export const ListLine = styled.div`
    font-size: 16px;
    min-height: 60px;
    background: #fff;
    position: relative;
    word-break: break-all;
`;

export const ListLineCellInfoText = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    color: #555;
`;

export const List = styled.div<{ gridTemplateColumns: string }>`
    display: flex;
    flex-direction: column;
    gap: 15px;

    ${ListHeaderLine}, ${ListLine} {
        padding: 0px 20px;
        display: grid;
        grid-column-gap: 20px;
        align-items: center;
        grid-template-columns: ${props => props.gridTemplateColumns};
    }
`;

const NoResultsListLineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;

    img {
        height: 40px;
    }
`;

export function NoResultsListLine() {
    return (
        <NoResultsListLineContainer>
            <img src={getStaticUrl("/images/bai-bot/bai-bot-sad.png")} />
            <ListLineCellInfoText>No results found</ListLineCellInfoText>
        </NoResultsListLineContainer>
    );
}

const ListLineDropdownCellContainer = styled.div`
    margin-left: -15px;

    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu {
        text-transform: uppercase;
        color: #333;
        font-weight: 600;
        font-size: 14px;
    }
`;

export function ListLineDropdownCell(props: {
    value: string,
    items: ({
        label: string,
        value: string,
        disabled?: boolean
    } | { divider: true })[],
    onChange: (value: string) => void,
    disabled?: boolean,
    "data-test-id"?: string
}) {
    const { value, items, onChange, disabled, "data-test-id": dataTestId } = props;

    return (<ListLineDropdownCellContainer data-test-id={dataTestId}>
        <Dropdown
            variant="standard"
            value={value}
            onChange={onChange}
            disableUnderline
            disabled={disabled}
        >
            {items.map(item => "divider" in item ? <Divider /> : (
                <MenuItem
                    key={item.value}
                    value={item.value}
                    disabled={item.disabled}
                >
                    {item.label}
                </MenuItem>
            ))}
        </Dropdown>
    </ListLineDropdownCellContainer>);
}
