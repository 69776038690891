import type { EndpointDefinition } from "../../_sdk/types/common";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getWorkspacePermissions: {
        route: "/workspaces/:workspaceId/permissions",
        method: "GET"
    },
    setWorkspacePermissions: {
        route: "/workspaces/:workspaceId/permissions",
        method: "PUT"
    },
    getUserPermissionsToResource: {
        route: "/workspaces/:workspaceId/permissions/:resourceType/:resourceId",
        method: "GET"
    },
    getUserActionPermissions: {
        route: "/workspaces/:workspaceId/action-permissions",
        method: "GET"
    }
};
