import { getCallable } from "../_sdk/client";
import {
    CreateScreenshotRequest, CreateScreenshotResponse,
    GetReferenceScreenshotsRequest, GetReferenceScreenshotsResponse,
    GetWorkspaceDebugDataRequest, GetWorkspaceDebugDataResponse
} from "./types";
import { endpoints, apiName } from "./endpoints";

const getReferenceScreenshots = getCallable<GetReferenceScreenshotsRequest, GetReferenceScreenshotsResponse>(apiName, "getScreenshots", {
    ...endpoints.getReferenceScreenShots,
    composer: request => {
        const { id } = request;
        return {
            url: `/debug/screenshots/${encodeURIComponent(id)}`,
            options: {
                method: "GET"
            }
        };
    }
});

const createScreenshot = getCallable<CreateScreenshotRequest, CreateScreenshotResponse>(apiName, "createScreenshot", {
    ...endpoints.createScreenshot,
    composer: request => {
        return {
            url: `/debug/screenshot/`,
            options: {
                method: "POST",
                body: JSON.stringify(request)
            }
        };
    }
});

const getWorkspaceDebugData = getCallable<GetWorkspaceDebugDataRequest, GetWorkspaceDebugDataResponse>(apiName, "getWorkspaceDebugData", {
    ...endpoints.getWorkspaceDebugData,
    composer: request => {
        const { workspaceId } = request;
        return {
            url: `/debug/workspace/${encodeURIComponent(workspaceId)}`,
            options: {
                method: "GET"
            }
        };
    }
});

export const callables = {
    getReferenceScreenshots,
    createScreenshot,
    getWorkspaceDebugData
};
