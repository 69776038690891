import React, { Component } from "react";
import styled from "styled-components";

import { Mic as MicIcon } from "@material-ui/icons";
import { Select, MenuItem, TextField, Icon } from "@material-ui/core";

import { AiGeneratedSlideGroupType, AiProvider } from "common/aiConstants";
import { delay } from "js/core/utilities/promiseHelper";
import { formatter } from "js/core/utilities/formatter";
import { themeColors } from "js/react/sharedStyles";
import { FlexBox } from "js/react/components/LayoutGrid";
import { FlexSpacer, Gap10 } from "js/react/components/Gap";
import { BlueButton } from "js/react/components/UiComponents";

const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    label {
        font-size: 20px;
        font-weight: 600;
`;

const InputContainer = styled.div`
    width: 100%;
    height: 50px;
    padding: 0 10px;

    display: flex;
    flex-flow: row;
    align-items: center;

    border: 1px solid ${themeColors.lightGray};
    background: white;

    input.MuiInputBase-input::-webkit-input-placeholder {
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: ${themeColors.darkGray};
    }

    input.MuiInputBase-input:disabled {
        color: ${themeColors.darkGray};
        user-select: none;
    }

    > button.MuiToggleButton-root {
        border: none;
    }

    > div.MuiFormControl-root {
        width: 100%;
    }

    > div.MuiInputBase-root {
        > div.MuiSelect-root {
            text-transform: none;
        }
    }
`;

const ExamplesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SpeechToTextButton = styled(MicIcon) <{ selected: boolean }>`
    cursor: pointer;
    color: ${themeColors.ui_blue};
    animation: ${props => props.selected ? "element-pulse-opacity 1.5s infinite" : "none"};
    padding: 5px;
    border-radius: 50%;

    :active {
        background-color: #f9f9f9;
    }
`;

interface PromptInputProps {
    label: string;
    prompt: string;
    onPromptChange: (prompt: string) => void;
    promptExamples?: string[];
    promptPlaceholder?: string;
    slideGroupType?: AiGeneratedSlideGroupType;
    onSlideGroupTypeChange?: (slideGroupType: AiGeneratedSlideGroupType) => void;
    onEnterKeyPress?: (event: React.KeyboardEvent) => void;
    actionButtonText: string;
    onActionButtonClick?: () => void;
    allowedSlideGroupTypes?: AiGeneratedSlideGroupType[];
}

interface PromptInputState {
    prompt: string;
    isUsingSpeechToText: boolean;
    isTypingPrompt: boolean;
}

class PromptInput extends Component<PromptInputProps, PromptInputState> {
    speechToText?: any;

    constructor(props) {
        super(props);

        this.state = {
            prompt: "",
            isUsingSpeechToText: false,
            isTypingPrompt: false,
        };
    }

    componentWillUnmount() {
        if (this.speechToText) {
            this.speechToText.stop();
        }
    }

    typePrompt = async (prompt: string) => {
        const { onPromptChange } = this.props;

        this.setState({ isTypingPrompt: true, prompt: "" });

        for (const char of prompt) {
            this.setState(({ prompt, ...state }) => ({ prompt: `${prompt}${char}`, ...state }));
            await delay(6);
        }

        onPromptChange(prompt);

        this.setState({ isTypingPrompt: false });
    }

    handleToggleSpeechToText = () => {
        const { isUsingSpeechToText } = this.state;

        if (isUsingSpeechToText) {
            this.setState({ isUsingSpeechToText: false });
            if (this.speechToText) {
                this.speechToText.stop();
                this.speechToText = undefined;
            }
            return;
        }

        // @ts-ignore
        this.speechToText = new (window.SpeechRecognition ?? window.webkitSpeechRecognition)();
        this.speechToText.interimResults = true;
        this.speechToText.continuous = true;

        this.speechToText.addEventListener("result", event => {
            const transcript = Array.from(event.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join("");

            if (transcript) {
                this.typePrompt(transcript);
            }
        });

        this.setState({ isUsingSpeechToText: true });

        this.speechToText.start();
    }

    handleChangePrompt = event => {
        const { onPromptChange } = this.props;
        onPromptChange(event.target.value);
    }

    handleKeyUpOnInput = event => {
        const { onEnterKeyPress } = this.props;
        if (onEnterKeyPress && event.which == 13) {
            onEnterKeyPress(event);
        }
    }

    handleChangeSlideTypeGroup = event => {
        const { onSlideGroupTypeChange } = this.props;
        onSlideGroupTypeChange && onSlideGroupTypeChange(event.target.value);
    }

    handleApplyExample = event => {
        this.typePrompt(event.target.value);
    }

    render() {
        const {
            label,
            prompt,
            promptPlaceholder = "Enter your prompt here",
            promptExamples = [],
            slideGroupType,
            actionButtonText,
            onActionButtonClick,
            allowedSlideGroupTypes = []
        } = this.props;
        const {
            prompt: statePrompt,
            isUsingSpeechToText,
            isTypingPrompt
        } = this.state;

        return (
            <Container>
                <FlexBox left middle fillWidth>
                    <label>{label}</label>
                </FlexBox>
                <Gap10 />
                <InputContainer>
                    <SpeechToTextButton
                        selected={isUsingSpeechToText}
                        onClick={this.handleToggleSpeechToText}
                    />
                    <TextField
                        value={isTypingPrompt ? statePrompt : prompt}
                        onChange={this.handleChangePrompt}
                        onKeyUp={this.handleKeyUpOnInput}
                        placeholder={promptPlaceholder}
                        InputProps={{ disableUnderline: true }}
                        disabled={isUsingSpeechToText || isTypingPrompt}
                    />
                    {allowedSlideGroupTypes.length > 0 && slideGroupType &&
                        <Select
                            value={slideGroupType}
                            onChange={this.handleChangeSlideTypeGroup}
                            disableUnderline
                        >
                            {allowedSlideGroupTypes.map((type, idx) => (
                                <MenuItem key={idx} value={type}>
                                    {type === AiGeneratedSlideGroupType.ALL_BUT_TITLE && "Auto"}
                                    {type !== AiGeneratedSlideGroupType.ALL_BUT_TITLE && formatter.capitalizeFirstLetter(type)}
                                </MenuItem>
                            ))}
                        </Select>
                    }
                </InputContainer>
                <Gap10 />
                <FlexBox fillWidth>
                    {promptExamples.length > 0 && <ExamplesContainer>
                        <Select
                            value={""}
                            displayEmpty
                            onChange={this.handleApplyExample}
                            disableUnderline
                            MenuProps={{
                                style: { zIndex: 10001 }
                            }}
                        >
                            <MenuItem value="" disabled>
                                {/*<BlueIcon>lightbulb</BlueIcon>*/}
                                Example Prompts
                            </MenuItem>
                            {promptExamples.map((prompt, idx) => (
                                <MenuItem key={idx} value={prompt}>
                                    {prompt}
                                </MenuItem>
                            ))}
                        </Select>
                    </ExamplesContainer>}
                    {/* @ts-ignore */}
                    <FlexSpacer />
                    {
                        !!onActionButtonClick &&
                        /* @ts-ignore */
                        <BlueButton disabled={isTypingPrompt} onClick={onActionButtonClick}>{actionButtonText}</BlueButton>
                    }
                </FlexBox>
            </Container>);
    }
}

export default PromptInput;
