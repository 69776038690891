import { getCallable } from "../../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import type {
    GetWorkspaceUsersRequest,
    GetWorkspaceUsersResponse,
    UpdateWorkspaceUserRequest,
    UpdateWorkspaceUserResponse,
    DeleteWorkspaceUserRequest,
    DeleteWorkspaceUserResponse
} from "./types";

const getWorkspaceUsers = getCallable<GetWorkspaceUsersRequest, GetWorkspaceUsersResponse>(
    apiName,
    "getWorkspaceUsers",
    {
        ...endpoints.getWorkspaceUsers,
        composer: request => {
            const searchParams = new URLSearchParams();
            if (request.uids) {
                searchParams.set("uids", JSON.stringify(request.uids));
            }

            return {
                url: `/workspaces/${request.workspaceId}/users?${searchParams.toString()}`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const updateWorkspaceUser = getCallable<UpdateWorkspaceUserRequest, UpdateWorkspaceUserResponse>(
    apiName,
    "updateWorkspaceUser",
    {
        ...endpoints.updateWorkspaceUser,
        composer: request => ({
            url: `/workspaces/${request.workspaceId}/users/${request.uid}`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        }),
    }
);

const deleteWorkspaceUser = getCallable<DeleteWorkspaceUserRequest, DeleteWorkspaceUserResponse>(
    apiName,
    "deleteWorkspaceUser",
    {
        ...endpoints.deleteWorkspaceUser,
        composer: request => ({
            url: `/workspaces/${request.workspaceId}/users/${request.uid}`,
            options: {
                method: "DELETE",
                body: JSON.stringify(request)
            }
        })
    }
);

export const callables = {
    getWorkspaceUsers,
    updateWorkspaceUser,
    deleteWorkspaceUser
};
