import _ from "lodash";

import { IGenericModelFields } from "../IGenericFields";

export enum WorkspaceUserRole {
    OWNER = "owner",
    MEMBER = "member",
    GUEST = "guest"
}

export enum WorkspaceIntegrationType {
    SALESFORCE = "salesforce",
}

export interface IWorkspacePlayerSettings {
    showTitle: boolean,
    showAuthor: boolean,
    requireEmail: boolean,
    showSocialSharing: boolean,
    showGetDeckUI: boolean,
    allowPdfDownload: boolean,
    customPlayerURLTemplate: string | null,
}

export interface IWorkspacePlayerBranding {
    active: boolean;
    backgroundColor: string;
    logoId: string | null;
    logoScale: number;
    useLoadingAnimation: boolean;
}

export interface IWorkspaceSSOSettings {
    enabled: boolean;
    strict: boolean;
    allowIdpInitiated: boolean;
    samlConfig?: {
        audience?: string;
        callbackUrl?: string;
        issuer?: string;
        certs: string[];
        entryPoint: string;
        idpIssuer: string;
    };
    userProvisioning: {
        allowJit: boolean;
        allowScim: boolean;
        scimToken?: string;
    };
}

export interface IWorkspaceStripe {
    customerId?: string;
    subscriptionId?: string;
}

export interface IWorkspaceIntegrationConfig {
    enabled: boolean;
}

export interface IWorkspaceSalesforceIntegrationConfig extends IWorkspaceIntegrationConfig {
    domainName: string;
    prn: string;
}

export interface IWorkspace extends IGenericModelFields {
    name: string;
    stripe: IWorkspaceStripe;
    sso: IWorkspaceSSOSettings;
    player: {
        settings: IWorkspacePlayerSettings;
        branding: IWorkspacePlayerBranding;
    },
    integrations: {
        [WorkspaceIntegrationType.SALESFORCE]?: IWorkspaceSalesforceIntegrationConfig;
    };
    domain: {
        domain: string;
        verified: boolean;
        verificationKey: string;
        verificationError: string;
        verifiedAt: number;
    };
}

export type ISanitizedWorkspace = Omit<IWorkspace, "stripe" | "sso">;

export interface IWorkspaceDocumentChangedEvent<T = any> {
    timestamp: number;
    documentType: "Workspace" | "WorkspaceUser" | "WorkspaceUserGroup" | "WorkspacePermission" | "WorkspaceResource" | "WorkspaceResourceFolder" | "WorkspacePlan" | "WorkspaceUserInvite";
    documentId: string;
    operationType: "create" | "update" | "delete";
    newDocument: T;
}

export interface IWorkspaceMetadata {
    id: string;
    name: string;
}

////////////////////////////// DEFAULTS //////////////////////////////

// Currently defined in features.js
const DEFAULT_WORKSPACE_PLAYER_SETTINGS: IWorkspacePlayerSettings = {
    showTitle: true,
    showAuthor: true,
    requireEmail: false,
    showSocialSharing: true,
    showGetDeckUI: false,
    allowPdfDownload: false,
    customPlayerURLTemplate: null,
};

// Currently defined in features.js
const DEFAULT_WORKSPACE_PLAYER_BRANDING: IWorkspacePlayerBranding = {
    active: false,
    backgroundColor: "#000000",
    logoId: null,
    logoScale: 1,
    useLoadingAnimation: true,
};

const DEFAULT_WORKSPACE_SSO_SETTINGS: IWorkspaceSSOSettings = {
    enabled: false,
    strict: false,
    allowIdpInitiated: true,
    userProvisioning: {
        allowJit: false,
        allowScim: false,
    },
};

export const GET_DEFAULT_WORKSPACE = () => _.cloneDeep<Pick<IWorkspace, "player" | "sso">>({
    player: {
        settings: DEFAULT_WORKSPACE_PLAYER_SETTINGS,
        branding: DEFAULT_WORKSPACE_PLAYER_BRANDING,
    },
    sso: DEFAULT_WORKSPACE_SSO_SETTINGS,
});
