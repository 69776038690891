import type { EndpointDefinition } from "../../_sdk/types/common";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getWorkspaceResourceFolders: {
        route: "/workspaces/:workspaceId/resource-folders",
        method: "GET"
    },
    updateWorkspaceResourceFolder: {
        route: "/workspaces/:workspaceId/resource-folders/:folderId",
        method: "PUT"
    },
    deleteWorkspaceResourceFolder: {
        route: "/workspaces/:workspaceId/resource-folders/:folderId",
        method: "DELETE"
    },
    createWorkspaceResourceFolder: {
        route: "/workspaces/:workspaceId/resource-folders",
        method: "POST"
    }
};
