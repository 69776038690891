import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Radio
} from "@material-ui/core";
import classNames from "classnames";
import React, { Component } from "react";
import styled from "styled-components";

import { player as playerApi, presentations as presentationsApi } from "apis/callables";
import { PdfCompressionTypes } from "common/constants";
import { ActionPermissionsObject } from "common/interfaces";
import WorkspaceController from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { fontManager } from "js/core/services/fonts";
import { download, trackActivity } from "js/core/utilities/utilities";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { exportToGoogleSlides } from "js/exporter/exportToGoogleSlides";
import { ExportToPPT } from "js/exporter/exportToPPT";
import CheckBox from "js/react/components/CheckBox";
import { BeautifulDialog, ShowDialog, ShowMessageDialog, ShowUpgradeDialog } from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { FlexBox } from "js/react/components/LayoutGrid";
import ProBadge from "js/react/components/ProBadge";
import { WarningWatermark } from "js/react/components/WarningWatermark";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";

const logger = getLogger(LogGroup.EXPORT);

const DialogTitleShort = styled(DialogTitle)`
    && {
        padding-bottom: 20px;
    }
`;

const DialogContentNoPad = styled(DialogContent)`
    && {
        padding: 0;
    }
`;

const InfoText = styled.span`
    font-size: 12px;
    font-weight: normal;
    color: #676767;
`;

const ExportOptions = styled.div`
    padding: 0 25px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .export-option {
        margin: 0 10px;
    }

    .radio-button-label {
        font-weight: bold;
    }
`;

const CheckBoxContainer = styled.div`
    .MuiCheckbox-root {
        padding: 0 9px !important; 
    }
`;

const RadioShort = styled(Radio)`
    && {
        padding: 0 9px;
    }
`;

const Description = styled.div`
    padding-left: 42px;
`;

const ExportExclusionOption = styled.div`
    border-top: 1px solid #eee;
    padding: 10px 0 0 0;
    margin-top: 15px;
    
    .control-label {
        font-size: 14px;
        opacity: 0.6;
    }
`;

const AddInFeature = styled.div`
    background: #F7FAFC;
    padding: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
`;

export default class ExportDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exportType: WorkspaceController.actionPermissions[ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE].use ? "editable" : "images",
            pdfCompressionType: "none",
            includeSkippedSlides: false
        };
    }

    get isExportingEditable() {
        return this.state.exportType === "editable";
    }

    get canExportEditable() {
        return WorkspaceController.actionPermissions[ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE].use;
    }

    get canExportOffline() {
        return WorkspaceController.actionPermissions[ActionPermissionsObject.DESKTOP_APP].use;
    }

    get canUpgradeToUseExportEditable() {
        return WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE, "use");
    }

    get canUpgradeToUseExportOffline() {
        return WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.DESKTOP_APP, "use");
    }

    get exportAllowed() {
        return WorkspaceController.actionPermissions[ActionPermissionsObject.EXPORT_PRESENTATION].use;
    }

    handleChangeType = event => {
        this.setState({ exportType: event.target.value });
    }

    handleAccept = async () => {
        const { presentation, closeDialog } = this.props;
        let { includeSkippedSlides = false, exportType, pdfCompressionType } = this.state;

        // force images if exporting an invalid combination
        if (!this.canExportEditable && exportType === "editable") {
            exportType = "images";
        } else if (!this.canExportOffline && exportType === "offline") {
            exportType = "images";
        }

        // verify there's anything to export
        if (!includeSkippedSlides) {
            const { models: slides } = presentation.slides;

            let skippedSlides;
            if (slides.some(slide => !slide.loaded)) {
                // some slide models aren't loaded so we have to pull their metadata from the api
                const slidesMetadata = await presentationsApi.getSlidesMetadata({ id: presentation.id });
                skippedSlides = slides.filter(slide => slidesMetadata[slide.id]?.isSkipped);
            } else {
                skippedSlides = slides.filter(slide => presentation.isSlideSkipped(slide));
            }

            const noSlidesExported = skippedSlides.length === slides.length;
            // nothing to export
            if (noSlidesExported) {
                ShowMessageDialog({
                    title: "No Slides to Export",
                    message: "This presentation appears to only contain skipped slides!"
                });
                return;
            }
        }

        // kick off the export
        if (exportType == "editable") {
            trackActivity("Presentation", "EditablePPTXExport", null, null, {}, { audit: true });
            const exporter = new ExportToPPT();
            exporter.export({ destination: "local", includeSkippedSlides });
        } else if (exportType == "images") {
            presentation.downloadExportCache("pptx", { destination: "local", includeSkippedSlides });
        } else if (exportType == "pdf") {
            presentation.downloadExportCache("pdf", { destination: "local", includeSkippedSlides, pdfCompressionType });
        } else if (exportType == "offline") {
            presentation.downloadExportCache("zip", { destination: "local", includeSkippedSlides });
        } else if (exportType == "google-slides") {
            exportToGoogleSlides({ includeSkippedSlides });
        }

        closeDialog();
    };

    downloadFonts = async () => {
        if (!this.canExportEditable) {
            return;
        }

        const progressDialog = ShowDialog(ProgressDialog, {
            title: "Exporting fonts..."
        });

        try {
            const canvasControllers = Object.values(PresentationEditorController.getCanvasControllers());

            // Making sure all canvases are rendered to allow fetching their fonts
            for (let canvasController of canvasControllers) {
                if (!canvasController.canvas) {
                    await canvasController.renderCanvas();
                }
            }

            let usedFonts = {};
            const getElementFonts = element => {
                Object.assign(usedFonts, element.fonts);
                Object.values(element.elements || {})
                    .forEach(getElementFonts);
            };
            canvasControllers.forEach(controller => Object.values(controller.canvas.layouter.canvasElement.elements).map(getElementFonts));

            usedFonts = Object.values(usedFonts);
            if (!usedFonts.some(font => font.isBuiltInFont)) {
                ShowMessageDialog({
                    title: "Can't download custom fonts",
                    message: "Your presentation uses custom uploaded fonts which cannot be downloaded from" +
                        " Beautiful.ai. Please install your original copies of these fonts before exporting your" +
                        " presentation."
                });
                return;
            } else if (usedFonts.some(font => font.isCustomFont)) {
                ShowMessageDialog({
                    title: "Can't download custom fonts",
                    message: "Your presentation uses both Beautiful.ai fonts and custom uploaded fonts. Custom uploaded" +
                        " fonts cannot be exported from Beautiful.ai. Please install your original copies of these fonts before exporting your" +
                        " presentation."
                });
            }

            for (const font of usedFonts.filter(font => font.isBuiltInFont)) {
                await fontManager.exportFontPackage(font.id);
            }
        } catch (err) {
            logger.error(err, "ExportDialog downloadFonts() failed to dowload used fonts");
        } finally {
            progressDialog.props.closeDialog();
        }
    }

    downloadOfflinePlayer = async () => {
        let os;
        if (window.navigator.platform.indexOf("Win") === 0) {
            os = "windows";
        }
        if (window.navigator.platform.indexOf("Mac") === 0) {
            os = "mac";
        }

        if (!os) {
            ShowMessageDialog({
                title: "Your OS is not supported",
                message: "Sorry, your operating system is not supported, offline player only supports MacOS and Windows"
            });
            return;
        }

        try {
            const { url } = await playerApi.getOfflinePlayerDownloadUrl({ os });
            download(url, `Beautiful.ai Offline Player${os === "windows" ? ".exe" : ".dmg"}`);
        } catch (err) {
            logger.error(err, "ExportDialog downloadOfflinePlayer() failed to dowload offline player", { os });
        }
    }

    handleToggleIncludeSkippedSlides = () => {
        this.setState({ includeSkippedSlides: !this.state.includeSkippedSlides });
    }

    handleImagesClick = () => {
        this.setState({ exportType: "images" });
    }

    handlePdfClick = () => {
        this.setState({ exportType: "pdf" });
    }

    handlePdfCompressionChange = event => {
        this.setState({ pdfCompressionType: event.target.value });
    }

    handlePdfCompressionToggle = () => {
        this.setState({
            pdfCompressionType: this.state.pdfCompressionType === "none" ? PdfCompressionTypes.HIGH : "none"
        });
    }

    handleEditableClick = () => {
        if (!this.canExportEditable) {
            if (this.canUpgradeToUseExportEditable) {
                ShowUpgradeDialog({
                    type: UpgradePlanDialogType.UPGRADE_PLAN,
                    analytics: { cta: "ExportToPPTX", ...this.props.presentation.getAnalytics() },
                    workspaceId: this.props.presentation.getWorkspaceId(),
                });
            }

            this.setState({ exportType: "images" });
        } else {
            this.setState({ exportType: "editable" });
        }
    }

    handleGoogleSlidesClick = () => {
        if (!this.canExportEditable) {
            if (this.canUpgradeToUseExportEditable) {
                ShowUpgradeDialog({
                    type: UpgradePlanDialogType.UPGRADE_PLAN,
                    analytics: { cta: "ExportToGoogleSlides", ...this.props.presentation.getAnalytics() },
                    workspaceId: this.props.presentation.getWorkspaceId(),
                });
            }

            this.setState({ exportType: "images" });
        } else {
            this.setState({ exportType: "google-slides" });
        }
    }

    handleOfflineClick = () => {
        if (!this.canExportOffline) {
            if (this.canUpgradeToUseExportOffline) {
                ShowUpgradeDialog({
                    type: UpgradePlanDialogType.UPGRADE_PLAN,
                    analytics: { cta: "ExportOffline", ...this.props.presentation.getAnalytics() },
                    workspaceId: this.props.presentation.getWorkspaceId(),
                });
            }

            this.setState({ exportType: "images" });
        } else {
            this.setState({ exportType: "offline" });
        }
    }

    render() {
        const { presentation, closeDialog } = this.props;
        const { exportType, pdfCompressionType, includeSkippedSlides } = this.state;

        if (!this.exportAllowed) {
            return (
                <BeautifulDialog
                    maxWidth="sm"
                    closeDialog={closeDialog}
                >
                    <DialogTitleShort>
                        <div>Export is not allowed for this workspace</div>
                    </DialogTitleShort>
                    <DialogActions>
                        <Button onClick={closeDialog}>Close</Button>
                    </DialogActions>
                </BeautifulDialog>
            );
        }

        return (
            <BeautifulDialog
                maxWidth="sm"
                closeDialog={closeDialog}
            >
                <DialogTitleShort>
                    <div>How would you like to export?</div>
                    <div>
                        <InfoText>
                            Some Beautiful.ai features cannot be exported, including slide animations and video.
                        </InfoText>
                    </div>
                    <CheckBoxContainer>
                        <CheckBox
                            value={includeSkippedSlides}
                            onChange={this.handleToggleIncludeSkippedSlides}
                            label={
                                <InfoText>Include skipped slides in export</InfoText>
                            }
                        />
                    </CheckBoxContainer>
                </DialogTitleShort>
                <DialogContentNoPad>
                    <ExportOptions>
                        <div className="export-option" onClick={this.handleEditableClick}>
                            <FlexBox verticalAlign="center" horizontalAlign="left">
                                <RadioShort
                                    checked={exportType === "editable"}
                                    onChange={this.handleChangeType}
                                    value="editable"
                                    name="exportType"
                                    disabled={!this.canExportEditable}
                                />
                                <div className="radio-button-label">Editable PowerPoint</div>
                                <ProBadge
                                    show={this.canUpgradeToUseExportEditable}
                                    analytics={{ cta: "ExportToPPTX", ...presentation.getAnalytics() }}
                                    workspaceId={presentation.getWorkspaceId()}
                                />
                            </FlexBox>

                            {
                                this.canExportEditable &&
                                <Description>
                                    <InfoText>
                                        <span>You may notice slight differences in exported slides. Fonts should be installed. </span>
                                        <a className="link-blue" onClick={this.downloadFonts}>
                                            Download fonts.
                                        </a>
                                    </InfoText>
                                </Description>
                            }
                        </div>

                        <div className="export-option" onClick={this.handleImagesClick}>
                            <FlexBox verticalAlign="center" horizontalAlign="left">
                                <RadioShort
                                    checked={exportType === "images"}
                                    onChange={this.handleChangeType}
                                    value="images"
                                    name="exportType"
                                />
                                <div className="radio-button-label">PowerPoint</div>
                            </FlexBox>

                            <Description>
                                <InfoText>Export slides as images.</InfoText>
                            </Description>
                        </div>

                        <div className="export-option" onClick={this.handleGoogleSlidesClick}>
                            <FlexBox verticalAlign="center" horizontalAlign="left">
                                <RadioShort
                                    checked={exportType === "google-slides"}
                                    onChange={this.handleChangeType}
                                    value="google-slides"
                                    name="exportType"
                                    disabled={!this.canExportEditable}
                                />
                                <div className="radio-button-label">Google Slides</div>
                                <ProBadge
                                    show={this.canUpgradeToUseExportEditable}
                                    analytics={{ cta: "ExportToPPTX", ...presentation.getAnalytics() }}
                                    workspaceId={presentation.getWorkspaceId()}
                                />
                            </FlexBox>

                            {
                                this.canExportEditable &&
                                <Description>
                                    <InfoText>Slides are editable and will be saved to your Google Drive as a Google Slides presentation.</InfoText>
                                </Description>
                            }
                        </div>

                        <div className="export-option" onClick={this.handlePdfClick}>
                            <FlexBox verticalAlign="center" horizontalAlign="left">
                                <RadioShort
                                    checked={exportType === "pdf"}
                                    onChange={this.handleChangeType}
                                    value="pdf"
                                    name="exportType"
                                />
                                <div className="radio-button-label">PDF</div>
                            </FlexBox>

                            <Description>
                                <InfoText>Export slides as images.</InfoText>
                            </Description>

                            <Description>
                                <CheckBoxContainer style={{ marginTop: 10 }}>
                                    <CheckBox
                                        value={pdfCompressionType !== "none"}
                                        onChange={this.handlePdfCompressionToggle}
                                        label={
                                            <InfoText>Compress PDF - may result in lower image resolution.</InfoText>
                                        }
                                    />
                                </CheckBoxContainer>
                            </Description>
                        </div>

                        <div className="export-option" onClick={this.handleOfflineClick}>
                            <FlexBox verticalAlign="center" horizontalAlign="left">
                                <RadioShort
                                    checked={exportType === "offline"}
                                    onChange={this.handleChangeType}
                                    value="offline"
                                    name="exportType"
                                    disabled={!this.canExportOffline}
                                />
                                <div className="radio-button-label">Present Offline</div>
                                <ProBadge
                                    show={this.canUpgradeToUseExportOffline}
                                    analytics={{ cta: "ExportOffline", ...presentation.getAnalytics() }}
                                    workspaceId={presentation.getWorkspaceId()}
                                />
                            </FlexBox>

                            {
                                this.canExportOffline &&
                                <Description>
                                    <InfoText>
                                        <a
                                            className={classNames({
                                                ["link-blue"]: true,
                                                ["disabled"]: !this.canExportOffline,
                                            })}
                                            onClick={this.downloadOfflinePlayer}
                                        >
                                            Install the Beautiful.ai Offline Player
                                        </a>
                                    </InfoText>
                                </Description>
                            }
                        </div>
                    </ExportOptions>
                    <WarningWatermark
                        cta="ExportWarningWatermark"
                        presentation={presentation}
                    />
                </DialogContentNoPad>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={this.handleAccept} color="primary">
                        Export
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
