import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";

import {
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";

import { Icon } from "js/Components/Icon";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { SubscriptionStatus } from "common/constants";
import { Button } from "js/Components/Button";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import getLogger, { LogGroup } from "js/core/logger";
import ErrorDialog from "js/react/components/Dialogs/ErrorDialog";
import { themeColors } from "js/react/sharedStyles";

const logger = getLogger(LogGroup.BILLING);

const ChargeInfoMessage = styled.span`
    margin-left: 12px;
    font-size: 14px;
`;

const CloseDialogButton = styled(Button)`
    &&& {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;

const SuccessfulPurchaseContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #222;
    font-size: 23px;
    text-align: center;
    padding: 0px 20px;

    >div {
        width: 100%;
    }
`;

const SuccessfulPurchaseIconContainer = styled.div`
    .bai-icon {
        font-size: 70px;
        margin: 20px;
        transition: all 0.5s ease;
        animation: checked 1s normal forwards ease-in-out;
        animation-iteration-count: 1;
        animation-delay: 0s;
        clip-path: inset(0 100% 0 0);
    }
`;

const SuccessfulPurchaseMessage = styled.div`
    animation: fadein 1s normal forwards ease-in-out;
    margin-bottom: 50px;
`;

const SuccessfulPurchaseReceipt = styled.div`
    font-size: 18px;
    margin-bottom: 10px;
    color: #555;
`;

interface EndTrialDialogProps {
    closeDialog: (result: boolean) => void;
}

export const EndTrialDialog = BillingController.withInitializedState(forwardRef(function EndTrialDialog(props: EndTrialDialogProps & BillingControllerState, ref: any) {
    const { closeDialog, stripeData: { subscription, upcomingInvoice } } = props;

    const [hasEndedTrial, setHasEndedTrial] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>(null);

    useEffect(() => {
        if (!subscription) {
            setErrorMessage("You do not have a subscription.");
            return;
        }
        if (subscription.status !== SubscriptionStatus.TRIALING) {
            setErrorMessage("Your trial has already ended.");
            return;
        }
        if (!upcomingInvoice) {
            setErrorMessage("Unable to get upcoming invoice.");
            return;
        }
    }, []);

    const handleEndTrial = async () => {
        setFetching(true);

        try {
            await BillingController.endTrialOnSubscription();

            if (props.stripeData.subscription.status === SubscriptionStatus.PAST_DUE) {
                setErrorMessage("Please update your payment method.");
            }

            setHasEndedTrial(true);
            setFetching(false);
        } catch (err) {
            logger.error(err, "[EndTrialDialog] Error ending trial");
            setErrorMessage("Sorry, something went wrong. Please try again later.");
        }
    };

    if (errorMessage) {
        return (<ErrorDialog
            ref={ref}
            // @ts-ignore
            preventClose
            title="Error"
            message={errorMessage}
            closeDialog={() => closeDialog(false)}
        />);
    }

    const amountDue = (upcomingInvoice?.amount_due ?? 0) / 100;

    return (
        <BeautifulDialog preventClose ref={ref}>
            <FetchingClickShield visible={fetching} backgroundColor={"white"} />

            {!hasEndedTrial && <>
                <DialogTitle>
                    Are you sure you want to end your trial now?
                </DialogTitle>
                <DialogContent>
                    <ChargeInfoMessage>
                        You will be charged <strong>${amountDue.toFixed(2)}</strong> and will have access to unlimited AI credits immediately.
                    </ChargeInfoMessage>
                </DialogContent>
                <DialogActions>
                    <Button unframed large onClick={() => closeDialog(false)}>
                        Cancel
                    </Button>
                    <Button blue large onClick={handleEndTrial}>
                        Upgrade now
                    </Button>
                </DialogActions>
            </>}

            {hasEndedTrial && <>
                <DialogContent>
                    <SuccessfulPurchaseContainer>
                        <SuccessfulPurchaseIconContainer>
                            <Icon color={themeColors.ui_blue}>check</Icon>
                        </SuccessfulPurchaseIconContainer>
                        <SuccessfulPurchaseMessage>
                            You successfully started your subscription.
                        </SuccessfulPurchaseMessage>
                        <SuccessfulPurchaseReceipt>
                            A receipt will be sent to your email shortly.
                        </SuccessfulPurchaseReceipt>
                        <CloseDialogButton
                            onClick={() => closeDialog(true)}
                            blue
                            large
                        >
                            Close
                        </CloseDialogButton>
                    </SuccessfulPurchaseContainer>
                </DialogContent>
            </>}
        </BeautifulDialog >
    );
}));
