import React, { Component } from "react";
import ReactChart from "js/react/components/ReactChart";
import { FetchStatus } from "js/react/constants";
import { formatter } from "js/core/utilities/formatter";
import AnalyticsController from "js/react/views/Analytics/AnalyticsController";

class SessionDetail extends Component {
    componentWillMount() {
        AnalyticsController.fetchSessionData(
            this.props.analytics.selectedLink.selectedSessionId
        );
    }

    render() {
        let selectedLink = this.props.analytics.selectedLink;

        let status = selectedLink.sessionDetail
            ? selectedLink.sessionDetail.status
            : { type: FetchStatus.LOADING };

        let series, categories;
        if (selectedLink.sessionDetail && selectedLink.sessionDetail.data) {
            let sips = this.props.presentation.getSips();
            series = [
                {
                    type: "column",
                    name: "Seconds",
                    data: sips
                        .map(slideId =>
                            selectedLink.sessionDetail.data.find(
                                row => row.slideid === slideId
                            )
                        )
                        .map(row => (row ? Math.round(row.sum / 1000) : null)),
                    tooltip: {
                        headerFormat: "",
                        pointFormatter: function() {
                            return (
                                "<span style='font-size: 10px; color: #333'>Slide " +
                                (this.x + 1) +
                                "</span><br/><span style='font-size: 12px; color: #333; font-weight: bold'>" +
                                formatter.formatDuration(
                                    this.y,
                                    "seconds",
                                    "h [hrs] m [mins] s [secs]"
                                ) +
                                "</span>"
                            );
                        }
                    }
                }
            ];
            categories = sips.map((slideId, i) => i + 1);
        }

        return (
            <div className="chart-panel">
                <label>Time per slide</label>
                <ReactChart
                    status={status}
                    series={series}
                    categories={categories}
                    height={200}
                    yAxisTitle="SECONDS"
                />
            </div>
        );
    }
}

export default AnalyticsController.withState(SessionDetail);

