import React, { Component } from "react";
import styled from "styled-components";
import { Dialog, DialogContent, DialogActions, Button, DialogTitle, DialogProps } from "@material-ui/core";
import { getStaticUrl } from "../../../config";

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        padding: 40px 50px 40px 50px;
        max-width: 800px;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    &.MuiDialogTitle-root {
        padding: 0px;
    }

    h2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #333;
        font-family: "Source Sans Pro";
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
        text-wrap: nowrap;
    }
`;

const StyledDialogLogo = styled.img`
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
`;

const StyledDialogContent = styled(DialogContent)`
    &.MuiDialogContent-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px;
        margin: 0px;
    }
`;
const FlexBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Message = styled.div`
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.5px;
    text-align: center;
    margin-bottom: 15px;
`;

const SubMessage = styled.div`
    width: 80%;
    color: #666;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.5px;
`;

const StyledList = styled.div`
    display: flex;
    background: #F7FAFC;
    width: 300px;
    height: 250px;
    padding: 20px 30px;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`;

const ListItem = styled.div`
    display: flex;
    justify-content: top;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    > p {
        color: #222;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin: 0;
        padding: 0;
    }

    > img {
        margin-top: 7px;
    }
`;

const StyledImage = styled.img`
    margin-top: 15px;
    margin-bottom: 15px;
`;

const StyledDialogActions = styled(DialogActions)`
    &.MuiDialogActions-root {
        padding: 30px 0px 0px 0px;
        margin: 0px;
        justify-content: space-between;
    }
`;

interface UpdateAppDialogProps extends DialogProps{
    title: string;
    message: string;
    submessage?: React.ReactNode;
    secondButtonLabel: string;
    onFirstButtonClick: () => void;
    onSecondButtonClick: () => void;
    closeDialog: () => void;
    preventClose?: boolean;
}

export default class UpdateAppDialog extends Component<UpdateAppDialogProps> {
    handleClose = () => {
        const { preventClose, closeDialog } = this.props;

        if (!preventClose && closeDialog) {
            closeDialog();
        }
    }

    render() {
        const {
            title,
            message,
            submessage,
            secondButtonLabel,
            onFirstButtonClick,
            onSecondButtonClick,
            closeDialog,
            preventClose,
            open = true,
            ...rest
        } = this.props;

        return (
            <StyledDialog
                onClose={(event, reason) => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        if (rest.hideBackdrop) {
                            return;
                        }
                        (event as Event).stopPropagation();
                    }
                    this.handleClose();
                }}
                disableEnforceFocus
                disableRestoreFocus
                open={open}
            >
                <StyledDialogTitle>
                    {title}
                </StyledDialogTitle>
                <StyledDialogContent>
                    {message && <Message>{message}</Message>}
                    <FlexBox>
                        <StyledImage
                            src={getStaticUrl(`/images/dialogs/pp-cycle.gif`)}
                            width={300}
                            height={250}
                        />
                        <StyledList>
                            <ListItem>
                                <StyledImage
                                    src={getStaticUrl(`/images/dialogs/check-mark.svg`)}
                                    width={9}
                                    height={8}
                                />
                                <p>
                                    Configure slides from one, easy to navigate panel
                                </p>
                            </ListItem>
                            <ListItem>
                                <StyledImage
                                    src={getStaticUrl(`/images/dialogs/check-mark.svg`)}
                                    width={9}
                                    height={8}
                                />
                                <p>
                                    Packed with expanded slide functionality and features
                                </p>
                            </ListItem>
                            <ListItem>
                                <StyledImage
                                    src={getStaticUrl(`/images/dialogs/check-mark.svg`)}
                                    width={9}
                                    height={8}
                                />
                                <p>
                                    Flexible grids to showcase data, text and images
                                </p>
                            </ListItem>
                            <ListItem>
                                <StyledImage
                                    src={getStaticUrl(`/images/dialogs/check-mark.svg`)}
                                    width={9}
                                    height={8}
                                />
                                <p>
                                    More control with new alignment, distribution and selection tools
                                </p>
                            </ListItem>
                            <ListItem>
                                <StyledImage
                                    src={getStaticUrl(`/images/dialogs/check-mark.svg`)}
                                    width={9}
                                    height={8}
                                />
                                <p>
                                    New Smart Slides!
                                </p>
                            </ListItem>
                        </StyledList>
                    </FlexBox>
                    {submessage && <SubMessage>{submessage}</SubMessage>}
                </StyledDialogContent>
                <StyledDialogActions>
                    <Button
                        onClick={() => {
                            onFirstButtonClick();
                            closeDialog && closeDialog();
                        }}
                        color="primary"
                    >
                        More info and faqs
                    </Button>
                    <Button
                        onClick={() => {
                            onSecondButtonClick();
                            closeDialog && closeDialog();
                        }}
                        color="primary"
                        variant="contained"
                    >
                        {secondButtonLabel}
                    </Button>
                </StyledDialogActions>
            </StyledDialog>
        );
    }
}
