import { getCallable } from "../../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import type {
    GetWorkspacesMetadataRequest,
    GetWorkspacesMetadataResponse,
    GetWorkspaceResourceFolderMetadataRequest,
    GetWorkspaceResourceFolderMetadataResponse
} from "./types";

export { apiName };

const getWorkspacesMetadata = getCallable<GetWorkspacesMetadataRequest, GetWorkspacesMetadataResponse>(
    apiName,
    "getWorkspacesMetadata",
    {
        ...endpoints.getWorkspacesMetadata,
        composer: request => {
            return {
                url: "/workspaces/metadata",
                options: {
                    method: "GET"
                }
            };
        }
    });

const getWorkspaceResourceFolderMetadata = getCallable<GetWorkspaceResourceFolderMetadataRequest, GetWorkspaceResourceFolderMetadataResponse>(
    apiName,
    "getWorkspaceResourceFolderMetadata",
    {
        ...endpoints.getWorkspaceResourceFolderMetadata,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/resource-folders/${request.resourceFolderId}/metadata`,
                options: {
                    method: "GET"
                }
            };
        }
    });

export const callables = {
    getWorkspacesMetadata,
    getWorkspaceResourceFolderMetadata
};
