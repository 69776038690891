import { GlobalStateController } from "bai-react-global-state";
import type { User } from "firebase/auth";
import _ from "lodash";
import React from "react";
import type Stripe from "stripe";

import { workspaces as workspacesApi } from "apis/callables";
import { GetWorkspaceStripeDataResponse } from "apis/workspaces/types";
import { PusherEventType } from "common/constants";
import { IWorkspaceDocumentChangedEvent } from "common/interfaces";
import WorkspaceController from "js/controllers/WorkspaceController";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import pusher, { ExtendedChannel } from "js/core/services/pusher";
import { getStripe } from "js/core/services/stripe";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import Spinner from "js/react/components/Spinner";
import { getSequentialRunner, SequentialRunner } from "common/utils/runSequentially";
const logger = getLogger(LogGroup.BILLING);

export interface BillingControllerState {
    initialized: boolean;
    initializeError: Error | null;
    workspaceId: string;
    stripeData: GetWorkspaceStripeDataResponse;
}

const initialState: BillingControllerState = {
    initialized: false,
    initializeError: null,
    workspaceId: null,
    stripeData: {
        customer: null,
        subscription: null,
        upcomingInvoice: null,
        invoices: {},
        paymentMethods: {}
    }
};

class BillingController extends GlobalStateController<BillingControllerState> {
    private _firebaseUser: User;
    private _pusherChannel: ExtendedChannel;
    private _pusherChannelUnbind: () => void
    private _runSequentially: SequentialRunner

    public get initialized() {
        return this._state.initialized;
    }

    constructor() {
        super(_.cloneDeep(initialState));
        this._runSequentially = getSequentialRunner();
    }

    public async initialize(workspaceId: string, firebaseUser: User) {
        await this._runSequentially(async () => {
            logger.info("[BillingController] initialize()", { workspaceId, uid: firebaseUser.uid });

            await this._reset(false);

            try {
                const stripeData = await workspacesApi.getWorkspaceStripeData({ workspaceId });

                this._pusherChannel = await pusher.subscribe(`private-legacy-workspace-${workspaceId === "personal" ? firebaseUser.uid : workspaceId}`);
                this._pusherChannelUnbind = this._pusherChannel.bindChunked(PusherEventType.DATA_RECORD_UPDATED, this._onPusherEvent);

                this._firebaseUser = firebaseUser;

                await this._updateState({
                    initialized: true,
                    stripeData,
                    workspaceId
                });
            } catch (err) {
                logger.error(err, "[BillingController] initialize() failed");

                await this._updateState({
                    ..._.cloneDeep(initialState),
                    initialized: false,
                    initializeError: err
                });
            }
        });
    }

    get usedSeatCount() {
        return WorkspaceController.usedSeatCount;
    }

    get paidSeatCount() {
        return this._state.stripeData.subscription ? this._state.stripeData.subscription.items.data[0].quantity : 0;
    }

    get availableSeatCount() {
        return Math.max(this.paidSeatCount - this.usedSeatCount, 0);
    }

    get hasPaymentMethod() {
        return Object.keys(this._state.stripeData.paymentMethods).length > 0;
    }

    get canChangeSubscriptionQuantity() {
        return this.hasPaymentMethod && !WorkspaceController.plan.isManaged;
    }

    public async reset() {
        return this._reset();
    }

    public async cancelSubscription() {
        return this._runSequentially(async () => {
            const subscription = await workspacesApi.cancelSubscription({ workspaceId: this._state.workspaceId });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription } }));
        });
    }

    public async reactivateSubscription() {
        return this._runSequentially(async () => {
            const subscription = await workspacesApi.reactivateSubscription({ workspaceId: this._state.workspaceId });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription } }));
        });
    }

    public async switchSubscriptionBillingInterval(billingInterval: "month" | "year") {
        return this._runSequentially(async () => {
            const subscription = await workspacesApi.switchSubscriptionBillingInterval({ workspaceId: this._state.workspaceId, billingInterval });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription } }));
        });
    }

    public async updatePaymentMethod(paymentMethodId: string) {
        return this._runSequentially(async () => {
            const { subscription, paymentMethods } = await workspacesApi.updatePaymentMethod({ workspaceId: this._state.workspaceId, paymentMethodId });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription, paymentMethods } }));
        });
    }

    public async payLatestInvoiceOnPastDueSubscription() {
        return this._runSequentially(async () => {
            const response = await workspacesApi.payLatestInvoiceOnPastDueSubscription({ workspaceId: this._state.workspaceId });

            if (response.actionRequired) {
                const stripe = await getStripe();
                const { error } = await stripe.confirmCardPayment(response.paymentIntent.client_secret, {});
                if (error) {
                    throw new Error(error.message);
                }
            }

            const stripeData = await workspacesApi.getWorkspaceStripeData({ workspaceId: this._state.workspaceId });
            await this._updateState(state => ({ ...state, stripeData }));
        });
    }

    public async endTrialOnSubscription() {
        return this._runSequentially(async () => {
            const { subscription, upcomingInvoice, invoices } = await workspacesApi.endTrialOnSubscription({ workspaceId: this._state.workspaceId });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription, upcomingInvoice, invoices } }));
        });
    }

    public async applyPromotionCodeToSubscription(promotionCode: string) {
        return this._runSequentially(async () => {
            const { subscription, upcomingInvoice } = await workspacesApi.applyPromotionCodeToSubscription({ workspaceId: this._state.workspaceId, promotionCode });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription, upcomingInvoice } }));
        });
    }

    public async updateBillingDetails(update: { address?: Stripe.Address, name?: string, email?: string, taxId?: Pick<Stripe.TaxId, "type" | "value"> }) {
        return this._runSequentially(async () => {
            const customer = await workspacesApi.updateBillingDetails({ workspaceId: this._state.workspaceId, ...update });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, customer } }));
        });
    }

    public async updateSubscriptionQuantity(quantity: number) {
        return this._runSequentially(async () => {
            const currentQuantity = this.paidSeatCount;

            const response = await workspacesApi.updateSubscriptionQuantity({ workspaceId: this._state.workspaceId, quantity });

            if (response.actionRequired === false) {
                await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription: response.subscription } }));
            }

            if (response.actionRequired === true) {
                const stripe = await getStripe();
                const { paymentIntent, invoiceId } = response;
                const { error } = await stripe.confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentIntent.payment_method });
                if (error) {
                    throw new Error(error.message);
                }
                const subscription = await workspacesApi.finalizeUpdateSubscriptionQuantity({ workspaceId: this._state.workspaceId, quantity, invoiceId });
                await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription } }));
            }

            //// Legacy analytics ////
            if (quantity > currentQuantity) {
                const billingProps = {
                    workspace_id: this._state.workspaceId,
                    seats_added: quantity - currentQuantity,
                    type: "expansion",
                    total_seats: quantity
                };
                trackActivity("Organization", "BillingIntentComplete", null, null, billingProps, { audit: true });
                await Api.klaviyoTrack.post({
                    eventName: "Organization:BillingIntentComplete",
                    billingProps,
                });
            }
            ////
        });
    }

    public withInitializedState<T extends React.ComponentType<any>>(Component: T, PreloadComponent: React.ComponentType<any> = Spinner, ErrorComponent: React.ComponentType<any> = () => null) {
        function Wrapper(props: React.ComponentProps<T>) {
            const { initialized, initializeError } = props;

            if (initializeError) {
                return <ErrorComponent {...props} />;
            }

            if (!initialized) {
                return <PreloadComponent {...props} />;
            }

            return <Component {...props} />;
        }

        return this.withState(Wrapper);
    }

    public async forceRefreshStripeData() {
        return this._runSequentially(async () => {
            const stripeData = await workspacesApi.getWorkspaceStripeData({ workspaceId: this._state.workspaceId });
            await this._updateState({ stripeData });
        });
    }

    private _onPusherEvent = (documentChangeEvents: IWorkspaceDocumentChangedEvent[]) => {
        this._runSequentially(async () => {
            for (const event of documentChangeEvents) {
                if (event.documentType === "WorkspacePlan") {
                    try {
                        const stripeData = await workspacesApi.getWorkspaceStripeData({ workspaceId: this._state.workspaceId });
                        this._updateState({ stripeData });
                    } catch (err) {
                        logger.error(err, "[BillingController] _onPusherEvent() failed");
                    }
                }
            }
        });
    }

    private _reset(runSequentially = true) {
        const reset = async () => {
            logger.info("[BillingController] reset()");

            if (this._pusherChannel) {
                this._pusherChannelUnbind();
                if (!this._pusherChannel.isInUse) {
                    pusher.unsubscribe(this._pusherChannel.name);
                }
                this._pusherChannel = null;
                this._pusherChannelUnbind = null;
            }

            await this._updateState(_.cloneDeep(initialState));
        };

        if (runSequentially) {
            return this._runSequentially(reset);
        }

        return reset();
    }
}

const billingController = new BillingController();

// for lazy import and debug
app.billingController = billingController;

export default billingController;
