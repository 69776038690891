import type { EndpointDefinition } from "../../_sdk/types/common";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getWorkspaceUserGroups: {
        route: "/workspaces/:workspaceId/user-groups",
        method: "GET"
    },
    updateWorkspaceUserGroups: {
        route: "/workspaces/:workspaceId/user-groups",
        method: "PUT"
    },
};
