import { app } from "js/namespaces";
import { _ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import StorageModel from "js/core/storage/storageModel";
import ReferenceCollection from "js/core/storage/referenceCollection";
import StorageCollection from "js/core/storage/storageCollection";
import getLogger, { LogGroup } from "js/core/logger";
import {
    ASSET_RESOLUTION,
    ASSET_ABBREVIATIONS,
    ASSET_MAX_AREA,
    AssetType,
    ASSET_SIZES,
    ASSET_FILETYPE,
    ASSET_SIZE_REGEX,
    OLD_ASSET_SIZES,
    ASSET_AREAS
} from "common/constants";
import Profiler from "js/core/profiler";
import { createHash } from "js/core/utilities/utilities";
import { readImage } from "js/core/utilities/imageUtilities";
import Api from "js/core/api";
import { loadImage, ImageLoadError } from "js/core/utilities/promiseHelper";
import { isRenderer } from "js/config";
import { getAssetIds, migrateIconAssetId } from "common/assetUtils";
import { getFontWeightName } from "common/fontConstants";
import { uploadBlobToSignedUrl } from "js/core/utilities/uploadBlobToSignedUrl";

const logger = getLogger(LogGroup.ASSETS);

const fileTypeFromUrl = url => {
    return (url.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
};

const profiler = Profiler.create({
    name: "Assets",
    type: Profiler.AVG_LOAD_TIME,
    warnTime: 400
});

const UserAssets = StorageCollection.extend({
    getQuery: function() {
        return {
            root: `userAssets/${app.user ? app.user.id : "anonymous"}`
        };
    },
    comparator: function(userAsset) {
        return userAsset.get("lastUsed");
    }
});

const Asset = StorageModel.extend({
    root: "assets",
    profiler: profiler,
    ensurePersisted: true,
    /* --
    Assets require skipReferenceCollectionLoad flag.
    There is a unique problem where we need to know the asset's type before instantiating a new Asset.
    Assets.prepareModel will fetch the data so we can create the right instance type in Assets.model
    However that Asset instance would normally then try to fetch its own data, which would mean fetching twice.
    skipReferenceCollectionLoad flag tells the referenceCollection (Assets) to skip its model load step.
    -- */
    skipReferenceCollectionLoad: true,

    get type() {
        return AssetType.IMAGE;
    },

    getSanitizedId: function() {
        return this.id.replace(/:/g, "-");
    },

    getIgnoredKeys: function() {
        return ["type"];
    },

    getAltText: function() {
        return this.get("alt") || this.get("name") || this.get("attribution");
    },

    addAssetToUserAssets: function() {
        const userAsset = ds.userAssets.get(this.id);
        let workspaceId = "personal";
        if (ds.selection && ds.selection.presentation) {
            workspaceId = ds.selection.presentation.getWorkspaceId();
        } else if (app.appController.workspaceId) {
            workspaceId = app.appController.workspaceId;
        }

        if (userAsset) {
            userAsset.set({
                lastUsed: new Date().getTime(),
                workspaceIds: {
                    ...userAsset.get("workspaceIds"),
                    [workspaceId]: true
                }
            });
        } else {
            ds.userAssets.add({
                id: this.id,
                lastUsed: new Date().getTime(),
                workspaceIds: {
                    [workspaceId]: true
                }
            });
        }
    },

    applyMigrations: function(type) {
        const promises = [];
        type = type || this.get("hasSolidBackground") ? AssetType.LOGO : AssetType.IMAGE;
        if (!this.has("type") && !(window.isPlayer || isRenderer)) {
            promises.push(
                Api.assets.put({ id: this.id, type })
                    .catch(err => {
                        // silently fail here in case of offline mode so getAssetById can still succeed.
                        // if type failed to be updated, we will just end up trying the migration again later.
                        logger.error(err, "applyMigrations() failed", { id: this.id, type });
                    })
            );
        }

        // return a promise that resolves as the asset
        return Promise.all(promises).then(() => this);
    },

    canUseOriginal: function() {
        return false;
    },

    canRemoveFromUserAssets: function() {
        return this.get("source") !== "dall-e";
    },

    isInvalid: function() {
        return false;
    },

    getBaseUrl: async function(suffix = null, reloadCache = false) {
        const actualId = this.get("actualId");
        if (actualId && actualId !== this.id) {
            // Get url of the actual asset
            const asset = await ds.assets.getAssetById(actualId);
            return asset.getBaseUrl(suffix, reloadCache);
        }

        if (window.isOfflinePlayer) {
            const url = `/userData/assets/${this.getSanitizedId()}${this.get("fileType") === "svg" ? ".svg" : ""}`;
            await loadImage(url);
            return url;
        }

        const { url } = await Api.assets.get({ id: this.id, suffix }, reloadCache);
        try {
            await loadImage(url);
        } catch (err) {
            if (reloadCache) {
                throw err;
            }

            logger.info(`getBaseUrl() failed to load asset ${this.id}, fetching a new url without cache and trying again`, { id: this.id, type: this.type });
            return this.getBaseUrl(suffix, true);
        }
        return url;
    }
});

const ImageAsset = Asset.extend({

    get type() {
        return AssetType.IMAGE;
    },

    getImageType: function() {
        if (this.has("imageType")) {
            return this.get("imageType");
        } else {
            return AssetType.IMAGE;
        }
    },

    // An asset is invalid if it doesn't have any of the sizes including the original
    isInvalid: function() {
        for (const size of [...Object.values(ASSET_SIZES), "original"]) {
            if (this.has(size)) {
                return false;
            }
        }

        return true;
    },

    resize: function() {
        if (
            app.user && // Only authed users can call /assetResize
            this.get("fileType") !== "svg" && // Svg can't be resized
            !isRenderer // Don't want to resize from renderer
        ) {
            // Don't want to wait for the response
            Api.assetResize.post({ id: this.id })
                .catch(err => logger.error(err, "Api.assetResize.post() failed", { id: this.id, type: this.type }));
        }
    },

    resizeIfNeeded: function() {
        // If the asset was created more than 30 minutes ago and hasn't
        // been resizied then resize it
        if (
            !window.isOfflinePlayer &&
            !isRenderer &&
            this.has("original") &&
            !this.get("resizeFailedAt") &&
            // We used to use this value for marking assets that failed to resize,
            // will check for it for backwards compatibility
            !this.get("invalidAsset") &&
            Date.now() - this.get("createdAt") > 30 * 60 * 1000 // 30 minutes
        ) {
            const assetArea = this.get("h") * this.get("w");
            for (const [maxArea, sizeName] of Object.entries(ASSET_SIZES)) {
                // Already has this size
                if (this.has(sizeName)) {
                    continue;
                }

                // Needs resize if the asset is bigger than the given size
                if (maxArea < assetArea) {
                    // Resizing
                    this.resize();
                    return;
                }
            }
        }
    },

    getURL: async function(size, reloadCache = false) {
        const actualId = this.get("actualId");
        if (actualId && actualId !== this.id) {
            // Get url of the actual asset
            const asset = await ds.assets.getAssetById(actualId);
            return asset.getURL(size, reloadCache);
        }

        if (this.get("fileType") === ASSET_FILETYPE.SVG || this.get("fileType") === ASSET_FILETYPE.GIF) {
            size = "original";
        }

        const sizes = Object.keys(ASSET_RESOLUTION).map(key => ASSET_RESOLUTION[key]);
        if (this.canUseOriginal()) {
            sizes.push("original");
        }

        let start = sizes.indexOf(size);
        // If the size is not found, use the last size
        if (start === -1) {
            start = sizes.length - 1;
        }

        const loadUrlForSize = async size => {
            const storedValue = this.get(size);
            if (!storedValue) {
                return null;
            }

            const maxArea = size === "original" ? null : this.getMaxAreaMatch(storedValue, size);
            try {
                return await this.getBaseUrl(maxArea, reloadCache);
            } catch (err) {
                if (err instanceof ImageLoadError) {
                    logger.warn(`[${this.type}] getURL() loadUrlForSize() failed to load image, will respond with empty url`, { id: this.id, size });
                    return null;
                }

                throw err;
            }
        };

        // Find size or larger...
        for (let i = start; i < sizes.length; i++) {
            const size = sizes[i];
            const url = await loadUrlForSize(size);
            if (url) {
                return url;
            }
        }

        // Find smaller sizes...
        for (let i = start - 1; i >= 0; i--) {
            const size = sizes[i];
            const url = await loadUrlForSize(size);
            if (url) {
                return url;
            }
        }

        // No size found, show the original
        const url = await loadUrlForSize("original");
        if (url) {
            return url;
        }

        throw new Error(`[${this.type}] getURL() failed to load image, could not load any of the sizes, including original`);
    },

    getMaxAreaMatch: function(storedValue, size) {
        // New assets
        if (storedValue === true) {
            return ASSET_AREAS[size];
        }

        // Old assets that have urls in their size attributes
        let match;
        try {
            match = new URL(storedValue).pathname.match(ASSET_SIZE_REGEX);
        } catch (err) {
            logger.error(err, "getMaxAreaMatch() failed", { storedValue, id: this.id, type: this.type });
        }
        if (match && match[1] && OLD_ASSET_SIZES.find(size => size === match[1])) {
            // Check if image uses old asset sizes
            return match[1];
        } else {
            // Assume asset uses new sizes
            return ASSET_AREAS[size];
        }
    },

    canUseOriginal: function() {
        if (this.get("h") * (this.get("w")) <= ASSET_MAX_AREA) {
            return true;
        }
        return !Object.keys(ASSET_RESOLUTION).reduce((result, key) => {
            return result || this.has(ASSET_RESOLUTION[key]);
        }, false);
    },
});

const LogoAsset = ImageAsset.extend({
    get type() {
        return AssetType.LOGO;
    }
});

const IconAsset = Asset.extend({
    readonly: true,

    get type() {
        return AssetType.ICON;
    },

    canUseOriginal: function() {
        return false;
    },
});

const AudioAsset = Asset.extend({

    get type() {
        return AssetType.AUDIO;
    },

    canUseOriginal: function() {
        return true;
    },

    getBaseUrl: async function(reloadCache = false) {
        if (window.isOfflinePlayer) {
            return `/userData/assets/${this.getSanitizedId()}`;
        }

        const { url } = await Api.assets.get({ id: this.id }, reloadCache);
        try {
            const resp = await fetch(url);
            if (resp.status >= 400) {
                throw new Error(`Resource responded with ${resp.status} status`);
            }
        } catch (err) {
            if (reloadCache) {
                throw err;
            }

            logger.info(`getBaseUrl() failed to load asset ${this.id}, fetching a new url without cache and trying again`, { id: this.id, type: this.type });
            return this.getBaseUrl(true);
        }
        return url;
    }
});

const FontAsset = Asset.extend({

    get type() {
        return AssetType.FONT;
    },

    getStyleAttrKey: function(weight, italic) {
        return `font-${weight}-${italic ? "italic" : "normal"}`;
    },

    canUpdate: function() {
        return this.get("userId") === app.user?.id;
    },

    /**
     * Updates the asset, uses the api in case the model is disconnected
     */
    safeUpdate: async function(updates) {
        if (this.connected) {
            this.update(updates);
        } else {
            await Api.assets.put({ id: this.id, ...updates });
        }
    },

    canUseOriginal: function() {
        return false;
    },

    isOldFontAsset: function() {
        return this.has("regular");
    },

    getAvailableFontStyles: function() {
        const fontFaces = [];

        if (this.isOldFontAsset()) {
            // This is an obsolete font asset
            fontFaces.push({
                obsoleteFontType: "regular",
                weight: 400,
                italic: false,
                fontFaceName: `${this.id}-Regular`
            });
            if (this.has("italic")) {
                fontFaces.push({
                    obsoleteFontType: "italic",
                    weight: 400,
                    italic: true,
                    fontFaceName: `${this.id}-Regular-Italic`
                });
            }
            if (this.has("bold")) {
                fontFaces.push({
                    obsoleteFontType: "bold",
                    weight: 700,
                    italic: true,
                    fontFaceName: `${this.id}-Bold`
                });
            }
            if (this.has("bolditalic")) {
                fontFaces.push({
                    obsoleteFontType: "bolditalic",
                    weight: 700,
                    italic: true,
                    fontFaceName: `${this.id}-Bold-Italic`
                });
            }
        } else {
            for (const key of Object.keys(this.attributes)) {
                if (key.startsWith("font-")) {
                    const weight = parseInt(key.split("-")[1]);
                    const italic = key.split("-")[2] === "italic";
                    const fontFaceName = `${this.id}-${getFontWeightName(weight)}${italic ? "-Italic" : ""}`;
                    fontFaces.push({ weight, italic, fontFaceName });
                }
            }
        }
        return fontFaces;
    },

    getBaseUrl: async function(weight, italic, obsoleteFontType = null, reloadCache = false) {
        let suffix;
        if (obsoleteFontType) {
            switch (obsoleteFontType) {
                case "regular":
                    suffix = "Regular";
                    break;
                case "italic":
                    suffix = "Italic";
                    break;
                case "bold":
                    suffix = "Bold";
                    break;
                case "bolditalic":
                    suffix = "Bold Italic";
                    break;
            }
        } else {
            suffix = `${weight}-${italic ? "italic" : "normal"}`;
        }

        if (window.isOfflinePlayer) {
            return `/userData/assets/${this.getSanitizedId()}-${suffix}`;
        }

        const { url } = await Api.assets.get({ id: this.id, suffix }, reloadCache);
        try {
            const resp = await fetch(url);
            if (resp.status >= 400) {
                throw new Error(`Resource responded with ${resp.status} status`);
            }
        } catch (err) {
            if (reloadCache) {
                throw err;
            }

            logger.info(`getBaseUrl() failed to load asset ${this.id}, fetching a new url without cache and trying again`, { id: this.id, type: this.type });
            return this.getBaseUrl(weight, italic, obsoleteFontType, true);
        }
        return url;
    },
});

const SvgAsset = Asset.extend({
    get type() {
        return AssetType.SVG;
    },

    canUseOriginal: function() {
        return true;
    },

    getBaseUrl: async function(reloadCache = false) {
        if (window.isOfflinePlayer) {
            return `/userData/assets/${this.getSanitizedId()}`;
        }

        const { url } = await Api.assets.get({ id: this.id }, reloadCache);
        try {
            const resp = await fetch(url);
            if (resp.status >= 400) {
                throw new Error(`Resource responded with ${resp.status} status`);
            }
        } catch (err) {
            if (reloadCache) {
                throw err;
            }

            logger.info(`getBaseUrl() failed to load asset ${this.id}, fetching a new url without cache and trying again`, { id: this.id, type: this.type });
            return this.getBaseUrl(true);
        }
        return url;
    },

    getSvg: async function() {
        const url = await this.getBaseUrl();
        const resp = await fetch(url);
        return resp.text();
    }
});

const VideoAsset = Asset.extend({
    get type() {
        return AssetType.VIDEO;
    },

    canUseOriginal: function() {
        return false;
    },

    getURL: async function() {
        if (window.isOfflinePlayer) {
            return `/userData/assets/${this.getSanitizedId()}`;
        }

        const { url } = await Api.assets.get({ id: this.id }, true);
        return url;
    }
});

const StockVideoAsset = VideoAsset.extend({
    get type() {
        return AssetType.STOCK_VIDEO;
    }
});

const Assets = ReferenceCollection.extend({
    type: "Assets",
    referenceRoot: "userAssets",
    ignoreErrors: true,
    comparator: "createdAt",

    model: function(attrs, options) {
        // we want our models to be connected and live, to respond to team asset changes
        options = { ...options, ...{ autoLoad: true } };

        switch (attrs.type) {
            case AssetType.AUDIO:
                return new AudioAsset(attrs, options);
            case AssetType.LOGO:
                return new LogoAsset(attrs, options);
            case AssetType.ICON:
                return new IconAsset(attrs, options);
            case AssetType.FONT:
                return new FontAsset(attrs, options);
            case AssetType.VIDEO:
                return new VideoAsset(attrs, options);
            case AssetType.STOCK_VIDEO:
                return new StockVideoAsset(attrs, options);
            case AssetType.SVG:
                return new SvgAsset(attrs, options);
            case AssetType.IMAGE:
            default:
                return new ImageAsset(attrs, options);
        }
    },

    prepareModel: async function(model) {
        return await StorageModel.fetchData(model.id, { root: "assets" });
    },

    getReferenceId: function() {
        return app.user ? app.user.id : "anonymous";
    },

    getAssetById: async function(assetId, type, attemptsLeft = 0) {
        // Use default icon name if the type is an icon and the assetId is not the new icon- format
        if (type === "icon") {
            assetId = migrateIconAssetId(assetId, app.currentTheme, app);
        }

        let asset;
        try {
            if (this.has(assetId)) {
                asset = this.get(assetId);
            } else {
                asset = await this.add({ id: assetId }, { remoteChange: true });
            }
        } catch (err) {
            // In some cases we load asset right after it's been created by the server,
            // so Firebase may reply with a not found error (happens rarely, but happens),
            // so for these cases we want to retry
            // Note that attemptsLeft is defaulted to zero, so no retries will be made
            if (err.statusCode === 404 && attemptsLeft > 0) {
                logger.info(`getAssetById() asset ${assetId} not found, will retry (${attemptsLeft} attempts left)`, { assetId, attemptsLeft });
                await new Promise(resolve => setTimeout(resolve, 1000));
                return this.getAssetById(assetId, type, attemptsLeft - 1);
            }

            throw err;
        }

        // I'm not sure if we can ever end up in this condition, but this logic was here initially for
        // whatever reason, so keeping it just in case
        if (!asset) {
            throw new Error(`Failed to load asset, id: ${assetId}, type: ${type}`);
        }

        return asset.applyMigrations(type);
    },

    getAssets: function(assetIds, type) {
        return Promise.all(assetIds.map(assetId => this.getAssetById(assetId, type)));
    },

    getAssetsByType: function(type, orgId = null) {
        const workspaceId = orgId || "personal";
        return ds.userAssets.load().then(() => {
            return ds.userAssets
                .filter(asset => {
                    // uploaded videos don't have their workspace set, so always return all uploaded videos
                    if (type === AssetType.VIDEO) return true;

                    const workspaceIds = asset.get("workspaceIds") || { personal: true };
                    return workspaceIds[workspaceId];
                })
                .map(asset => asset.id);
        }).then(ids => {
            return this.getAssets(ids, type);
        }).then(assets => {
            return assets.filter(asset => asset.get("type") === type);
        });
    },

    loadAssetsForSlide: function(slide) {
        const assets = getAssetIds(slide.toJSON()).filter(({ id }) => !this.has(id));
        return this.add(assets, { remoteChange: true })
            .catch(err => {
                logger.error(err, "loadAssetsForSlide() failed to add assets", { slideId: slide.id });
            });
    },

    // Note: this will eventually update the assets list
    createAsset: function(data) {
        return this.add(Object.assign({
            createdAt: new Date().getTime(),
            userId: app.user.id,
        }, data), { loadModels: false });
    },

    /**
     * Loads an existing image asset or creates a new one from the supplied image
     */
    getOrCreateImage: async function({ file, fileType, url, assetType, name, metadata = {}, imagePropsOverride = {}, extra = {}, hidden = false }) {
        logger.info("getOrCreateImage()", { fileType, url: url?.substring(0, 1000), assetType, name, metadata, imagePropsOverride, extra, hidden });

        /**
         * Loads an asset if exists, if the asset is invalid then treats it as a non-existing asset
         */
        const loadAssetIfExists = async id => {
            try {
                const asset = ds.assets.model({ id, type: assetType });
                // This will throw an error with 404 status code if the asset doesn't exist
                await asset.load();
                // Assume the asset doesn't exist if the asset is invalid
                if (asset.isInvalid()) {
                    return null;
                }
                return asset;
            } catch (err) {
                if (err.statusCode === 404) {
                    return null;
                } else {
                    throw err;
                }
            }
        };

        let asset;

        // tags sometimes comes back as a comma-separated string, so we need to convert it to an array
        if (metadata.tags && !Array.isArray(metadata.tags)) {
            metadata.tags = metadata.tags.split(",").map(tag => tag.trim());
        }

        if (url) {
            const hash = createHash(url);
            const id = `${hash}-${ASSET_ABBREVIATIONS[assetType]}`;

            asset = await loadAssetIfExists(id);
            if (!asset) {
                const blob = await fetch(url).then(resp => resp.blob());
                const { file: readFile, imageProps } = await readImage(blob, assetType);
                if (imageProps.fileType && imageProps.fileType !== fileType) {
                    fileType = imageProps.fileType;
                    logger.warn("getOrCreateImage() fileType mismatch", { url, fileType, imagePropsFileType: imageProps.fileType });
                }
                asset = await ds.assets.loadImageFileAndCreateAsset(id, assetType, readFile, name || readFile.name, fileType, metadata, hash, { ...imageProps, ...imagePropsOverride }, extra);
            }
        } else if (file) {
            const { file: readFile, imageProps, dataUrl } = await readImage(file, assetType);
            const hash = createHash(dataUrl, "BYTES");
            const id = `${hash}-${ASSET_ABBREVIATIONS[assetType]}`;

            asset = await loadAssetIfExists(id);
            if (!asset) {
                if (imageProps.fileType && imageProps.fileType !== fileType) {
                    fileType = imageProps.fileType;
                    logger.warn("getOrCreateImage() fileType mismatch", { url, fileType, imagePropsFileType: imageProps.fileType });
                }
                asset = await ds.assets.loadImageFileAndCreateAsset(id, assetType, readFile, name || readFile.name, fileType, metadata, hash, { ...imageProps, ...imagePropsOverride }, extra);
            }
        } else {
            throw new Error("File or URL required");
        }

        if (!hidden) {
            asset.addAssetToUserAssets();
        }

        return asset;
    },

    loadImageFileAndCreateAsset: async function(id, assetType, file, fileName, fileType, metadata, hash, imageProperties, extra) {
        logger.info("loadImageFileAndCreateAsset()", { id, assetType, fileName, fileType, metadata, hash, imageProperties, extra });

        // Uploading the file to the temp assets bucket
        // Note: will explicitly set content type for svg because the blob will have the image/jpeg mime type in Mac OS
        // which breaks svgs loading
        const { fileName: tempFileName } = await this.uploadTempAssetFile(file, fileType === "svg" ? "image/svg+xml" : null);

        let assetName;
        if (metadata?.source === "upload") {
            // Regex removes file extension
            assetName = fileName.replaceAll(/\.[^.]*$/g, "");
        } else {
            // Regex removes html tags
            assetName = metadata.attribution?.replaceAll(/<[^>]*>/g, "");
        }

        // Composing a model
        const assetModel = {
            id,
            type: assetType,
            w: imageProperties.width,
            h: imageProperties.height,
            name: fileName,
            fileType: fileType,
            hash: hash,
            orgId: app.appController.workspaceId === "personal" ? undefined : app.appController.workspaceId,
            source: _.defaultTo(metadata.source, "unspecified"),
            attribution: _.defaultTo(metadata.attribution, ""),
            alt: _.defaultTo(metadata.alt_description || metadata.alt, null),
            assetName: _.defaultTo(assetName, ""),
            tags: _.defaultTo(metadata.tags, []),
            link: _.defaultTo(metadata.link, ""),
            hasAlpha: _.defaultTo(imageProperties.hasAlpha, false),
            hasSolidBackground: _.defaultTo(imageProperties.hasSolidBackground, false),
            imageBackgroundColor: _.defaultTo(imageProperties.imageBackgroundColor, null),
            ...extra,
        };

        // Creating new asset (this will move the file from temp assets bucket to the assets bucket
        // and set the correct metadata on it and call the asset resizer)
        await Api.assets.post({ assetModel, files: [{ tempFileName, suffix: null }] });

        // Returning the created asset model, note the retries parameter
        return ds.assets.getAssetById(assetModel.id, assetType, 10);
    },

    cloneAsset: async function(assetId, assetModelUpdates = {}, cloneFiles = true) {
        // Creating new asset
        const { id, type } = await Api.assets.post({ cloneAssetId: assetId, assetModelUpdates, cloneFiles });

        // Returning the created asset model, note the retries parameter
        return ds.assets.getAssetById(id, type, 10);
    },

    /**
     * Loads an existing stock video asset or creates a new one from the supplied model
     */
    getOrCreateStockVideo: async function(videoProps) {
        const hash = createHash(videoProps.url, "BYTES");
        const id = `${hash}-${ASSET_ABBREVIATIONS[AssetType.STOCK_VIDEO]}`;
        const fileType = fileTypeFromUrl(videoProps.url);
        const assetName = videoProps.metadata.attribution?.replaceAll(/<[^>]*>/g, "");

        const assetModel = {
            id,
            type: AssetType.STOCK_VIDEO,
            assetName,
            fileType,
            w: videoProps.width,
            h: videoProps.height,
            ...videoProps,
        };

        /**
         * Loads an asset if exists, if the asset is invalid then treats it as a non-existing asset
         */
        const loadAssetIfExists = async id => {
            try {
                const asset = ds.assets.model({ id, type: AssetType.STOCK_VIDEO });
                // This will throw an error with 404 status code if the asset doesn't exist
                await asset.load();
                // Assume the asset doesn't exist if the asset is invalid
                if (asset.isInvalid()) {
                    return null;
                }
                return asset;
            } catch (err) {
                if (err.statusCode === 404) {
                    return null;
                } else {
                    throw err;
                }
            }
        };

        let asset = await loadAssetIfExists(id);
        if (!asset) {
            asset = await ds.assets.loadStockVideoAndCreateAsset(assetModel);
        }
        asset.addAssetToUserAssets();

        return asset;
    },

    loadStockVideoAndCreateAsset: async function(assetModel) {
        // Creating new asset (this will move the file from temp assets bucket to the assets bucket
        // and set the correct metadata on it and call the asset resizer)
        await Api.assets.post({ assetModel });

        // Returning the created asset model, note the retries parameter
        return ds.assets.getAssetById(assetModel.id, AssetType.STOCK_VIDEO, 10);
    },

    /**
     * Uploads a blob into the temp assets storage bucket
     */
    uploadTempAssetFile: async function(file, contentType = null) {
        const { writeUrl, readUrl, fileName } = await Api.tempAssets.post();
        await uploadBlobToSignedUrl(file, writeUrl, () => { }, contentType);

        return { writeUrl, readUrl, fileName };
    }
});

export {
    UserAssets,
    Asset,
    Assets,
    ImageAsset,
    IconAsset,
    AudioAsset,
    StockVideoAsset,
};
