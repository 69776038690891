import React, { Component } from "react";
import styled from "styled-components";

import { Box } from "js/react/components/LayoutGrid";
import { stopPropagation } from "js/core/utilities/stopPropagation";

const Container = styled(Box)`
    display: flex;
    height: 30px;
`;

const StyledInput = styled.input`
    font-size: 13px;
    width: 100%;
    height: 100%;
    outline: none;
    box-sizing: border-box;
    padding-left: 4px;
    border-radius: 0px;
    border: solid 1px #bbb;
`;

export class Input extends Component {
    render() {
        const { width, disabled, value = "", onChange, ...rest } = this.props;

        return (
            <Container width={width ?? 60}>
                <StyledInput type="text"
                    value={value}
                    onChange={onChange}
                    onMouseDown={stopPropagation()}
                    onKeyDown={this.handleKeyDown}
                    disabled={disabled}
                    {...rest}
                />
            </Container>
        );
    }
}

