import React, { Component } from "react";

import { presentations as presentationsApi } from "apis/callables";
import { ActionPermissionsObject, PresentationActivityType, WorkspacePlanTier } from "common/interfaces";
import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import WorkspaceController from "js/controllers/WorkspaceController";
import AppController from "js/core/AppController";
import { ds } from "js/core/models/dataService";
import { getPresentation } from "js/core/models/presentation";
import { Key } from "js/core/utilities/keys";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import DesignerBotIcon from "js/react/components/DesignerBotIcon";
import { Gap20 } from "js/react/components/Gap";
import ProBadge from "js/react/components/ProBadge";
import { Divider, UINavigation, UINavigationItem } from "js/react/components/UiComponents";
import { browserHistory } from "js/react/history";
import GeneratePresentationPane from "js/react/views/AIGeneration/panes/GeneratePresentationPane/GeneratePresentationPane";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { $ } from "js/vendor";

import { PresentationTemplateController } from "js/core/dataServices/PresentationTemplateDataService";
import { AnimatedFullScreenContainer } from "js/editor/components/AnimatedFullScreenContainer";
import { CreatePresentationDialogPaneType } from "js/editor/CreatePresentation/CreatePresentationDialogTypes";
import { ChooseStarterTemplatePane } from "js/editor/CreatePresentation/panes/ChooseStarterTemplatePane";
import { ChooseTeamTemplatePane } from "js/editor/CreatePresentation/panes/ChooseTeamTemplate";
import { CreateBlankPresentationPane } from "js/editor/CreatePresentation/panes/CreateBlankPresentationPane";
import { ImportPresentationFromPPTPane } from "js/editor/CreatePresentation/panes/ImportPresentationFromPPTPane";
import FetchingClickShield from "js/react/components/FetchingClickShield";

export class CreatePresentationDialog extends Component {
    constructor() {
        super();

        this.state = {
            isCreatingPresentation: false,
        };
    }

    componentDidMount() {
        $(window).on("keydown.add-slide-container", event => {
            if (event.which == Key.ESCAPE) {
                this.handleBackButton();
            }
        });
    }

    componentWillUnmount() {
        $(window).off("keydown.add-slide-container");
    }

    handleSelectPane = pane => {
        const { isTeamTemplate } = this.props;

        AppController.showCreatePresentationDialog({
            pane,
            state: {
                isTeamTemplate
            }
        });
    }

    handleBackButton = () => {
        // We want to be able to return to the correct page when we close the dialog
        browserHistory.goBack();
    }

    handleComplete = () => {
        this.closeDialog();
    }

    closeDialog() {
        AppController.showLibrary();
    }

    handleCreatePresentation = async options => {
        const {
            libraryFilter,
            isTeamTemplate,
            callback
        } = this.props;
        const { isCreatingPresentation } = this.state;

        if (isCreatingPresentation) {
            return;
        }

        this.setState({ isCreatingPresentation: true });

        const workspaceId = AppController.workspaceId;

        let createdPresentation;
        switch (options.type) {
            case "team-template":
                createdPresentation = await PresentationLibraryController.createPresentationFromTemplate({
                    templatePresentationId: options.template.id,
                    libraryFilter,
                });
                break;
            case "theme":
                createdPresentation = await PresentationLibraryController.createPresentation({
                    workspaceId,
                    libraryFilter,
                    theme: options.theme
                });
                break;
            case "starter-template":
                createdPresentation = await PresentationLibraryController.createPresentationFromStarterTemplate({
                    workspaceId,
                    libraryFilter,
                    starterTemplateId: options.templateId,
                });
                const props = {
                    presentationId: options.templateId,
                    source: "template"
                };
                trackActivity("Presentation", "GetDeck", null, null, props, { audit: true });
                break;
            case "ppt-import":
                createdPresentation = await PresentationLibraryController.createPresentationFromPPTImport({
                    presentationId: options.presentationId,
                    libraryFilter,
                });
                break;
            case "generate-with-ai":
                createdPresentation = await PresentationLibraryController.createPresentation({
                    name: options.name,
                    libraryFilter,
                    theme: options.theme || ds.builtInThemes.sample(),
                    slides: options.defaultSlides,
                    metadata: options.metadata,
                    workspaceId,
                });
                await createdPresentation.prepareSlides(false);
                createdPresentation.slides.map(async slide => {
                    await slide.getThumbnailUrl(createdPresentation.id, "small", 0, true);
                });
                trackActivity("Presentation", "Generate", null, null, {
                    presentationId: createdPresentation.id,
                    numSlides: options.defaultSlides.length,
                    fromTour: false,
                });
                break;
        }

        // record presentation creation as activity log
        await presentationsApi.recordActivity({
            id: createdPresentation.id,
            activity: PresentationActivityType.CREATED,
            activityData: { creationType: options.type }
        });

        // check if first presentation flag exists
        if (!app.user.get("firstPresentationId")) {
            app.user.update({ firstPresentationId: createdPresentation.id });
            await app.user.updatePromise;
        }

        // Ensure the new presentation template
        //   triggers the create dialog when edited
        isTeamTemplate && createdPresentation.update({
            isTemplateConfirmed: false,
        });

        if (isTeamTemplate) {
            const presentationTemplate = await PresentationTemplateController.convertToPresentationTemplate(createdPresentation);
            if (presentationTemplate) {
                AppController.showEditor({ presentationId: presentationTemplate.id });
            }
        } else {
            if (callback) {
                callback(createdPresentation);
            } else {
                AppController.showEditor({ presentationId: createdPresentation.id });
            }
        }
    }

    render() {
        const { pane } = this.props;
        const { isCreatingPresentation } = this.state;

        const workspaceId = AppController.workspaceId;

        const designerBotAvailable = WorkspaceController.actionPermissions[ActionPermissionsObject.GENERATIVE_AI].use;
        const canUpgradeToDesignerBot = WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.GENERATIVE_AI, "use");
        const showDesignedBot = designerBotAvailable || canUpgradeToDesignerBot;
        const requireTeamTheme = !WorkspaceController.actionPermissions[ActionPermissionsObject.CUSTOM_THEMES].use;

        return (
            <AnimatedFullScreenContainer>
                <FetchingClickShield visible={isCreatingPresentation} backgroundColor="rgb(248, 250, 252)" />

                <UINavigation
                    selected={pane}
                    onSelect={this.handleSelectPane}
                    onBack={this.closeDialog}
                >
                    <label>Start from...</label>
                    <Gap20 />
                    <UINavigationItem
                        key={CreatePresentationDialogPaneType.BLANK_PRESENTATION}
                        id={CreatePresentationDialogPaneType.BLANK_PRESENTATION}
                        title="Blank Presentation"
                        icon="add_circle"
                    />
                    {showDesignedBot && <Divider color="#444" margin={15} />}
                    {showDesignedBot &&
                        <UINavigationItem
                            key={CreatePresentationDialogPaneType.GENERATE_WITH_AI}
                            id={CreatePresentationDialogPaneType.GENERATE_WITH_AI}
                            title="Generate with AI..."
                            image={<DesignerBotIcon />}
                            proBadge={
                                <ProBadge
                                    upgradeType={UpgradePlanDialogType.UPGRADE_PLAN}
                                    show={canUpgradeToDesignerBot}
                                    analytics={{ cta: "PresentationBot", ...ds.selection?.presentation?.getAnalytics() }}
                                    workspaceId={AppController.workspaceId}
                                />
                            }
                        />
                    }

                    <Divider color="#444" margin={15} />
                    <UINavigationItem
                        key={CreatePresentationDialogPaneType.TEAM_TEMPLATES}
                        id={CreatePresentationDialogPaneType.TEAM_TEMPLATES}
                        title="Team Templates"
                        icon="video_library"
                        proBadge={
                            <ProBadge
                                upgradeType={UpgradePlanDialogType.TEAM_NOOP}
                                show={WorkspaceController.plan.tier === WorkspacePlanTier.BASIC || WorkspaceController.plan.tier === WorkspacePlanTier.PRO}
                                analytics={{ cta: "TeamTemplates" }}
                                workspaceId={workspaceId}
                            />
                        }
                    />
                    {
                        WorkspaceController.actionPermissions[ActionPermissionsObject.INSPIRATION_SLIDES].use &&
                        <UINavigationItem
                            key={CreatePresentationDialogPaneType.STARTER_TEMPLATES}
                            id={CreatePresentationDialogPaneType.STARTER_TEMPLATES}
                            title="Starter Templates"
                            icon="lightbulb"
                        />
                    }

                    <Divider color="#444" margin={15} />
                    {
                        WorkspaceController.actionPermissions[ActionPermissionsObject.IMPORT_PPT].use &&
                        <UINavigationItem
                            key={CreatePresentationDialogPaneType.IMPORT_PPT}
                            id={CreatePresentationDialogPaneType.IMPORT_PPT}
                            title="Import PPT..."
                            icon="upload"
                        />
                    }
                </UINavigation>

                {pane === CreatePresentationDialogPaneType.BLANK_PRESENTATION &&
                    <CreateBlankPresentationPane
                        onCreate={theme => this.handleCreatePresentation({ type: "theme", theme })}
                        requireTeamTheme={requireTeamTheme}
                    />
                }
                {pane === CreatePresentationDialogPaneType.STARTER_TEMPLATES &&
                    <ChooseStarterTemplatePane onCreate={this.handleCreatePresentation} />
                }
                {pane === CreatePresentationDialogPaneType.TEAM_TEMPLATES &&
                    <ChooseTeamTemplatePane onCreate={this.handleCreatePresentation} />
                }
                {pane === CreatePresentationDialogPaneType.GENERATE_WITH_AI &&
                    <GeneratePresentationPane onCreate={this.handleCreatePresentation} requireTeamTheme={requireTeamTheme} />
                }
                {pane === CreatePresentationDialogPaneType.IMPORT_PPT &&
                    <ImportPresentationFromPPTPane onCreate={this.handleCreatePresentation} />
                }
            </AnimatedFullScreenContainer>
        );
    }
}
