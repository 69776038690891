import React, { Component } from "react";
import CanvasPreview from "js/react/components/CanvasPreview";
import styled from "styled-components";
import { $, _ } from "js/vendor";
import {
    PreviewSlide1,
    PreviewSlide2,
    PreviewSlide3,
    PreviewSlide4,
    PreviewSlide5
} from "./previewSlides";
import { Theme } from "js/core/models/theme";
import Spinner from "js/react/components/Spinner";
import { FlexBox } from "js/react/components/LayoutGrid";
import { FlexSpacer, Gap10, Gap20 } from "js/react/components/Gap";
import { SimpleLabel } from "js/react/components/SimpleLabel";
import { SimpleColorPicker } from "js/react/components/SimpleColorPicker";
import Logos from "js/core/models/logos";
import { BackgroundStyleType, ForeColorType, PaletteColorType } from "common/constants";

const TitleLabel = styled.label`
  font-size: 14px;
  color: #333;
`;

const PreviewPane = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  padding: 20px 30px;
  position: relative;
`;

const Container = styled.div`
  opacity: ${props => props.isLoading ? 0 : 1};
  transition: 100ms;
`;

export class ThemePreview extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.ref = React.createRef();

        let theme = new Theme(props.theme, { disconnected: true, autoLoad: false });
        theme.loadTheme();

        this.state = {
            selectedIndex: 0,
            transition: false,
            isLoading: true,
            slideColors: [],
            backgroundColors: [],
            slideColor: "colorful",
            backgroundColor: theme.get("defaultBackgroundColor") ?? "background_light",
            theme
        };

        this.renderedCanvases = [];
    }

    componentDidMount() {
        this._isMounted = true;
        this.forceUpdate();
        $(window).on("resize.preview", () => {
            this.forceUpdate();
        });
        this.loadColors();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.theme !== this.props.theme) {
            this.renderedCanvases = [];
            let theme = new Theme(this.props.theme, { disconnected: true, autoLoad: false });
            theme.loadTheme();
            let backgroundColor = this.state.backgroundColor;
            if (prevProps.theme.defaultBackgroundColor !== theme.get("defaultBackgroundColor")) {
                backgroundColor = theme.get("defaultBackgroundColor");
            }
            this.setState({
                theme
            }, async ()=>{
                await this.loadColors();
                let newColor = this.state.backgroundColors.find(c=>c.id === backgroundColor);
                this.handleSetBackgroundColor(newColor);
            });
        }
    }

    loadColors = async () => {
        const { theme } = this.state;

        let slideColors = _.map(theme.palette.getSlideColors(), (color, key) => ({
            id: key,
            value: color,
            type: "color"
        }));

        slideColors.push({
            id: "colorful",
            value: slideColors.map(color => color.value),
            type: "multi-color"
        });

        slideColors.push({
            id: "auto",
            value: ["white", "black"],
            type: "split"
        });

        const backgroundColors = _.map(theme.palette.getBackgroundColors(), (color, key) => ({
            id: key,
            value: color,
            type: "color"
        }));

        for (let bg of (theme.get("backgroundGradients") || [])) {
            backgroundColors.push({
                id: bg.id,
                value: bg,
                type: "gradient"
            });
        }

        for (let bg of (theme.get("backgroundImages") || [])) {
            backgroundColors.push({
                id: bg.id,
                value: await Logos.getSignedUrlAndLoad(bg.asset, false),
                type: "asset"
            });
        }

        this.setState({ slideColors, backgroundColors });
    }

    componentWillUnmount() {
        this._isMounted = false;
        $(window).off(".preview");
    }

    handleClick = index => {
        this.setState({ selectedIndex: index, transition: true });
    }

    handleRenderedCanvas(index) {
        this.renderedCanvases.push(index);
        if (this.renderedCanvases.length == 5) {
            this.props.onRendered && this.props.onRendered();
            if (this._isMounted) {
                this.setState({ isLoading: false });
            }
        }
    }

    handleSetBackgroundColor = color => {
        const { slideColor } = this.state;
        let changedSlideColor;
        let backgroundStyle;

        if (color.type === "color") {
            switch (color.id) {
                case PaletteColorType.BACKGROUND_DARK:
                    backgroundStyle = BackgroundStyleType.DARK;
                    break;
                case PaletteColorType.BACKGROUND_LIGHT:
                    backgroundStyle = BackgroundStyleType.LIGHT;
                    break;
                case PaletteColorType.BACKGROUND_ACCENT:
                    backgroundStyle = BackgroundStyleType.ACCENT;
                    break;
                default:
                    backgroundStyle = BackgroundStyleType.COLOR;
                    changedSlideColor = ForeColorType.NEUTRAL;
            }
        } else if (color.type == "gradient") {
            backgroundStyle = BackgroundStyleType.GRADIENT;
        } else if (color.type == "asset") {
            backgroundStyle = BackgroundStyleType.IMAGE;
        }

        this.setState({
            slideColor: changedSlideColor ?? slideColor,
            backgroundColor: color.id,
            backgroundStyle
        });
    }

    render() {
        const {
            selectedIndex,
            transition,
            theme,
            isLoading,
            backgroundColors,
            slideColor,
            backgroundColor,
            backgroundStyle
        } = this.state;

        const width = $(this.ref.current).width();
        const height = $(this.ref.current).height();

        let scale = width / 1280;
        let smallCanvasScale = (width - 40) / 1280 / 4;

        const previewHeight = 720 * scale + 10 + 720 * smallCanvasScale;
        if (previewHeight > height - 50) {
            scale *= (height - 50) / previewHeight;
            smallCanvasScale *= (height - 50) / previewHeight;
        }

        const frameTransforms = [];

        let left = width / 2 - 1280 * scale / 2;
        let x = left;
        for (let i = 0; i < 5; i++) {
            if (i == selectedIndex) {
                frameTransforms.push({ x: left, y: 20, scale });
            } else {
                frameTransforms.push({ x, y: 720 * scale + 35, scale: smallCanvasScale });
                x += 1280 * smallCanvasScale + 15;
            }
        }

        return (
            <PreviewPane ref={this.ref}>
                {isLoading && <Spinner />}
                <FlexBox left fillWidth>
                    <TitleLabel>THEME PREVIEW</TitleLabel>
                    <FlexSpacer />
                    <Gap20 />
                    <SimpleLabel>Background</SimpleLabel>
                    <Gap10 />
                    <SimpleColorPicker selectedColor={backgroundColor}
                        size={24}
                        colors={backgroundColors}
                        onSelect={this.handleSetBackgroundColor}
                    />
                </FlexBox>
                <Container isLoading={isLoading}>
                    <CanvasPreview
                        onClick={() => this.handleClick(0)}
                        translateX={frameTransforms[0].x}
                        translateY={frameTransforms[0].y}
                        scale={frameTransforms[0].scale}
                        theme={theme}
                        transition={transition}
                        slideModel={PreviewSlide2}
                        onRendered={() => this.handleRenderedCanvas(0)}
                        slideColor={slideColor}
                        backgroundColor={backgroundColor}
                        backgroundStyle={backgroundStyle}
                    />
                    <CanvasPreview
                        onClick={() => this.handleClick(1)}
                        translateX={frameTransforms[1].x}
                        translateY={frameTransforms[1].y}
                        scale={frameTransforms[1].scale}
                        theme={theme}
                        transition={transition}
                        slideModel={PreviewSlide1}
                        onRendered={() => this.handleRenderedCanvas(0)}
                        slideColor={slideColor}
                        backgroundColor={backgroundColor}
                        backgroundStyle={backgroundStyle}
                    />
                    <CanvasPreview
                        onClick={() => this.handleClick(2)}
                        translateX={frameTransforms[2].x}
                        translateY={frameTransforms[2].y}
                        scale={frameTransforms[2].scale}
                        theme={theme}
                        transition={transition}
                        slideModel={PreviewSlide3}
                        onRendered={() => this.handleRenderedCanvas(0)}
                        slideColor={slideColor}
                        backgroundColor={backgroundColor}
                        backgroundStyle={backgroundStyle}
                    />
                    <CanvasPreview
                        onClick={() => this.handleClick(3)}
                        translateX={frameTransforms[3].x}
                        translateY={frameTransforms[3].y}
                        scale={frameTransforms[3].scale}
                        theme={theme}
                        transition={transition}
                        slideModel={PreviewSlide4}
                        onRendered={() => this.handleRenderedCanvas(0)}
                        slideColor={slideColor}
                        backgroundColor={backgroundColor}
                        backgroundStyle={backgroundStyle}
                    />
                    <CanvasPreview
                        onClick={() => this.handleClick(4)}
                        translateX={frameTransforms[4].x}
                        translateY={frameTransforms[4].y}
                        scale={frameTransforms[4].scale}
                        theme={theme}
                        transition={transition}
                        slideModel={PreviewSlide5}
                        onRendered={() => this.handleRenderedCanvas(0)}
                        slideColor={slideColor}
                        backgroundColor={backgroundColor}
                        backgroundStyle={backgroundStyle}
                    />
                </Container>
            </PreviewPane>

        );
    }
}

