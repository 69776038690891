import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";

import { IconButton, Icon, Tooltip } from "@material-ui/core";
import getUserProfile from "js/core/services/userProfiles";
import { Thumbnail } from "js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import moment from "moment";
import { getPresentationContextMenuItems } from "js/editor/PresentationLibrary/PresentationContextMenu";
import { themeColors } from "js/react/sharedStyles";
import { $, _ } from "js/vendor";
import { ContextMenu, IconMenu } from "js/react/components/UiComponents";
import Avatar from "js/react/components/Avatar";
import { app } from "js/namespaces";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { ShareDialog } from "js/react/views/PresentationSettings/dialogs/ShareDialog";
import LoadableAsync from "js/react/components/LoadableAsync";
import PresentationLibraryController, { PresentationLibraryPresentation, PresentationLibraryFilter, PresentationLibrarySort, PresentationLibraryControllerState } from "js/controllers/PresentationLibraryController";
import { getPresentation } from "js/core/models/presentation";

const LibraryList = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 80px 1fr 100px 100px 60px 160px 40px;
  grid-gap: 20px;
  justify-content: flex-start;
  align-items: center;
`;

const LibraryListHeader = styled(LibraryList)`
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  text-transform: uppercase;
`;

const LibraryListContainer = styled.div`
    border-top: 2px solid #f5f5f9;
    height: 100%;

    .dark-mode & {
        border-top: 2px solid #222;
    }
`;

const LibraryListItem = styled(LibraryList) <{ selected: boolean; readOnly: boolean }>`
  background: ${props => props.selected ? themeColors.lightBlue : "white"};
  border-bottom: 2px solid #f5f5f9;
  padding: 10px 10px;
  letter-spacing: 0px;
  ${({ readOnly = false }) => !readOnly && css<{ selected: boolean }>`
    &:hover {
        background: ${props => props.selected ? themeColors.lightBlue : themeColors.hoverBlue};
    }`}
  .thumbnail {
    width: 80px;
    height: 45px;
    img {
      height: 100%;
      outline: solid 1px #ddd;
    }
  }
  .title {
    font-weight: 600;
  }
  .col {
    display: flex;
    align-items: center;
  }
  .avatar {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .dark-mode & {
    background: ${props => props.selected ? "#444" : "#333"};
    border-bottom: 2px solid #222;

    ${({ readOnly = false }) => !readOnly && css<{ selected: boolean }>`
    &:hover {
        background: ${props => props.selected ? "#444" : "#555"};
    }`}
  }
`;

const Placeholder = styled.div`
  width: 100%;
  height: 45px;
`;

interface PresentationLibraryListProps extends PresentationLibraryControllerState {
    onSelectedPresentations: (presentations: PresentationLibraryPresentation[]) => void;
    onDoubleClick: (presentation: PresentationLibraryPresentation) => void;
    onStartDragItems: (items: PresentationLibraryPresentation[]) => void;
    onDropDragItems: (items: PresentationLibraryPresentation[]) => void;
    readOnly: boolean;
    onSort: (sort: PresentationLibrarySort) => void;
    showHeader?: boolean;
}

export const PresentationLibraryList = PresentationLibraryController.withState(
    class PresentationLibraryList extends Component<PresentationLibraryListProps, {
        selection: PresentationLibraryPresentation[];
        showContextMenu: boolean;
        contextMenuX: number;
        contextMenuY: number;
    }> {
        listRef: React.RefObject<HTMLDivElement>;
        startSelectionIndex: number;

        constructor(props: PresentationLibraryListProps) {
            super(props);

            this.state = {
                selection: [],
                showContextMenu: false,
                contextMenuX: 0,
                contextMenuY: 0
            };

            this.listRef = React.createRef();
        }

        handleMouseDownOnItem = (item: PresentationLibraryPresentation, event: React.MouseEvent<HTMLDivElement>) => {
            const { filteredPresentations, readOnly } = this.props;
            let { selection } = this.state;

            if (readOnly) {
                return;
            }

            event.stopPropagation();

            if (event.metaKey || event.ctrlKey) {
                if (selection.includes(item)) {
                    selection = selection.filter(i => i !== item);
                } else {
                    selection = [...selection, item];
                }
            } else if (event.shiftKey) {
                const startIndex = this.startSelectionIndex || 0;
                const endIndex = filteredPresentations.indexOf(item);

                if (endIndex > startIndex) {
                    for (let i = startIndex + 1; i <= endIndex; i++) {
                        let item = filteredPresentations[i];
                        if (!selection.includes(item)) {
                            selection = [...selection, item];
                        }
                    }
                } else {
                    for (let i = startIndex; i >= endIndex; i--) {
                        let item = filteredPresentations[i];
                        if (!selection.includes(item)) {
                            selection = [...selection, item];
                        }
                    }
                }
            } else {
                if (!selection.includes(item)) {
                    selection = [item];
                    this.startSelectionIndex = filteredPresentations.indexOf(item);
                }
                this.dragItems(event, item, selection);
            }

            this.setState({ selection });
        }

        handleMouseUpOnItem = (item: PresentationLibraryPresentation, event: React.MouseEvent<HTMLDivElement>) => {
            const { onSelectedPresentations, readOnly } = this.props;

            if (onSelectedPresentations) {
                onSelectedPresentations([item]);
            }

            if (readOnly) {
                return;
            }

            event.preventDefault();

            if (event.shiftKey || event.metaKey || event.ctrlKey) {
                return;
            }

            this.setState({ selection: [item] });
        }

        dragItems(event, mousedItem, selection) {
            const startX = event.pageX;
            const startY = event.pageY;

            let $dragSelection;
            const startDrag = () => {
                $dragSelection = $("body").addEl($.div("dragged-items"));
                $dragSelection.css({
                    position: "absolute",
                    zIndex: 1000,
                    width: 133,
                    height: 77,
                    pointerEvents: "none",
                    boxShadow: "10px 10px 20px rgba(0,0,0,.4)"
                });

                for (let item of selection) {
                    const $el = $(this.listRef.current).find(`[data-item-id=${item.id}]`);
                    if ($el.length) {
                        const $image = $el.find(".thumbnail img").clone();
                        $image.css({ position: "absolute", border: "solid 1px #333", pointerEvents: "none" });
                        $image.width(133).left(0).top(0);
                        if (item != mousedItem) {
                            $image.css({ transform: `rotate(${Math.random() * 20 - 10}deg)` });
                            $dragSelection.prepend($image);
                        } else {
                            $dragSelection.append($image);
                        }
                    }
                }

                this.props.onStartDragItems && this.props.onStartDragItems(selection);
            };

            $(document).on("mousemove.drag", event => {
                if (!$dragSelection && (Math.abs(event.pageX - startX) > 5 || Math.abs(event.pageY - startY) > 5)) {
                    startDrag();
                } else if ($dragSelection) {
                    $dragSelection.left(event.pageX).top(event.pageY);
                }
            });

            $(document).on("mouseup.drag", event => {
                $(document).off(".drag");

                if ($dragSelection) {
                    $dragSelection.remove();

                    _.defer(() => {
                        // defer this so the drop on the folder happens before we turn off isDraggingItems state
                        this.props.onDropDragItems && this.props.onDropDragItems(selection);
                    });
                }
            });
        }

        handleContextMenu = event => {
            const { readOnly } = this.props;
            if (readOnly) {
                return;
            }

            event.preventDefault();
            this.setState({ showContextMenu: true, contextMenuX: event.pageX, contextMenuY: event.pageY });
        }

        handleCloseContextMenu = () => {
            this.setState({ showContextMenu: false });
        }

        async loadPermission(selection) {
            if (selection.length === 1) {
                let item = selection[0];
                await item?.presentationModel?.getUserPermissions(true);
            } else {
                return;
            }
        }

        render() {
            const { filteredPresentations, sort, onSort, readOnly, onDoubleClick, showHeader = !app.isConstrained, filter } = this.props;
            const { selection, showContextMenu, contextMenuX, contextMenuY } = this.state;

            return (
                <div className="library-list" ref={this.listRef}>
                    {showHeader &&
                        <LibraryListHeader>
                            <ColumnHeader
                                label="Presentation"
                                field="name"
                                sort={sort}
                                onSort={onSort} />
                            <div></div>
                            <ColumnHeader
                                label="Created"
                                field="createdAt"
                                sort={sort}
                                onSort={onSort} />
                            <ColumnHeader
                                label="Updated"
                                field="modifiedAt"
                                sort={sort}
                                onSort={onSort} />
                            <ColumnHeader
                                label="Slides"
                                field="slideCount"
                                sort={sort}
                                onSort={onSort} />
                            <ColumnHeader
                                label="Owner"
                                field="ownerName"
                                sort={sort}
                                onSort={onSort} />
                        </LibraryListHeader>
                    }
                    <LibraryListContainer>
                        {filteredPresentations.map((presentation, idx) => (
                            <PresentationLibraryListItem
                                key={`${presentation.id}:${idx}`}
                                item={presentation}
                                readOnly={readOnly}
                                selected={selection.includes(presentation)}
                                onMouseDown={event => this.handleMouseDownOnItem(presentation, event)}
                                onMouseUp={event => this.handleMouseUpOnItem(presentation, event)}
                                onDoubleClick={onDoubleClick}
                                onContextMenu={this.handleContextMenu}
                                filter={filter}
                            />
                        ))}
                    </LibraryListContainer>

                    {showContextMenu &&
                        <LoadableAsync
                            load={() => this.loadPermission(selection)}
                            renderChildren={() => (
                                <ContextMenu
                                    // @ts-ignore
                                    x={contextMenuX}
                                    y={contextMenuY}
                                    onClose={this.handleCloseContextMenu}
                                >
                                    {getPresentationContextMenuItems({ selectedPresentations: selection })}
                                </ContextMenu>
                            )}
                        />
                    }
                </div>
            );
        }
    }
);

const ColumnHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  .MuiIcon-root {
    font-size: 14px;
    margin-left: 10px;
    color: #666;
  }
`;

class ColumnHeader extends Component<{
    label: string;
    field: string;
    sort: PresentationLibrarySort;
    onSort: (sort: PresentationLibrarySort) => void;
}> {
    handleClick = event => {
        const { field, sort, onSort } = this.props;
        onSort({ field, reverse: !sort.reverse });
    }

    render() {
        const { label, field, sort } = this.props;

        return (
            <ColumnHeaderDiv onClick={this.handleClick}>
                {label}
                {sort.field == field && sort.reverse && <Icon>arrow_upward</Icon>}
                {sort.field == field && !sort.reverse && <Icon>arrow_downward</Icon>}
            </ColumnHeaderDiv>
        );
    }
}

interface PresentationLibraryListItemProps {
    item: PresentationLibraryPresentation;
    readOnly: boolean;
    selected: boolean;
    onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseUp: (event: React.MouseEvent<HTMLDivElement>) => void;
    onContextMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
    onDoubleClick: (presentation: PresentationLibraryPresentation) => void;
    filter: PresentationLibraryFilter;
}

class PresentationLibraryListItem extends Component<PresentationLibraryListItemProps, {
    url: string;
    ownerName: string;
    avatarUrl: string;
    visible: boolean;
}> {
    isMounted = false;
    ref: React.RefObject<HTMLDivElement>;
    observer: IntersectionObserver;

    constructor(props) {
        super(props);

        this.state = {
            url: "",
            ownerName: "",
            avatarUrl: "",
            visible: false,
        };

        this.ref = React.createRef();
    }

    componentDidMount() {
        this.isMounted = true;
        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting === true) {
                if (_.isEmpty(this.state.url)) {
                    this.fetchImages();
                }
                if (!this.state.visible) {
                    this.setState({ visible: true });
                }
            } else {
                if (this.state.visible) {
                    this.setState({ visible: false });
                }
            }
        });

        this.observer.observe(this.ref.current);
    }

    componentWillUnmount() {
        this.isMounted = false;
        this.observer.disconnect();
    }

    async fetchImages() {
        const { item } = this.props;

        if (!this.isMounted) {
            return;
        }

        await Promise.all([
            (async () => {
                const url = await item.getThumbnailUrl();
                this.setState({ url });
            })(),
            (async () => {
                const { displayName, email, photoURL } = await getUserProfile(item.ownerUid);
                this.setState({
                    ownerName: displayName ?? email ?? "",
                    avatarUrl: photoURL ?? ""
                });
            })()
        ]);
    }

    handleSharePresentation = async selectedPresentation => {
        const presentation = await getPresentation(selectedPresentation.id, { permission: "write", autoSync: true });
        ShowDialog(ShareDialog, { presentation, onClose: () => presentation.disconnect() });
    }

    render() {
        const { item, readOnly, onMouseDown, onMouseUp, onContextMenu, selected, onDoubleClick, filter } = this.props;
        const { url, ownerName, avatarUrl, visible } = this.state;

        return (
            <LibraryListItem
                className="library-list-item"
                data-item-id={item.id}
                selected={selected}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onDoubleClick={() => onDoubleClick && onDoubleClick(item)}
                onContextMenu={onContextMenu}
                ref={this.ref}
                readOnly={readOnly}
            >
                {!visible && <Placeholder />}
                {visible && <>
                    <Thumbnail readOnly={readOnly} url={item.dirty ? null : url} showSpinner={true} />
                    <div className="title">{item.name}</div>
                    {
                        !app.isConstrained &&
                        <>
                            <div className="col">{moment(item.createdAt).format("MMM D YYYY")}</div>
                            <div className="col">{moment(item.modifiedAt).format("MMM D YYYY")}</div>
                            <div className="col">{item.slideCount}</div>
                            <Tooltip title={ownerName}>
                                <div className="col">
                                    <Avatar url={avatarUrl} />
                                    {`${ownerName.substr(0, 20)}...`}
                                </div>
                            </Tooltip>
                            {
                                !readOnly &&
                                <IconMenu>
                                    {getPresentationContextMenuItems({ selectedPresentations: [item] })}
                                </IconMenu>
                            }
                        </>
                    }
                    {
                        !window.isZoomApp &&
                        app.isMobileOrTablet &&
                        !readOnly &&
                        item.permissions.write &&
                        <>
                            <IconButton
                                className="text-blue"
                                onMouseDown={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                onMouseUp={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                onDoubleClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                                onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    this.handleSharePresentation(item);
                                }}
                            >
                                <Icon>share</Icon>
                            </IconButton>
                        </>
                    }
                </>}
            </LibraryListItem>
        );
    }
}
