import React, { Component } from "react";
import styled from "styled-components";

import { Switch } from "@material-ui/core";

import { Icon } from "./Icon";
import { themeColors } from "js/react/sharedStyles";

const Mixed = styled.div`
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #ccc;
    padding: 6px 10px;
    background: #ddd;
`;

const StyledSwitch = styled(Switch)`
    &&& {
        height: 31px;
        padding: 9px 12px;

        .MuiSwitch-switchBase {
            padding: 5px;
        }

        .MuiSwitch-colorPrimary.Mui-checked {
            color: #11a9e2;
        }

        .MuiSwitch-colorPrimary.Mui-disabled {
            color: #ccc;
        }

        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color: #11a9e2;
        }

        .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track {
            background-color: #555;
        }
    }
`;

export class ToggleSwitch extends Component {
    triggerAction = () => {
        this.props.onChange(!this.props.value);
    }

    render() {
        const { id, value = false, onChange, disabled, ["data-test-id"]: dataTestId } = this.props;

        if (value == "mixed") {
            return <Mixed onClick={() => onChange(true)}>MIXED</Mixed>;
        } else {
            return (
                <StyledSwitch
                    id={id}
                    checked={value}
                    color="primary"
                    edge="end"
                    disabled={disabled}
                    onChange={event => onChange(event.target.checked)}
                    data-test-id={dataTestId}
                />
            );
        }
    }
}

const ToggleIconContainer = styled.div`
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: ${props => props.round ? "50%" : "2px"};
    background: ${props => props.value ? themeColors.ui_blue50percent : "#ccc"};
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${props => props.disabled ? 0.5 : 1};
`;

export class ToggleIcon extends Component {
    render() {
        const { icon, iconOff, value = false, onChange, round, disabled } = this.props;

        if (value == "mixed") {
            return <Mixed onClick={() => onChange(true)}>MIXED</Mixed>;
        } else {
            return (
                <ToggleIconContainer value={value} onClick={() => onChange(!value)} round={round} disabled={disabled}>
                    <Icon color="rgba(0,0,0,.4)">{value ? icon : (iconOff ? iconOff : icon)}</Icon>
                </ToggleIconContainer>
            );
        }
    }
}

