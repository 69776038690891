import { SUGGEST_TEMPLATE_FORM } from "common/constants";
import { getPricingPageUrl } from "./pricing";
import { trackActivity } from "./utilities";
import AppController from "js/core/AppController";
import { isPPTAddin } from "js/config";
import WorkspaceController from "js/controllers/WorkspaceController";

export const openPricingPage = (personalPlan = "pro", analytics = null) => {
    const currentPlan = WorkspaceController.isBasicOrGuest ? "basic" : personalPlan;

    if (analytics) {
        if (!analytics.cta) {
            throw new Error("analytics.cta is required");
        }

        trackActivity("Upgrade", "ShowPricingPage", null, null, analytics);
    }

    // if the user is in the PPT addin, we need to show a dialog instead of opening a new tab
    if (isPPTAddin) {
        window.location = `/ppt-addin/choosePlan?hideBasic=true&emphasizePlan=pro&hasTakenTrial=${AppController.user.hasTakenTrial}&hasTakenTeamTrial=${AppController.user.hasTakenTeamTrial}`;
    } else {
        window.open(getPricingPageUrl(AppController.user.hasTakenTrial, AppController.user.has("hasTakenTeamTrial"), currentPlan), "_blank");
    }
};

export const openTeamUpgrade = (analytics = {}, callback) => {
    analytics.plan = WorkspaceController.isBasicOrGuest ? "basic" : AppController.user.analyticsPersonalPlan;
    trackActivity("Upgrade", "ShowTeamPricingPage", null, null, analytics);

    // if the user is in the PPT addin, we need to show a dialog instead of opening a new tab
    if (isPPTAddin) {
        window.location = `/ppt-addin/choosePlan?hideBasic=true&emphasizePlan=team&hasTakenTrial=${AppController.user.hasTakenTrial}&hasTakenTeamTrial=${AppController.user.hasTakenTeamTrial}`;
    } else {
        window.open("/team-upgrade", "_blank");
    }
};

export const openSuggestTemplateForm = () => {
    window.open(SUGGEST_TEMPLATE_FORM, "_blank");
};
