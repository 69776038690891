import type { EndpointDefinition } from "../../_sdk/types/common";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getWorkspaceUsers: {
        route: "/workspaces/:workspaceId/users",
        method: "GET"
    },
    updateWorkspaceUser: {
        route: "/workspaces/:workspaceId/users/:userId",
        method: "PUT"
    },
    deleteWorkspaceUser: {
        route: "/workspaces/:workspaceId/users/:userId",
        method: "DELETE"
    }
};
