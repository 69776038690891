import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled, { StyledComponent } from "styled-components";

import { IWorkspacePlayerSettings } from "common/interfaces";
import getObjectHash from "common/utils/getObjectHash";
import { Breadcrumb } from "js/Components/Breadcrumb";
import { getStaticUrl } from "js/config";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { PresentationPrivacyType } from "js/core/models/presentation";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { UIPane } from "js/react/components/UiComponents";
import { StackableSearchBarContainer, StackableUIPaneResultsContainer } from "js/react/views/AddSlide/Panes/Components/SearchBox";
import PlayerSettings from "js/react/views/PresentationSettings/components/PlayerSettings";

const logger = getLogger(LogGroup.WORKSPACES);

const Container = styled(UIPane)`
    width: 100%;
    overflow-y: scroll;
    display: block;
` as unknown as StyledComponent<"div", any, {}, never>;

const PlayerSettingsContainer = styled.div`
    background: white;
    padding: 20px;
`;

export default WorkspaceController.withInitializedState(
    function PlayerSettingsPane(props: WorkspaceControllerState) {
        const { workspace } = props;

        const [playerSettings, setPlayerSettings] = useState<IWorkspacePlayerSettings>(_.cloneDeep(workspace.player.settings));
        const [fetching, setFetching] = useState(false);

        useEffect(() => {
            if (getObjectHash(workspace.player.settings) !== getObjectHash(playerSettings)) {
                setPlayerSettings(_.cloneDeep(workspace.player.settings));
            }
        }, [getObjectHash(workspace.player.settings)]);

        const handleChange = async (delta: Partial<IWorkspacePlayerSettings>) => {
            setFetching(true);

            try {
                // Just in case, you never know what these old components are sending
                const cleanDelta = _.pick(delta, ["showTitle", "showAuthor", "requireEmail", "showSocialSharing", "showGetDeckUI", "allowPdfDownload"]);
                if (Object.keys(cleanDelta).length === 0) {
                    return;
                }

                // Update the UI immediately for smoother UX
                setPlayerSettings({
                    ...playerSettings,
                    ...cleanDelta,
                });

                await WorkspaceController.updateWorkspace({ player: { settings: { ...cleanDelta } } });
            } catch (error) {
                logger.error(error, "[PlayerSettingsPane] Error updating player settings");

                ShowErrorDialog({
                    title: "Error updating player settings",
                    message: "An error occurred while updating the player settings. Please try again later.",
                });

                // Revert the UI to the previous state
                setPlayerSettings(playerSettings);
            } finally {
                setFetching(false);
            }
        };

        return (
            <Container>
                <StackableSearchBarContainer className="search-container">
                    <Breadcrumb>Player Settings</Breadcrumb>
                </StackableSearchBarContainer>
                <StackableUIPaneResultsContainer>
                    <PlayerSettingsContainer className="player_settings">
                        <PlayerSettings
                            sectionTitle={"Default Player Settings"}
                            removeBeautifulBranding
                            privacySetting={PresentationPrivacyType.PUBLIC}
                            slideThumbnail={getStaticUrl("/images/thumbnails/sample-slide-large.png")}
                            presentationName={"Presentation Title"}
                            handleChange={handleChange}
                            showTitle={playerSettings.showTitle}
                            showAuthor={playerSettings.showAuthor}
                            requireEmail={playerSettings.requireEmail}
                            showSocialSharing={playerSettings.showSocialSharing}
                            showGetDeckUI={playerSettings.showGetDeckUI}
                            allowPdfDownload={playerSettings.allowPdfDownload}
                            disabled={fetching}
                        />
                    </PlayerSettingsContainer>
                </StackableUIPaneResultsContainer>
            </Container>
        );
    }
);
