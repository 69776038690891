export interface ErrorResponseBody {
    message: string;
    [key: string]: any;
}

export class BaseApiError extends Error {
    public readonly status: number;
    public readonly responseBody: ErrorResponseBody;
    public readonly name: string;

    constructor(message: string, responseBody?: Omit<ErrorResponseBody, "message">) {
        super(message);
        this.responseBody = { message, ...responseBody };
        this.name = this.constructor.name;
    }
}

export class InternalServerError extends BaseApiError {
    public readonly status = 500;
}

export class NotImplementedError extends BaseApiError {
    public readonly status = 501;
}

export class BadRequestError extends BaseApiError {
    public readonly status = 400;
}

export class NotFoundError extends BaseApiError {
    public readonly status = 404;
}

export class UnauthorizedError extends BaseApiError {
    public readonly status = 401;
}

export class ForbiddenError extends BaseApiError {
    public readonly status = 403;
}

export class TooManyRequestsError extends BaseApiError {
    public readonly status = 429;
}

export class ConflictError extends BaseApiError {
    public readonly status = 409;
}

export class PaymentRequiredError extends BaseApiError {
    public readonly status = 402;
}
