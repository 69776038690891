import { _ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import AppController from "js/core/AppController";
import * as appSearch from "js/core/services/appSearch";

import DataService from "./DataService";

interface TeamSlidesDataServiceSearchQuery {
    organizationId: string,
    fullTextSearchQuery?: string,
    fullTextSearchByFields?: string[],
    tag?: string,
    size?: number,
    minScore?: number,
}

interface TeamSlidesDataServiceDataItem {
    id: string,
    libraryItemId: string,
    organizationId: string,
    libraryItem: any, // Backbone model
    isTemplate: boolean,
    isUsedInPresentations: boolean,
    isDisabled: boolean,
    name: string,
    tags: string[],
    presentationCount: number,
    ratedByCount: number,
    ratedByUsers: {
        [userUid: string]: boolean
    },
    createdAt: number,
    modifiedAt?: number,
    score: number
}

class TeamSlidesDataService extends DataService {
    public async search(query: TeamSlidesDataServiceSearchQuery): Promise<TeamSlidesDataServiceDataItem[]> {
        const {
            organizationId,
            fullTextSearchQuery,
            fullTextSearchByFields = [],
            tag,
            size = TeamSlidesDataService.SEARCH_QUERY_SIZE,
            minScore = TeamSlidesDataService.SEARCH_QUERY_MIN_SCORE,
        } = query;

        const defaultTeam = ds.teams.defaultTeamForOrg(organizationId);
        if (!defaultTeam) {
            return [];
        }

        const trashedPresentationIds = PresentationLibraryController.getPresentations(AppController.workspaceId)
            .filter(presentation => presentation.trashedAt).map(presentation => presentation.id);

        const libraryItemToSearchResult = (libraryItem, score) => {
            const usedPresentationIds = Object.keys(libraryItem.get("usedInPresentations") ?? {})
                .filter(presentationId => !trashedPresentationIds.includes(presentationId));

            return {
                id: libraryItem.get("contentId"),
                libraryItemId: libraryItem.id,
                organizationId,
                libraryItem,
                isTemplate: libraryItem.get("isTemplate"),
                isUsedInPresentations: usedPresentationIds.length > 0,
                isDisabled: !!libraryItem.get("isDisabled"),
                name: libraryItem.get("name"),
                description: libraryItem.get("description"),
                tags: Object.keys(libraryItem.get("tags") ?? {}),
                presentationCount: usedPresentationIds.length,
                ratedByUsers: libraryItem.get("ratedByUsers") ?? {},
                ratedByCount: Object.keys(libraryItem.get("ratedByUsers") ?? {}).length,
                createdAt: libraryItem.get("createdAt"),
                modifiedAt: libraryItem.get("modifiedAt"),
                score
            };
        };

        if (!fullTextSearchQuery && !tag) {
            // Just return all library items of the team
            return defaultTeam.libraryItems.models
                .map(libraryItem => libraryItemToSearchResult(libraryItem, Number.POSITIVE_INFINITY))
                // Sort by age since we don't have score here
                .sort((a, b) => (b.modifiedAt ?? b.createdAt) - (a.modifiedAt ?? a.createdAt))
                .slice(0, size);
        }

        // Perform search
        let { results: appsearchResults } = await appSearch.search({
            workspaceId: organizationId,
            searchEngine: appSearch.SearchEngine.SHARED_SLIDES,
            query: fullTextSearchQuery ?? "",
            page: { size },
            filters: tag
                ? {
                    all: [
                        { org_id: organizationId },
                        { tags: tag }
                    ]
                }
                : { org_id: [organizationId] },
            searchFields: fullTextSearchByFields.length > 0 ? fullTextSearchByFields.reduce((searchFields, fieldName) => ({ ...searchFields, [fieldName]: {} }), {}) : undefined
        });

        appsearchResults = appsearchResults
            // Filter by score to avoid excessive library items loading
            .filter(result => result._meta.score >= minScore);

        let searchResults: TeamSlidesDataServiceDataItem[] = await Promise.all(appsearchResults.map(async ({
            id: { raw: slideId },
            _meta: { score }
        }): Promise<TeamSlidesDataServiceDataItem | null> => {
            // This condition is only possible in cases when the library item or team has just been removed
            // and appsearch didn't have enough time to update
            const libraryItem = defaultTeam.libraryItems.find(libraryItem => libraryItem.get("type") === "slides" && libraryItem.get("contentId") === slideId);
            if (!libraryItem) {
                return null;
            }

            return libraryItemToSearchResult(libraryItem, score);
        }));

        searchResults = searchResults
            // Remove non existing items
            .filter(item => !!item)
            .sort((a, b) => b.score - a.score);

        return searchResults;
    }
}

export default TeamSlidesDataService;
