import { IGenericModelFields } from "../IGenericFields";

export enum WorkspacePermissionObjectType {
    RESOURCE_FOLDER = "resourceFolder",
    WORKSPACE_RESOURCE = "workspaceResource"
}

export enum WorkspacePermissionSubjectType {
    USER = "user",
    USER_GROUP = "userGroup"
}

export interface IWorkspacePermissionAccess {
    read: {
        object: boolean;
        permissions: boolean;
    };
    write: {
        object: boolean;
        permissions: boolean;
    };
    owner: boolean;
}

export interface IWorkspacePermission extends IWorkspacePermissionAccess, IGenericModelFields {
    readonly workspaceId: string;
    readonly objectId: string;
    readonly objectType: WorkspacePermissionObjectType;
    readonly subjectId: string;
    readonly subjectType: WorkspacePermissionSubjectType;
}
