import { AssetType, SLIDE_DATA_STATE_FIELDS, TaskState, THUMBNAIL_SIZES, UndoType } from "common/constants";
import getSlideText from "common/utils/getSlideText";
import { isOfflinePlayer, isRenderer } from "js/config";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import Thumbnails from "js/core/models/thumbnails";
import { getExperiments } from "js/core/services/experiments";
import NotificationsService from "js/core/services/notifications";
import pusher from "js/core/services/pusher";
import Adapter from "js/core/storage/adapter";
import { loadImage } from "js/core/utilities/promiseHelper";
import { app } from "js/namespaces";
import { ShowDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { _, Backbone } from "js/vendor";
import React, { setGlobal } from "reactn";
import Profiler from "../profiler";
import DummyAdapter from "../storage/dummyAdapter";
import SlidesAdapter from "../storage/slidesAdapter";
import StorageModel from "../storage/storageModel";
import { downloadFromUrl, filenameForExport, trackActivity } from "../utilities/utilities";
import { ds } from "./dataService";
import { slides as slidesApi } from "apis/callables";

const logger = getLogger(LogGroup.SLIDE);

const profiler = Profiler.create({
    name: "Slide",
    type: Profiler.AVG_LOAD_TIME,
    warnTime: 400
});

const nonTextKeys = {
    content_type: true,
    content_value: true
};

const SOURCE = {
    local: 0,
    remote: 1,
    server: 2,
    initialize: 3
};

const Slide = StorageModel.extend({
    root: "slides",
    profiler: profiler,
    readonly: isOfflinePlayer,
    hasChanges: false,
    // Has to be set to true in order to make sure
    // the initial model is always normalized by
    // the adapter before firing the load event
    ensurePersisted: true,

    createAdapter: function(options) {
        return new SlidesAdapter({ ...options, model: this });
    },

    getDummyAdapterOptions() {
        return {
            removeTrailingNullsFromArrays: true
        };
    },

    initialize: function(model, options) {
        this.type = "Slide";
        this.destroyed = false;
        this.dataStateIndex = 0;
        this.once("load", () => {
            this.dataStateIndex = 0;
            this._updateDataState({ source: SOURCE.initialize });
        });
        this.presentation = options.presentation;
    },

    isMigrated: function() {
        if (this.adapter instanceof SlidesAdapter) {
            return !this.adapter.fallbackToCommandsAdapter;
        }

        return false;
    },

    hasNextState: function() {
        const states = this.get("states");
        return states && this.dataStateIndex + 1 < states.length;
    },

    nextState: function() {
        if (!this.hasNextState()) {
            return;
        }
        this.dataStateIndex++;
        this._updateDataState({ source: SOURCE.local });
    },

    generateLoadPromise(...args) {
        return StorageModel.prototype.generateLoadPromise.call(this, ...args).then(() => {
            const loaders = [];
            if (ds.assets) {
                loaders.push(ds.assets.loadAssetsForSlide(this));
            }
            return Promise.all(loaders);
        }).then(() => {
            const templateId = this.get("template_id");
            const version = this.get("version");

            // Hack to mitigate the bug that we had in ppt importer when it wasn't setting
            // version on the generated slide models
            if (templateId === "ppt-slide" && !version) {
                this.update({ version: 9 }, { save: false });
            }

            return this;
        });
    },

    setDataState: function(index) {
        if (!this.loaded) {
            return;
        }
        this.dataStateIndex = index;
        this._updateDataState({ source: SOURCE.local });
    },

    debug: function() {
        /* eslint-disable no-console */
        console.log("-----------------------------------------------");
        console.log("Slide:" + this.id + " index: " + this.presentation.getSlideIndex(this.id));
        console.log("Template: " + this.get("template_id"));
        console.log(JSON.stringify(this.get("states")[0], null, 2));
        /* eslint-disable no-console */
    },

    handleRemoteChange: function(type, data) {
        const changeSet = StorageModel.prototype.handleRemoteChange.call(this, type, data);

        // The initialize type is handled when the slide loads, so skip double firing here.
        if (type === Adapter.TYPE.initialize) {
            return;
        }

        if (changeSet.hasUpdates) {
            this._updateDataState({ source: this._isDataStateChangeSet(changeSet) ? SOURCE.remote : SOURCE.server });
        }
    },

    update: function(attrs, options = {}) {
        if (isRenderer || window.isPlayer) {
            const targetObject = {};
            Error.captureStackTrace(targetObject);
            logger.warn(`[Slide] trying to update when data should be read only, trace for debugging: ${targetObject.stack}`, { id: this.id });
            options = Object.assign({}, options, { save: false });
        }

        // These values are set and returned by the server or the model, so we won't allow changing them from the outsize of the model
        const ensureValueNotChanged = key => {
            if (options.removeMissingKeys || key in attrs) {
                attrs[key] = this.get(key);
            }
        };
        ["_changeId", "modifiedAt"].forEach(ensureValueNotChanged);

        const changeSet = StorageModel.prototype.update.call(this, attrs, options);
        if (!options.isCommit && changeSet.hasUpdates && this._isDataStateChangeSet(changeSet)) {
            this._updateDataState(Object.assign(options, { source: SOURCE.local }));
        }

        if (changeSet.hasUpdates && !options.isFromMigration) {
            const updatedKeys = Object.keys(changeSet.update);
            // We don't want to mark the slide as changed if it was only the modifiedAt
            // or assignedUser value that got updated (which we do when we want to generate new
            // thumbnails, i.e. when the theme of the presentation gets updated)
            if (updatedKeys.length !== 1 || (updatedKeys[0] !== "modifiedAt" && updatedKeys[0] !== "assignedUser" && updatedKeys[0] !== "status")) {
                this.hasChanges = true;
            }
        }

        return changeSet;
    },

    getLibraryItem() {
        const orgId = this.presentation.get("orgId");
        const libraryItemId = this.get("libraryItemId");
        if (!orgId || !libraryItemId) {
            return null;
        }
        return ds.teams.defaultTeamForOrg(orgId).libraryItems.get(libraryItemId);
    },

    // detaches the current adapter so that any changes to the slide model will not save automatically
    // call saveChanges() to restore original adapter and commit any changes since detach was called
    detachAdapter: function() {
        this.isDetached = true;

        // store the original adapter and current state
        this.originalAdapter = this.adapter;
        this.originalState = _.cloneDeep(this.attributes);

        this.adapter = new DummyAdapter(this.getDummyAdapterOptions());
    },

    // reattaches the detached adapter and saves any changes since detached
    saveChanges: async function() {
        if (!this.isDetached) {
            throw new Error("saveChanges can only be called after detachAdapter() has been called on the model");
        }

        this.isDetached = false;

        const newState = _.cloneDeep(this.attributes);

        // silently restore the model to the original state from when we detached the adapter
        this.update(this.originalState, {
            removeMissingKeys: true,
            silent: true,
            save: false
        });

        // restore the original adapter and update to the new state
        this.adapter = this.originalAdapter;
        const { hasUpdates } = this.update(newState, {
            removeMissingKeys: true,
            silent: true,
            save: true
        });

        await this.updatePromise;

        return { hasUpdates };
    },

    _isDataStateChangeSet: function(changeSet) {
        return SLIDE_DATA_STATE_FIELDS.some(key => key in changeSet.original);
    },

    _reloadDataState: function(options) {
        this.dataState = getSlideDataStateFromAttributes(this.attributes, this.dataStateIndex);

        if (!options.silent) {
            this.trigger("updateDataState", this, Object.assign(options, {
                remoteChange: options.source === "remote",
                initialize: options.source === "initialize"
            }));
        }
    },

    _updateDataState: function(options) {
        switch (options.source) {
            case SOURCE.local:
                this._reloadDataState(options);
                break;
            case SOURCE.remote:
                this._reloadDataState(options);
                break;
            case SOURCE.initialize:
                this._reloadDataState(options);
                break;
            case SOURCE.server:
                break;
            default:
                logger.warn("[Slide] Source unknown: " + options.source, { id: this.id });
                break;
        }
    },

    /**
     * This will push the dataState to the model.
     * @param silent If true, no events will be emitted. this can prevent feedback loops in cases where we
     * don't want the display to update.
     */
    commit: function(options = {}) {
        // dataState contains element models as well as other props that are part of
        // the slide model so we merge them into the slide model but we'll remove elements
        // from the root and add them to the states prop
        //
        // NOTE: we have to clone deep this.attributes to make sure we don't mutate them
        // when mutating the data object
        let data = { ..._.cloneDeep(this.attributes), ...this.dataState };
        delete data.elements;

        // States may have been removed if there were no elements on the slide
        if (!data.states) {
            data.states = [this.dataState.elements];
            // Force data state index to zero since we have only one state
            this.dataStateIndex = 0;
        } else {
            // Assigning elements to the current data state index
            data.states[this.dataStateIndex] = this.dataState.elements;
        }

        // This clone deep is mandatory because the update function in some cases
        // can mount subobjects of the data objects to the model's attributes
        // in a non-mutation safe manner so we can end up with data state having
        // references to the model's attributes
        data = _.cloneDeep(data);

        // Special case to avoid unnecessary slide model updates
        const annotations = data.states[this.dataStateIndex].annotations;
        const prevAnnotations = this.attributes.states ? this.attributes.states[this.dataStateIndex]?.annotations : null;
        if (_.isEqual(annotations, { items: [], connections: { items: [] } }) && !prevAnnotations) {
            data.states[this.dataStateIndex].annotations = null;
        }

        // Preserve the original state in case we need to save an undo step
        const saveUndo = app.undoManager && options.undo !== false;
        const forceUndo = saveUndo && options.forceUndo;
        const originalAttrs = saveUndo ? _.cloneDeep(this.attributes) : null;

        // Update the model
        const { hasUpdates } = this.update(
            data,
            {
                removeMissingKeys: true,
                isCommit: true,
                isFromMigration: _.defaultTo(options.isFromMigration, false)
            }
        );

        if ((hasUpdates || forceUndo) && saveUndo) {
            const { undoOldState = {}, undoNewState = {} } = options;

            app.undoManager.set(
                UndoType.SLIDE_DATA,
                this.id,
                {
                    attributes: originalAttrs,
                    ...undoOldState
                },
                {
                    attributes: this.attributes,
                    ...undoNewState
                },
                {
                    ...(options.undoOptions ?? {}),
                    presentation: this.presentation
                },
            );
        }

        return this.dataState;
    },

    discardChanges: function() {
        this._updateDataState({ source: SOURCE.local, silent: true });
    },

    disconnect: async function() {
        this.off();

        // Make sure we finished editing before disconnecting
        await this.finishedEditing();
        this.adapter.disconnect();
    },

    destroy(options) {
        //Call Backbone's destroy instead of the StorageModel's destroy so we can call our delete api instead.
        Backbone.Model.prototype.destroy.call(this, options);
        this.destroyed = true;
        this.adapter.disconnect();
        //delete the slide via an endpoint.
        if ((!options || !options.remoteChange) && !this.disconnected) {
            return slidesApi.deleteSlide({ id: this.id });
        } else {
            return Promise.resolve({});
        }
    },

    finishedEditing: async function() {
        if (this.hasChanges && !this.isDetached) {
            this.hasChanges = false;

            const timestamp = new Date().getTime();

            try {
                StorageModel.prototype.update.call(this, { modifiedAt: timestamp });
                await this.updatePromise;

                const libraryItem = this.getLibraryItem();
                if (libraryItem) {
                    // When team slide is updated, update libraryItem metadata to reflect when and who updated
                    libraryItem.update({ contentModifiedAt: timestamp });
                    await libraryItem.updatePromise;
                }

                if (this.presentation && !this.presentation.disconnected) {
                    // If the slide is the first slide of its presentation
                    if (this.presentation.getSlideIndex(this.id) === 0) {
                        this.presentation.update({ firstSlideModifiedAt: timestamp });
                        await this.presentation.updatePromise;
                    }

                    // Fire and forget
                    NotificationsService.notifyOnSlideEdited(this.presentation.id, this.id)
                        .catch(err => logger.error(err, "[slide] finishedEditing() NotificationsService.notifyOnSlideEdited() failed", { id: this.id }));

                    // Fire and forget
                    Api.slideText.post({ slideId: this.id })
                        .catch(err => logger.error(err, "[slide] finishedEditing() Api.slideText.post() failed", { id: this.id }));
                }
            } catch (err) {
                logger.error(err, "[Slide] modifiedAt set failed", { id: this.id });

                ShowWarningDialog({
                    title: "Something went wrong.",
                    message: <>
                        <span>
                            There may be a temporary problem with your internet connection,<br />
                            or the permissions for this presentation may have changed.<br />
                            If the problem persists, please contact <a href="mailto:support@beautiful.ai">support@beautiful.ai</a>
                        </span>
                    </>,
                    acceptCallback: async () => {
                        if (this.presentation.invalid) {
                            //to ensure the presentation library shows the correct permission for the presentation refetch the user permissions
                            await this.presentation.getUserPermissions(true);
                        }
                        app.router.navigate("/", { trigger: true });
                    },
                });
            } finally {
                const {
                    template_recommendations: { enabled: hasTemplateRecommendations }
                } = await getExperiments(["template_recommendations"]);

                trackActivity("Slide", "ContentModified", null, null, {
                    "slide_id": this.id,
                    "slides_created": 0,
                    "source_presentation_id": this.presentation?.get("sourcePresentationId") || "",
                    "source_presentation_name": this.presentation?.get("sourcePresentationName") || "",
                    "is_from_recommended_template": this.presentation?.get("metadata")?.isFromRecommendedTemplate ?? false,
                    "object": "",
                    "object_label": "",
                    "action": "",
                    "experiment_id": "5FC18A79E182FE7C764425D4F852F501",
                    "experiment_group_assignment": hasTemplateRecommendations ? "variant-a" : "control"
                }, { skipAmplitude: true });
            }
        }
    },

    getSlideText() {
        return getSlideText(this.dataState.elements);
    },

    hasMultipleStages() {
        return this.get("template_id") === "textgrid_carousel";
    },

    getPlaybackStageCount() {
        if (this.hasMultipleStages()) {
            return this.dataState?.elements.primary.items?.length ?? 1;
        }
        return 1;
    },

    async getThumbnailUrl(presetationOrLinkId = null, size = "small", stageIndex = 0, preventModelLoad = false, fallbackTimestamp = null) {
        if (!THUMBNAIL_SIZES[size]) {
            throw new Error(`Unknown thumbnail size ${size}`);
        }

        if (window.isOfflinePlayer) {
            const url = `/userData/thumbnails/${this.id}${stageIndex === 0 ? "" : `-${stageIndex}`}`;
            await loadImage(url);
            return url;
        }

        if (!this.loaded && !preventModelLoad) {
            await this.load();
        }

        let timestamp;
        if (this.loaded) {
            timestamp = this.get("modifiedAt");
        } else if (fallbackTimestamp) {
            timestamp = fallbackTimestamp;
        } else {
            timestamp = this.instantiatedAt;
        }

        return Thumbnails.getSignedUrlAndLoad(this.id, timestamp, presetationOrLinkId, THUMBNAIL_SIZES[size].suffix, stageIndex);
    },

    async generateJpegs() {
        if (!this.disconnected) {
            await this.load();
        }

        const theme = await app.themeManager.loadThemeFromPresentation(this.presentation);

        const { channelId } = await Api.jpegs.post({
            slideModel: this.attributes,
            theme: theme.attributes,
            presentationId: this.presentation.id
        });

        const { urls } = await pusher.waitForEvent(channelId, TaskState.FINISHED, TaskState.ERROR, 2 * 60 * 1000);
        return urls;
    },

    exportSlideToJpeg() {
        trackActivity("Presentation", "JPEGExport", null, null, {
            workspace_id: this.presentation.getWorkspaceId()
        }, { audit: true });
        setGlobal({
            isLoadingDialogOpen: true
        });

        const dialog = ShowDialog(ProgressDialog, {
            title: "Exporting Slide to JPEG...",
        });

        const exportSlide = async () => {
            const urls = await this.generateJpegs();

            for (const [idx, url] of urls.entries()) {
                await downloadFromUrl(url,
                    filenameForExport({
                        name: this.presentation.get("name"),
                        assetType: "jpeg",
                        slideNum: this.getIndex() + 1,
                        secondarySlideNum: idx
                    }));
            }

            setGlobal({
                isLoadingDialogOpen: false
            });
            dialog.props.closeDialog();
        };

        exportSlide()
            .catch(err => {
                setGlobal({
                    isLoadingDialogOpen: false
                });
                dialog.props.closeDialog();
                logger.error(err, "[Slide] exportSlide() failed", { id: this.id });
            });
    },

    getIndex() {
        return this.presentation.getSlideIndex(this.id);
    },

    async toObject() {
        const attrs = await StorageModel.prototype.toObject.call(this);
        // Loading thumbnails of all sizes
        await Promise.all(Object.keys(THUMBNAIL_SIZES).map(size => this.getThumbnailUrl(this.presentation.id, size)));
        return attrs;
    },

    toText: function() {
        const text = [];

        function toText(obj) {
            if (typeof obj === "string" && obj !== "") {
                text.push(obj);
            } else if (_.isObject(obj)) {
                Object.keys(obj).forEach(key => {
                    if (!nonTextKeys[key]) {
                        toText(obj[key]);
                    }
                });
            }
        }

        toText(this.get("states"));
        text.push(this.get("slide_notes"));
        return text.join(" ");
    },

    isLibrarySlide: function() {
        return this.has("libraryItemId");
    },

    isLocked() {
        return this.isLibrarySlide();
    },

    isLibrarySlideInCurrentUserOrg: function() {
        const orgId = this.presentation.get("orgId");
        if (this.presentation.getWorkspaceId() !== "personal") {
            const defaultTeam = ds.teams.defaultTeamForOrg(orgId);
            if (defaultTeam.has("sharedResources") && defaultTeam.get("sharedResources")[1]) {
                if (defaultTeam.get("sharedResources")[1][this.get("libraryItemId")]) {
                    return true;
                }
            }
        }
        return false;
    },

    hasAudibleVideoAsset: function() {
        let hasAudibleVideo = false;
        const checkForVideo = object => {
            if (!object) {
                return;
            }

            if (!_.isObject(object)) {
                return;
            }

            if (
                object.videoAssetId ||
                object.videoId ||
                (
                    (object.content_type === AssetType.STOCK_VIDEO || object.content_type === AssetType.VIDEO) &&
                    object.content_value
                )
            ) {
                if (!object.assetProps?.muted) {
                    hasAudibleVideo = true;
                    return;
                }
            }

            Object.keys(object).forEach(key => {
                return checkForVideo(object[key]);
            });
        };
        checkForVideo(this.get("states"));

        return hasAudibleVideo;
    }
});

const Slides = Backbone.Collection.extend({
    model: Slide,

    url: function() {
        return "slides";
    },

    initialize: function() {
        this.type = "Slides";
    }
});

function getSlideDataStateFromAttributes(attributes, dataStateIndex = 0) {
    // Make sure elements is defined even if there are no
    // states because the canvas will be mutating it and will
    // crash if it is not an object
    let elements = {};
    const states = attributes.states;
    if (states && states[dataStateIndex]) {
        elements = _.cloneDeep(states[dataStateIndex]);
    }

    // Layout and animations have to be mutation-safe and always defined as well
    const layout = _.cloneDeep(attributes.layout ?? {});
    const animations = _.cloneDeep(attributes.animations ?? {});

    // This will contain a set of data which will be manipulated
    // by the canvas, so we have to make sure that mutating it is safe
    // hence clone deep elements and layout
    const dataState = {
        elements,
        layout,
        animations
    };

    // Pick the rest of the keys
    SLIDE_DATA_STATE_FIELDS.forEach(key => {
        if (key === "states" || key in dataState) {
            return;
        }
        dataState[key] = _.cloneDeep(attributes[key]);
    });

    return dataState;
}

export { getSlideDataStateFromAttributes, Slide, Slides };
