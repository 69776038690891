import _ from "lodash";

import { IGenericModelFields } from "../IGenericFields";

export enum WorkspaceUserGroupType {
    ALL_WORKSPACE_OWNERS = "all_workspace_owners",
    ALL_WORKSPACE_MEMBERS = "all_workspace_members",
    ALL_WORKSPACE_GUESTS = "all_workspace_guests",
    CUSTOM = "custom"
}

export enum ActionPermissionsObject {
    /** Analytics (e.g. player views, etc.) */
    ANALYTICS = "analytics",
    /** Billing (subscription, plan, payment, etc.) */
    BILLING = "billing",
    /** Brand guardrails */
    BRAND_GUARDRAILS = "brandGuardrails",
    /** Classic slides (authoring mode) */
    CLASSIC_SLIDES = "classicSlides",
    /** Convert slides to classic */
    CONVERT_TO_CLASSIC = "convertToClassic",
    /** Custom fonts */
    CUSTOM_FONTS = "customFonts",
    /** Custom presentation themes */
    CUSTOM_THEMES = "customThemes",
    /** Data linking */
    DATA_LINKING = "dataLinking",
    /** Desktop application (offline player) */
    DESKTOP_APP = "desktopApp",
    /** Presentation export (all types of export) */
    EXPORT_PRESENTATION = "exportPresentation",
    /** Export to editable PowerPoint */
    EXPORT_PRESENTATION_TO_EDITABLE = "exportPresentationToEditable",
    /** Collaboration with external workspaces */
    EXTERNAL_WORKSPACES_COLLABORATION = "externalWorkspacesCollaboration",
    /** Resource transfer to external workspaces */
    EXTERNAL_WORKSPACES_RESOURCE_TRANSFER = "externalWorkspacesResourceTransfer",
    /** AI-powered content generation */
    GENERATIVE_AI = "generativeAI",
    /** Import from PowerPoint */
    IMPORT_PPT = "importPPT",
    /** Inspiration slides gallery */
    INSPIRATION_SLIDES = "inspirationSlides",
    /** Third-party integrations */
    INTEGRATIONS = "integrations",
    /** Player branding customization */
    PLAYER_BRANDING = "playerBranding",
    /** Player settings */
    PLAYER_SETTINGS = "playerSettings",
    /** Presentation links expiration */
    PRESENTATION_LINKS_EXPIRATION = "presentationLinksExpiration",
    /** Presentation links disabling */
    PRESENTATION_LINKS_DISABLING = "presentationLinksDisabling",
    /** Remove BAI branding */
    REMOVE_BAI_BRANDING = "removeBaiBranding",
    /** Resource folders */
    RESOURCE_FOLDERS = "resourceFolders",
    /** Resources (all types, including personal presentations) */
    RESOURCES = "resources",
    /** Version history */
    REVISION_HISTORY = "revisionHistory",
    /** Secured links */
    SECURED_SHARING = "securedSharing",
    /** Shared resources (ones that are explicitly supposed to be shared) */
    SHARED_RESOURCES = "sharedResources",
    /** Single sign-on */
    SSO = "sso",
    /** Stock content libraries */
    STOCK_LIBRARIES = "stockLibraries",
    /** User groups */
    USER_GROUPS = "userGroups",
    /** Users (all types, including guests) */
    USERS = "users",
    /** Web image search and import */
    WEB_IMAGES = "webImages",
    /** Workspace guests */
    WORKSPACE_GUESTS = "workspaceGuests",
    /** Workspace name */
    WORKSPACE_NAME = "workspaceName",
}

export enum PermissionActionType {
    /** Use a stateless feature (e.g. import from PowerPoint, etc.), or use a specific type of resource */
    USE = "use",
    /** Create new resources governed by the feature */
    CREATE = "create",
    /** Manage a stateless feature (e.g. billing, integrations, etc.) */
    MANAGE = "manage"
}

/**
 * Represents the action permissions for a workspace user group.
 *
 * Note: if you're changing the name of an object, or adding a new object,
 * make sure to address all ts errors that arise from this.
 */
export interface IWorkspaceActionPermissions {
    [ActionPermissionsObject.ANALYTICS]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.BILLING]: {
        [PermissionActionType.MANAGE]: boolean;
    };
    [ActionPermissionsObject.BRAND_GUARDRAILS]: {
        [PermissionActionType.MANAGE]: boolean;
    };
    [ActionPermissionsObject.CLASSIC_SLIDES]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.CONVERT_TO_CLASSIC]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.CUSTOM_FONTS]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.CUSTOM_THEMES]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.DATA_LINKING]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.DESKTOP_APP]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.EXPORT_PRESENTATION]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.EXTERNAL_WORKSPACES_RESOURCE_TRANSFER]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.GENERATIVE_AI]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.IMPORT_PPT]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.INSPIRATION_SLIDES]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.INTEGRATIONS]: {
        [PermissionActionType.MANAGE]: boolean;
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.PLAYER_BRANDING]: {
        [PermissionActionType.MANAGE]: boolean;
    };
    [ActionPermissionsObject.PLAYER_SETTINGS]: {
        /** Ability to change global player settings for the workspace */
        [PermissionActionType.MANAGE]: boolean;
        /** Ability to change player settings for own/collaborated presentations */
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.PRESENTATION_LINKS_DISABLING]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.REMOVE_BAI_BRANDING]: {
        [PermissionActionType.USE]: boolean;
    };
    /** Manage, read, write are governed by resource permissions (IWorkspacePermission) */
    [ActionPermissionsObject.RESOURCE_FOLDERS]: {
        [PermissionActionType.CREATE]: boolean;
    };
    /** Manage, read, write are governed by resource permissions (IWorkspacePermission) */
    [ActionPermissionsObject.RESOURCES]: {
        [PermissionActionType.CREATE]: boolean;
    };
    [ActionPermissionsObject.REVISION_HISTORY]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.SECURED_SHARING]: {
        [PermissionActionType.USE]: boolean;
    };
    /**
     * Should be treated as a stateless feature, meaning the general ability to manage/use
     * team resources. The permissions to the actual resources are governed
     * by resource permissions (IWorkspacePermission), the ability to create resources
     * is governed by ActionPermissionsObject.RESOURCES
     */
    [ActionPermissionsObject.SHARED_RESOURCES]: {
        [PermissionActionType.MANAGE]: boolean;
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.SSO]: {
        [PermissionActionType.MANAGE]: boolean;
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.STOCK_LIBRARIES]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.USER_GROUPS]: {
        [PermissionActionType.MANAGE]: boolean;
        [PermissionActionType.CREATE]: boolean;
    };
    [ActionPermissionsObject.USERS]: {
        [PermissionActionType.MANAGE]: boolean;
    };
    [ActionPermissionsObject.WEB_IMAGES]: {
        [PermissionActionType.USE]: boolean;
    };
    [ActionPermissionsObject.WORKSPACE_GUESTS]: {
        [PermissionActionType.MANAGE]: boolean;
        [PermissionActionType.CREATE]: boolean;
    };
    [ActionPermissionsObject.WORKSPACE_NAME]: {
        [PermissionActionType.MANAGE]: boolean;
    };
}

export interface IWorkspaceUserGroup extends IGenericModelFields {
    readonly workspaceId: string;
    name: string;
    readonly type: WorkspaceUserGroupType;
    actionPermissions: IWorkspaceActionPermissions
}

export enum LegacyWorkspaceGroupIds {
    OWNERS = "owners",
    LIBRARIANS = "librarians",
    MEMBERS = "members",
    GUESTS = "guests"
}

export const LEGACY_WORKSPACE_GROUP_IDS_MAP = {
    [WorkspaceUserGroupType.ALL_WORKSPACE_OWNERS]: LegacyWorkspaceGroupIds.OWNERS,
    [WorkspaceUserGroupType.ALL_WORKSPACE_MEMBERS]: LegacyWorkspaceGroupIds.MEMBERS,
    [WorkspaceUserGroupType.ALL_WORKSPACE_GUESTS]: LegacyWorkspaceGroupIds.GUESTS,
    [WorkspaceUserGroupType.CUSTOM]: LegacyWorkspaceGroupIds.LIBRARIANS
};

////////////////////////////// DEFAULTS //////////////////////////////
const DEFAULT_WORKSPACE_OWNERS_USER_GROUP_ACTION_PERMISSIONS: IWorkspaceActionPermissions = {
    [ActionPermissionsObject.ANALYTICS]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.BILLING]: {
        [PermissionActionType.MANAGE]: true
    },
    [ActionPermissionsObject.BRAND_GUARDRAILS]: {
        [PermissionActionType.MANAGE]: true
    },
    [ActionPermissionsObject.CLASSIC_SLIDES]: {
        [PermissionActionType.USE]: true,
    },
    [ActionPermissionsObject.CONVERT_TO_CLASSIC]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.CUSTOM_FONTS]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.CUSTOM_THEMES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.DATA_LINKING]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.DESKTOP_APP]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.EXPORT_PRESENTATION]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.EXTERNAL_WORKSPACES_RESOURCE_TRANSFER]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.GENERATIVE_AI]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.IMPORT_PPT]: {
        [PermissionActionType.USE]: true,
    },
    [ActionPermissionsObject.INSPIRATION_SLIDES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.INTEGRATIONS]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.PLAYER_BRANDING]: {
        [PermissionActionType.MANAGE]: true
    },
    [ActionPermissionsObject.PLAYER_SETTINGS]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.PRESENTATION_LINKS_DISABLING]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.REMOVE_BAI_BRANDING]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.RESOURCE_FOLDERS]: {
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.RESOURCES]: {
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.REVISION_HISTORY]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.SECURED_SHARING]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.SHARED_RESOURCES]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.SSO]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.STOCK_LIBRARIES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.USER_GROUPS]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.USERS]: {
        [PermissionActionType.MANAGE]: true
    },
    [ActionPermissionsObject.WEB_IMAGES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.WORKSPACE_GUESTS]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.WORKSPACE_NAME]: {
        [PermissionActionType.MANAGE]: true
    }
};

const DEFAULT_WORKSPACE_MEMBERS_USER_GROUP_ACTION_PERMISSIONS: IWorkspaceActionPermissions = {
    [ActionPermissionsObject.ANALYTICS]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.BILLING]: {
        [PermissionActionType.MANAGE]: false
    },
    [ActionPermissionsObject.BRAND_GUARDRAILS]: {
        [PermissionActionType.MANAGE]: false
    },
    [ActionPermissionsObject.CLASSIC_SLIDES]: {
        [PermissionActionType.USE]: true,
    },
    [ActionPermissionsObject.CONVERT_TO_CLASSIC]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.CUSTOM_FONTS]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.CUSTOM_THEMES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.DATA_LINKING]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.DESKTOP_APP]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.EXPORT_PRESENTATION]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.EXTERNAL_WORKSPACES_RESOURCE_TRANSFER]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.GENERATIVE_AI]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.IMPORT_PPT]: {
        [PermissionActionType.USE]: true,
    },
    [ActionPermissionsObject.INSPIRATION_SLIDES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.INTEGRATIONS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.PLAYER_BRANDING]: {
        [PermissionActionType.MANAGE]: false
    },
    [ActionPermissionsObject.PLAYER_SETTINGS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.PRESENTATION_LINKS_DISABLING]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.REMOVE_BAI_BRANDING]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.RESOURCE_FOLDERS]: {
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.RESOURCES]: {
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.REVISION_HISTORY]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.SECURED_SHARING]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.SHARED_RESOURCES]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.SSO]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.STOCK_LIBRARIES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.USER_GROUPS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.USERS]: {
        [PermissionActionType.MANAGE]: false
    },
    [ActionPermissionsObject.WEB_IMAGES]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.WORKSPACE_GUESTS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: true
    },
    [ActionPermissionsObject.WORKSPACE_NAME]: {
        [PermissionActionType.MANAGE]: false
    }
};

const DEFAULT_WORKSPACE_GUESTS_USER_GROUP_ACTION_PERMISSIONS: IWorkspaceActionPermissions = {
    ...DEFAULT_WORKSPACE_MEMBERS_USER_GROUP_ACTION_PERMISSIONS,
    [ActionPermissionsObject.ANALYTICS]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.BRAND_GUARDRAILS]: {
        [PermissionActionType.MANAGE]: false
    },
    [ActionPermissionsObject.CONVERT_TO_CLASSIC]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.CUSTOM_FONTS]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.DESKTOP_APP]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.EXTERNAL_WORKSPACES_RESOURCE_TRANSFER]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.GENERATIVE_AI]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.IMPORT_PPT]: {
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.INTEGRATIONS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.PLAYER_BRANDING]: {
        [PermissionActionType.MANAGE]: false
    },
    [ActionPermissionsObject.PLAYER_SETTINGS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.PRESENTATION_LINKS_DISABLING]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.PRESENTATION_LINKS_EXPIRATION]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.REMOVE_BAI_BRANDING]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.RESOURCE_FOLDERS]: {
        [PermissionActionType.CREATE]: false
    },
    [ActionPermissionsObject.RESOURCES]: {
        [PermissionActionType.CREATE]: false
    },
    [ActionPermissionsObject.REVISION_HISTORY]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.SECURED_SHARING]: {
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.SHARED_RESOURCES]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.SSO]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.USE]: false
    },
    [ActionPermissionsObject.USER_GROUPS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: false
    },
    [ActionPermissionsObject.WORKSPACE_GUESTS]: {
        [PermissionActionType.MANAGE]: false,
        [PermissionActionType.CREATE]: false
    }
};

const DEFAULT_WORKSPACE_LIBRARIANS_USER_GROUP_ACTION_PERMISSIONS: IWorkspaceActionPermissions = {
    ...DEFAULT_WORKSPACE_MEMBERS_USER_GROUP_ACTION_PERMISSIONS,
    [ActionPermissionsObject.BRAND_GUARDRAILS]: {
        [PermissionActionType.MANAGE]: true
    },
    [ActionPermissionsObject.SHARED_RESOURCES]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.USE]: true
    },
    [ActionPermissionsObject.USER_GROUPS]: {
        [PermissionActionType.MANAGE]: true,
        [PermissionActionType.CREATE]: true
    }
};

const DEFAULT_WORKSPACE_RESTRICTED_ACTION_PERMISSIONS: IWorkspaceActionPermissions = _.cloneDeep(DEFAULT_WORKSPACE_MEMBERS_USER_GROUP_ACTION_PERMISSIONS);
Object.keys(DEFAULT_WORKSPACE_RESTRICTED_ACTION_PERMISSIONS).forEach(key => {
    Object.keys(DEFAULT_WORKSPACE_RESTRICTED_ACTION_PERMISSIONS[key]).forEach(action => {
        DEFAULT_WORKSPACE_RESTRICTED_ACTION_PERMISSIONS[key][action] = false;
    });
});

export const GET_DEFAULT_WORKSPACE_OWNERS_USER_GROUP = () => _.cloneDeep<Pick<IWorkspaceUserGroup, "actionPermissions" | "type" | "name">>({
    actionPermissions: DEFAULT_WORKSPACE_OWNERS_USER_GROUP_ACTION_PERMISSIONS,
    type: WorkspaceUserGroupType.ALL_WORKSPACE_OWNERS,
    name: "All Owners"
});

export const GET_DEFAULT_WORKSPACE_MEMBERS_USER_GROUP = () => _.cloneDeep<Pick<IWorkspaceUserGroup, "actionPermissions" | "type" | "name">>({
    actionPermissions: DEFAULT_WORKSPACE_MEMBERS_USER_GROUP_ACTION_PERMISSIONS,
    type: WorkspaceUserGroupType.ALL_WORKSPACE_MEMBERS,
    name: "All Members"
});

export const GET_DEFAULT_WORKSPACE_GUESTS_USER_GROUP = () => _.cloneDeep<Pick<IWorkspaceUserGroup, "actionPermissions" | "type" | "name">>({
    actionPermissions: DEFAULT_WORKSPACE_GUESTS_USER_GROUP_ACTION_PERMISSIONS,
    type: WorkspaceUserGroupType.ALL_WORKSPACE_GUESTS,
    name: "All Guests"
});

export const GET_DEFAULT_RESTRICTED_ACTION_PERMISSIONS = () => _.cloneDeep(DEFAULT_WORKSPACE_RESTRICTED_ACTION_PERMISSIONS);

/**
 * Used for legacy/migrated librarian members
 */
export const GET_DEFAULT_WORKSPACE_LIBRARIANS_USER_GROUP = () => _.cloneDeep<Pick<IWorkspaceUserGroup, "actionPermissions" | "type" | "name">>({
    actionPermissions: DEFAULT_WORKSPACE_LIBRARIANS_USER_GROUP_ACTION_PERMISSIONS,
    type: WorkspaceUserGroupType.CUSTOM,
    name: "Librarians"
});
