import { getCallable } from "../../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import type {
    GetWorkspaceResourceFoldersRequest,
    GetWorkspaceResourceFoldersResponse,
    UpdateWorkspaceResourceFolderRequest,
    UpdateWorkspaceResourceFolderResponse,
    DeleteWorkspaceResourceFolderRequest,
    DeleteWorkspaceResourceFolderResponse,
    CreateWorkspaceResourceFolderRequest,
    CreateWorkspaceResourceFolderResponse
} from "./types";

export { apiName };

const getWorkspaceResourceFolders = getCallable<GetWorkspaceResourceFoldersRequest, GetWorkspaceResourceFoldersResponse>(
    apiName,
    "getWorkspaceResourceFolders",
    {
        ...endpoints.getWorkspaceResourceFolders,
        composer: request => {
            const queryParams = new URLSearchParams();
            if (request.folderIds) {
                queryParams.set("folderIds", JSON.stringify(request.folderIds));
            }

            return {
                url: `/workspaces/${request.workspaceId}/resource-folders?${queryParams.toString()}`,
                options: {
                    method: "GET"
                }
            };
        }
    }
);

const updateWorkspaceResourceFolder = getCallable<UpdateWorkspaceResourceFolderRequest, UpdateWorkspaceResourceFolderResponse>(
    apiName,
    "updateWorkspaceResourceFolder",
    {
        ...endpoints.updateWorkspaceResourceFolder,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/resource-folders/${request.folderId}`,
                options: {
                    method: "PUT",
                    body: JSON.stringify(request)
                }
            };
        }
    }
);

const deleteWorkspaceResourceFolder = getCallable<DeleteWorkspaceResourceFolderRequest, DeleteWorkspaceResourceFolderResponse>(
    apiName,
    "deleteWorkspaceResourceFolder",
    {
        ...endpoints.deleteWorkspaceResourceFolder,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/resource-folders/${request.folderId}`,
                options: {
                    method: "DELETE"
                }
            };
        }
    }
);

const createWorkspaceResourceFolder = getCallable<CreateWorkspaceResourceFolderRequest, CreateWorkspaceResourceFolderResponse>(
    apiName,
    "createWorkspaceResourceFolder",
    {
        ...endpoints.createWorkspaceResourceFolder,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/resource-folders`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    }
);

export const callables = {
    getWorkspaceResourceFolders,
    updateWorkspaceResourceFolder,
    deleteWorkspaceResourceFolder,
    createWorkspaceResourceFolder
};

