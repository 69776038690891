import React, { useState, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

import getLogger from "js/core/logger";
import { Box } from "js/react/components/LayoutGrid";

const logger = getLogger();

const ClickInterceptor = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: transparent;
    pointer-events: auto;
`;

function isDateValid(date) {
    if (!date) {
        return false;
    }

    if (typeof date !== "object") {
        return false;
    }

    if (typeof date.getTime !== "function") {
        return false;
    }

    const time = date.getTime();
    if (typeof time !== "number") {
        return false;
    }

    return !isNaN(time);
}

const DatePickerComponent = ({ value, startDate, endDate, onChange, width = 100, selectsRange, onCalendarOpen, onCalendarClose, showTimeSelect, dateFormat, ...props }) => {
    const [_startDate, setStartDate] = useState(null);
    const [_endDate, setEndDate] = useState(null);
    const [_isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!_isOpen && value && !moment(value).isSame(_startDate)) {
            if (!value) {
                logger.warn("[DatePicker] value is not provided, will fallback to today's date");
                value = new Date();
            }

            if (!isDateValid(value)) {
                logger.warn("[DatePicker] value is not a valid date, will fallback to today's date", { value });
                value = new Date();
            }

            if (startDate && !isDateValid(startDate)) {
                logger.warn("[DatePicker] startDate is not a valid date, will fallback to today's date", { startDate });
                startDate = new Date();
            }

            if (endDate && !isDateValid(endDate)) {
                logger.warn("[DatePicker] endDate is not a valid date, will fallback to today's date", { endDate });
                endDate = new Date();
            }

            setStartDate(startDate ?? value);
            setEndDate(endDate);
        }
    }, [_isOpen, value, _startDate]);

    const handleDateChanged = useCallback(value => {
        if (selectsRange) {
            setStartDate(value[0]);
            setEndDate(value[1]);

            if (value[1]) {
                onChange(value[0], value[1]);
            }
        } else {
            setStartDate(value);
            onChange(value);
        }
    }, [selectsRange, onChange]);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
        onCalendarOpen && onCalendarOpen();
    }, [onCalendarOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        onCalendarClose && onCalendarClose();
    }, [onCalendarClose]);

    const handleInterceptorMouseDown = useCallback(event => {
        // This is hacky, we stop propagation of the React synthetic event (so it doesn't get intercepted by
        // any of the parents in React tree) but we let the native event propagate so that the date picker
        // outside click listener can catch it
        event.stopPropagation();
    }, []);

    if (isNaN(value?.getTime())) {
        logger.warn("[DatePicker] value is not a date, will fallback to today's date", { value });
        value = new Date();
    }

    return (
        <>
            <Box {...props}>
                <ReactDatePicker
                    selected={_startDate}
                    onChange={handleDateChanged}
                    startDate={_startDate}
                    endDate={_endDate}
                    onCalendarOpen={handleOpen}
                    onCalendarClose={handleClose}
                    popperContainer={({ children }) => createPortal(children, document.body)}
                    showTimeSelect={showTimeSelect}
                    dateFormat={dateFormat}
                    {...props}
                />
            </Box>
            {_isOpen && createPortal(<ClickInterceptor onMouseDown={handleInterceptorMouseDown} />, document.body)}
        </>
    );
};

const areEqual = (prevProps, nextProps) => {
    return (
        moment(prevProps.value).isSame(nextProps.value) &&
        moment(prevProps.startDate).isSame(nextProps.startDate) &&
        moment(prevProps.endDate).isSame(nextProps.endDate)
    );
};

export const DatePicker = React.memo(DatePickerComponent, areEqual);
