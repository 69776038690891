import { Icon } from "@material-ui/core";
import React from "react";

import { ActionPermissionsObject } from "common/interfaces";
import WorkspaceController from "js/controllers/WorkspaceController";

import { UpgradePlanDialogType } from "../views/MarketingDialogs/UpgradePlanDialog";
import { ShowUpgradeDialog } from "./Dialogs/BaseDialog";

export function WarningWatermark(props) {
    const {
        presentation,
        cta,
        notCentered,
    } = props;

    if (!presentation) {
        throw new Error("presentation is required");
    }

    if (!cta) {
        throw new Error("cta is required");
    }

    const stylePanel = {
        display: "flex",
        justifyContent: notCentered ? "start" : "center",
        alignItems: "center",
        padding: "20px",
        fontSize: "14px",
    };
    const styleIcon = {
        color: "#ffaa00",
        marginRight: "20px",
        fontSize: "32px",
    };

    const handleUpgrade = event => {
        event.stopPropagation();

        ShowUpgradeDialog({
            type: UpgradePlanDialogType.UPGRADE_PLAN,
            analytics: {
                cta,
                ...presentation.getAnalytics(),
            },
            workspaceId: WorkspaceController.workspaceId,
        });
    };

    const hasSmartSlides = presentation.hasSmartSlides() || !presentation.slides.length;
    const renderWarning =
        hasSmartSlides &&
        !WorkspaceController.actionPermissions[ActionPermissionsObject.REMOVE_BAI_BRANDING].use &&
        WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.REMOVE_BAI_BRANDING, "use");

    if (!renderWarning) {
        return null;
    }

    return (
        <div className="warning-watermark" style={stylePanel}>
            <Icon style={styleIcon}>auto_awesome</Icon>
            <span>
                <span>Smart Slides are a Pro feature and will include our watermark. </span>
                <a className="link-blue" onClick={handleUpgrade}>Upgrade to Pro</a>
                <span> to get unlimited access to Smart Slides and remove all watermarks.</span>
            </span>
        </div>
    );
}
