export class PopupBlockedError extends Error { }

class SelfClosingWindow extends Window {
    public lastSearch: string
}

export async function openWindowAndWaitForItToBeClosed(url: string): Promise<URLSearchParams> {
    const newWindow = (window.open(url, "_blank") as SelfClosingWindow);

    if (!newWindow) {
        throw new PopupBlockedError();
    }

    while (!newWindow.closed) {
        await new Promise(resolve => setTimeout(resolve, 500));
    }

    if (!newWindow.lastSearch) {
        return null;
    }

    return new URLSearchParams(newWindow.lastSearch);
}
