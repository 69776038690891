import React, { useEffect, useState } from "react";

export const useIntersectionObserver = (
    ref: React.RefObject<any>,
    init?: {
        root?: Element | Document | null;
        rootMargin?: string;
        threshold?: number | number[];
    }
) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            setIsIntersecting(entries[0].isIntersecting);
        }, init);
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, [ref]);

    return isIntersecting;
};
