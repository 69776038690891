import {
    Avatar,
    Button,
    ClickAwayListener,
    Grow,
    ListItem,
    MenuItem,
    OutlinedInput,
    Paper,
    Popper
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React, { Component } from "react";
import styled from "styled-components";

import { ActionPermissionsObject } from "common/interfaces";
import WorkspaceController from "js/controllers/WorkspaceController";
import { Key } from "js/core/utilities/keys";
import { createInviteSuggestionContext } from "js/react/components/InviteInput/InviteContext";
import { InviteSuggestions } from "js/react/components/InviteInput/InviteSuggestions";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { themeColors } from "js/react/sharedStyles";
import { _ } from "js/vendor";

// Styling selected menu items (hilited when selecting with up/down arrows)
const theme = {
    overrides: {
        MuiMenuItem: {
            root: {
                "&$selected": {
                    backgroundColor: "#eeeeee"
                }
            }
        }
    }
};

const AssignSlideOutlinedInput = styled(OutlinedInput)`
    &&& {
        width: 100%;
        border-radius: 2px;
        height: 40px;

        input::placeholder {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: bolder;
        }
    }
`;

const AssignedUserListItem = styled(ListItem)`
    &&& {
        display: flex;
        justify-content: center;
        flex-flow: column;
        margin-bottom: 10px;
        padding: 10px 20px 14px 14px;
        font-size: 13px;
        color: #444;

        > div {
            width: 100%;
            display: flex;
            align-items: center;
        }

        p {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 600;
            font-size: 13px;
            color: rgb(68, 68, 68);
            word-break: break-word;
            white-space: normal;
        }
    }
`;

const AssignUserListItem = styled(ListItem)`
    &&& {
        padding: 15px 20px 10px 14px;
        font-size: 13px;
        color: #444;
    }
`;

const AddCollaboratorMenuItem = styled(MenuItem)`
    &&& {
        padding: 14px 20px 14px 14px;
        font-size: 13px;
        color: #444;

        span {
            margin: 0 auto;
            text-transform: uppercase;
            letter-spacing: .6px;
            color: ${themeColors.ui_blue};
            font-weight: 600;
        }
    }
`;

const UnassignButton = styled(Button)`
    &&& {
        color: #666666;
        font-weight: bolder;
        margin: 0 auto;
    }
`;

const StyledMenuList = styled.ul`
    &&& {
        padding: 10px 0 10px 0;
    }
`;

const StyledAvatar = styled(Avatar)`
    &&& {
        margin-right: 10px;
    }
`;

const Divider = styled.hr`
    border: 1px solid #eeeeee;
`;

export class AssignCollaboratorOrTeammateDropdown extends Component {
    state = {
        inviteContext: null,
        selectedUserIndex: null,
        filteredCollaborators: [],
        filteredTeammates: []
    }

    componentDidMount() {
        const {
            anchorEl,
            presentation,
            filter = "",
        } = this.props;

        const prohibitExternalWorkspaceCollaboration = !WorkspaceController.actionPermissions[ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION].use;

        let inviteContext = createInviteSuggestionContext({
            labelCategoryNew: "Teammates",
            includeFolders: false,
            includeCurrentUser: true,
            presentationId: presentation.id,
            allowOldSelection: true,
            oldFirst: true,
            filter,
            prohibitExternalWorkspaceCollaboration,
            saveContext: inviteContext => this.setState({ inviteContext }),
        });

        this.setState({
            inviteContext,
        });

        if (anchorEl) {
            window.addEventListener("keydown", this.handleKeyDown);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            filter,
            anchorEl,
        } = this.props;
        let {
            inviteContext,
        } = this.state;

        if (!_.isEqual(prevProps.filter, filter)) {
            inviteContext.setFilter(filter);
        }

        if (anchorEl && !prevProps.anchorEl) {
            window.addEventListener("keydown", this.handleKeyDown);
            this.setState({ selectedUserIndex: null });
        } else if (!anchorEl && prevProps.anchorEl) {
            window.removeEventListener("keydown", this.handleKeyDown);
            this.setState({ selectedUserIndex: null });
        }
    }

    componentWillUnmount() {
        const { anchorEl } = this.props;
        if (anchorEl) {
            window.removeEventListener("keydown", this.handleKeyDown);
        }

        this.state.inviteContext.destroy();
    }

    handleKeyDown = event => {
        const {
            onAddCollaboratorClick,
        } = this.props;

        const {
            inviteContext,
        } = this.state;

        switch (event.keyCode) {
            case Key.ENTER:
            case Key.UP_ARROW:
            case Key.DOWN_ARROW:
                // Prevent further events
                event.stopImmediatePropagation();
                event.preventDefault();

                switch (event.keyCode) {
                    case Key.ENTER:
                        let item = inviteContext.selectPendingSelection();
                        // if item is null, it means that the user/email is not within the organization
                        if (item === null) {
                            onAddCollaboratorClick(inviteContext?.filter, inviteContext?.init);
                        } else {
                            this.handleSuggestionSelect(item);
                        }

                        break;
                    case Key.UP_ARROW:
                        inviteContext.pendingSelectPrev();
                        break;
                    case Key.DOWN_ARROW:
                        inviteContext.pendingSelectNext();
                        break;
                }
                break;
        }
    }

    handleInputFilterChange = event => {
        const {
            inviteContext,
        } = this.state;

        const filter = event.target.value;
        inviteContext.setFilter(filter);
    }

    handleSuggestionSelect = item => {
        const { onSelect } = this.props;
        const { inviteContext } = this.state;
        onSelect && onSelect(item);

        inviteContext.clearSelection();
    }

    renderContent() {
        const {
            onClose,
            onAddCollaboratorClick,
            onUnassignClick,
            assignedUser = null,
            showAssignUserInput = false,
            showInviteCollaboratorButton = false,
            sourceName
        } = this.props;

        const {
            inviteContext,
        } = this.state;

        return (
            <Paper>
                <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
                    <StyledMenuList className="assign-slide-container">
                        {assignedUser &&
                            <>
                                <AssignedUserListItem>
                                    <div testing-data-assigned-email={assignedUser.email}>
                                        <StyledAvatar src={assignedUser.photoURL} />
                                        <div>
                                            <p>{assignedUser.displayName || assignedUser.email}</p>
                                            {assignedUser.isPending &&
                                                <p style={{ fontWeight: 600, color: "#a9a9a9" }}>Pending sign-up</p>
                                            }
                                        </div>
                                    </div>
                                    <UnassignButton onClick={onUnassignClick}>Unassign</UnassignButton>
                                </AssignedUserListItem>
                                <Divider />
                            </>
                        }
                        {showAssignUserInput &&
                            <AssignUserListItem>
                                <AssignSlideOutlinedInput
                                    autoFocus={true}
                                    onChange={this.handleInputFilterChange}
                                    value={inviteContext?.filter}
                                    placeholder={assignedUser ? "Reassign slide..." : "Assign slide..."}
                                />
                            </AssignUserListItem>
                        }
                        {
                            inviteContext &&
                            <>
                                {!!inviteContext.filteredAllSuggestions.length && (assignedUser || showAssignUserInput) && <Divider />}
                                <InviteSuggestions
                                    inviteContext={inviteContext}
                                    sourceName={sourceName}
                                    showUpgradeCTA={false}
                                    onSelect={this.handleSuggestionSelect}
                                />
                                {!!inviteContext.filteredAllSuggestions.length && showInviteCollaboratorButton && <Divider />}
                            </>
                        }
                        {showInviteCollaboratorButton && (
                            <AddCollaboratorMenuItem onClick={() => onAddCollaboratorClick(inviteContext?.filter, inviteContext?.init)}>
                                <span>Add Collaborator</span>
                            </AddCollaboratorMenuItem>
                        )}
                    </StyledMenuList>
                </ClickAwayListener>
            </Paper>
        );
    }

    render() {
        const { anchorEl, usePopper = true } = this.props;

        return (
            <MuiThemeProvider theme={dialogTheme}>
                <MuiThemeProvider theme={theme}>
                    {usePopper && (
                        <Popper
                            style={{ zIndex: 3, width: "300px" }}
                            open={!!anchorEl}
                            anchorEl={anchorEl}
                            transition
                        >
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    {this.renderContent()}
                                </Grow>
                            )}
                        </Popper>
                    )}
                    {!usePopper && this.renderContent()}
                </MuiThemeProvider>
            </MuiThemeProvider>
        );
    }
}
