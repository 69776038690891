import React, { useState, useEffect, forwardRef } from "react";
import { Stripe } from "@stripe/stripe-js";

import getLogger, { LogGroup } from "js/core/logger";
import { getStripe } from "js/core/services/stripe";

const logger = getLogger(LogGroup.STRIPE);

type InjectedProps = { stripe: Stripe };
type WrapperProps<T extends React.ComponentType<any>> = Omit<React.ComponentProps<T>, keyof InjectedProps> & Partial<InjectedProps>;

export default function withStripe<T extends React.ComponentType<any>>(Component: T) {
    return forwardRef<T, WrapperProps<T>>(function ComponentWithStripe(props, componentRef) {
        if (props.stripe) {
            // Already passed
            // @ts-ignore
            return <Component {...props} ref={componentRef} />;
        }

        const [stripe, setStripe] = useState<Stripe | null>(null);

        useEffect(() => {
            getStripe()
                .then(stripe => setStripe(stripe))
                .catch(err => logger.error(err, "withStripe() failed to load Stripe.js"));
        }, []);

        if (!stripe) {
            return null;
        }

        // @ts-ignore
        return <Component {...props} stripe={stripe} ref={componentRef} />;
    });
}
