import { getCallable } from "../../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import type {
    GetWorkspacePermissionsRequest,
    GetWorkspacePermissionsResponse,
    SetWorkspacePermissionsRequest,
    SetWorkspacePermissionsResponse,
    GetUserPermissionsToResourceRequest,
    GetUserPermissionsToResourceResponse,
    GetUserActionPermissionsRequest,
    GetUserActionPermissionsResponse
} from "./types";

export { apiName };

const getWorkspacePermissions = getCallable<GetWorkspacePermissionsRequest, GetWorkspacePermissionsResponse>(
    apiName,
    "getWorkspacePermissions",
    {
        ...endpoints.getWorkspacePermissions,
        composer: request => {
            const searchParams = new URLSearchParams();
            if (request.object) {
                searchParams.set("object", JSON.stringify(request.object));
            }

            return {
                url: `/workspaces/${request.workspaceId}/permissions?${searchParams.toString()}`,
                options: {
                    method: "GET"
                }
            };
        }
    }
);

const setWorkspacePermissions = getCallable<SetWorkspacePermissionsRequest, SetWorkspacePermissionsResponse>(
    apiName,
    "setWorkspacePermissions",
    {
        ...endpoints.setWorkspacePermissions,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/permissions`,
                options: {
                    method: "PUT",
                    body: JSON.stringify(request)
                }
            };
        }
    }
);

const getUserPermissionsToResource = getCallable<GetUserPermissionsToResourceRequest, GetUserPermissionsToResourceResponse>(
    apiName,
    "getUserPermissionsToResource",
    {
        ...endpoints.getUserPermissionsToResource,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/permissions/${encodeURIComponent(request.resourceType)}/${encodeURIComponent(request.resourceId)}`,
                options: {
                    method: "GET"
                }
            };
        }
    }
);

const getUserActionPermissions = getCallable<GetUserActionPermissionsRequest, GetUserActionPermissionsResponse>(
    apiName,
    "getUserActionPermissions",
    {
        ...endpoints.getUserActionPermissions,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/action-permissions`,
                options: {
                    method: "GET"
                }
            };
        }
    }
);

export const callables = {
    getWorkspacePermissions,
    setWorkspacePermissions,
    getUserPermissionsToResource,
    getUserActionPermissions
};
