import type { EndpointDefinition } from "../../_sdk/types/common";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getWorkspaceStripeData: {
        route: "/workspaces/:workspaceId/billing/stripe",
        method: "GET"
    },
    getWorkspacePlanData: {
        route: "/workspaces/:workspaceId/billing/plan",
        method: "GET"
    },
    cancelSubscription: {
        route: "/workspaces/:workspaceId/billing/subscriptions/cancel",
        method: "POST"
    },
    reactivateSubscription: {
        route: "/workspaces/:workspaceId/billing/subscriptions/reactivate",
        method: "POST"
    },
    switchSubscriptionBillingInterval: {
        route: "/workspaces/:workspaceId/billing/subscriptions/interval",
        method: "POST"
    },
    previewUpcomingInvoiceForBillingInterval: {
        route: "/workspaces/:workspaceId/billing/invoices/preview/billing-interval",
        method: "GET"
    },
    previewInvoicesForQuantity: {
        route: "/workspaces/:workspaceId/billing/invoices/preview/quantity",
        method: "GET"
    },
    updatePaymentMethod: {
        route: "/workspaces/:workspaceId/billing/payment-method",
        method: "POST"
    },
    payLatestInvoiceOnPastDueSubscription: {
        route: "/workspaces/:workspaceId/billing/subscriptions/pay-latest-invoice",
        method: "POST"
    },
    endTrialOnSubscription: {
        route: "/workspaces/:workspaceId/billing/subscriptions/end-trial",
        method: "POST"
    },
    applyPromotionCodeToSubscription: {
        route: "/workspaces/:workspaceId/billing/subscriptions/promotion-code",
        method: "POST"
    },
    updateBillingDetails: {
        route: "/workspaces/:workspaceId/billing/customers/billing-details",
        method: "POST"
    },
    updateSubscriptionQuantity: {
        route: "/workspaces/:workspaceId/billing/subscriptions/quantity",
        method: "POST"
    },
    finalizeUpdateSubscriptionQuantity: {
        route: "/workspaces/:workspaceId/billing/subscriptions/quantity-finalize",
        method: "POST"
    }
};
