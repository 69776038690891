import React, { Component } from "react";
import { Button, DialogActions, FormControlLabel, RadioGroup, Radio, TextField, DialogTitle, DialogContent, FormControl } from "@material-ui/core";

import { _ } from "js/vendor";
import { app } from "js/namespaces";
import { Slide } from "js/core/models/slide";
import DummyAdapter from "js/core/storage/dummyAdapter";

import { Gap10 } from "js/react/components/Gap";
import { Field } from "js/react/components/UiComponents";
import TagsList from "js/react/components/TagsList";
import AddTagsDialog from "js/react/views/UserOptions/dialogs/AddTagsDialog";
import { BeautifulDialog, ShowDialog, ShowErrorDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import styled from "styled-components";
import { toLower, trim, uniqBy } from "lodash";
import { TeamResourceTypes } from "js/react/views/TeamResources/TeamResources";
import AppController from "js/core/AppController";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";

const RadioLabel = styled.div`
  font-size: 14px;
  margin-left: 31px;
  margin-bottom: 20px;
  color: #666;
  margin-top: -5px;
`;

const RadioButtonControl = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 18px;
    font-weight: 600;
  }
`;

export class CreateSharedSlideDialog extends Component {
    state = {
        name: "",
        description: "",
        tags: [],
        slideType: TeamResourceTypes.SLIDE_TEMPLATES,
        fetching: false
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { resourceType } = this.props;
        if (resourceType) {
            this.setState({
                slideType: resourceType
            });
        }
    }

    handleAddTag = () => {
        //get all tags from user's teams
        let existingTags = [];
        const defaultTeam = AppController.currentTeam;
        defaultTeam.libraryItems.forEach(libraryItem => {
            existingTags.push(Object.keys(libraryItem.get("tags") || {}));
        });

        //remove duplicates
        existingTags = uniqBy(existingTags.flat().map(trim), toLower);

        ShowDialog(AddTagsDialog, {
            activeTags: this.state.tags,
            existingTags,
            acceptCallback: newTags => {
                this.setState({
                    tags: newTags,
                });
            },
        });
    }

    handleRemoveTag = inputTag => {
        this.setState(({ tags }) => ({ tags: tags.filter(tag => tag !== inputTag) }));
    }

    handleConfirm = async () => {
        const { slide, presentation, onBeforeSaveSlide, closeDialog } = this.props;
        const { name, description, tags, slideType } = this.state;

        if (name.trim() === "") {
            return ShowErrorDialog({ title: "Please enter a name for your team slide" });
        }

        try {
            this.setState({ fetching: true });

            let createdSlide;
            // If the slide is not saved or we are creating a Team Slide Template then will create and save a new slide
            if (slide.adapter instanceof DummyAdapter || slideType == TeamResourceTypes.SLIDE_TEMPLATES) {
                const slideAttributes = _.cloneDeep(slide.attributes);
                slideAttributes.isTeamSlideTemplate = slideType == TeamResourceTypes.SLIDE_TEMPLATES;
                delete slideAttributes.id;
                delete slideAttributes.presentationId;
                delete slideAttributes.libraryItemId;
                createdSlide = new Slide(slideAttributes, { userId: app.user.id, autoSync: false });
                await slide.load();
            } else {
                createdSlide = slide;
            }

            // Before committing a slide, perform any updates
            onBeforeSaveSlide && onBeforeSaveSlide(createdSlide);

            const libraryItemModel = {
                name, description,
                tags: tags.reduce((tags, tag) => ({ ...tags, [tag]: true }), {}),
                type: "slides",
                isTemplate: slideType == TeamResourceTypes.SLIDE_TEMPLATES,
                template_id: slide.get("template_id"),
                contentId: createdSlide.id,
            };

            let slideUpdatePromise;
            if (presentation) {
                // if we are sharing an existing slide as a TeamSlide, trigger a model change so it switches a TeamSlide in the current presentation
                // If updating an existing slide then we'll wait for the changes to propagate to the slide
                slideUpdatePromise = createdSlide === slide
                    ? new Promise(resolve => createdSlide.once("change:libraryItemId", resolve))
                    : Promise.resolve();
                libraryItemModel.usedInPresentations = {
                    [presentation.id]: true
                };
                libraryItemModel.usedInPublicPresentations = {
                    [presentation.id]: presentation.get("public") === true ? true : null
                };
                libraryItemModel.usedByUsers = {
                    [app.user.id]: true
                };
            }

            await AppController.currentTeam.libraryItems.createLibraryItem(libraryItemModel, createdSlide, AppController.orgId);

            // Wait for the changes to propagate (if needed)
            if (slideUpdatePromise) {
                await slideUpdatePromise;
            }

            closeDialog();
        } catch (err) {
            this.setState({ fetching: false });
            ShowWarningDialog({ title: "An error occurred while sharing your slide", message: err });
        }
    }

    render() {
        const { closeDialog } = this.props;
        const { name, description, tags, fetching, slideType } = this.state;
        const selected = slideType === TeamResourceTypes.SLIDE_TEMPLATES ? TeamResourceTypes.SLIDE_TEMPLATES : TeamResourceTypes.SHARED_SLIDES;

        return (
            <BeautifulDialog maxWidth="md" fullWidth={false} closeDialog={closeDialog} preventClose={fetching}>
                <FetchingClickShield visible={fetching} backgroundColor="rgba(255, 255, 255, 0.7)" />
                <DialogTitle>Share this slide with your team</DialogTitle>
                <DialogContent style={{ paddingLeft: 100, overflowY: "auto" }}>
                    <Field title="Slide Name">
                        <TextField
                            id="slide-name"
                            value={name}
                            placeholder="Enter a name for the team slide"
                            fullWidth
                            onChange={event => this.setState({ name: sanitizeHtmlText(event.target.value) })}
                        />
                    </Field>
                    <Field title="Add a description or instructions for this slide (optional)">
                        <TextField multiline
                            fullWidth
                            value={description}
                            placeholder="Type a description here"
                            onChange={event => this.setState({ description: sanitizeHtmlText(event.target.value) })}
                            inputProps={{
                                onBlur: event => this.setState({ description: event.target.value })
                            }}
                        />
                    </Field>
                    <Field title="Add Tags for easier searching (optional)">
                        <Gap10 />
                        <TagsList
                            tags={tags}
                            onRemove={this.handleRemoveTag}
                            onClick={this.handleAddTag}
                        />
                    </Field>

                    <Field title="How do you want to share this slide with your team?">
                        <Gap10 />
                        <FormControl>
                            <RadioGroup
                                value={selected}
                                defaultValue={TeamResourceTypes.SLIDE_TEMPLATES}
                                name="share-type"
                                style={{ marginLeft: 20 }}
                                onChange={event => this.setState({ slideType: event.target.value })}
                            >
                                <RadioButtonControl className="shared-template" control={<Radio />} label="Create Slide Template" value={TeamResourceTypes.SLIDE_TEMPLATES} />
                                <RadioLabel>An editable slide for your team to customize.</RadioLabel>
                                <RadioButtonControl className="shared-slides" control={<Radio />} label="Create Shared Slide" value={TeamResourceTypes.SHARED_SLIDES} />
                                <RadioLabel>A finished, uneditable slide. Team Librarians can edit Shared Slides and automatically update all instances.</RadioLabel>
                            </RadioGroup>
                        </FormControl>
                    </Field>
                </DialogContent>

                <DialogActions>
                    <Button id="cancelButton" onClick={closeDialog}>
                        Cancel
                    </Button>
                    <Button id="okButton" onClick={this.handleConfirm} color="primary">
                        Share Slide
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
