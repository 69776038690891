import { getCallable } from "../../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import type {
    GetWorkspaceUserInvitesRequest,
    GetWorkspaceUserInvitesResponse,
    ResendWorkspaceUserInviteRequest,
    ResendWorkspaceUserInviteResponse,
    GetWorkspaceUserInviteUrlRequest,
    GetWorkspaceUserInviteUrlResponse,
    DeleteWorkspaceUserInviteRequest,
    DeleteWorkspaceUserInviteResponse,
    UpdateWorkspaceUserInviteRequest,
    UpdateWorkspaceUserInviteResponse,
    InviteWorkspaceUsersRequest,
    InviteWorkspaceUsersResponse
} from "./types";

const getWorkspaceUserInvites = getCallable<GetWorkspaceUserInvitesRequest, GetWorkspaceUserInvitesResponse>(
    apiName,
    "getWorkspaceUserInvites",
    {
        ...endpoints.getWorkspaceUserInvites,
        composer: request => {
            const searchParams = new URLSearchParams();
            if (request.emails) {
                searchParams.set("emails", JSON.stringify(request.emails));
            }
            if (request.includeAccepted) {
                searchParams.set("includeAccepted", "true");
            }

            return {
                url: `/workspaces/${request.workspaceId}/userInvites?${searchParams.toString()}`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const getWorkspaceUserInviteUrl = getCallable<GetWorkspaceUserInviteUrlRequest, GetWorkspaceUserInviteUrlResponse>(
    apiName,
    "getWorkspaceUserInviteUrl",
    {
        ...endpoints.getWorkspaceUserInviteUrl,
        composer: request => ({
            url: `/workspaces/${request.workspaceId}/userInvites/${request.inviteId}/url`,
            options: {
                method: "GET"
            }
        })
    }
);

const resendWorkspaceUserInvite = getCallable<ResendWorkspaceUserInviteRequest, ResendWorkspaceUserInviteResponse>(
    apiName,
    "resendWorkspaceUserInvite",
    {
        ...endpoints.resendWorkspaceUserInvite,
        composer: request => ({
            url: `/workspaces/${request.workspaceId}/userInvites/${request.inviteId}/resend`,
            options: {
                method: "POST"
            }
        })
    });

const deleteWorkspaceUserInvite = getCallable<DeleteWorkspaceUserInviteRequest, DeleteWorkspaceUserInviteResponse>(
    apiName,
    "deleteWorkspaceUserInvite",
    {
        ...endpoints.deleteWorkspaceUserInvite,
        composer: request => ({
            url: `/workspaces/${request.workspaceId}/userInvites/${request.inviteId}`,
            options: {
                method: "DELETE"
            }
        })
    });

const updateWorkspaceUserInvite = getCallable<UpdateWorkspaceUserInviteRequest, UpdateWorkspaceUserInviteResponse>(
    apiName,
    "updateWorkspaceUserInvite",
    {
        ...endpoints.updateWorkspaceUserInvite,
        composer: request => ({
            url: `/workspaces/${request.workspaceId}/userInvites/${request.inviteId}`,
            options: {
                method: "PUT",
                body: JSON.stringify(request.update)
            }
        })
    }
);

const inviteWorkspaceUsers = getCallable<InviteWorkspaceUsersRequest, InviteWorkspaceUsersResponse>(
    apiName,
    "inviteWorkspaceUsers",
    {
        ...endpoints.inviteWorkspaceUsers,
        composer: request => ({
            url: `/workspaces/${request.workspaceId}/userInvites`,
            options: {
                method: "POST",
                body: JSON.stringify(request)
            }
        })
    }
);

export const callables = {
    getWorkspaceUserInvites,
    getWorkspaceUserInviteUrl,
    resendWorkspaceUserInvite,
    deleteWorkspaceUserInvite,
    updateWorkspaceUserInvite,
    inviteWorkspaceUsers
};
