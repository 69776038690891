import { v4 as uuid } from "uuid";

import { isOfflinePlayer, isCypress, clientName, buildVersion, isStorybook } from "js/config";

import Logger from "js/core/logger/Logger";
import ConsoleTransport from "js/core/logger/transports/ConsoleTransport";
import ApiTransport from "js/core/logger/transports/ApiTransport";
import { LoggerTransportContext, LogEntryContext, LogGroup } from "js/core/logger/types";
import { auth } from "js/firebase/auth";

// Id of the current session (gets reset on page refresh and bundle reload)
// used as trace id
const sessionId = uuid();

// Logging client id, basically browser id
let browserId = localStorage.getItem("bai-browser-id");
if (!browserId) {
    browserId = uuid();
    localStorage.setItem("bai-browser-id", browserId);
}

// Transports we use
const transportContext: LoggerTransportContext = {
    sessionId,
    browserId,
    clientName,
    clientVersion: buildVersion,
    userAgent: navigator.userAgent
};

const transports = [new ConsoleTransport(transportContext)];
if (!isOfflinePlayer && !isCypress && !isStorybook) {
    transports.push(new ApiTransport(transportContext));
}

// Custom entries context generator
function getLogEntryContext(): LogEntryContext {
    return {
        uid: isOfflinePlayer ? undefined : auth().currentUser?.uid,
        location: window.location.origin + window.location.pathname,
    };
}

// Loggers, will be lazy initialized (except for window logger)
const loggers: Partial<Record<LogGroup, Logger>> = {
    [LogGroup.WINDOW]: new Logger(transports, getLogEntryContext, LogGroup.WINDOW)
};

/**
 * Returns a logger for the specified logging group
 */
function getLogger(group: LogGroup = LogGroup.DEFAULT) {
    if (!loggers[group]) {
        loggers[group] = new Logger(transports, getLogEntryContext, group);
    }

    return loggers[group];
}

export default getLogger;

export {
    sessionId,
    browserId,
    getLogger,
    LogGroup
};
