import React, { Component } from "react";
import styled from "styled-components";

import { themeColors } from "js/react/sharedStyles";

import { Icon } from "./Icon";
import { ToolTip } from "./ToolTip";
import { WithLabel } from "./WithLabel";

interface StyledWithLabelProps {
    className: string;
    label: string;
    below: boolean;
    center: boolean;
}

const RoundedContainer = styled.div<{ size: number }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    flex-shrink: 0;

    color: #fff;
    background: ${themeColors.ui_blue};

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    pointer-events: auto;

    .bai-icon {
        font-size: 20px;
        color: white !important;
        transform: scale(${props => props.size / 40});
    }
`;

const StyledWithLabel = styled(WithLabel)<StyledWithLabelProps>`
    &&& {
        cursor: pointer;

        label {
            cursor: pointer;
        }
    }
`;

const IconButtonContainer = styled.div<{ hasLabel: boolean; dimmed: boolean; size: number, disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${props => props.hasLabel ? "flex-start" : "center"};
    gap: 10px;
    min-width: 30px;
    height: 30px;

    font-family: Source Sans Pro;
    font-weight: 600;
    letter-spacing: 0.025em;
    
    cursor: pointer;
    pointer-events: ${props => props.disabled ? "none" : "auto"};
    
    &:hover {
        background: white;
    }
    
    .bai-icon {
        color: ${themeColors.ui_blue};
        opacity: ${props => props.dimmed ? 0.5 : 1};
        font-size: ${props => props.size || 20}px;
    }
`;

const IconContainer = styled.div`
    background: ${themeColors.ui_blue};
    border-radius: 50%;
    padding: 5px;
    .bai-icon {
        color: white;
    }
`;

interface IconButtonProps {
    className?: string;
    icon?: React.ReactNode | string;
    size?: any;
    label?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    color?: string;
    fill?: boolean;
    dimmed?: boolean;
    tooltip?: string;
    contained?: boolean;
    disabled?: boolean;
    [key: string]: any;
}

export class IconButton extends Component<IconButtonProps> {
    render() {
        const { className = "", icon, size, label, onClick, color = themeColors.ui_blue, fill, dimmed, tooltip, contained, disabled } = this.props;

        let labelComponent, iconComponent;

        let children = React.Children.toArray(this.props.children);
        labelComponent = label ?? children.find(child => typeof child === "string");
        const iconChild = children.find(child =>
            React.isValidElement(child) && child.type === Icon
        );
        iconComponent = React.cloneElement(
            icon ? <Icon color={color}>{icon}</Icon> : (iconChild as React.ReactElement),
            { fill, dimmed }
        );
        let hasLabel = labelComponent ? true : false;

        if (contained) {
            iconComponent = <IconContainer style={{ padding: (size ?? 20) / 3, background: color }}>{iconComponent}</IconContainer>;
        }

        let Content = (
            <IconButtonContainer className={`bai-icon-button ${className}`} onClick={!disabled ? onClick : null} hasLabel={hasLabel} dimmed={dimmed} size={size} disabled={disabled}>
                {iconComponent}
                {labelComponent}
            </IconButtonContainer>
        );

        if (tooltip) {
            return (
                <ToolTip title={tooltip} enterDelay={700}>
                    {Content}
                </ToolTip>
            );
        }

        return Content;
    }
}

interface RoundIconButtonProps extends IconButtonProps {
    "data-test-id"?: string;
}

export class RoundIconButton extends Component<RoundIconButtonProps> {
    render() {
        const { className = "", icon, label, onClick, onMouseUp, color = themeColors.ui_blue, size = 40, dimmed = false, fill, tooltip, "data-test-id": dataTestId } = this.props;

        const iconProps = {
            color,
            fill,
            opacity: dimmed ? 0.5 : 1,
        };

        let Content = (
            <>
                {label && (
                    // @ts-ignore
                    <StyledWithLabel label={label} below center className={className}>
                        <RoundedContainer onClick={onClick} onMouseUp={onMouseUp} size={size} data-test-id={dataTestId}>
                            <Icon {...iconProps}>{icon}</Icon>
                        </RoundedContainer>
                    </StyledWithLabel>
                )}
                {!label && (
                    <RoundedContainer onClick={onClick} onMouseUp={onMouseUp} className={className} size={size} data-test-id={dataTestId}>
                        <Icon {...iconProps}>{icon}</Icon>
                    </RoundedContainer>
                )}
            </>
        );

        if (tooltip) {
            return (
                <ToolTip title={tooltip} enterDelay={700}>
                    {Content}
                </ToolTip>
            );
        }

        return Content;
    }
}

