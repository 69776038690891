import React, { Children, Component, createContext, useContext, useRef } from "react";
import styled from "styled-components";

import { Popover } from "@material-ui/core";

import getLogger from "js/core/logger";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { Gap5 } from "js/react/components/Gap";
import { stopPropagation } from "js/core/utilities/stopPropagation";
import { themeColors } from "js/react/sharedStyles";

import { Icon } from "./Icon";
import { ToolTip } from "./ToolTip";
import { PropertyPanelContainer, PropertySectionList } from "../EditorComponents/PropertyPanel";

const logger = getLogger();

const Container = styled.div.attrs(({ disabled }) => ({
    style: {
        // pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.5 : 1
    }
}))`
    align-items: center;
    cursor: pointer;
    pointer-events: auto;
    //height: 100%;
    min-height: 30px;
    background: none;
    border: none;
    color: currentColor;
    display: flex;
    padding: 0px;

    &.contained {
        background: white;
        border: solid 1px #ccc;
        padding: ${props => props.showArrow ? "2px 5px 3px 10px" : "5px"};

        &:hover {
            background: ${themeColors.rollover};
        }
        
        label {
            color: #333;
        }
    }

    label {
        cursor: pointer;
        font-family: "Source Sans Pro";
        font-size: 13px;
        font-weight: 600;
    }

    .drop-down-arrow {
        .bai-icon {
            font-size: 24px !important;
        }
    }

    button.MuiButtonBase-root > span.MuiButton-label {
        color: white;
    }

    // &:hover {
    //     .bai-icon {
    //         color: ${themeColors.ui_blue};
    //     }
    // }
`;

export const PopupContainer = styled.div`
    //padding: 5px 0px;
    display: grid;
    grid-auto-rows: max-content;
    grid-gap: 0px;
    width: ${({ width }) => width ? `${width}px` : "auto"};
    
    & > div, & > label {
        padding: 10px 20px;
    }

    & > button {
        margin: 10px;
    }

    label {
        color: #333;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.03em;
    }

    hr {
        background: #ddd;
        width: 100%;
        height: 1px;
        border: none;
    }
`;

const StyledPopupContent = styled.div`
    width: ${props => (props.width + "px") ?? "auto"};
    ${PropertyPanelContainer} {
        padding: 0px;
        background: white !important;
    }

    ${PropertySectionList} {
        padding: 15px 20px;
    }

    .MuiMenuItem-root {
        padding: 15px 20px 15px 15px;
        font-family: "Source Sans Pro";
        font-size: 13px;
        font-weight: 600;

        .icon {
            margin-right: 6px;
            color: rgb(119, 119, 119);
            font-size: 20px;
            vertical-align: -6px;
        }
    }

    .MuiFormControlLabel-label {
        font-family: "Source Sans Pro";
        font-size: 13px;
        font-weight: 600;
        text-transform: none;
    }
`;

const popupContext = createContext();

export function PopupPreview({ children }) {
    const closePopup = useContext(popupContext);

    return typeof children === "function"
        ? children(closePopup)
        : (children ?? null);
}

export function PopupContent({ children, width = null }) {
    const closePopup = useContext(popupContext);
    const shouldStopPropagationRef = useRef(true);

    const handleMouseEvent = event => {
        const { type, target } = event;
        // Special case for MUI sliders and
        const isSliderThumb = type === "mousedown" && target?.classList?.contains("MuiSlider-thumb");
        // Special case for ChromePicker
        const isChromePicker = type === "mousemove" && target?.closest(".chrome-picker");

        if (shouldStopPropagationRef.current) {
            stopPropagation()(event);
        }

        if (isSliderThumb || isChromePicker) {
            shouldStopPropagationRef.current = false;
            return;
        }

        if (type === "mouseup" && !shouldStopPropagationRef.current) {
            shouldStopPropagationRef.current = true;
        }

        if (type === "mousedown") {
            // Prevent focus change
            event.preventDefault();
            event.nativeEvent.preventDefault();
        }
    };

    return (
        <StyledPopupContent
            onMouseDown={handleMouseEvent}
            onMouseMove={handleMouseEvent}
            onMouseUp={handleMouseEvent}
            onClick={handleMouseEvent}
            width={width}
        >
            {
                typeof children === "function"
                    ? children(closePopup)
                    : (children ?? null)
            }
        </StyledPopupContent>
    );
}

export class Popup extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.state = {
            show: false,
            anchorEl: null,
            anchorPosition: { left: 100, top: 100 }
        };
    }

    showPopup = () => {
        if (this.props.disabled) return;

        PresentationEditorController.setPopupState(true);
        // Workaround - without it, the first popup of the session appears in the wrong place
        setTimeout(() => {
            PresentationEditorController.setPopupState(true);
        }, 1);
        this.setState({
            show: true,
            anchorEl: this.ref.current,
        });

        this.props.onShow?.();
    };

    closePopup = () => {
        this.setState({ anchorEl: null, show: false });
        PresentationEditorController.setPopupState(false);
        this.props.onClose?.();
    };

    updatePopupPosition = () => {
        this.setState({ anchorEl: null }, () => {
            this.setState({ anchorEl: this.ref.current });
        });
    };

    handlePreviewMouseDown = event => {
        // Prevent focus change
        event.preventDefault();
        event.nativeEvent.preventDefault();

        this.props.onPreviewMouseDown?.(event);
    };

    render() {
        const {
            icon,
            label,
            showArrow = false,
            contained,
            position,
            children,
            tooltip,
            disabled = false,
            disabledTooltip,
            overrideContainerStyles = {},
            onPreviewMouseDown = () => { },
            onPopoverMouseDown = () => { }
        } = this.props;
        const { anchorEl,
            anchorPosition } = this.state;

        let popupPosition = {};
        if (position == "above") {
            popupPosition = {
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center"
                },
                transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                }
            };
        } else {
            popupPosition = {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "center"
                }
            };
        }

        const childrenAsArray = Children.toArray(children);
        const Preview = childrenAsArray.find(child => child.type === PopupPreview) ?? null;
        const Content = childrenAsArray.find(child => child.type === PopupContent) ?? null;

        if (!Content) {
            logger.warn("Popup was rendered without PopupContent");
        }

        const { Provider } = popupContext;

        return (
            <>
                <ToolTip
                    title={disabled ? disabledTooltip : tooltip}
                    placement="bottom"
                >
                    <Container
                        ref={this.ref}
                        className={`bai-popup ${contained ? "contained" : ""}`}
                        onClick={stopPropagation(this.showPopup)}
                        onMouseDown={this.handlePreviewMouseDown}
                        showArrow={showArrow}
                        disabled={disabled}
                        style={{
                            ...overrideContainerStyles
                        }}
                    >
                        {icon && <Icon>{icon}</Icon>}
                        {icon && label && <Gap5 />}
                        {label && <label>{label}</label>}
                        {Preview}
                        {showArrow && <Icon className="drop-down-arrow">arrow_drop_down</Icon>}
                    </Container>
                </ToolTip>

                <Popover
                    id="id"
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    disableEnforceFocus
                    disableAutoFocus
                    disableRestoreFocus
                    {...popupPosition}
                    onClose={this.closePopup}
                    anchorReference="anchorEl"
                    anchorPosition={anchorPosition}
                    onMouseDown={onPopoverMouseDown}
                >
                    <Provider value={this.closePopup}>
                        {Content}
                    </Provider>
                </Popover>
            </>
        );
    }
}

